import React, { Component } from 'react'
import { Col, Form, Input, Switch } from 'antd'

import TooltipIcon from '../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../utils/enums-tooltips'
import Description from '../../reusables/components/description'
import GroupNameContainer from '../../reusables/containers/group-name-container'
import SolutionsContainer from '../../reusables/containers/solutions-container'
import MemoryStore from '../../utils/memory-store'

class BatchActionsFormGeneral extends Component {
  constructor (props) {
    super(props)

    this.entry = props.entry

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc
    }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.entry.data[key] = value
  }

  render () {
    return (
      <Col xs={23} lg={20} xl={14}>
        <div>
          <Form.Item>
            <Switch
              ref='isActive'
              disabled={MemoryStore.userProfile.teamPrivileges.batchactions === 'Reader'}
              checkedChildren='Active'
              unCheckedChildren='Inactive'
              defaultChecked={this.entry.data.isActive}
              onChange={(e) => {
                this.onFieldChange('isActive', e)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Profile Key '}
            <TooltipIcon title={EnumsTooltips.general.profileKey} />
            <Input
              name='key'
              placeholder='Provide a unique Profile Key'
              disabled={!this.state.isNewDoc || MemoryStore.userProfile.teamPrivileges.batchactions === 'Reader'}
              defaultValue={this.entry.data.key}
              onChange={e => {
                this.onFieldChange('key', e.target.value)
              }}
              required
            />
          </Form.Item>
          <Description
            disabled={MemoryStore.userProfile.teamPrivileges.batchactions === 'Reader'}
            description={this.entry.data.description}
            onChange={e => {
              this.onFieldChange('description', e.target.value)
            }}
          />
          <GroupNameContainer
            disabled={MemoryStore.userProfile.teamPrivileges.batchactions === 'Reader'}
            defaultValue={this.entry.data.groupName}
            onValueChange={value => {
              this.onFieldChange('groupName', value)
            }}
            onValueSelect={value => {
              this.onFieldChange('groupName', value)
            }}
          />
          <SolutionsContainer
            disabled={MemoryStore.userProfile.teamPrivileges.batchactions === 'Reader'}
            defaultValue={this.entry.data.solutions}
            onValueChange={(value) => {
              this.onFieldChange('solutions', value)
            }}
          />
        </div>
      </Col>
    )
  }
}

export default BatchActionsFormGeneral
