import React from 'react'
import MemoryStore from '../../../utils/memory-store'
import Enums from '../../../utils/enums'

import { Tabs, Card, Button, Form, message, Row, Col, Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

/*
Custom Components Import
*/
import DataMappingFormGeneral from './data-mapping-form-general'
import FieldMappingsContainer from '../../containers/field-mappings-container'
import MultiEntryMappingsContainer from '../../containers/multi-entry-mappings-container'
import SourceDetailsJson from './src-details/src-details-json'
import SourceDetailsExcel from './src-details/src-details-excel'
import DestinationDetailsJson from './des-details/des-details-json'
import DestinationDetailsExcel from './des-details/des-details-excel'

const TabPane = Tabs.TabPane

class DataMappingForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]

    this.state = {
      sourceType: this.entry.data.sourceType,
      destinationType: this.entry.data.destinationType,
      isLoading: false
    }

    this.onFieldChange = this.onFieldChange.bind(this)
    this.updateArrayData = this.updateArrayData.bind(this)
    this.cancelProfileConfirm = this.cancelProfileConfirm.bind(this)
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  cancelProfileConfirm () {
    if (this.entry.custom.isModified) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  saveProfile () {
    const tmpThis = this

    tmpThis.setState({
      isLoading: true
    })

    // Reset isModified and isModifiedSubform properties
    this.entry.custom.isModified = false
    this.entry.custom.isModifiedSubform = false

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, (err) => {
      tmpThis.setState({
        isLoading: false
      })

      if (err) {
        message.error(err)
      } else {
        tmpThis.props.closeTab(tmpThis.props.tabKey, tmpThis.props.tabKey, tmpThis.props.tabs)
      }
    })
  }

  onFieldChange (key, value) {
    if (key === 'sourceType') {
      this.setState({
        sourceType: value
      })
    }

    if (key === 'destinationType') {
      this.setState({
        destinationType: value
      })
    }

    this.entry.custom.isModified = true
    this.entry.data[key] = value
  }

  updateArrayData (data) {
    this.entry.custom.isModified = true
    this.entry.data.fieldMappings = data
  }

  render () {
    return (
      <Row type='flex' justify='center'>
        <Col xs={23} lg={20} xl={16}>
          <Form
            name='data-mapping-form'
          >
            <Card
              title={this.props.title}
              type='inner'
              style={{textAlign: 'left'}}
            >
              <Tabs
                defaultActiveKey='1'
                animated={false}
              >
                <TabPane tab='General' key='1'>
                  <DataMappingFormGeneral onChange={this.onFieldChange} tabKey={this.props.tabKey} />
                </TabPane>
                <TabPane tab='Source Details' key='2'>
                  {this.state.sourceType === '1' ? <SourceDetailsJson onChange={this.onFieldChange} tabKey={this.props.tabKey} state={this.state} /> :null}
                  {this.state.sourceType === '2' ? <SourceDetailsExcel onChange={this.onFieldChange} tabKey={this.props.tabKey} state={this.state} /> :null}
                </TabPane>
                <TabPane tab='Destination Details' key='3'>
                  {this.state.destinationType === '1' ? <DestinationDetailsJson onChange={this.onFieldChange} tabKey={this.props.tabKey} state={this.state} /> :null}
                  {this.state.destinationType === '2' ? <DestinationDetailsExcel onChange={this.onFieldChange} theme={this.props.theme} tabKey={this.props.tabKey} state={this.state} /> :null}
                </TabPane>
                <TabPane tab='Field Mappings' key='4'>
                  <FieldMappingsContainer tabKey={this.props.tabKey} updateArrayData={this.updateArrayData} data={this.entry.data.fieldMappings} profile={this.entry.data} />
                </TabPane>
                <TabPane tab='Multi Entry Mappings' key='5'>
                  <MultiEntryMappingsContainer tabKey={this.props.tabKey} entry={this.entry.data} />
                </TabPane>
              </Tabs>
              <Row>
                <div style={{ marginTop: 10 }}>
                  {MemoryStore.userProfile.teamPrivileges.datamappings !== 'Reader' ?
                    <Button
                      name='submit'
                      disabled={this.state.isLoading}
                      htmlType='submit'
                      style={{ backgroundColor:'#67AD5B', color:'white', width: 80, marginRight: 10 }}
                      onClick={() => this.saveProfile()}
                    >
                      {this.state.isLoading ? <LoadingOutlined spin /> : <span>Submit</span>}
                    </Button>
                    :null}
                  <Button
                    name='cancel'
                    disabled={this.state.isLoading}
                    label='Cancel'
                    style={{ backgroundColor:'#EB5181', color:'white', width: 'auto', marginRight: 10 }}
                    onClick={e => {
                      e.preventDefault()
                      this.cancelProfileConfirm()
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Row>
            </Card>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default DataMappingForm
