import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { activateUser, validatePassword, verifyRecaptchaToken, verifyRecaptchaTokenv2 } from '../core-actions'
import CoreTeamActivate from '../components/core-team-activate'

const mapStateToProps = state => {
  return {
    theme: state.core.theme
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      activateUser,
      validatePassword,
      verifyRecaptchaToken,
      verifyRecaptchaTokenv2
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoreTeamActivate)
