import { formatRecordData } from '../utils/utilities'
import MemoryStore from '../utils/memory-store'
import SolutionsFormContainer from '../solutions/containers/solutions-form-container'
import Enums from '../utils/enums'

export const filterViewData = (viewData, searchFilter) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return viewData
  } else {
    return viewData.filter(t =>
      (
        (t.data.name.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.key.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.description.toLowerCase().indexOf(searchFilter) > -1)
      )
    )
  }
}

export const formatRecord = (entry, data) => {
  let tempIndex = 0

  entry.key = entry._id // Needed for View's Unique key requirement
  entry = formatRecordData(entry)
  entry.data.isActiveDescription = entry.data.isActive ? 'Yes' : 'No' // Needed for View Column

  if (entry.custom.isNewDoc) {
    // Add new Entry to State
    entry.custom.isNewDoc = false
    entry.custom.action = 'create'
    data.push(entry)
  } else {
    if (entry.custom.isSavedDoc) {
      entry.custom.action = 'update'
    } else {
      entry.custom.action = 'create'
    }

    // Update Entry in State
    tempIndex = data.findIndex(t => t._id === entry._id)
    data[tempIndex] = entry
  }

  return data
}

export const getSolutions = () => {
  return MemoryStore.agilite.AdminSolutions.getData()
}

export const createCopy = (currEntryData, callback) => {
  const newEntry = JSON.parse(JSON.stringify(MemoryStore.solutions.dataTemplate))
  newEntry._id = new Date().getTime().toString()
  newEntry.custom.tempId = newEntry._id

  const entryData = JSON.parse(JSON.stringify(currEntryData))
  const tabType = Enums.VALUES_STRINGS.FORM
  const content = SolutionsFormContainer
  const closable = true
  const key = newEntry._id
  const app = Enums.APP_IDS.SOLUTIONS

  let title = Enums.APP_PROFILE_TITLES.solutions + Enums.VALUES_STRINGS.COLON + Enums.VALUES_STRINGS.SPACE
  let payload = {}

  // create unique key
  entryData.key = currEntryData.key + '_copy'
  entryData.name = currEntryData.name + ' (Copy)'

  newEntry.data = entryData

  title += entryData.key

  payload = {
    content,
    title,
    key,
    app,
    tabType,
    closable
  }

  MemoryStore.activeEntries[newEntry._id] = newEntry

  callback(payload)
}
