import { formatRecordData } from '../utils/utilities'

export const filterViewData = (viewData, searchFilter) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return viewData
  } else {
    return viewData.filter(
      t =>
        t.data.name.toLowerCase().indexOf(searchFilter) > -1 ||
        t.data.key.toLowerCase().indexOf(searchFilter) > -1 ||
        t.data.description.toLowerCase().indexOf(searchFilter) > -1
    )
  }
}

export const filterBotProcesses = (data, searchFilter, callback) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return callback(data)
  } else {
    return callback(
      data.filter(
        t =>
          t.name.toLowerCase().indexOf(searchFilter) > -1 ||
          t.description.toLowerCase().indexOf(searchFilter) > -1 ||
          t.key.toLowerCase().indexOf(searchFilter) > -1
      )
    )
  }
}

export const formatRecord = (entry, data) => {
  let tempIndex = 0

  entry = formatRecordData(entry)
  entry.data.isActiveDescription = entry.data.isActive ? 'Yes' : 'No' // Needed for View Column

  if (entry.custom.isNewDoc) {
    // Add new Entry to State
    entry.custom.isNewDoc = false
    entry.custom.action = 'create'
    data.push(entry)
  } else {
    if (entry.custom.isSavedDoc) {
      entry.custom.action = 'update'
    } else {
      entry.custom.action = 'create'
    }

    // Update Entry in State
    tempIndex = data.findIndex(t => t._id === entry._id)
    data[tempIndex] = entry
  }

  return data
}
