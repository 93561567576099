import React from 'react'
import { Form, AutoComplete } from 'antd'

export default class GroupName extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      groupNames: []
    }
  }

  UNSAFE_componentWillMount () {
    this.generateGroupNameList()
  }

  generateGroupNameList () {
    const tmpArray = []

    this.props.state.data.map(record => {
      if (!tmpArray.some(entry => entry.value === record.data.groupName) && record.data.groupName !== '' && record.data.groupName) {
        tmpArray.push({ label: record.data.groupName, value: record.data.groupName })
      }

      return tmpArray
    })

    this.setState({
      groupNames: tmpArray
    })
  }

  render () {
    return (
      <Form.Item>
        Group Name (optional)
        <AutoComplete
          cypressid='groupName'
          className='group_name'
          placeholder='Used to group Profiles'
          disabled={this.props.disabled}
          defaultValue={this.props.defaultValue}
          options={this.state.groupNames}
          onSelect={this.props.onValueSelect}
          onChange={this.props.onValueChange}
          filterOption={(inputValue, option) => {
            return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }}
        />
      </Form.Item>
    )
  }
}
