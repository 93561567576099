import React from 'react'
import { Form, Select, message } from 'antd'
import { getSolutions } from '../../solutions/solutions-utils'
import Enums from '../../utils/enums'

const { Option } = Select

export default class Solutions extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      solutions: []
    }
  }

  UNSAFE_componentWillMount () {
    this.generateSolutions()
  }

  generateSolutions () {
    const tmpArray = []

    getSolutions()
      .then(res => {
        res.data.map(solution => {
          tmpArray.push(<Option key={solution.data.key}>{solution.data.name}</Option>)

          return tmpArray
        })

        this.setState({
          solutions: tmpArray
        })
      })
      .catch(err => {
        if (err.response) {
          message.error(err.response.data.errorMessage)
        } else {
          message.error(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }

  render () {
    return (
      <Form.Item>
        {this.props.title ? this.props.title : 'Solution(s) (optional)'}
        <Select
          cypressid='solutions'
          mode='multiple'
          style={{ width: '100%' }}
          placeholder='Used to link solutions'
          defaultValue={this.props.defaultValue}
          onChange={this.props.onValueChange}
          disabled={this.props.disabled}
          className='solutions'
        >
          {this.state.solutions}
        </Select>
      </Form.Item>
    )
  }
}
