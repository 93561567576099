import React, { Component } from 'react'
import { Modal, message } from 'antd'
import { columnTemplates } from '../../utils/templates'
import agiliteTheme from '../../utils/agilite-theme'
import Enums from '../../utils/enums'
import MemoryStore from '../../utils/memory-store'
import { v1 } from 'agilite-utils/uuid'

import ListViewDND from './list-view-dnd'
import ActionForm from '../../batch-actions/components/action-form'

class BatchActions extends Component {
  constructor (props) {
    super(props)

    this.entry = props.entry
    this.data = props.data

    this.state = {
      entry: props.data,
      dialogOpen: false,
      activeRecord: null,
      newAction: false
    }

    // Setup Event Binding
    this.createRecord = this.createRecord.bind(this)
    this.saveRecord = this.saveRecord.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.deleteRecord = this.deleteRecord.bind(this)
    this.handleValidateRecord = this.handleValidateRecord.bind(this)
    this.formatViewData = this.formatViewData.bind(this)
    this.handleCloseModalDialog = this.handleCloseModalDialog.bind(this)
    this.handleCloseModalDialogConfirm = this.handleCloseModalDialogConfirm.bind(this)
    this.updateArrayData = this.updateArrayData.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.formatViewData()
  }

  UNSAFE_componentWillUpdate () {
    this.formatViewData()
  }

  createRecord () {
    const activeRecord = JSON.parse(JSON.stringify(MemoryStore.batchactions.batchActionTemplate))
    activeRecord.isNewEntry = true
    activeRecord._id = v1()

    this.setState({
      dialogOpen: true,
      activeRecord,
      newAction: true
    })
  }

  saveRecord () {
    const activeRecord = JSON.parse(JSON.stringify(this.state.activeRecord))
    const entry = this.props.data
    const index = entry.findIndex(t => t._id === this.state.activeRecord._id)

    if (activeRecord.isNewEntry) {
      entry.push(activeRecord)
    } else {
      entry.splice(index, 1, activeRecord)
    }

    this.setState({
      entry,
      dialogOpen: false,
      activeRecord: null
    })
  }

  editRecord (recordId) {
    const index = this.state.entry.findIndex(t => t._id === recordId)
    const activeRecord = JSON.parse(JSON.stringify(this.state.entry[index]))

    activeRecord.isNewEntry = false
    this.setState({ dialogOpen: true, activeRecord, newAction: false })
  }

  deleteRecord (recordId) {
    this.entry.custom.isModifiedSubform = true

    const entry = this.props.data
    const index = entry.findIndex(t => t._id === recordId)

    entry.splice(index, 1)
    this.setState({ entry })
  }

  handleValidateRecord () {
    let success = true
    let errMsg = null

    if (this.state.activeRecord.actionType === '') {
      errMsg = 'Please provide a Action Type'
      success = false
    } else if (this.state.activeRecord.actionType === 'connectors/execute') {
      if (!this.state.activeRecord.profileKey.trim()) {
        errMsg = 'Please provide a Profile Key'
        success = false
      } else if (!this.state.activeRecord.routeKey.trim()) {
        errMsg = 'Please provide a Route Key'
        success = false
      } else if (this.state.activeRecord.storeResponseDataAs && this.state.activeRecord.storeResponseDataAs === 'add') {
        if (!this.state.activeRecord.propName || this.state.activeRecord.propName === '') {
          errMsg = 'Please provide a Property Name'
          success = false
        }
      }
    }

    if (success) {
      this.saveRecord()
    } else {
      message.error(errMsg)
    }

    return null
  }

  formatViewData () {
    // Set actionTypeDescription
    this.state.entry.map(action => {
      action.isActiveDescription = action.isActive ? 'Yes' : 'No'
      switch (action.actionType) {
        case 'connectors/execute':
          action.actionTypeDescription = 'Connectors - Execute'
          break
        default:
          break
      }
      return action.actionTypeDescription
    })

    // Set isActiveDescription
    this.state.entry.map(action => {
      action.isActiveDescription = action.isActive ? 'Yes' : 'No'
      return action.isActiveDescription
    })
  }

  handleCloseModalDialog () {
    this.entry.custom.isModifiedSubform = false

    this.setState({
      dialogOpen: false,
      activeRecord: null
    })
  }

  handleCloseModalDialogConfirm () {
    if (this.entry.custom.isModifiedSubform) {
      Modal.confirm({
        onOk: () => this.handleCloseModalDialog(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.handleCloseModalDialog()
    }
  }

  updateArrayData (data) {
    this.entry.custom.isModified = true
    this.entry.data.actions = data

    this.setState({
      entry: this.entry.data.actions
    })
  }

  render () {
    return (
      <div style={{ marginBottom: 10 }}>
        <ListViewDND
          btnCreateLabel='Create New'
          viewTitle='Actions'
          createRecord={this.createRecord}
          editRecord={this.editRecord}
          deleteRecord={this.deleteRecord}
          filterViewData={this.props.filterViewData}
          viewData={this.state.entry}
          viewColumns={columnTemplates.batchAction}
          theme={agiliteTheme}
          callback={this.updateArrayData}
        />
        {this.state.activeRecord ?
          <Modal
            title={this.state.newAction ? 'New Action' : `Action ${this.state.activeRecord.actionType}`}
            okButtonProps={{
              style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' }
            }}
            okText='Ok'
            visible={this.state.dialogOpen}
            onOk={this.handleValidateRecord}
            onCancel={this.handleCloseModalDialogConfirm}
            width={1050}
            style={{ top: 10 }}
            destroyOnClose
            maskClosable={false}
          >
            <ActionForm
              action={this.state.activeRecord}
              data={this.props.entry}
            />
          </Modal>
          : null}
      </div>
    )
  }
}

export default BatchActions
