import React from 'react'

import { Row, Modal, Col, message } from 'antd'
import MemoryStore from '../../../utils/memory-store'

import BotProcessesForm from '../bot-processes/bot-processes-form'
import ListViewDND from '../../../reusables/components/list-view-dnd'
import { columnTemplates } from '../../../utils/templates'

class BotProcesses extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      entry: props.entry,
      dialogOpen: false,
      activeRecord: null,
      bpmState: null
    }

    // Setup Event Binding
    this.createRecord = this.createRecord.bind(this)
    this.saveRecord = this.saveRecord.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.deleteRecord = this.deleteRecord.bind(this)
    this.handleValidateRecord = this.handleValidateRecord.bind(this)
    this.formatViewData = this.formatViewData.bind(this)
    this.handleCloseModalDialog = this.handleCloseModalDialog.bind(this)
    this.updateArrayData = this.updateArrayData.bind(this)
    this.formatData = this.formatData.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.formatViewData()
    this.props.fetchAllData('bpm', (success, error) => {
      if (error) {
        message.error(error)
      }
    })
  }

  UNSAFE_componentWillUpdate () {
    this.formatViewData()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({ bpmState: nextProps.state.bpm })
  }

  createRecord () {
    const activeRecord = JSON.parse(JSON.stringify(MemoryStore.botbuilder.botProcessTemplate))
    activeRecord.isNewEntry = true
    activeRecord._id = new Date().getTime().toString()
    this.setState({ dialogOpen: true, activeRecord })
  }

  saveRecord () {
    const entry = this.props.entry
    const index = entry.data.botProcesses.findIndex(
      t => t._id === this.state.activeRecord._id
    )

    if (this.state.activeRecord.isNewEntry) {
      entry.data.botProcesses.push(this.state.activeRecord)
    } else {
      entry.data.botProcesses.splice(index, 1, this.state.activeRecord)
    }

    this.setState({
      entry,
      dialogOpen: false,
      activeRecord: null
    })
  }

  editRecord (recordId) {
    const index = this.state.entry.data.botProcesses.findIndex(
      t => t._id === recordId
    )

    const activeRecord = JSON.parse(
      JSON.stringify(this.state.entry.data.botProcesses[index])
    )

    activeRecord.isNewEntry = false
    this.setState({ dialogOpen: true, activeRecord })
  }

  deleteRecord (recordId) {
    const entry = this.props.entry
    const index = entry.data.botProcesses.findIndex(t => t._id === recordId)

    entry.data.botProcesses.splice(index, 1)
    this.setState({ entry })
  }

  handleValidateRecord () {
    if (this.state.activeRecord.processKey === '') {
      return message.error('Please provide a Process Key')
    }

    if (this.state.activeRecord.name === '') {
      return message.error('Please provide a Process Name')
    }

    this.saveRecord()
  }

  formatViewData () {
    // Set isActiveDescription
    this.state.entry.data.botProcesses.map(process => {
      process.isActiveDescription = process.isActive ? 'Yes' : 'No'
      return process.isActiveDescription
    })

    // Set processTypeDescription
    this.state.entry.data.botProcesses.map(process => {
      switch (process.processType) {
        case '1':
          process.processTypeDescription = 'BPM Bot'
          break
        default:
      }

      return true
    })
  }

  handleCloseModalDialog () {
    this.setState({ dialogOpen: false, activeRecord: null })
  }

  updateArrayData (data) {
    this.props.entry.data.botProcesses = data
    this.setState({ entry: this.props.entry })
  }

  formatData (data) {
    for (const x in data) data[x].key = data[x].processKey
    return data
  }

  render () {
    return (
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <ListViewDND
            btnCreateLabel='Create New'
            viewTitle='Bot Processes'
            createRecord={this.createRecord}
            editRecord={this.editRecord}
            deleteRecord={this.deleteRecord}
            filterViewData={this.props.filterViewData}
            viewData={this.formatData(this.state.entry.data.botProcesses)}
            viewColumns={columnTemplates.botProcesses}
            theme={this.props.theme}
            callback={this.updateArrayData}
          />
          <Modal
            title='Bot Process'
            okButtonProps={{
              style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' }
            }}
            okText='Ok'
            visible={this.state.dialogOpen}
            onOk={this.handleValidateRecord}
            onCancel={this.handleCloseModalDialog}
            width={1000}
            style={{ top: 10 }}
            destroyOnClose
            maskClosable={false}
          >
            <BotProcessesForm
              entry={this.state.activeRecord}
              theme={this.props.theme}
              bpmState={this.state.bpmState}
            />
          </Modal>
        </Col>
      </Row>
    )
  }
}

export default BotProcesses
