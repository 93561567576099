import React, { useState } from 'react'

import { Row, Col, Form, Input, Card, Menu, Dropdown, Modal, message, List } from 'antd'
import { Icon } from 'react-fa'
import { changePassword } from '../core-actions'

import MemoryStore from '../../utils/memory-store'
import PasswordValidationItem from '../../reusables/components/password-validation-item'

const MenuItem = Menu.Item

const data = [
  {
    property: 'min',
    description: 'Minimum of 8 characters',
    complete: false
  },
  {
    property: 'symbols',
    description: 'Contains a symbol',
    complete: false
  },
  {
    property: 'digits',
    description: 'Contains a number',
    complete: false
  },
  {
    property: 'uppercase',
    description: 'Contains an uppercase letter',
    complete: false
  },
  {
    property: 'password',
    description: 'Passwords must match',
    complete: false
  }
]

const CoreUserProfile = ({ validatePassword }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [passwordValid, setPasswordValid] = useState({ value: false })

  const entry = MemoryStore.userProfile
  const tmpEntry = MemoryStore.registerForm

  const menu = (
    <Menu>
      <MenuItem key='0' onClick={() => setModalVisible(true)}>
        <span style={{ padding: '10 0' }}>Change Password</span>
      </MenuItem>
    </Menu>
  )

  const onChange = (key, value) => {
    switch (key) {
      case 'password':
        tmpEntry.password = value

        validatePassword(data, tmpEntry.password, (result) => {
          result.map(element => {
            data.map(obj => {
              if (obj.property === element) {
                obj.complete = false
              }
              return true
            })
            return true
          })

          // Check if passwords match
          if (tmpEntry.password === tmpEntry.passwordConfirm && tmpEntry.password !== '') {
            data[4].complete = true
          } else {
            data[4].complete = false
          }

          if (result.length === 0 && tmpEntry.passwordConfirm === tmpEntry.password) {
            setPasswordValid(JSON.parse(JSON.stringify({ value: true })))
          } else {
            setPasswordValid(JSON.parse(JSON.stringify({ value: false })))
          }
        })
        break
      case 'passwordConfirm':
        tmpEntry.passwordConfirm = value

        // Check if passwords match
        if (tmpEntry.passwordConfirm === tmpEntry.password && tmpEntry.passwordConfirm !== '') {
          data[4].complete = true
          setPasswordValid(JSON.parse(JSON.stringify({ value: true })))
        } else {
          data[4].complete = false
          setPasswordValid(JSON.parse(JSON.stringify({ value: false })))
        }
        break
      case 'currentPassword':
        tmpEntry.currentPassword = value
        break
      default:
    }
  }

  const onPasswordChange = () => {
    changePassword((result, errMsg) => {
      if (!result) {
        message.error(errMsg)
      } else if (result) {
        message.success('Password successfully changed')
        setModalVisible(false)
        // Map through data and set complete to false for each item
        data.map(entry => {
          entry.complete = false
          return true
        })
      }
    })
  }

  return (
    <Row type='flex' justify='center'>
      <Col xs={23} sm={23} md={16} lg={12} xl={10} xxl={8}>
        <Card
          title='User Profile'
          style={{ marginTop: 20, textAlign: 'left' }}
          type='inner'
          extra={(
            <Dropdown overlay={menu} trigger={['click']}>
              <Icon name='ellipsis-v' style={{ padding: 5, fontSize: 17, color: '#B4483E', cursor: 'pointer' }} />
            </Dropdown>
          )}
        >
          <Form>
            <Form.Item>
              First Name:
              <Input
                placeholder='Enter your first name'
                type='text'
                readOnly
                defaultValue={entry.firstName}
              />
            </Form.Item>
            <Form.Item>
              Last Name:
              <Input
                placeholder='Enter your last name'
                type='text'
                readOnly
                defaultValue={entry.lastName}
              />
            </Form.Item>
            <Form.Item>
              Email Address:
              <Input
                placeholder='Email Address'
                type='email'
                readOnly
                defaultValue={entry.email}
              />
            </Form.Item>
            <Form.Item>
              Team ID:
              <Input
                placeholder='Team ID'
                readOnly
                defaultValue={entry.teamId}
              />
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Modal
        visible={modalVisible}
        closable={false}
        destroyOnClose
        title='Change Password'
        okText='Submit'
        onOk={() => onPasswordChange()}
        okButtonProps={{ disabled: !passwordValid.value }}
        onCancel={() => {
          setModalVisible(false)
          // Map through data and set complete to false for each item
          data.map(entry => {
            entry.complete = false
            return true
          })
        }}
        maskClosable={false}
      >
        <Form>
          <Form.Item>
            Current Password
            <Input.Password onChange={e => onChange('currentPassword', e.target.value)} />
          </Form.Item>
          <Form.Item>
            New Password
            <Input.Password onChange={e => onChange('password', e.target.value)} />
          </Form.Item>
          <Form.Item>
            Confirm New Password
            <Input.Password onChange={e => onChange('passwordConfirm', e.target.value)} />
          </Form.Item>
          <Form.Item>
            <List
              dataSource={data}
              renderItem={item => (
                <PasswordValidationItem item={item} />
              )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  )
}
 
export default CoreUserProfile
