import moment from 'moment'
import Enums from './enums'

export const generalTemplates = {
  customTemplate: {
    action: '',
    tempId: '',
    isSavedDoc: false,
    isNewDoc: true,
    readOnly: false,
    isModified: false,
    isModifiedSubform: false,
    status: Enums.VALUES_STRINGS.NEW,
    tierStructuresCustom: {
      tierHistory: [],
      tierMap: []
    }
  },
  labelValueTemplate: {
    key: '',
    label: '',
    value: ''
  },
  keyValueTemplate: {
    key: '',
    paramKey: '',
    paramValue: ''
  },
  valueToggleTemplate: {
    value: '',
    valueFlag: false
  },
  visibleObjectsTemplate: {
    id: '',
    isEditable: false,
    isMandatory: false,
    inputOptions: {
      label: '',
      inputType: 'text',
      choices: [{
        label: '',
        value: ''
      }],
      messages: [{
        value: '',
        iln: {}
      }],
      iln: {}
    }
  }
}

// MODULECONDITION
export const columnTemplates = {
  keywords: [{
    copyKey: true,
    editRecord: true,
    title: 'Profile Key',
    dataIndex: ['data', 'key'],
    key: 'profileKey',
    width: 200
  }, {
    title: 'Group Name',
    dataIndex: ['data', 'groupName'],
    key: 'groupName',
    width: 150,
    groupable: true
  }, {
    title: 'Solution(s)',
    dataIndex: ['data', 'solutions'],
    key: 'solutions',
    width: 50
  }, {
    title: 'Is Active',
    dataIndex: ['data', 'isActiveDescription'],
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['delete', 'create-copy'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  keywords_nested: [{
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    width: 200
  }, {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    width: 200
  }],
  tierstructures: [
    {
      copyKey: true,
      editRecord: true,
      title: 'Tier Key',
      dataIndex: ['data', 'key'],
      key: 'tierKey',
      width: 200
    },
    {
      title: 'Description',
      dataIndex: ['data', 'description'],
      key: 'description',
      width: 150,
      groupable: true
    },
    {
      title: 'Solution(s)',
      dataIndex: ['data', 'solutions'],
      key: 'solutions',
      width: 50
    },
    {
      title: 'Is Active',
      dataIndex: ['data', 'isActiveDescription'],
      key: 'isActiveDescription',
      width: 50,
      groupable: true
    },
    {
      isActions: true,
      actionTypes: ['delete', 'showValues', 'create-copy'],
      title: 'Actions',
      key: 'actions',
      width: 50
    }
  ],
  tierentry: [
    {
      copyKey: true,
      editRecord: true,
      title: 'Tier Key',
      dataIndex: 'key',
      key: 'tierKey',
      width: 200
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150,
      groupable: true
    },
    {
      title: 'Is Active',
      dataIndex: 'isActiveDescription',
      key: 'isActive',
      width: 50
    },
    {
      isActions: true,
      actionTypes: ['delete'],
      title: 'Actions',
      key: 'actions',
      width: 50
    }
  ],
  tierstructures_nested: [
    {
      copyKey: true,
      editRecord: true,
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      width: 200
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150,
      groupable: true
    },
    {
      title: 'Is Active',
      dataIndex: 'isActiveDescription',
      key: 'isActive',
      width: 50
    },
    {
      isActions: true,
      actionTypes: ['showValues'],
      title: 'Actions',
      key: 'actions',
      width: 50
    }
  ],
  tier_values: [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      width: 200
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: 200
    }
  ],
  numbering: [{
    editRecord: true,
    title: 'Name',
    dataIndex: ['data', 'name'],
    key: 'name',
    width: 200
  }, {
    copyKey: true,
    title: 'Profile Key',
    dataIndex: ['data', 'key'],
    key: 'profileKey',
    width: 150
  }, {
    title: 'Description',
    dataIndex: ['data', 'description'],
    key: 'description',
    width: 200
  }, {
    title: 'Group Name',
    dataIndex: ['data', 'groupName'],
    key: 'groupName',
    width: 150,
    groupable: true
  }, {
    title: 'Solution(s)',
    dataIndex: ['data', 'solutions'],
    key: 'solutions',
    width: 50
  }, {
    title: 'Is Active',
    dataIndex: ['data', 'isActiveDescription'],
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['delete', 'create-copy'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  connectors: [{
    editRecord: true,
    title: 'Name',
    dataIndex: ['data', 'name'],
    key: 'name',
    width: 200
  }, {
    title: 'Description',
    dataIndex: ['data', 'description'],
    key: 'description',
    width: 200
  }, {
    title: 'Connection Type',
    dataIndex: ['data', 'connectionTypeLabel'],
    key: 'connectionTypeLabel',
    width: 200,
    groupable: true
  }, {
    copyKey: true,
    title: 'Profile Key',
    dataIndex: ['data', 'key'],
    key: 'profileKey',
    width: 150
  }, {
    title: 'Solution(s)',
    dataIndex: ['data', 'solutions'],
    key: 'solutions',
    width: 50
  }, {
    title: 'Is Active',
    dataIndex: ['data', 'isActiveDescription'],
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['delete', 'create-copy', 'reset-connection-pool'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  teamusers: [{
    editRecord: true,
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    width: 200
  }, {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    width: 200
  }, {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 150
  }, {
    title: 'Role',
    dataIndex: ['teamRights', 'role'],
    key: 'role',
    width: 200,
    groupable: true
  }, {
    title: 'Status',
    dataIndex: ['teamRights', 'status'],
    key: 'status',
    width: 150,
    groupable: true
  }],
  roles: [
    {
      hasChildren: true,
      editRecord: true,
      title: 'Role Name  ',
      dataIndex: ['data', 'name'],
      key: 'name',
      width: 200
    }, {
      title: 'Description',
      dataIndex: ['data', 'description'],
      key: 'description',
      width: 200
    },
    {
      title: 'Group Name',
      dataIndex: ['data', 'groupName'],
      key: 'groupName',
      width: 150
    },
    {
      title: 'Responsible User',
      dataIndex: ['data', 'tmpResponsibleUser'],
      key: 'responsibleUser',
      width: 150
    }, {
      title: 'Levels',
      dataIndex: ['data', 'tmpLevels'],
      key: 'levels',
      width: 150
    }, {
      title: 'Solution(s)',
      dataIndex: ['data', 'solutions'],
      key: 'solutions',
      width: 50
    }, {
      title: 'Is Active',
      dataIndex: ['data', 'isActiveDescription'],
      key: 'isActive',
      width: 50
    }, {
      isActions: true,
      actionTypes: ['delete', 'create-copy'],
      title: 'Actions',
      key: 'actions',
      width: 50
    }
  ],
  apikeys: [{
    editRecord: true,
    title: 'Key',
    dataIndex: 'firstName',
    key: 'firstName',
    width: 200
  }, {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 200
  }, {
    title: 'Is Active',
    dataIndex: ['teamRights', 'isActiveDescription'],
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['generate-api-key'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  templates: [{
    copyKey: true,
    editRecord: true,
    title: 'Key',
    dataIndex: ['data', 'key'],
    key: 'key',
    width: 200
  }, {
    title: 'Description',
    dataIndex: ['data', 'description'],
    key: 'description',
    width: 200
  }, {
    title: 'Group Name',
    dataIndex: ['data', 'groupName'],
    key: 'groupName',
    width: 150,
    groupable: true
  }, {
    title: 'Solution(s)',
    dataIndex: ['data', 'solutions'],
    key: 'solutions',
    width: 50
  }, {
    title: 'Is Active',
    dataIndex: ['data', 'isActiveDescription'],
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['delete', 'create-copy'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  datamappings: [{
    editRecord: true,
    title: 'Name',
    dataIndex: ['data', 'name'],
    key: 'name',
    width: 200
  }, {
    title: 'Description',
    dataIndex: ['data', 'description'],
    key: 'description',
    width: 200
  }, {
    title: 'Group Name',
    dataIndex: ['data', 'groupName'],
    key: 'groupName',
    width: 150,
    groupable: true
  }, {
    copyKey: true,
    title: 'Key',
    dataIndex: ['data', 'key'],
    key: 'key',
    width: 150
  }, {
    title: 'Solution(s)',
    dataIndex: ['data', 'solutions'],
    key: 'solutions',
    width: 50
  }, {
    title: 'Is Active',
    dataIndex: ['data', 'isActiveDescription'],
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['delete', 'reverse-mapping', 'create-copy'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  bpm: [{
    editRecord: true,
    title: 'Profile Name',
    dataIndex: ['data', 'name'],
    key: 'name',
    width: 200
  }, {
    title: 'Description',
    dataIndex: ['data', 'description'],
    key: 'description',
    width: 200
  }, {
    title: 'Group Name',
    dataIndex: ['data', 'groupName'],
    key: 'groupName',
    width: 150,
    groupable: true
  }, {
    copyKey: true,
    title: 'Key',
    dataIndex: ['data', 'key'],
    key: 'key',
    width: 150
  }, {
    title: 'Solution(s)',
    dataIndex: ['data', 'solutions'],
    key: 'solutions',
    width: 50
  }, {
    title: 'Is Active',
    dataIndex: ['data', 'isActiveDescription'],
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['delete', 'create-copy'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  bpm_nested: [
    {
      copyKey: true,
      title: 'Process Step Key',
      dataIndex: 'key',
      key: 'key',
      width: 200
    },
    {
      title: 'Process Step Name',
      dataIndex: 'name',
      key: 'name',
      width: 200
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150
    },
    {
      title: 'Is Active',
      dataIndex: 'isActiveDescription',
      key: 'isActive',
      width: 50
    }
  ],
  bpm_nested2: [
    {
      copyKey: true,
      title: 'Option Key',
      dataIndex: 'key',
      key: 'key',
      width: 200
    },
    {
      title: 'Option Name',
      dataIndex: 'name',
      key: 'name',
      width: 200
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150
    },
    {
      title: 'Is Active',
      dataIndex: 'isActiveDescription',
      key: 'isActive',
      width: 50
    }
  ],
  botbuilder: [{
    editRecord: true,
    title: 'Profile Name',
    dataIndex: 'data.name',
    key: 'name',
    width: 200
  }, {
    title: 'Description',
    dataIndex: 'data.description',
    key: 'description',
    width: 200
  }, {
    title: 'Key',
    dataIndex: 'data.key',
    key: 'key',
    width: 150
  }, {
    title: 'Is Active',
    dataIndex: 'data.isActiveDescription',
    key: 'isActive',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['delete'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  gatewayadapters: [{
    editRecord: true,
    title: 'Profile Name',
    dataIndex: ['data', 'name'],
    key: 'name',
    width: 200
  }, {
    title: 'Description',
    dataIndex: ['data', 'description'],
    key: 'description',
    width: 200
  }, {
    title: 'Adapter Type',
    dataIndex: ['data', 'adapterType'],
    key: 'adapterType',
    width: 150,
    groupable: true
  }, {
    title: 'Group Name',
    dataIndex: ['data', 'groupName'],
    key: 'groupName',
    width: 150,
    groupable: true
  }, {
    title: 'URL',
    dataIndex: ['data', 'url'],
    key: 'url',
    width: 250
  }, {
    title: 'Solution(s)',
    dataIndex: ['data', 'solutions'],
    key: 'solutions',
    width: 50
  }, {
    title: 'Is Active',
    dataIndex: ['data', 'isActiveDescription'],
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['create-copy'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  connectorRoutes: [
    {
      editRecord: true,
      title: 'Route Name',
      dataIndex: 'name',
      key: 'name',
      width: 150
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150
    },
    {
      copyKey: true,
      title: 'Route Key',
      dataIndex: 'key',
      key: 'key',
      width: 150
    },
    {
      title: 'Is Active',
      key: 'isActiveDsecription',
      dataIndex: 'isActiveDescription',
      width: 80
    },
    {
      isActions: true,
      actionTypes: ['delete'],
      title: 'Actions',
      key: 'actions',
      width: 50
    }
  ],
  botProcesses: [
    {
      editRecord: true,
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150
    },
    {
      title: 'Key',
      dataIndex: 'processKey',
      key: 'key',
      width: 150
    },
    {
      title: 'Process Type',
      dataIndex: 'processTypeDescription',
      key: 'processType',
      width: 150
    },
    {
      title: 'Is Active',
      key: 'isActiveDsecription',
      dataIndex: 'isActiveDescription',
      width: 80
    },
    {
      isActions: true,
      actionTypes: ['delete'],
      title: 'Actions',
      key: 'actions',
      width: 60
    }
  ],
  gatewayMappings: [
    {
      editRecord: true,
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150
    },
    {
      title: 'Route URL Endpoint',
      dataIndex: 'endpoint',
      key: 'endpoint',
      width: 150
    },
    {
      title: 'Targeted Endpoint',
      dataIndex: 'targetedEndpoint',
      key: 'targetedEndpoint',
      width: 150
    },
    {
      title: 'Is Active',
      key: 'isActiveDsecription',
      dataIndex: 'isActiveDescription',
      width: 80
    },
    {
      isActions: true,
      actionTypes: ['delete'],
      title: 'Actions',
      key: 'actions',
      width: 60
    }
  ],
  fieldMappings: [
    {
      editRecord: true,
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150
    },
    {
      title: 'Src Attribute',
      dataIndex: 'srcName',
      key: 'srcName',
      width: 150
    },
    {
      title: 'Src Path',
      dataIndex: 'srcPath',
      key: 'srcPath',
      width: 150
    },
    {
      title: 'Src Type',
      dataIndex: 'srcType',
      key: 'srcType',
      width: 100
    },
    {
      title: 'Des Attribute',
      dataIndex: 'desName',
      key: 'desName',
      width: 150
    },
    {
      title: 'Des Path',
      dataIndex: 'desPath',
      key: 'desPath',
      width: 150
    },
    {
      title: 'Des Type',
      dataIndex: 'desType',
      key: 'desType',
      width: 100
    },
    {
      title: 'Is Active',
      key: 'isActiveDsecription',
      dataIndex: 'isActiveDescription',
      width: 80
    },
    {
      isActions: true,
      actionTypes: ['delete'],
      title: 'Actions',
      key: 'actions',
      width: 60
    }
  ],
  multiEntryMappings: [
    {
      editRecord: true,
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150
    },
    {
      title: 'Is Active',
      key: 'isActiveDsecription',
      dataIndex: 'isActiveDescription',
      width: 80
    },
    {
      isActions: true,
      actionTypes: ['delete'],
      title: 'Actions',
      key: 'actions',
      width: 60
    }
  ],
  processSteps: [
    {
      editRecord: true,
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150
    },
    {
      copyKey: true,
      title: 'Key',
      key: 'key',
      dataIndex: 'key',
      width: 80
    },
    {
      title: 'Is Active',
      key: 'isActiveDsecription',
      dataIndex: 'isActiveDescription',
      width: 80
    },
    {
      title: 'Step Type',
      key: 'stepType',
      dataIndex: 'stepType',
      width: 80
    },
    {
      isActions: true,
      actionTypes: ['delete'],
      title: 'Actions',
      key: 'actions',
      width: 60
    }
  ],
  stepOptions: [
    {
      editRecord: true,
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150
    },
    {
      copyKey: true,
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      width: 150
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150
    },
    {
      title: 'Is Active',
      key: 'isActiveDsecription',
      dataIndex: 'isActiveDescription',
      width: 80
    },
    {
      isActions: true,
      actionTypes: ['delete'],
      title: 'Actions',
      key: 'actions',
      width: 60
    }
  ],
  batchactions: [{
    copyKey: true,
    editRecord: true,
    title: 'Profile Key',
    dataIndex: ['data', 'key'],
    key: 'key',
    width: 200
  }, {
    title: 'Description',
    dataIndex: ['data', 'description'],
    key: 'description',
    width: 200
  }, {
    title: 'Group Name',
    dataIndex: ['data', 'groupName'],
    key: 'groupName',
    width: 200,
    groupable: true
  }, {
    title: 'Solution(s)',
    dataIndex: ['data', 'solutions'],
    key: 'solutions',
    width: 50
  }, {
    title: 'Is Active',
    dataIndex: ['data', 'isActiveDescription'],
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['delete', 'create-copy'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  batchAction: [{
    editRecord: true,
    title: 'Action Type',
    dataIndex: 'actionTypeDescription',
    key: 'actionType',
    width: 200
  }, {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 200
  }, {
    title: 'Is Active',
    dataIndex: 'isActiveDescription',
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['delete'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  events: [{
    copyKey: true,
    editRecord: true,
    title: 'Event Key',
    dataIndex: ['data', 'key'],
    key: 'key',
    width: 200
  }, {
    title: 'Linked Profile',
    dataIndex: ['data', 'linkedProfile'],
    key: 'linkedProfile',
    width: 150
  }, {
    title: 'Description',
    dataIndex: ['data', 'description'],
    key: 'description',
    width: 200
  }, {
    title: 'Group Name',
    dataIndex: ['data', 'groupName'],
    key: 'groupName',
    width: 200,
    groupable: true
  }, {
    title: 'Solution(s)',
    dataIndex: ['data', 'solutions'],
    key: 'solutions',
    width: 50
  }, {
    title: 'Is Active',
    dataIndex: ['data', 'isActiveDescription'],
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['delete', 'create-copy'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  eventsSubscriptions: [{
    editRecord: true,
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 200
  }, {
    title: 'Type',
    dataIndex: 'subscriberType',
    key: 'subscriberType',
    width: 200
  }, {
    title: 'Is Active',
    dataIndex: 'isActiveDescription',
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['delete'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  batchlogging: [{
    editRecord: true,
    title: 'Name',
    dataIndex: ['data', 'name'],
    key: 'name',
    width: 200
  }, {
    copyKey: true,
    title: 'Profile Key',
    dataIndex: ['data', 'key'],
    key: 'profileKey',
    width: 150
  }, {
    title: 'Description',
    dataIndex: ['data', 'description'],
    key: 'description',
    width: 200
  }, {
    title: 'Group Name',
    dataIndex: ['data', 'groupName'],
    key: 'groupName',
    width: 150,
    groupable: true
  }, {
    title: 'Solution(s)',
    dataIndex: ['data', 'solutions'],
    key: 'solutions',
    width: 50
  }, {
    title: 'Is Active',
    dataIndex: ['data', 'isActiveDescription'],
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['delete', 'create-copy'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  nodered: [{
    editRecord: true,
    title: 'Name',
    dataIndex: ['data', 'name'],
    key: 'name',
    width: 200
  }, {
    copyKey: true,
    title: 'Profile Key',
    dataIndex: ['data', 'key'],
    key: 'profileKey',
    width: 150
  }, {
    title: 'Description',
    dataIndex: ['data', 'description'],
    key: 'description',
    width: 200
  }, {
    title: 'Group Name',
    dataIndex: ['data', 'groupName'],
    key: 'groupName',
    width: 150,
    groupable: true
  }, {
    title: 'Solution(s)',
    dataIndex: ['data', 'solutions'],
    key: 'solutions',
    width: 50
  }, {
    title: 'Status',
    dataIndex: ['data', 'isActiveDescription'],
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['delete', 'create-copy'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  solutions: [{
    editRecord: true,
    title: 'Name',
    dataIndex: ['data', 'name'],
    key: 'name',
    width: 200
  }, {
    title: 'Profile Key',
    dataIndex: ['data', 'key'],
    key: 'profileKey',
    width: 150
  }, {
    title: 'Description',
    dataIndex: ['data', 'description'],
    key: 'description',
    width: 200
  }, {
    title: 'Is Active',
    dataIndex: ['data', 'isActiveDescription'],
    key: 'isActiveDescription',
    width: 50,
    groupable: true
  }, {
    isActions: true,
    actionTypes: ['delete', 'create-copy'],
    title: 'Actions',
    key: 'actions',
    width: 50
  }],
  errorlogs: [{
    copyKey: true,
    editRecord: true,
    title: 'Id',
    dataIndex: '_id',
    key: 'id',
    width: '15%'
  }, {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '11%',
    renderMethod: value => {
      return moment(value).format('YYYY-MM-DD HH:mm')
    }
  }, {
    title: 'Module',
    dataIndex: ['data', 'moduleName'],
    key: 'moduleName',
    width: '6%',
    filterEnabled: true,
    filterMethod: (value, record) => record.data.moduleName.indexOf(value) === 0,
    filterProp: 'moduleName'
  }, {
    title: 'Endpoint',
    dataIndex: ['data', 'uri'],
    key: 'uri',
    width: '12%',
    filterEnabled: true,
    filterMethod: (value, record) => record.data.uri.indexOf(value) === 0,
    filterProp: 'uri'
  }, {
    title: 'Status Code',
    dataIndex: ['data', 'responseCode'],
    key: 'responseCode',
    width: '7%',
    filterEnabled: true,
    filterMethod: (value, record) => record.data.responseCode === value,
    filterProp: 'responseCode'
  }, {
    title: 'Error Message',
    dataIndex: ['data', 'response'],
    key: 'response',
    width: '40%'
  }, {
    title: 'Resolved',
    dataIndex: ['data', 'resolvedDate'],
    key: 'resolved',
    width: '4%',
    isActions: true,
    actionTypes: ['resolvedUnresolved']
  }, {
    isActions: true,
    actionTypes: ['email', 'export'],
    title: 'Actions',
    key: 'actions',
    width: '10%'
  }]
}
