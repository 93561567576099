import React from 'react'
import { Row, Col } from 'antd'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Config from '../../utils/config'

/*
  Custom Components Import
*/
import CoreToolbarContainer from '../containers/core-toolbar-container'
import CoreAnonymousWrapper from './core-anonymous-wrapper'
import CoreLandingContainer from '../containers/core-landing-container'
import Enums from '../../utils/enums'

class CoreApp extends React.Component {
  componentDidMount () {
    window.addEventListener('beforeunload', (e) => {
      (e || window.event).returnValue = Enums.MESSAGES.AGILITE_CLOSE // Gecko + IE
      return Enums.MESSAGES.AGILITE_CLOSE // Gecko + Webkit, Safari, Chrome etc.
    })
  }

  // TODO: this.props.app will in essense always be 'home'. We need to refactor this logic and it's container
  render () {
    const reCaptchaEnabled = Config.reCaptcha?.enabled || false

    return (
      <div>
        <Row type='flex' justify='center'>
          <Col xs={24} sm={24} md={24} lg={24}>
            <CoreToolbarContainer />
            {this.props.app === Enums.APP_IDS.HOME ? (
              <div style={{ marginTop: 5 }}>
                {this.props.loggedIn ? (
                  <CoreLandingContainer />
                ) : (
                  <div>
                    {reCaptchaEnabled ?
                      <GoogleReCaptchaProvider reCaptchaKey={Config.reCaptcha.v3_siteKey}>
                        <CoreAnonymousWrapper facet={this.props.facet} />
                      </GoogleReCaptchaProvider>
                      :
                      <CoreAnonymousWrapper facet={this.props.facet} />
                    }
                  </div>
                )}
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
    )
  }
}

export default CoreApp
