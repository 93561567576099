import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { signOutUser, addTab } from '../core-actions'
import CoreUserMenu from '../components/core-user-menu'

const mapStateToProps = state => {
  return {
    app: state.core.app,
    theme: state.core.theme,
    tabs: state.core.tabObject.tabs
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      signOutUser,
      addTab
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoreUserMenu)
