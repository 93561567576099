import React from 'react'
import { Icon } from 'react-fa'
import { DragSource, DropTarget } from 'react-dnd'
import { withDragDropContext } from './html5-backend'
import update from 'immutability-helper'
import { Popconfirm, Button, Table, Row, Col, Input } from 'antd'
import { v1 } from 'agilite-utils/uuid'

import MemoryStore from '../../utils/memory-store'

import '../reusables-views.css'
import CopyKey from './copy-key'

function dragDirection (
  dragIndex,
  hoverIndex,
  initialClientOffset,
  clientOffset,
  sourceClientOffset
) {
  const hoverMiddleY = (initialClientOffset.y - sourceClientOffset.y) / 2
  const hoverClientY = clientOffset.y - sourceClientOffset.y

  if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
    return 'downward'
  }

  if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
    return 'upward'
  }
}

class BodyRow extends React.Component {
  render () {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      dragRow,
      clientOffset,
      sourceClientOffset,
      initialClientOffset,
      ...restProps
    } = this.props

    const style = {
      ...restProps.style,
      cursor: 'move'
    }

    let className = restProps.className

    if (isOver && initialClientOffset) {
      const direction = dragDirection(
        dragRow.index,
        restProps.index,
        initialClientOffset,
        clientOffset,
        sourceClientOffset
      )

      if (direction === 'downward') {
        className += ' drop-over-downward'
      }

      if (direction === 'upward') {
        className += ' drop-over-upward'
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    )
  }
}

const rowSource = {
  beginDrag (props) {
    return { index: props.index }
  }
}

const rowTarget = {
  drop (props, monitor) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  }
}

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  sourceClientOffset: monitor.getSourceClientOffset()
}))(
  DragSource('row', rowSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    dragRow: monitor.getItem(),
    clientOffset: monitor.getClientOffset(),
    initialClientOffset: monitor.getInitialClientOffset()
  }))(BodyRow)
)

class DragSortingTable extends React.Component {
  constructor (props) {
    super(props)

    this.components = {
      body: {
        row: DragableBodyRow
      }
    }

    this.state = {
      viewColumns: props.viewColumns,
      viewData: props.viewData,
      filteredViewData: this.formatViewData(props.viewData),
      searchFilter: '',
      columns: this.props.viewData,
      theme: this.props.theme
    }

    this.updateViewColumns = this.updateViewColumns.bind(this)
    this.formatViewData = this.formatViewData.bind(this)
    this.filterViewData = this.filterViewData.bind(this)
    this.generateViewHeader = this.generateViewHeader.bind(this)
    this.moveRow = this.moveRow.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.updateViewColumns(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.updateViewColumns(nextProps)

    this.setState({
      viewColumns: nextProps.viewColumns,
      viewData: nextProps.viewData,
      filteredViewData: this.formatViewData(nextProps.viewData),
      searchFilter: ''
    })
  }

  updateViewColumns (props) {
    // Add Intelligence to relevant Columns
    const columns = props.viewColumns

    for (const x in columns) {
      // Check which column needs to be grouped
      if (columns[x].groupable) {
        for (const propName in props) {
          if (propName === columns[x].key + 'Filter') {
            columns[x].filters = props[propName]
          }
        }
      }
    }

    this.setState({ columns })
  }

  formatViewData (viewData) {
    viewData = JSON.parse(JSON.stringify(viewData))

    return viewData.map((entry) => {
      entry.rowKey = v1()
      return entry
    })
  }

  filterViewData (searchFilter) {
    const tmpThis = this
    searchFilter = searchFilter.toLowerCase()

    tmpThis.props.filterViewData(tmpThis.state.viewData, searchFilter, function (filteredViewData) {
      tmpThis.setState({
        searchFilter,
        filteredViewData: tmpThis.formatViewData(filteredViewData)
      })
    })
  }

  generateViewHeader () {
    return (
      <div>
        <Row type='flex' justify='space-between'>
          <Col xs={7} sm={5} md={4} lg={4} xl={3} xxl={2} style={{ textAlign: 'left' }}>
            <Button
              cypressid='createNew'
              disabled={MemoryStore.userProfile.privileges[this.props.appId] === 'Reader' ? true : false}
              style={{ backgroundColor: '#67AD5B', color: 'white' }}
              onClick={e => {
                e.preventDefault()
                this.props.createRecord()
              }}
            >
              {this.props.btnCreateLabel}
            </Button>
          </Col>
          <Col xs={13} sm={14} md={16} lg={18} xl={19} xxl={21}>
            <Input
              name='search'
              placeholder={`Search ${this.props.viewTitle}...`}
              defaultValue={this.state.searchFilter}
              onChange={(e) => {
                this.filterViewData(e.target.value)
              }}
              onKeyPress={(e) => {
                if (e.keyCode === 13 || e.charCode === 13) {
                  e.preventDefault()
                  return false
                }
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }

  moveRow (dragIndex, hoverIndex) {
    const {
      viewData
    } = this.state
    const dragRow = viewData[dragIndex]

    this.setState(
      update(this.state, {
        viewData: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        }
      })
    )

    this.props.callback(this.state.viewData.concat())

    return this.filterViewData(this.state.searchFilter)
  }

  render () {
    const columns = []
    const tmpThis = this
    let tmpObj = {}
    let data = null

    for (const x in this.state.columns) {
      tmpObj = JSON.parse(JSON.stringify(this.state.columns[x]))

      // Check which column is used to edit the record and copy the key
      if (this.state.columns[x].editRecord && this.state.columns[x].copyKey) {
        tmpObj.render = (text, record, index) => {
          return (
            <div>
              <a onClick={() => tmpThis.props.editRecord(record._id, index)}>{text}</a>
              <CopyKey value={record.data ? record.data.key : record.key} />
            </div>
          )
        }
      } else if (this.state.columns[x].editRecord) {
        tmpObj.render = (text, record, index) => {
          return (
            <div><a onClick={() => tmpThis.props.editRecord(record._id, index)}>{text}</a></div>
          )
        }
      } else if (this.state.columns[x].copyKey) {
        tmpObj.render = (text, record) => {
          return (
            <div>
              {text}
              <CopyKey value={record.data ? record.data.key : record.key} />
            </div>
          )
        }
      }

      if (this.state.columns[x].groupable) {
        // eslint-disable-next-line
        tmpObj.onFilter = function (value, record) {
          data = record.data ? record.data : record
          return data[tmpThis.state.columns[x].key].toLowerCase() === value.toLowerCase()
        }
      }

      if (this.state.columns[x].isActions) {
        // eslint-disable-next-line
        tmpObj.render = (text, record) => {
          return (
            <div style={{ minWidth: 50 }}>
              {/* //First Delete Action */}
              {((this.state.columns[x].actionTypes.indexOf('delete') > -1) && (MemoryStore.userProfile.teamPrivileges[this.props.appId] !== 'Reader')) ?
                <div className={process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'} style={{ marginRight: 10 }}>
                  <Popconfirm
                    title='Are you sure delete this Profile?'
                    onConfirm={() => {
                      this.props.deleteRecord(record._id)
                    }}
                    okText='Yes'
                    cancelText='No'
                  >
                    {/* eslint-disable-next-line */}
                    <a title='Delete Profile'>
                      <Icon name='trash-o' style={{ color: tmpThis.state.theme.dangerColor }} />
                    </a>
                  </Popconfirm>
                </div>
                : null}
            </div>
          )
        }
      }

      columns.push(tmpObj)
    }

    return (
      <div>
        <Row type='flex' justify='center'>
          <Col xs={24}>
            <Table
              id='list-view-dnd'
              columns={columns}
              dataSource={this.state.filteredViewData}
              bordered
              showHeader
              title={this.generateViewHeader}
              size='middle'
              pagination={false}
              components={this.components}
              onRow={(record, index) => ({
                index,
                moveRow: this.moveRow
              })}
              rowKey={record => record.rowKey}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

const ListViewDND = withDragDropContext(DragSortingTable)

export default ListViewDND
