import React, { Component } from 'react'
import { Form, Switch, Col, Row, Input, AutoComplete, Radio, message } from 'antd'
import MemoryStore from '../../utils/memory-store'
import TooltipIcon from '../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../utils/enums-tooltips'
import { getAPIKeys, getTeamUsers } from '../events-utils'

const { TextArea } = Input

class EventsSubscriptionForm extends Component {
  constructor (props) {
    super(props)

    this.entry = this.props.data

    this.state = {
      subscriberType: this.props.subscription.subscriberType,
      subscribers: []
    }
  }

  UNSAFE_componentWillMount () {
    this.props.generateSubscribers((result) => {
      this.setState({ subscribers: result })
    })
  }

  onFieldChange (key, value) {
    const tmpArray = []
    
    if (key === 'subscriberType') {
      switch (value) {
        case 'user':
          getTeamUsers((err, response) => {
            if (err) {
              if (err.response) {
                message.error(err.response.data.errorMessage)
              } else {
                message.error(err)
              }
            } else {
              response.data.map(record => {
                return tmpArray.push({ label: record.email, value: record.email })
              })
              this.setState({ subscribers: tmpArray })
            }
          })
          break
        case 'apikey':
          getAPIKeys((err, response) => {
            if (err) {
              if (err.response) {
                message.error(err.response.data.errorMessage)
              } else {
                message.error(err)
              }
            } else {
              response.data.map(record => {
                return tmpArray.push({ label: record.firstName, value: record.firstName })
              })
              this.setState({ subscribers: tmpArray })
            }
          })
          break
        default:
          break
      }
    }
    this.entry.custom.isModifiedSubform = true
    this.props.subscription[key] = value
  }

  render () {
    const radioStyle = {
      display: 'inline',
      height: '30px',
      lineHeight: '30px'
    }

    return (
      <Row>
        <Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={15}>
          <Form>
            <Form.Item>
              <Switch
                ref='isActive'
                disabled={MemoryStore.userProfile.teamPrivileges.events === 'Reader'}
                checkedChildren='Active'
                unCheckedChildren='Inactive'
                defaultChecked={this.props.subscription.isActive}
                onChange={(e) => {
                  this.onFieldChange('isActive', e)
                }}
              />
            </Form.Item>
            <Form.Item>
              <span style={{ color: 'red' }}>* </span>
              {'Subscription Name '}
              <TooltipIcon title={EnumsTooltips.general.profileName} />
              <Input
                className='subscriptionName'
                placeholder='Provide a Subscription Name'
                disabled={MemoryStore.userProfile.teamPrivileges.events === 'Reader'}
                defaultValue={this.props.subscription.name}
                onChange={e => this.onFieldChange('name', e.target.value)}
                required
              />
            </Form.Item>
            <Form.Item>
              <span style={{ color: 'red' }}>* </span>
              {'Webhook URL '}
              <TextArea
                className='webhookUrl'
                placeholder='Provide a Webhook URL'
                disabled={MemoryStore.userProfile.teamPrivileges.events === 'Reader'}
                defaultValue={this.props.subscription.webhookUrl}
                onChange={e => this.onFieldChange('webhookUrl', e.target.value)}
                rows={2}
                type='url'
                required
              />
            </Form.Item>
            <Form.Item>
              <div style={{ marginRight: 10 }}><span style={{ color: 'red' }}>* </span>Subscriber Type</div>
              <Radio.Group
                disabled={MemoryStore.userProfile.teamPrivileges.events === 'Reader'}
                onChange={e => {
                  this.onFieldChange('subscriberType', e.target.value)
                  this.setState({ subscriberType: e.target.value })
                }}
                value={this.state.subscriberType}
              >
                <Radio style={radioStyle} value='user'>
                  User
                </Radio>
                <Radio style={radioStyle} value='apikey'>
                  API Key
                </Radio>
              </Radio.Group>
            </Form.Item>
            {this.state.subscriberType !== '' ?
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>
                {'Subscriber '}
                <AutoComplete
                  className='subscriber'
                  options={this.state.subscribers}
                  placeholder='Provide a Subscriber'
                  disabled={MemoryStore.userProfile.teamPrivileges.events === 'Reader'}
                  defaultValue={this.props.subscription.subscriber}
                  onChange={value => this.onFieldChange('subscriber', value)}
                  onSelect={value => this.onFieldChange('subscriber', value)}
                  required
                />
              </Form.Item>
              : null}
          </Form>
        </Col>
      </Row>
    )
  }
}

export default EventsSubscriptionForm
