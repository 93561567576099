import React from 'react'
import { Select, Form, Input, Row, Col } from 'antd'
import MemoryStore from '../../../../utils/memory-store'
import Notes from '../../../../reusables/components/notes'

class FieldMappingSourceExcel extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      fieldMapping: this.props.fieldMapping,
      type: this.props.fieldMapping.srcType
    }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.fieldMapping[key] = value
  }

  render () {
    return (
      <Row>
        <Col span={24}>
          <Form name='field-mapping-src-excel'>
            {!this.props.isParentMulti ?
              <div>
                <Form.Item>
                  <span style={{ color: 'red' }}>* </span>Excel Worksheet Name
                  <Input
                    name='worksheet_name'
                    placeholder='Provide the name of your Worksheet'
                    disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                    defaultValue={this.state.fieldMapping.srcPath}
                    required
                    onChange={(e) => {
                      this.onFieldChange('srcPath', e.target.value)
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <span style={{ color: 'red' }}>* </span>Cell Name
                  <Input
                    name='cell_name'
                    placeholder='Provide the Worksheet Cell Name'
                    disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                    defaultValue={this.state.fieldMapping.srcName}
                    required
                    onChange={(e) => {
                      this.onFieldChange('srcName', e.target.value)
                    }}
                  />
                </Form.Item>
              </div>
              :
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>Cell Key
                <Input
                  name='cell_key'
                  placeholder='Cell Key (Horizontal=Col Letter/Vertical=Row No)'
                  disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                  defaultValue={this.state.fieldMapping.srcName}
                  required
                  onChange={(e) => {
                    this.onFieldChange('srcName', e.target.value)
                  }}
                />
              </Form.Item>}
            <Form.Item>
              Cell Type
              <Select
                className='cell_type'
                disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                placeholder='Specify an Cell Type'
                defaultValue={this.state.fieldMapping.srcType}
                onChange={(value) => {
                  this.onFieldChange('srcType', value)
                }}
              >
                <Select.Option value='1'>String</Select.Option>
                <Select.Option value='2'>Integer</Select.Option>
                <Select.Option value='3'>Double</Select.Option>
                <Select.Option value='4'>Percentage</Select.Option>
              </Select>
            </Form.Item>
            <Notes
              disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
              notes={this.state.fieldMapping.srcNotes}
              onChange={(e) => {
                this.onFieldChange('srcNotes', e.target.value)
              }}
            />
          </Form>
        </Col>
      </Row>
    )
  }
}

export default FieldMappingSourceExcel
