import React from 'react'
import MemoryStore from '../../utils/memory-store'
import Enums from '../../utils/enums'
import { Form, Row, Button, Card, Tabs, Col, Popconfirm, Dropdown, Menu, message, Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Icon } from 'react-fa'

import NodeRedFormGeneral from './node-red-form-general'
import NodeRedFlowData from './node-red-flow-data'
// import NodeRedRevision from './node-red-revision'

const MenuItem = Menu.Item

class NodeRedForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc,
      isLoading: false
    }
  }

  cancelProfileConfirm () {
    if (this.entry.custom.isModified) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  saveProfile (activate) {
    const tmpThis = this

    tmpThis.setState({
      isLoading: true
    })

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, activate, (err) => {
      tmpThis.setState({
        isLoading: false
      })

      if (err) {
        message.error(err)
      } else {
        // Reset isModified and isModifiedSubform properties
        tmpThis.entry.custom.isModified = false
        tmpThis.entry.custom.isModifiedSubform = false

        tmpThis.props.closeTab(
          tmpThis.props.tabKey,
          tmpThis.props.tabKey,
          tmpThis.props.tabs
        )
      }
    })
  }

  validateProfile (activate) {
    let errMsg = null
    let success = true

    if (!this.entry.data.key) {
      errMsg = 'Please provide a Profile Key'
      success = false
    } else if (!this.entry.data.name) {
      errMsg = 'Please provide a Profile Name'
      success = false
    }

    if (success) {
      this.saveProfile(activate)
    } else {
      message.error(errMsg)
    }
  }

  render () {
    const menu = (
      <Menu>
        <MenuItem key='0'>
          <Popconfirm
            title='Are you sure you want to disable this Node-RED flow?'
            onConfirm={() => {
              this.entry.data.isActive = false
              this.validateProfile(false)
            }}
            okText='Yes'
            cancelText='No'
          >
            <span style={{ padding: '10 0' }}>Disable Node-RED Flow</span>
          </Popconfirm>
        </MenuItem>
      </Menu>
    )

    return (
      <Row type='flex' justify='center'>
        <Col xs={23} lg={20} xl={16}>
          <Card
            style={{ textAlign: 'left' }}
            title={this.props.title}
            type='inner'
            extra={!this.state.isNewDoc && this.entry.data.isActive === true && MemoryStore.userProfile.teamPrivileges.nodered !== 'Reader' ?
              <Dropdown overlay={menu} trigger={['click']}>
                <Icon name='ellipsis-v' style={{ padding: 5, fontSize: 17, color: '#B4483E', cursor: 'pointer' }} />
              </Dropdown>
              : null}
          >
            <Form
              autoComplete='off'
            >
              <Tabs animated={false}>
                <Tabs.TabPane tab='General' key='general'>
                  <NodeRedFormGeneral
                    tabKey={this.props.tabKey}
                    theme={this.props.theme}
                    entry={this.entry}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Flow Data' key='flow_data'>
                  <NodeRedFlowData
                    entry={this.entry}
                  />
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab='Revisions' key='revisions'>
                  <NodeRedRevision
                    entry={this.entry}
                    data={this.entry.data.revisions}
                  />
                </Tabs.TabPane> */}
              </Tabs>
              <Row>
                <div style={{ marginTop: 10 }}>
                  {MemoryStore.userProfile.teamPrivileges.nodered !== 'Reader' ?
                    (
                      <>
                        <Button
                          disabled={this.state.isLoading}
                          style={{
                            backgroundColor: '#67AD5B',
                            color: 'white',
                            width: 80,
                            marginRight: 10
                          }}
                          onClick={() => this.validateProfile()}
                        >
                          {this.state.isLoading ? <LoadingOutlined spin /> : <span>Submit</span>}
                        </Button>
                        <Popconfirm
                          title='Are you sure you want to Submit and Activate this profile?'
                          okText='Yes'
                          cancelText='No'
                          onConfirm={() => this.validateProfile(true)}
                          disabled={this.state.isLoading}
                        >
                          <Button
                            style={{
                              backgroundColor: '#67AD5B',
                              color: 'white',
                              width: 'auto',
                              marginRight: 10
                            }}
                          >
                            Submit & Activate
                          </Button>
                        </Popconfirm>
                      </>
                    ) : null}
                  <Button
                    label='Cancel'
                    disabled={this.state.isLoading}
                    style={{
                      backgroundColor: '#EB5181',
                      color: 'white',
                      width: 'auto',
                      marginRight: 10
                    }}
                    onClick={() => this.cancelProfileConfirm()}
                  >
                    Cancel
                  </Button>
                </div>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default NodeRedForm
