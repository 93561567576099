import React from 'react'
import { Row, Col, Form, Input, Select } from 'antd'
import MemoryStore from '../../../../../utils/memory-store'
import AceEditor from 'react-ace'

import 'brace/mode/json'
import 'brace/theme/xcode'

class ConnectorsFormTypeOracleDb extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.disabled = MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'
    this.state = { entry: props.entry || {} }

    // Bindings
    this.onFieldChange = this.onFieldChange.bind(this)
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.props.entry[key] = value
  }

  render () {
    return (
      <Row>
        <Col xs={24} lg={16}>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'User'}
            <Input
              name='user'
              placeholder='Database user'
              disabled={this.disabled}
              defaultValue={this.state.entry.user}
              onChange={e => {
                this.onFieldChange('user', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Password'}
            <Input
              name='password'
              placeholder='Database password'
              disabled={this.disabled}
              defaultValue={this.state.entry.password}
              autoComplete='new-password'
              type='password'
              onChange={e => {
                this.onFieldChange('password', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Connection String'}
            <Input
              name='connectionString'
              placeholder='OracleDB Connection String'
              disabled={this.disabled}
              defaultValue={this.state.entry.connectionString}
              onChange={e => {
                this.onFieldChange('connectionString', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'External Authorization'}
            <Select
              disabled={this.disabled}
              defaultValue={this.state.entry.externalAuth === true ? true : false}
              onChange={e => {
                this.onFieldChange('externalAuth', e)
              }}
            >
              <Select.Option value={true}>True</Select.Option>
              <Select.Option value={false}>False</Select.Option>
            </Select>
          </Form.Item>
          <div>
            <h3>
              {'Connection Pool Settings'}
            </h3>
            <AceEditor
              placeholder='{ JSON }'
              showPrintMargin={false}
              readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              cursorStart={1}
              mode='json'
              theme='xcode'
              style={{ width: '100%', height: 200 }}
              name='aceEditor'
              value={this.state.entry.poolSettings}
              setOptions={{
                showLineNumbers: true,
                newLineMode: 'unix'
              }}
              onChange={value => {
                this.onFieldChange('poolSettings', value)
              }}
            />
          </div>
        </Col>
      </Row>
    )
  }
}

export default ConnectorsFormTypeOracleDb
