import { connect } from 'react-redux'
import { setSearchFilter } from '../batch-logging-actions'
import { filterViewData, createCopy } from '../batch-logging-utils'
import { generateGrouping } from '../../utils/utilities'
import ListViewContainer from '../../reusables/containers/list-view-container'
import Enums from '../../utils/enums'

const mapStateToProps = (state) => {
  if (!state[state.core.tabObject.activeApp]) {
    return {}
  }

  if (state.core.tabObject.activeApp !== Enums.APP_IDS.BATCH_LOGGING) {
    return {}
  }

  const appId = state.core.tabObject.activeApp
  const data = state[appId].data
  const viewData = filterViewData(data, state[appId].searchFilter)
  const groupNameFilter = generateGrouping(viewData, 'groupName')
  const isActiveDescriptionFilter = generateGrouping(viewData, 'isActiveDescription')

  return {
    viewData,
    groupNameFilter,
    isActiveDescriptionFilter
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateCopy: (recordData, callback) => {
      createCopy(recordData, callback)
    },
    setSearchFilter: (value) => {
      dispatch(setSearchFilter(value))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListViewContainer)
