import React from 'react'

import { Row, Col, Form, Input, Switch } from 'antd'

import MemoryStore from '../../../../../utils/memory-store'
import AceEditor from 'react-ace'
import 'brace/mode/json'
import 'brace/theme/xcode'

class ConnectorsFormTypeFTP extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry
    }

    this.onFieldChange = this.onFieldChange.bind(this)
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.props.entry[key] = value
  }

  render () {
    return (
      <Row>
        <Col xs={24} lg={16}>
          <Form.Item>
            Host
            <Input
              name='host'
              placeholder='Defaults to localhost'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.host}
              onChange={e => {
                this.onFieldChange('host', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            Port
            <Input
              name='port'
              placeholder='Defaults to 21'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.port}
              onChange={e => {
                this.onFieldChange('port', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            Secure?
            <Switch
              style={{ marginLeft: 10 }}
              defaultChecked={this.state.entry.secure !== '0'}
              checkedChildren='Yes'
              unCheckedChildren='No'
              onChange={e => {
                this.onFieldChange('secure', e === false ? '0' : '1')
              }}
            />
          </Form.Item>
          <Form.Item>
            Secure Options (JSON)
            <AceEditor
              readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              showPrintMargin={false}
              cursorStart={1}
              mode='json'
              theme='xcode'
              style={{ width: '100%', height: 100 }}
              name='aceEditor'
              value={this.state.entry.secureOptions}
              setOptions={{
                showLineNumbers: true,
                newLineMode: 'unix'
              }}
              onChange={e => {
                this.onFieldChange('secureOptions', e)
              }}
            />
          </Form.Item>
          <Form.Item>
            Username
            <Input
              name='username'
              placeholder='Defaults to anonymous'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.username}
              autoComplete='new-password'
              onChange={e => {
                this.onFieldChange('username', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            Password
            <Input
              name='password'
              placeholder='Defaults to guest'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.password}
              autoComplete='new-password'
              type='password'
              onChange={e => {
                this.onFieldChange('password', e.target.value)
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    )
  }
}

export default ConnectorsFormTypeFTP
