import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { registerUser, verifyRecaptchaToken, verifyRecaptchaTokenv2, validatePassword } from '../core-actions'
import CoreRegister from '../components/core-register'

const mapStateToProps = state => {
  return {
    theme: state.core.theme
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      registerUser,
      verifyRecaptchaToken,
      verifyRecaptchaTokenv2,
      validatePassword
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoreRegister)
