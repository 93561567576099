export const actions = {
  RESET_STATE: 'RESET_STATE_ERROR_LOGS',
  CREATE_PROFILE: 'CREATE_PROFILE_ERROR_LOGS',
  SAVE_PROFILE: 'SAVE_PROFILE_ERROR_LOGS',
  CANCEL_PROFILE: 'CANCEL_PROFILE_ERROR_LOGS',
  EDIT_PROFILE: 'EDIT_PROFILE_ERROR_LOGS',
  DELETE_PROFILE: 'DELETE_PROFILE_ERROR_LOGS',
  FETCH_ALL_DATA: 'FETCH_ALL_DATA_ERROR_LOGS',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER_ERROR_LOGS',
  UPDATE_DATA: 'UPDATE_DATA_ERROR_LOGS'
}

/*
  DISPATCH FUNCTIONS
*/
export const setSearchFilter = (value) => {
  return {
    type: actions.SET_SEARCH_FILTER,
    value
  }
}

export const setErrorLogsData = (data) => {
  return {
    type: actions.FETCH_ALL_DATA,
    payload: data
  }
}
