import { actions } from './error-logs-actions'
import errorLogsState from './error-logs-state'

export default (state = errorLogsState, action) => {
  switch (action.type) {
    case actions.RESET_STATE:
      return Object.assign({}, state, errorLogsState)
    case actions.SET_SEARCH_FILTER:
      return Object.assign({}, state, {
        searchFilter: action.value
      })
    case actions.FETCH_ALL_DATA:
      return Object.assign({}, state, {
        data: action.payload
      })
    default:
      return state
  }
}
