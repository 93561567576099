import React from 'react'
import { Menu, Modal } from 'antd'
import CoreUserProfileContainer from '../containers/core-user-profile-container'
import Enums from '../../utils/enums'
import MemoryStore from '../../utils/memory-store'

class CoreUserMenu extends React.Component {
  constructor (props) {
    super(props)
    this.handleAddTab = this.handleAddTab.bind(this)
  }

  handleAddTab () {
    this.props.addTab({
      content: <CoreUserProfileContainer />,
      title: 'User Profile',
      key: Enums.VALUES_STRINGS.USER_PROFILE,
      app: Enums.VALUES_STRINGS.USER_PROFILE,
      tabType: Enums.VALUES_STRINGS.EMPTY,
      closable: true
    })
  }

  signOutConfirm () {
    let tmpValue = false

    for (let x in MemoryStore.activeEntries) {
      if (MemoryStore.activeEntries[x].custom.isModified) {
        tmpValue = true
      }
    }

    if (tmpValue) {
      Modal.confirm({
        onOk: () => this.props.signOutUser(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT_SIGN_OUT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true,
        width: 500
      })
    } else {
      this.props.signOutUser()
    }
  }

  render () {
    return (
      <Menu
        style={{
          backgroundColor: 'white',
          borderRadius: 5,
          border: '1px solid lightgray'
        }}
      >
        <Menu.Item key='1' onClick={this.handleAddTab}>
          User Profile
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key='2'
          onClick={() => {
            this.signOutConfirm()
          }}
        >
          Sign Out
        </Menu.Item>
      </Menu>
    )
  }
}

export default CoreUserMenu
