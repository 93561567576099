import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { switchResourcesMenu, createTeam, fetchAllData, addTab, resetTabs, closeTab, switchTeam } from '../core-actions'
import CoreToolbar from '../components/core-toolbar'

const mapStateToProps = state => {
  return {
    appId: state.core.active,
    title: state.core.title,
    loggedIn: state.core.user.loggedIn,
    theme: state.core.theme,
    tabKey: state.core.tabObject.activeKey,
    tabs: state.core.tabObject.tabs
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      switchResourcesMenu,
      switchTeam,
      fetchAllData,
      createTeam,
      addTab,
      resetTabs,
      closeTab
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoreToolbar)
