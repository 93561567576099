import React from 'react'
import { Row, Modal, Col, message } from 'antd'
import MemoryStore from '../../../utils/memory-store'
import MultiEntryForm from '../multi-entry-profiles/multi-entry-form'
import ListViewDND from '../../../reusables/components/list-view-dnd'
import { columnTemplates } from '../../../utils/templates'
import Enums from '../../../utils/enums'

class MultiEntryMappings extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry,
      dialogOpen: false,
      activeRecord: null
    }

    // Setup Event Binding
    this.createRecord = this.createRecord.bind(this)
    this.saveRecord = this.saveRecord.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.deleteRecord = this.deleteRecord.bind(this)
    this.handleValidateRecord = this.handleValidateRecord.bind(this)
    this.formatViewData = this.formatViewData.bind(this)
    this.handleCloseModalDialog = this.handleCloseModalDialog.bind(this)
    this.handleCloseModalDialogConfirm = this.handleCloseModalDialogConfirm.bind(this)
    this.updateArrayData = this.updateArrayData.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.formatViewData()
  }

  UNSAFE_componentWillUpdate () {
    this.formatViewData()
  }

  createRecord () {
    const activeRecord = JSON.parse(JSON.stringify(MemoryStore.datamappings.multiEntryTemplate))
    activeRecord.isNewEntry = true
    activeRecord._id = new Date().getTime().toString()

    this.setState({
      dialogOpen: true,
      activeRecord
    })
  }

  saveRecord () {
    const entry = this.props.entry
    const data = entry.multiEntryProfiles.concat()
    const index = data.findIndex(t => t._id === this.state.activeRecord._id)

    if (this.state.activeRecord.isNewEntry) {
      data.push(this.state.activeRecord)
    } else {
      data.splice(index, 1, this.state.activeRecord)
    }

    entry.multiEntryProfiles = data

    this.setState({
      entry,
      dialogOpen: false,
      activeRecord: null
    })
  }

  editRecord (recordId) {
    const index = this.state.entry.multiEntryProfiles.findIndex(t => t._id === recordId)
    const activeRecord = JSON.parse(JSON.stringify(this.state.entry.multiEntryProfiles[index]))

    activeRecord.isNewEntry = false
    this.setState({ dialogOpen: true, activeRecord })
  }

  deleteRecord (recordId) {
    this.entry.custom.isModifiedSubform = true

    const entry = this.props.entry
    const data = entry.multiEntryProfiles.concat()
    const index = data.findIndex(t => t._id === recordId)

    data.splice(index, 1)
    entry.multiEntryProfiles = data
    this.setState({ entry })
  }

  handleValidateRecord () {
    const entry = this.props.entry
    const activeRecord = this.state.activeRecord
    let errMsg = null

    // Format Prop Values
    activeRecord.name = activeRecord.name.trim()
    activeRecord.description = activeRecord.description.trim()
    activeRecord.srcJson.path = activeRecord.srcJson.path.trim()
    activeRecord.srcExcel.worksheet = activeRecord.srcExcel.worksheet.trim()
    activeRecord.srcExcel.startingPoint = activeRecord.srcExcel.startingPoint.trim()
    activeRecord.desJson.path = activeRecord.desJson.path.trim()
    activeRecord.desExcel.worksheet = activeRecord.desExcel.worksheet.trim()
    activeRecord.desExcel.startingPoint = activeRecord.desExcel.startingPoint.trim()

    // Validate Prop Values
    if (!activeRecord.name) {
      errMsg = 'Please provide a Profile Name'
    } else if (entry.sourceType === '2') {
      if (!activeRecord.srcExcel.worksheet) {
        errMsg = 'Please provide a Source Worksheet Name'
      } else if (!activeRecord.srcExcel.startingPoint) {
        errMsg = 'Please provide a Source Starting Point'
      }
    } else if (entry.destinationType === '2') {
      if (!activeRecord.desExcel.worksheet) {
        errMsg = 'Please provide a Destination Worksheet Name'
      } else if (!activeRecord.desExcel.startingPoint) {
        errMsg = 'Please provide a Destination Starting Point'
      }
    }

    if (errMsg) return message.error(errMsg)
    this.saveRecord()
  }

  formatViewData () {
    // Set isActiveDescription
    this.state.entry.multiEntryProfiles.map(mapping => {
      mapping.isActiveDescription = mapping.isActive ? 'Yes' : 'No'
      return mapping.isActiveDescription
    })
  }

  handleCloseModalDialog () {
    this.entry.custom.isModifiedSubform = false

    this.setState({ dialogOpen: false, activeRecord: null })
  }

  handleCloseModalDialogConfirm () {
    if (this.entry.custom.isModifiedSubform) {
      Modal.confirm({
        onOk: () => this.handleCloseModalDialog(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.handleCloseModalDialog()
    }
  }

  updateArrayData (data) {
    this.entry.custom.isModified = true
    this.props.entry.multiEntryProfiles = data
    this.setState({ entry: this.props.entry })
  }

  render () {
    return (
      <Row>
        <Col span={24}>
          <ListViewDND
            btnCreateLabel='Create New'
            viewTitle='Multi Entry Profiles'
            createRecord={this.createRecord}
            editRecord={this.editRecord}
            deleteRecord={this.deleteRecord}
            filterViewData={this.props.filterViewData}
            viewData={this.state.entry.multiEntryProfiles}
            viewColumns={columnTemplates.multiEntryMappings}
            theme={this.props.theme}
            callback={this.updateArrayData}
          />
          <Modal
            title='Multi Entry Profile'
            okButtonProps={{ style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' } }}
            okText='Ok'
            visible={this.state.dialogOpen}
            onOk={this.handleValidateRecord}
            onCancel={this.handleCloseModalDialogConfirm}
            width='65%'
            style={{ top: 10 }}
            destroyOnClose
            maskClosable={false}
          >
            <MultiEntryForm
              tabKey={this.props.tabKey}
              entry={this.state.activeRecord}
              theme={this.props.theme}
              profile={this.state.entry}
              isParentMulti
            />
          </Modal>
        </Col>
      </Row>
    )
  }
}

export default MultiEntryMappings
