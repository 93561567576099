import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import Theme from '../../utils/agilite-theme'

export default function TooltipIcon (props) {
  return (
    <Tooltip title={props.title}>
      <QuestionCircleOutlined style={{ color: Theme.warningColor }} />
    </Tooltip>
  )
}
