import Configurations from './config.json'

export default {
  APP_IDS: {
    HOME: 'home',
    NODE_RED: 'nodered',
    NODE_RED_DASHBOARD: 'nodereddashboard',
    KEYWORDS: 'keywords',
    TIER_STRUCTURES: 'tierstructures',
    NUMBERING: 'numbering',
    CONNECTORS: 'connectors',
    DATA_MAPPING: 'datamappings',
    TEMPLATES: 'templates',
    BPM: 'bpm',
    ROLES: 'roles',
    TEAM_USERS: 'teamusers',
    API_KEYS: 'apikeys',
    BOT_BUILDER: 'botbuilder',
    GATEWAY_ADAPTERS: 'gatewayadapters',
    BATCH_ACTIONS: 'batchactions',
    EVENTS: 'events',
    BATCH_LOGGING: 'batchlogging',
    SOLUTIONS: 'solutions',
    ERROR_LOGS: 'errorlogs'
  },
  APP_NAMES: {
    home: 'Home',
    userprofile: 'User Profile',
    nodered: 'Node-RED',
    nodereddashboard: 'Node-RED Dashboard',
    keywords: 'Keywords',
    tierstructures: 'Tier Structures',
    numbering: 'Numbering',
    connectors: 'Connectors',
    datamappings: 'Data Mapping',
    templates: 'Templates',
    bpm: 'BPM',
    roles: 'Roles',
    teamusers: 'Team Users',
    apikeys: 'API Keys',
    botbuilder: 'Bot Builder',
    gatewayadapters: 'Gateway Adapters',
    batchactions: 'Batch Actions',
    events: 'Events',
    batchlogging: 'Batch Logging',
    solutions: 'Solutions',
    errorlogs: 'Error Logs'
  },
  APP_PROFILE_TITLES: {
    keywords: 'Keyword Profile',
    tierstructures: 'Tier Structure Profile',
    numbering: 'Numbering Profile',
    connectors: 'Connector Profile',
    datamappings: 'Data Mapping Profile',
    templates: 'Template Profile',
    bpm: 'BPM Profile',
    roles: 'Role Profile',
    teamusers: 'Team User',
    apikeys: 'API Key',
    botbuilder: 'Bot Builder Profile',
    gatewayadapters: 'Gateway Adapter Profile',
    batchactions: 'Batch Action Profile',
    events: 'Event Profile',
    batchlogging: 'Batch Log Profile',
    nodered: 'Node-RED Profile',
    solutions: 'Solution Profile',
    errorlogs: 'Error Log Profile'
  },
  APP_VIEW_TITLES: {
    keywords: 'Keyword Profiles',
    tierstructures: 'Tier Structure Profiles',
    numbering: 'Numbering Profiles',
    connectors: 'Connector Profiles',
    datamappings: 'Data Mapping Profiles',
    templates: 'Template Profiles',
    bpm: 'BPM Profiles',
    roles: 'Role Profiles',
    teamusers: 'Team Users',
    apikeys: 'API Keys',
    botbuilder: 'Bot Builder Profiles',
    gatewayadapters: 'Gateway Adapter Profiles',
    batchactions: 'Batch Action Profiles',
    events: 'Event Profiles',
    batchlogging: 'Batch Logging Profiles',
    nodered: 'Node-RED Profiles',
    solutions: 'Solution Profiles',
    errorlogs: 'Error Logs'
  },
  FACETS: {
    LOADING: 'loading',
    SIGN_IN: 'sign-in',
    FORGOT: 'forgot',
    FORGOT_CONFIRM: 'forgot-confirm',
    REGISTER: 'register',
    REGISTER_CONFIRM: 'register-confirm',
    RESET: 'reset',
    RESET_CONFIRM: 'reset-confirm',
    ACTIVATE: 'activate',
    ACTIVATE_SUCCESS: 'activate-success',
    ACTIVATE_CONFIRMATION: 'activate-confirm',
    VERIFY_SUCCESS: 'verify-success',
    VERIFY_FAILED: 'verify-failed',
    VERIFY_CONFIRM: 'verify-confirm'
  },
  VALUES_STRINGS: {
    AGILITE_LOGO: 'Agilit-e Logo',
    AGILITE_WELCOME: 'Welcome to the Agilit-e Admin Portal',
    EMPTY: '',
    SPACE: ' ',
    COLON: ':',
    ZERO: '0',
    NEW_LOWER_CASE: 'new',
    NEW_PROPER_CASE: 'New',
    APPLICATION_JSON: 'application/json',
    APPLICATION_OCTET_STREAM: 'application/octet-stream',
    DATA: 'data',
    ADMINISTRATION: 'Administration',
    MODULES: 'Modules',
    PORTAL_MENU: 'Portal Menu',
    USER_PROFILE: 'userProfile',
    VIEW: 'view',
    FORM: 'form',
    YES: 'Yes',
    NO: 'No',
    ALL: 'all',
    SPECIFIC: 'specific',
    ERROR_CODES: '400,401,402,403,404,405,406,407,408,409,410,411,412,413,414,415,416,417,418,419,420,421,422,423,424,425,426,428,429,430,431,440,444,449,450,451,460,463,494,495,496,497,498,499,500,501,502,503,504,505,506,507,508,509,510,511,520,521,522,523,524,525,526,527,529,530,561,598'
  },
  HEADER_PROPS: {
    API_KEY: 'api-key',
    TEAM_NAME: 'team-name',
    TEAM_ID: 'team-id',
    RECORD_ID: 'record-id',
    CONTENT_TYPE: 'Content-Type',
    INCLUDE_MODULES: 'include-modules',
    START_DATE: 'start-date',
    END_DATE: 'end-date',
    INCLUDE_RESOLVED_LOGS: 'include-resolved-logs',
    STATUS_CODES: 'status-codes',
    LOG_PROFILE_KEY: 'log-profile-key'
  },
  REQ_TYPE: {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete'
  },
  MEMBERSHIP_TYPES: {
    INDIE: '1',
    SMB: '2',
    ENTERPRISE: '3',
    NPO: '4'
  },
  MESSAGES: {
    AGILITE_CLOSE: `Are you sure you want to close the ${Configurations.appTitle}?`,
    CANCEL_TITLE: 'Confirmation',
    CANCEL_CONTENT: 'Are you sure you want to close this record? Any unsaved changes will be discarded',
    CANCEL_CONTENT_IMPORT: 'Are you sure you want to cancel the import?',
    CANCEL_CONTENT_TEAMS: 'Are you sure you want to switch teams? Any unsaved changes will be discarded',
    CANCEL_CONTENT_SIGN_OUT: 'Are you sure you want to sign out? Any unsaved changes will be discarded',
    UNKNOWN_ERROR: 'An unknown error occurred. The Agilit-e service may not be available'
  },
  CONNECTORS_CONNECTION_DESCRIPTION: {
    1: {
      message: <span><b>Connector Type: </b>Web API</span>,
      description: (
        <>
          <p><b>Module Used: </b>axios</p>
          <a href='https://www.npmjs.com/package/axios' target='_blank' rel='noreferrer'>https://www.npmjs.com/package/axios</a>
        </>
      )
    },
    6: {
      message: <span><b>Connector Type: </b>SMTP</span>,
      description: (
        <>
          <p><b>Module Used: </b>nodemailer</p>
          <a href='https://nodemailer.com/' target='_blank' rel='noreferrer'>https://nodemailer.com/</a>
        </>
      )
    },
    7: {
      message: <span><b>Connector Type: </b>DB2 iSeries</span>,
      description: (
        <>
          <p><b>Module Used: </b>node-jt400</p>
          <a href='https://www.npmjs.com/package/node-jt400' target='_blank' rel='noreferrer'>https://www.npmjs.com/package/node-jt400</a>
        </>
      )
    },
    10: {
      message: <span><b>Connector Type: </b>MongoDB</span>,
      description: (
        <>
          <p><b>Module Used: </b>mongoosejs</p>
          <a href='https://mongoosejs.com/' target='_blank' rel='noreferrer'>https://mongoosejs.com/</a>
        </>
      )
    },
    11: {
      message: <span><b>Connector Type: </b>FTP</span>,
      description: (
        <>
          <p><b>Module Used: </b>ftp</p>
          <a href='https://www.npmjs.com/package/ftp' target='_blank' rel='noreferrer'>https://www.npmjs.com/package/ftp</a>
        </>
      )
    },
    12: {
      message: <span><b>Connector Type: </b>Microsoft SQL</span>,
      description: (
        <>
          <p><b>Module Used: </b>mssql</p>
          <a href='https://www.npmjs.com/package/mssql' target='_blank' rel='noreferrer'>https://www.npmjs.com/package/mssql</a>
        </>
      )
    },
    13: {
      message: <span><b>Connector Type: </b>LDAP</span>,
      description: (
        <>
          <p><b>Module Used: </b>ldapts</p>
          <a href='https://www.npmjs.com/package/ldapts' target='_blank' rel='noreferrer'>https://www.npmjs.com/package/ldapts</a>
        </>
      )
    },
    14: {
      message: <span><b>Connector Type: </b>PostgreSQL</span>,
      description: (
        <>
          <p><b>Module Used: </b>pg</p>
          <a href='https://www.npmjs.com/package/pg' target='_blank' rel='noreferrer'>https://www.npmjs.com/package/pg</a>
        </>
      )
    },
    15: {
      message: <span><b>Connector Type: </b>OracleDB</span>,
      description: (
        <>
          <p><b>Module Used: </b>oracledb</p>
          <a href='https://www.npmjs.com/package/oracledb' target='_blank' rel='noreferrer'>https://www.npmjs.com/package/oracledb</a>
        </>
      )
    },
    16: {
      message: <span><b>Connector Type: </b>SSH2-SFTP</span>,
      description: (
        <>
          <p><b>Module Used: </b>ssh2-sftp-client</p>
          <a href='https://www.npmjs.com/package/ssh2-sftp-client' target='_blank' rel='noreferrer'>https://www.npmjs.com/package/ssh2-sftp-client</a>
        </>
      )
    },
    17: {
      message: <span><b>Connector Type: </b>IMAP</span>,
      description: (
        <>
          <p><b>Module Used: </b>imap-simple</p>
          <a href='https://www.npmjs.com/package/imap-simple' target='_blank' rel='noreferrer'>https://www.npmjs.com/package/imap-simple</a>
        </>
      )
    },
    18: {
      message: <span><b>Connector Type: </b>Snowflake</span>,
      description: (
        <>
          <p><b>Module Used: </b>snowflake-sdk</p>
          <a href='https://docs.snowflake.com/' target='_blank' rel='noreferrer'>https://docs.snowflake.com</a>
        </>
      )
    }
  }
}
