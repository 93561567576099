import React, { useState } from 'react'
import { Input, Form, Row, Col, Select, Button, Card, Spin, Alert, message, Checkbox } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import AgiliteTheme from '../../../utils/agilite-theme'
import { reAssignResponsibleUser, getResponsibleRoles } from '../../bpm-utils'
import MemoryStore from '../../../utils/memory-store'

const BPMAdministartion = (props) => {
  const [isVisible, setIsVisible] = useState(true)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({
    visible: false,
    totalRecords: 0
  })
  const [fieldsDisabled, setFieldsDisabled] = useState(false)
  const [entry, setEntry] = useState({
    currentResponsibleUser: '',
    newResponsibleUser: '',
    actionType: 'reassign',
    roles: []
  })

  const { Option } = Select

  const handleChange = (key, value) => {
    if (key === 'actionType') {
      if (value === 'reassign') {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }
    setEntry(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  const handleSubmit = async () => {
    let result = null

    // Validations
    if (!entry.currentResponsibleUser) return message.error('Please provide a Current Responsible User')
    if (!entry.newResponsibleUser) return message.error('Please provide a New Responsible User')
    if (entry.currentResponsibleUser === entry.newResponsibleUser) return message.error('Current Responsible User and New Responsible User can not be the same')

    try {
      setLoading(true)

      if (entry.roles.length === 0) {
        setFieldsDisabled(true)

        result = await getResponsibleRoles(props.entry.key, entry.currentResponsibleUser.trim())

        setEntry(prevState => ({
          ...prevState,
          roles: result.data
        }))
      } else {
        result = await reAssignResponsibleUser(props.entry.key, entry.currentResponsibleUser.trim(), entry.newResponsibleUser.trim(), entry.roles)

        setAlert({
          visible: true,
          totalRecords: result.headers['total-records']
        })
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error)
    }
  }

  const handleReset = () => {
    setEntry({
      currentResponsibleUser: '',
      newResponsibleUser: '',
      actionType: 'reassign',
      roles: []
    })
    setFieldsDisabled(false)
    setAlert({
      visible: false,
      totalRecords: 0
    })
  }

  return (
    <Card
      actions={isVisible && !loading ?
          [
            <Button
              key='reassign'
              style={{ background: AgiliteTheme.successColor, borderColor: AgiliteTheme.successColor, color: AgiliteTheme.white, cursor: 'pointer' }}
              onClick={handleSubmit}
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
            >
              Re-assign
            </Button>,
            <Button
              key='reset'
              style={{ background: AgiliteTheme.dangerColor, borderColor: AgiliteTheme.dangerColor, color: AgiliteTheme.white, cursor: 'pointer' }}
              onClick={handleReset}
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
            >
              Reset
            </Button>
          ]
        : null}
    >
      <Spin tip='Re-assigning Responsibility...' spinning={loading}>
        <Row type='flex' justify='space-between'>
          <Col span={10}>
            <Form.Item>
              Action Type
              <Select
                label='Action Type'
                onChange={value => handleChange('actionType', value)}
                value={entry.actionType}
                disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader' || fieldsDisabled}
              >
                <Option value='reassign'>Re-assign Responsibility</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify='space-between'>
          <Col span={10}>
            <Form.Item
              key='current_responsible_user'
              hidden={!isVisible}
            >
              <span style={{ color: 'red' }}>* </span>
              Current Responsible User
              <Input
                onChange={e => handleChange('currentResponsibleUser', e.target.value)}
                value={entry.currentResponsibleUser}
                disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader' || fieldsDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item>
              <ArrowRightOutlined
                style={{
                  fontSize: 25,
                  marginBottom: 20,
                  marginTop: 30,
                  marginLeft: 40,
                  marginRight: 40,
                  color: AgiliteTheme.successColor
                }}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              key='new_responsible_user'
              hidden={!isVisible}
            >
              <span style={{ color: 'red' }}>* </span>
              New Responsible User
              <Input
                onChange={e => handleChange('newResponsibleUser', e.target.value)}
                value={entry.newResponsibleUser}
                disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader' || fieldsDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          {entry.roles.length > 0 ?
            <Col span={8}>
              <Card title='Select Roles To Re-Assign'>
                <Checkbox.Group onChange={roleNames => handleChange('roleNames', roleNames)} defaultValue={entry.roles}>
                  {entry.roles.map(entry => {
                    return (
                      <Row key={entry}>
                        <Checkbox value={entry}>{entry}</Checkbox>
                      </Row>
                    )
                  })}
                </Checkbox.Group>
              </Card>
            </Col>
            : null}
        </Row>
        <Row>
          <Col span={8}>
            {alert.visible ?
              <Alert
                style={{ marginTop: 10 }}
                description={`Process Completed. ${alert.totalRecords} BPM Stub(s) Re-assigned.`}
                type='success'
              />
              : null}
          </Col>
        </Row>
      </Spin>
    </Card>
  )
}

export default BPMAdministartion
