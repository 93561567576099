import { connect } from 'react-redux'
import CoreApp from '../components/core-app'

const mapStateToProps = state => {
  return {
    app: state.core.app,
    loggedIn: state.core.user.loggedIn,
    facet: state.core.facet
  }
}

export default connect(mapStateToProps)(CoreApp)
