import React from 'react'

import { Row, Col, Select, Form } from 'antd'

import SMTPRouteSendMail from './routes/smtp-send-mail'
import MemoryStore from '../../../../../utils/memory-store'

class SMTPRouteMain extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry.routeData,
      routeType: props.entry.routeData.routeType ? props.entry.routeData.routeType : ''
    }
  }

  onChangeRouteType (e) {
    this.entry.custom.isModifiedSubform = true
    this.setState({ routeType: e })
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value
  }

  render () {
    return (
      <Row>
        <Col xs={24} xl={16}>
          <h3>Route Info</h3>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>Route Type
            <Select
              className='route_type'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.routeType ? this.state.entry.routeType : ''}
              onChange={value => {
                this.onChangeRouteType(value)
                this.onFieldChange('routeType', value)
              }}
            >
              <Select.Option value=''>-Select-</Select.Option>
              <Select.Option value='0'>Send Mail</Select.Option>
            </Select>
          </Form.Item>
          {this.state.routeType === '0' ? (
            <SMTPRouteSendMail entry={this.props.entry} tabKey={this.props.tabKey} />
          ) : null}
        </Col>
      </Row>
    )
  }
}

export default SMTPRouteMain
