import { connect } from 'react-redux'
import BotProcesses from '../components/bot-processes/bot-processes'
import MemoryStore from '../../utils/memory-store'
import { fetchAllData } from '../../core/core-actions'
import { filterBotProcesses } from '../bot-builder-utils'

const mapStateToProps = state => {
  const tabKey = state.core.tabObject.activeKey
  const entry = MemoryStore.activeEntries[tabKey]

  return {
    entry,
    theme: state.core.theme,
    state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchAllData: (appId, callback) => {
      dispatch(fetchAllData(appId, callback))
    },
    filterViewData: (data, searchFilter, callback) => {
      filterBotProcesses(data, searchFilter, callback)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BotProcesses)
