import React from 'react'
import { Row, Col, Form, Input, Switch } from 'antd'
import MemoryStore from '../../../../../utils/memory-store'

class ConnectorsFormTypeMSSQL extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.disabled = MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'
    this.state = {
      entry: props.entry || {},
      encrypt: props.entry.encrypt === '0' ? false : true
    }

    // Bindings
    this.onFieldChange = this.onFieldChange.bind(this)
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.props.entry[key] = value
  }

  render () {
    return (
      <Row>
        <Col xs={24} lg={16}>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Server'}
            <Input
              name='server'
              placeholder='Server to connect to'
              disabled={this.disabled}
              defaultValue={this.state.entry.server}
              onChange={e => {
                this.onFieldChange('server', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Domain (Optional)'}
            <Input
              name='domain'
              placeholder='Used for Domain login'
              disabled={this.disabled}
              defaultValue={this.state.entry.domain}
              onChange={e => {
                this.onFieldChange('domain', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Port (Optional)'}
            <Input
              name='port'
              placeholder='Defaults to 1433'
              disabled={this.disabled}
              defaultValue={this.state.entry.port}
              onChange={e => {
                this.onFieldChange('port', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Username'}
            <Input
              name='username'
              placeholder='Provide a Username'
              disabled={this.disabled}
              defaultValue={this.state.entry.user}
              onChange={e => {
                this.onFieldChange('user', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Password'}
            <Input
              name='password'
              placeholder='Provide a Password'
              disabled={this.disabled}
              defaultValue={this.state.entry.password}
              autoComplete='new-password'
              type='password'
              onChange={e => {
                this.onFieldChange('password', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Database (Optional)'}
            <Input
              name='database'
              placeholder='Defaults based on server configuration'
              disabled={this.disabled}
              defaultValue={this.state.entry.database}
              onChange={e => {
                this.onFieldChange('database', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Request Timeout (Optional)'}
            <Input
              name='request_timeout'
              placeholder='Request timeout in ms. Defaults to 15000'
              disabled={this.disabled}
              defaultValue={this.state.entry.requestTimeout}
              onChange={e => {
                this.onFieldChange('requestTimeout', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            Encrypt?
            <Switch
              style={{ marginLeft: 10 }}
              disabled={this.disabled}
              checkedChildren='Yes'
              unCheckedChildren='No'
              defaultChecked={this.state.encrypt}
              onChange={checked => {
                this.onFieldChange('encrypt', checked === true ? '1' : '0')
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    )
  }
}

export default ConnectorsFormTypeMSSQL
