import React from 'react'
import { Row, Col, Form, Input, Switch, Select } from 'antd'
import MemoryStore from '../../../utils/memory-store'
import GroupNameContainer from '../../../reusables/containers/group-name-container'
import SolutionsContainer from '../../../reusables/containers/solutions-container'
import TooltipIcon from '../../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../../utils/enums-tooltips'
import Description from '../../../reusables/components/description'
import Notes from '../../../reusables/components/notes'

const { TextArea } = Input
const Option = Select.Option

class GatewayAdapterFormGeneral extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: this.entry,
      isNewDoc: this.entry.custom.isNewDoc
    }
  }

  render () {
    return (
      <Row type='flex' justify='space-between'>
        <Col xs={24} sm={24} md={18} lg={18}>
          <Form.Item>
            <Switch
              disabled={this.props.disabled}
              checkedChildren='Active'
              unCheckedChildren='Inactive'
              defaultChecked={this.entry.data.isActive}
              onChange={(e) => {
                this.props.onChange('isActive', e)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>{'Profile Name '}
            <TooltipIcon title={EnumsTooltips.general.profileName} />
            <Input
              name='name'
              disabled={this.props.disabled}
              placeholder='Provide a Profile Name'
              defaultValue={this.entry.data.name}
              onChange={(e) => {
                this.props.onChange('name', e.target.value)
              }}
            />
          </Form.Item>
          <Description
            disabled={this.props.disabled}
            description={this.entry.data.description}
            onChange={(e) => {
              this.props.onChange('description', e.target.value)
            }}
          />
          <Form.Item>
            Gateway Adapter Type<br />
            <Select
              className='adapter_type'
              style={{ width: 150 }}
              defaultValue={this.entry.data.adapterType}
              disabled={!this.entry.custom.isNewDoc || this.props.disabled}
              onChange={(e) => {
                this.props.onChange('adapterType', e)
              }}
            >
              <Option value='server'>Server</Option>
              <Option value='client'>Client</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>Agilit-e API Key
            <TextArea
              className='api_key'
              disabled={this.props.disabled}
              placeholder='Please provide an Agilit-e generated API Key'
              defaultValue={this.entry.data.apiKey}
              rows={3}
              onChange={(e) => {
                this.props.onChange('apiKey', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>Gateway Adapter URL
            <Input
              name='adapter_url'
              disabled={this.props.disabled}
              placeholder='e.g. https://gatewayadapter.com'
              defaultValue={this.entry.data.url}
              type='url'
              onChange={(e) => {
                this.props.onChange('url', e.target.value)
              }}
            />
          </Form.Item>
          <GroupNameContainer
            disabled={this.props.disabled}
            defaultValue={this.entry.data.groupName}
            onValueChange={(value) => {
              this.props.onChange('groupName', value)
            }}
            onValueSelect={(value) => {
              this.props.onChange('groupName', value)
            }}
          />
          <SolutionsContainer
            disabled={this.props.disabled}
            defaultValue={this.entry.data.solutions}
            onValueChange={(value) => {
              this.props.onChange('solutions', value)
            }}
          />
          <Notes
            disabled={this.props.disabled}
            notes={this.entry.data.notes}
            onChange={(e) => {
              this.props.onChange('notes', e.target.value)
            }}
          />
        </Col>
      </Row>
    )
  }
}

export default GatewayAdapterFormGeneral
