import React from 'react'
import { Form, Input } from 'antd'
import AceEditor from 'react-ace'

import 'brace/mode/json'
import 'brace/theme/xcode'

import CompileTemplateIcon from '../../../../../../reusables/components/compile-template-icon'
import MemoryStore from '../../../../../../utils/memory-store'

class LDAPRouteExop extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry.routeData,
      controls: props.entry.routeData.controls || ''
    }

    // Bindings
    this.onFieldChange = this.onFieldChange.bind(this)
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value.trim()
  }

  render () {
    return (
      <div>
        <Form.Item>
          <span style={{ color: 'red' }}>* </span>
          {'OID '}
          <CompileTemplateIcon />
          <Input
            name='oid'
            placeholder='Object Identifier'
            disabled={this.props.disabled}
            defaultValue={this.state.entry.oid}
            onChange={e => {
              this.onFieldChange('oid', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          {'Value '}
          <CompileTemplateIcon />
          <Input
            name='value'
            placeholder='Value'
            disabled={this.props.disabled}
            defaultValue={this.state.entry.value}
            onChange={e => {
              this.onFieldChange('value', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          <h3>Controls <CompileTemplateIcon /></h3>
          <AceEditor
            showPrintMargin={false}
            readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            cursorStart={1}
            mode='json'
            theme='xcode'
            style={{ width: '100%', height: 300 }}
            name='aceEditor'
            value={this.state.controls}
            setOptions={{
              showLineNumbers: true,
              newLineMode: 'unix'
            }}
            onChange={value => {
              this.onFieldChange('controls', value)
              this.setState({ controls: value })
            }}
          />
        </Form.Item>
      </div>
    )
  }
}

export default LDAPRouteExop
