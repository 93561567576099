import { connect } from 'react-redux'
import { setSearchFilter } from '../api-keys-actions'
import { filterViewData } from '../api-keys-utils'
import { generateGrouping } from '../../utils/utilities'
import ListViewContainer from '../../reusables/containers/list-view-container'
import Enums from '../../utils/enums'

const mapStateToProps = state => {
  if (!state[state.core.tabObject.activeApp]) return {}
  if (state.core.tabObject.activeApp !== Enums.APP_IDS.API_KEYS) return {}

  const appId = state.core.tabObject.activeApp
  const data = state[appId].data
  const viewData = filterViewData(data, state[appId].searchFilter)
  const isActiveDescriptionFilter = generateGrouping(viewData, 'teamRights.isActiveDescription')

  return {
    viewData,
    isActiveDescriptionFilter
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSearchFilter: value => {
      dispatch(setSearchFilter(value))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListViewContainer)
