import Config from './config.json'
import { generalTemplates } from './templates'

// TODO: Replace relevant values with Enums

// MODULECONDITION
export default {
  agilite: null,
  apiKey: '', // TODO: Delete when you finished updating the Admin Portal to use Agilite Node Module
  activeEntries: {},
  visibleObjectsTemplate: generalTemplates.visibleObjectsTemplate,
  keywords: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: true,
        key: '',
        groupName: '',
        values: [],
        solutions: []
      }
    }
  },
  tierstructures: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: true,
        key: '',
        description: '',
        notes: '',
        values: [],
        tierEntries: [],
        solutions: []
      }
    },
    tierEntriesTemplate: {
      _id: '',
      isActive: true,
      key: '',
      description: '',
      notes: '',
      values: [generalTemplates.labelValueTemplate],
      tierEntries: []
    },
    tierHistory: [],
    tierMap: []
  },
  numbering: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: true,
        isHidden: false,
        key: '',
        name: '',
        description: '',
        groupName: '',
        prefix: '',
        suffix: '',
        startAt: 1,
        incrementBasedOn: '',
        minLength: 1,
        solutions: []
      }
    }
  },
  connectors: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: true,
        key: '',
        name: '',
        description: '',
        groupName: '',
        solutions: [],
        connectionType: '1',
        connectionData: {},
        routes: []
      }
    },
    routeTemplate: { // TODO: There shouldn't be a property for every connection type. Too much overhead and model complexity
      _id: '',
      isNewEntry: true,
      isActive: true,
      key: '',
      name: '',
      description: '',
      routeData: {}
    },
    activeEntryTab: '1',
    activeRouteTab: '1',
    activeRoute: {}
  },
  datamappings: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: true,
        key: '',
        name: '',
        description: '',
        groupName: '',
        solutions: [],
        sourceType: '',
        destinationType: '',
        fieldMappings: [],
        multiEntryProfiles: [],
        mappingActions: [],
        srcJson: {
          startingPoint: ''
        },
        srcExcel: {
          srcType: '1',
          fileId: '',
          attachments: [],
          deleteAttachments: []
        },
        desJson: {
          outputType: '1'
        },
        desExcel: {
          desType: '1',
          attachments: [],
          deleteAttachments: [],
          fileName: ''
        }
      }
    },
    fieldMappingTemplate: {
      _id: '',
      isNewEntry: true,
      isActive: true,
      isParentMulti: false,
      name: '',
      description: '',
      srcPath: '',
      srcName: '',
      srcType: '1',
      srcNotes: '',
      desPath: '',
      desName: '',
      desType: '1',
      desNotes: ''
    },
    multiEntryTemplate: {
      _id: '',
      isNewEntry: true,
      isActive: true,
      name: '',
      description: '',
      srcJson: {
        path: ''
      },
      srcExcel: {
        direction: '1', // Default to Horizontal
        worksheet: '',
        startingPoint: ''
      },
      desJson: {
        path: ''
      },
      desExcel: {
        direction: '1', // Default to Horizontal
        worksheet: '',
        startingPoint: ''
      },
      multiEntryMappings: []
    },
    mappingActionsTemplate: {
      _id: '',
      isActive: true,
      action: '',
      description: ''
    },
    activeEntryTab: '1',
    activeMultiEntryTab: '1',
    activeFieldMapping: {},
    activeMultiEntry: {},
    activeMultiEntryMapping: {}
  },
  templates: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: true,
        execute: false,
        templateType: 'std',
        editorType: 'design',
        key: '',
        description: '',
        groupName: '',
        solutions: [],
        data: '',
        mode: 'plain_text',
        theme: 'xcode',
        sendTo: '',
        copyTo: '',
        blindCopyTo: '',
        subject: ''
      }
    }
  },
  bpm: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: true,
        key: '',
        name: '',
        description: '',
        groupName: '',
        solutions: [],
        appUrl: '',
        referenceUrl: '',
        appAdmin: '',
        processSteps: [],
        notes: '',
        iln: {},
        keywords: [],
        numberingId: '',
        lockedExpiry: Config.modules.bpm.lockedExpiryMinutes ? Config.modules.bpm.lockedExpiryMinutes : 0
      }
    },
    processStepTemplate: {
      _id: '',
      isNewEntry: true,
      isActive: true,
      stepType: 'First Step',
      key: '',
      name: '',
      description: '',
      instructions: '',
      duration: '',
      processStage: '',
      responsibility: '1',
      responsibleRole: '',
      eventStamp: [],
      roleLevels: [],
      visibleObjects: [generalTemplates.visibleObjectsTemplate],
      stepOptions: [],
      notes: '',
      referenceUrl: '',
      notificationTemplate: '',
      iln: {},
      keywords: []
    },
    stepOptionTemplate: {
      _id: '',
      isNewEntry: true,
      isActive: true,
      key: '',
      name: '',
      description: '',
      eventStamp: [],
      nextStep: '',
      visibleObjects: [generalTemplates.visibleObjectsTemplate],
      notes: '',
      iln: {},
      keywords: []
    },
    activeEntryTab: '1',
    activeProcessStep: {},
    activeProcessStepTab: '1',
    activeStepOption: {},
    activeStepOptionTab: '1'
  },
  roles: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: true,
        isHidden: false,
        name: '',
        description: '',
        groupName: '',
        solutions: [],
        tmpResponsibleUser: '',
        responsibleUser: [],
        levels: ['']
      }
    }
  },
  botbuilder: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: true,
        key: '',
        name: '',
        msAppId: '',
        msAppPwd: '',
        luisAppUrl: '',
        description: '',
        groupName: '',
        notes: '',
        urlRouteName: '',
        botProcesses: []
      }
    },
    botProcessTemplate: {
      _id: '',
      isNewEntry: true,
      isActive: true,
      processType: '1',
      processKey: '',
      name: '',
      description: '',
      notes: '',
      webhookSubmit: ''
    },
    activeEntryTab: '1',
    activeBotProcess: {},
    activeBotProcessTab: '1'
  },
  gatewayadapters: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: true,
        adapterType: 'server',
        name: '',
        description: '',
        url: '',
        groupName: '',
        solutions: [],
        apiKey: '',
        notes: '',
        mappings: []
      }
    },
    mappingsTemplate: {
      _id: '',
      isNewEntry: true,
      isActive: true,
      name: '',
      description: '',
      requestType: '',
      endpoint: '',
      targetedEndpoint: '',
      ipWhitelist: [],
      ipBlacklist: [],
      notes: ''
    },
    activeEntryTab: '1',
    activeMapping: {},
    activeMappingTab: '1'
  },
  teamusers: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      isActive: true,
      firstName: '',
      lastName: '',
      email: '',
      status: 'New',
      teamRights: {
        role: 'User',
        privileges: {
          batchLogProfiles: 'Editor',
          bpm: 'Editor',
          connectors: 'Editor',
          datamappings: 'Editor',
          keywords: 'Editor',
          numbering: 'Editor',
          roles: 'Editor',
          templates: 'Editor',
          tierstructures: 'Editor',
          gatewayadapters: 'Editor',
          batchactions: 'Editor',
          events: 'Editor',
          batchlogging: 'Editor',
          nodered: 'Editor',
          importexport: '',
          teams: '',
          apikeys: '',
          dashboards: ''
        }
      }
    }
  },
  apikeys: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      firstName: '',
      description: '',
      teamRights: {
        isActive: true,
        role: 'User',
        privileges: {
          batchLogProfiles: 'Editor',
          bpm: 'Editor',
          connectors: 'Editor',
          datamappings: 'Editor',
          keywords: 'Editor',
          numbering: 'Editor',
          roles: 'Editor',
          templates: 'Editor',
          tierstructures: 'Editor',
          gatewayadapters: 'Editor',
          batchactions: 'Editor',
          events: 'Editor',
          batchlogging: 'Editor',
          nodered: 'Editor',
          importexport: '',
          teams: '',
          apikeys: '',
          dashboards: ''
        }
      }
    }
  },
  batchactions: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: true,
        key: '',
        description: '',
        groupName: '',
        solutions: [],
        parentId: '',
        actions: []
      }
    },
    batchActionTemplate: {
      isActive: true,
      actionType: ''
    }
  },
  events: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: true,
        key: '',
        linkedProfile: '',
        description: '',
        groupName: '',
        solutions: [],
        subscriptions: [],
        actions: []
      }
    },
    subscriptionTemplate: {
      isActive: true,
      name: '',
      webhookUrl: '',
      subscriberType: '',
      subscriber: ''
    }
  },
  batchlogging: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: true,
        isHidden: false,
        key: '',
        name: '',
        description: '',
        groupName: '',
        solutions: [],
        notes: '',
        logLevel: 'basic'
      }
    }
  },
  nodered: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: false,
        key: '',
        name: '',
        description: '',
        groupName: '',
        solutions: [],
        flows: [],
        settings: {},
        sessions: {},
        credentials: {}
      }
    }
  },
  solutions: {
    dataTemplate: {
      _id: '',
      custom: generalTemplates.customTemplate,
      data: {
        isActive: true,
        key: '',
        name: '',
        description: ''
      }
    }
  },
  userProfile: { // TODO: See if this can be combined with Register Form
    firstName: '',
    lastName: '',
    email: '',
    teamId: '',
    canCreateTeam: false
  },
  registerForm: { // TODO: Also used for Activate and Reset Form
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    teamId: '',
    password: '',
    passwordConfirm: '',
    currentPassword: '',
    membershipType: ''
  },
  isoLanguages: [],
  defaultLanguage: ''
}
