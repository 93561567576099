import React from 'react'
import { Form, Input, Select } from 'antd'
import MemoryStore from '../../../../../../utils/memory-store'

import AceEditor from 'react-ace'
import CompileTemplateIcon from '../../../../../../reusables/components/compile-template-icon'
import 'brace/mode/sql'
import 'brace/theme/xcode'

class DB2ISeriesRouteExecuteQry extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry.routeData,
      qryType: props.entry.routeData.qryType ? props.entry.routeData.qryType : '',
      getQuery: props.entry.routeData.getQuery ? props.entry.routeData.getQuery : ''
    }
    this.onFieldChange = this.onFieldChange.bind(this)
  }

  changeQryType (e) {
    this.entry.custom.isModifiedSubform = true
    this.setState({ qryType: e })
  }

  changeGetQry (e) {
    this.entry.custom.isModifiedSubform = true
    this.setState({ getQuery: e })
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value.trim()
  }

  render () {
    return (
      <div>
        <Form.Item>
          Query Type
          <Select
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            defaultValue={this.state.entry.qryType ? this.state.entry.qryType : ''}
            onChange={e => {
              this.onFieldChange('qryType', e)
              this.changeQryType(e)
            }}
          >
            <Select.Option value=''>-Select-</Select.Option>
            <Select.Option value='0'>Select Statement</Select.Option>
            <Select.Option value='1'>Insert Statement</Select.Option>
            <Select.Option value='2'>Update Statement</Select.Option>
            <Select.Option value='3'>Delete Statement</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          Get Query Statement
          <Select
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            defaultValue={this.state.getQuery}
            onChange={e => {
              this.onFieldChange('getQuery', e)
              this.changeGetQry(e)
            }}
          >
            <Select.Option value=''>-Select-</Select.Option>
            <Select.Option value='0'>Here</Select.Option>
            <Select.Option value='1'>From Template Profile</Select.Option>
          </Select>
        </Form.Item>
        {this.state.getQuery === '0' ? (
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'SQL Qry Body '}
            <CompileTemplateIcon />
            <AceEditor
              readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              showPrintMargin={false}
              cursorStart={1}
              mode='sql'
              theme='xcode'
              style={{ width: '100%', height: 300 }}
              name='aceEditor'
              value={this.state.entry.data}
              setOptions={{
                showLineNumbers: true,
                newLineMode: 'unix'
              }}
              onChange={e => {
                this.onFieldChange('data', e)
              }}
            />
          </Form.Item>
        ) : (
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Template Profile Key '}
            <CompileTemplateIcon />
            <Input
              placeholder='Template Key'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.templateKey}
              onChange={e => {
                this.onFieldChange('templateKey', e.target.value)
              }}
            />
          </Form.Item>
        )}
      </div>
    )
  }
}

export default DB2ISeriesRouteExecuteQry
