import React from 'react'

import { Row, Modal, Col, message } from 'antd'
import MemoryStore from '../../../utils/memory-store'

import ProcessStepForm from '../process-steps/process-step-form'
import ListViewDND from '../../../reusables/components/list-view-dnd'
import { columnTemplates } from '../../../utils/templates'
import { cleanUpTableData } from '../../../utils/utilities'
import Enums from '../../../utils/enums'

class ProcessSteps extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry,
      dialogOpen: false,
      activeRecord: null,
      isNewDoc: this.props.isNewDoc
    }

    // Setup Event Binding
    this.createRecord = this.createRecord.bind(this)
    this.saveRecord = this.saveRecord.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.deleteRecord = this.deleteRecord.bind(this)
    this.handleValidateRecord = this.handleValidateRecord.bind(this)
    this.formatViewData = this.formatViewData.bind(this)
    this.handleCloseModalDialog = this.handleCloseModalDialog.bind(this)
    this.handleCloseModalDialogConfirm = this.handleCloseModalDialogConfirm.bind(this)
    this.updateArrayData = this.updateArrayData.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.formatViewData()
  }

  UNSAFE_componentWillUpdate() {
    this.formatViewData()
  }

  createRecord() {
    const activeRecord = JSON.parse(JSON.stringify(MemoryStore.bpm.processStepTemplate))
    activeRecord.isNewEntry = true
    activeRecord._id = new Date().getTime().toString()

    this.setState({
      dialogOpen: true,
      activeRecord,
      isNewDoc: true
    })
  }

  saveRecord(tmpActiveRecord) {
    const activeRecord = JSON.parse(JSON.stringify(tmpActiveRecord))
    const entry = this.props.data.processSteps
    const index = entry.findIndex(t => t._id === this.state.activeRecord._id)

    activeRecord.eventStamp = cleanUpTableData(activeRecord.eventStamp, false)
    activeRecord.roleLevels = cleanUpTableData(activeRecord.roleLevels, false)

    if (activeRecord.isNewEntry) {
      entry.push(activeRecord)
    } else {
      entry.splice(index, 1, activeRecord)
    }

    this.setState({
      entry,
      dialogOpen: false,
      activeRecord: JSON.parse(JSON.stringify(MemoryStore.bpm.processStepTemplate)),
      isNewDoc: this.props.isNewDoc
    })
  }

  editRecord(recordId) {
    const index = this.state.entry.findIndex(t => t._id === recordId)
    const activeRecord = JSON.parse(JSON.stringify(this.state.entry[index]))

    activeRecord.isNewEntry = false
    this.setState({ dialogOpen: true, activeRecord })
  }

  deleteRecord(recordId) {
    this.entry.custom.isModifiedSubform = true

    const entry = this.props.entry
    const index = entry.findIndex(t => t._id === recordId)

    entry.splice(index, 1)
    this.setState({ entry })
  }

  handleValidateRecord() {
    const tmpActiveRecord = JSON.parse(JSON.stringify(this.state.activeRecord))
    let success = true
    let errorMessage = ''
    let tmpArray = []
    let errMsg = null

    // Trim Key & Name
    tmpActiveRecord.key = tmpActiveRecord.key.trim()
    tmpActiveRecord.name = tmpActiveRecord.name.trim()

    if (!tmpActiveRecord.key.trim()) {
      errMsg = 'Please provide a Process Step Key'
    } else if (!tmpActiveRecord.name.trim()) {
      errMsg = 'Please provide a Process Step Name'
    } else if (!tmpActiveRecord.processStage.trim()) {
      errMsg = 'Please provide a Process Stage'
    } else if (!tmpActiveRecord.responsibleRole.trim() || tmpActiveRecord.responsibleRole === 'add_new') {
      if (tmpActiveRecord.stepType !== 'Completed Step' && tmpActiveRecord.stepType !== 'Cancelled Step') {
        errMsg = 'Please provide a Responsible Role'
      }
    } else if (!tmpActiveRecord.duration.trim() && tmpActiveRecord.stepType !== 'Completed Step' && tmpActiveRecord.stepType !== 'Cancelled Step') {
      errMsg = 'Please provide a SLA Duration (Minutes)'
    }

    switch (tmpActiveRecord.stepType) {
      case 'Completed Step':
      case 'Cancelled Step':
        tmpActiveRecord.responsibleRole = ''
        tmpActiveRecord.duration = '0'
        tmpActiveRecord.responsibility = '1'
        break
      default:
    }

    if (errMsg) return message.error(errMsg)

    // Duplicate Check for Key and Name
    this.props.entry.map(record => {
      if (tmpActiveRecord.key.toLowerCase() === record.key.toLowerCase() && tmpActiveRecord._id !== record._id) {
        errorMessage = `Key '${tmpActiveRecord.key}' is already being used`
        success = false
      } else if (tmpActiveRecord.name.toLowerCase() === record.name.toLowerCase() && tmpActiveRecord._id !== record._id) {
        errorMessage = `Name '${tmpActiveRecord.name}' is already being used`
        success = false
      }

      return null
    })

    if (success) {
      // Cleanup Empty Visible Objects

      tmpActiveRecord.visibleObjects.map((object, index) => {
        // Cleanup choices and messages
        object.inputOptions.choices = cleanUpTableData(object.inputOptions.choices, true)

        object.inputOptions.choices.map(choice => {
          return delete choice.key
        })

        object.inputOptions.messages = cleanUpTableData(object.inputOptions.messages, false)

        if (!object.id) {
          tmpActiveRecord.visibleObjects.splice(index, 1)
        }

        return true
      })

      this.setState({ activeRecord: tmpActiveRecord })
    }

    if (success) {
      tmpArray = []

      // Validate that no duplicate Visible Objects exist
      tmpActiveRecord.visibleObjects.map(object => {
        return tmpArray.push(object.id)
      })

      tmpArray.map((value, index) => {
        tmpArray.splice(index, 1)
        if (tmpArray.includes(value)) {
          success = false
          errorMessage = `Visible Object '${value}' already exists`
        }
        return success
      })
    }

    this.setState({
      activeRecord: tmpActiveRecord
    })

    if (success) {
      this.saveRecord(tmpActiveRecord)
    } else {
      message.error(errorMessage)
    }

    return null
  }

  formatViewData() {
    // Set isActiveDescription
    this.state.entry.map(process => {
      process.isActiveDescription = process.isActive ? 'Yes' : 'No'
      return process.isActiveDescription
    })
  }

  handleCloseModalDialog() {
    this.entry.custom.isModifiedSubform = false

    this.setState({
      dialogOpen: false,
      activeRecord: JSON.parse(JSON.stringify(MemoryStore.bpm.processStepTemplate))
    })
  }

  handleCloseModalDialogConfirm() {
    if (this.entry.custom.isModifiedSubform) {
      Modal.confirm({
        onOk: () => this.handleCloseModalDialog(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.handleCloseModalDialog()
    }
  }

  updateArrayData(data) {
    this.entry.custom.isModified = true
    this.props.data.processSteps = data

    this.setState({
      entry: this.props.data.processSteps
    })
  }

  render() {
    return (
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <ListViewDND
            btnCreateLabel='Create New'
            viewTitle='Process Steps'
            createRecord={this.createRecord}
            editRecord={this.editRecord}
            deleteRecord={this.deleteRecord}
            filterViewData={this.props.filterViewData}
            viewData={this.state.entry}
            viewColumns={columnTemplates.processSteps}
            theme={this.props.theme}
            callback={this.updateArrayData}
          />
          <Modal
            title='Process Step Profile'
            okButtonProps={{
              style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' }
            }}
            okText='Ok'
            visible={this.state.dialogOpen}
            onOk={this.handleValidateRecord}
            onCancel={this.handleCloseModalDialogConfirm}
            width={1050}
            style={{ top: 10 }}
            destroyOnClose
            maskClosable={false}>
            <ProcessStepForm
              entry={this.state.activeRecord}
              theme={this.props.theme}
              data={this.props.data}
              templateState={this.props.state.templates}
              tabKey={this.props.tabKey}
              isNewDoc={this.state.isNewDoc}
            />
          </Modal>
        </Col>
      </Row>
    )
  }
}

export default ProcessSteps
