import React from 'react'
import Dropzone from 'react-dropzone'
import { Row, Col, Checkbox, Form, Dropdown, Card, Button, Menu, Radio, Table, message, Spin } from 'antd'
import { Icon } from 'react-fa'

import Enums from '../../utils/enums'
import SolutionsContainer from '../containers/solutions-container'
import { getFile, deleteFile } from '../../core/core-utils'
import MemoryStore from '../../utils/memory-store'
import configuration from '../../utils/config.json'

const FormItem = Form.Item

export default class ImportExport extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      checked: {
        keyword: true,
        numbering: true,
        connector: true,
        dataMapping: true,
        template: true,
        bpm: true,
        role: true,
        gatewayAdapter: true,
        tierStructure: true,
        batchLoggingProfile: true,
        event: true,
        batchAction: true,
        nodered: true
      },
      includeData: true,
      includeCredentials: true,
      filterBySolutions: false,
      solutions: [],
      dropzoneProgress: false,
      attachments: [],
      deleteAttachments: [],
      showSpin: false,
      disabled: false
    }

    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleSelectAll = this.handleSelectAll.bind(this)
    this.handleDeselectAll = this.handleDeselectAll.bind(this)
    this.handleRadioChange = this.handleRadioChange.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleSolutionsChange = this.handleSolutionsChange.bind(this)

    // Dropzone
    this.onUpload = this.onUpload.bind(this)
    this.onAttachmentUploaded = this.onAttachmentUploaded.bind(this)
    this.onAttachmentDelete = this.onAttachmentDelete.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  handleOnChange (key, e) {
    const tmpArr = this.state.checked

    switch (key) {
      case Enums.APP_IDS.KEYWORDS:
        tmpArr.keyword = e.target.checked
        break
      case Enums.APP_IDS.NUMBERING:
        tmpArr.numbering = e.target.checked
        break
      case Enums.APP_IDS.CONNECTORS:
        tmpArr.connector = e.target.checked
        break
      case Enums.APP_IDS.DATA_MAPPING:
        tmpArr.dataMapping = e.target.checked
        break
      case Enums.APP_IDS.TEMPLATES:
        tmpArr.template = e.target.checked
        break
      case Enums.APP_IDS.BPM:
        tmpArr.bpm = e.target.checked
        break
      case Enums.APP_IDS.ROLES:
        tmpArr.role = e.target.checked
        break
      case Enums.APP_IDS.GATEWAY_ADAPTERS:
        tmpArr.gatewayAdapter = e.target.checked
        break
      case Enums.APP_IDS.TIER_STRUCTURES:
        tmpArr.tierStructure = e.target.checked
        break
      case Enums.APP_IDS.BATCH_ACTIONS:
        tmpArr.batchAction = e.target.checked
        break
      case Enums.APP_IDS.EVENTS:
        tmpArr.event = e.target.checked
        break
      case Enums.APP_IDS.BATCH_LOGGING:
        tmpArr.batchLoggingProfile = e.target.checked
        break
      case Enums.APP_IDS.NODE_RED:
        tmpArr.nodered = e.target.checked
        break
      default:
    }

    this.setState({ checked: tmpArr })
  }

  handleSelectAll () {
    const tmpObj = this.state.checked

    for (const item in tmpObj) {
      tmpObj[item] = true
    }

    this.setState({ checked: tmpObj })
  }

  handleDeselectAll () {
    const tmpObj = this.state.checked

    for (const item in tmpObj) {
      tmpObj[item] = false
    }

    this.setState({ checked: tmpObj })
  }

  handleRadioChange (value) {
    this.setState({ includeData: value })
  }

  handleExcludeConfigChange (value) {
    this.setState({ includeCredentials: value })
  }

  handleFilterChange (e) {
    this.setState({ filterBySolutions: e.target.checked })
  }

  handleSolutionsChange (solutions) {
    this.setState({ solutions })
  }

  // DROPZONE FUNCTIONS
  onUpload (fileName, contentType, data) {
    this.setState({ showSpin: true })

    MemoryStore.agilite.Files.uploadFile(fileName, contentType, data)
      .then(res => {
        this.onAttachmentUploaded(res)
        this.setState({ showSpin: false })
      })
      .catch(err => {
        if (err.response) {
          message.error(err.response.data.errorMessage)
        } else {
          message.error(Enums.MESSAGES.UNKNOWN_ERROR)
        }

        this.setState({ showSpin: false })
      })
  }

  onAttachmentUploaded (res) {
    if (res) {
      const tmpThis = this

      setTimeout(() => {
        const entry = {
          _id: res.data._id,
          fileName: res.data.filename,
          key: res.data._id
        }

        tmpThis.setState({
          showProgress: false,
          attachments: [entry]
        })
      }, 800)
    }
  }

  getDropzone ({ handleAttachmentUpload }) {
    return (
      <Dropzone
        accept='.json'
        multiple={false}
        maxSize={configuration.dropZoneConfig.import.maxFileSize}
        onDrop={files => {
          // eslint-disable-next-line
          const reader = new FileReader()

          reader.onabort = () => message.error('File reading was aborted')
          reader.onerror = () => message.error('File reading has failed')
          reader.onload = () => {
            handleAttachmentUpload(files[0].name, Enums.VALUES_STRINGS.APPLICATION_OCTET_STREAM, reader.result)
          }
          if (files.length > 0) {
            reader.readAsArrayBuffer(files[0])
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section className='dropzone'>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Click here or drop JSON file for importing</p>
            </div>
          </section>
        )}
      </Dropzone>
    )
  }

  UNSAFE_componentWillMount () {
    MemoryStore.activeEntries.import = {
      _id: 'Import',
      custom: {
        isModified: false
      }
    }
  }

  componentWillUnmount () {
    delete MemoryStore.activeEntries.import
  }

  onCancel () {
    const tmpDeleteIds = this.state.deleteAttachments

    // First Add any temporary attachments to the delete list
    for (const x in this.state.attachments) {
      tmpDeleteIds.push(this.state.attachments[x]._id)
    }

    for (const i in tmpDeleteIds) {
      deleteFile(tmpDeleteIds[i])
    }

    this.setState({ attachments: [], deleteAttachments: [] })

    return null
  }

  onAttachmentDelete () {
    // There's only 1 attachment, so we can just add it for deletion and clear the array
    const id = this.state.attachments[0]._id
    const tmpIds = this.state.deleteAttachments
    tmpIds.push(id)

    this.setState({
      attachments: [],
      deleteAttachments: tmpIds
    })
  }

  render () {
    const menu = (
      <Menu>
        <Menu.Item key='0' onClick={this.handleSelectAll}>
          Select All
        </Menu.Item>
        <Menu.Item key='1' onClick={this.handleDeselectAll}>
          Deselect All
        </Menu.Item>
      </Menu>
    )

    const columns = [
      {
        title: 'Attachments',
        dataIndex: 'fileName',
        key: 'key',
        render: (text, entry) => {
          return (
            <span>
              {/* eslint-disable-next-line */}
              <a href='javascript:' onClick={() => getFile(entry.key, entry.fileName)}>
                {entry.fileName}
              </a>
            </span>
          )
        }
      },
      {
        title: '',
        key: 'actions',
        render: () => {
          return (
            <span
              style={{
                color: 'red',
                fontSize: 16,
                cursor: 'pointer',
                padding: 3,
                display: this.state.disabled ? 'none' : 'contents'
              }}
              onClick={() => this.onAttachmentDelete()}
            >
              <Icon name='trash-o' />
            </span>
          )
        }
      }
    ]

    return (
      <Card
        title={this.props.cardTitle}
        style={{ marginTop: 20, textAlign: 'left' }}
        bordered
        type='inner'
        extra={
          <Dropdown overlay={menu} trigger={['click']}>
            <Icon
              name='ellipsis-v'
              style={{
                padding: 5,
                fontSize: 17,
                color: '#B4483E',
                cursor: 'pointer'
              }}
            />
          </Dropdown>
        }
      >
        <Row type='flex' justify='space-between' gutter={10}>
          <Col xs={23} lg={16}>
            <Card title={this.props.leftColumnTitle} type='inner'>
              <Form
                autoComplete='off'
                style={{ marginTop: 20 }}
              >
                <Form.Item>
                  <Radio.Group disabled={this.state.disabled} defaultValue={this.state.includeData} onChange={(e) => this.handleRadioChange(e.target.value)}>
                    <Radio value={false}>Profiles Only</Radio>
                    {/* eslint-disable-next-line */}
                    <Radio value={true}>Profiles And Data</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    disabled={this.state.disabled}
                    style={{ marginRight: 10 }}
                    defaultChecked={false}
                    checked={this.state.filterBySolutions}
                    onChange={(e) => this.handleFilterChange(e)}
                  >
                    Filter By Solution(s)
                  </Checkbox>
                </Form.Item>
                {this.state.filterBySolutions ?
                  <div style={{ width: '80%' }}>
                    <SolutionsContainer
                      disabled={this.state.disabled}
                      defaultValue={this.state.solutions}
                      onValueChange={this.handleSolutionsChange}
                      title='Solution(s)'
                    />
                  </div>
                  : null}
                <Form.Item>
                  <Checkbox
                    disabled={this.state.disabled}
                    style={{ marginRight: 10 }}
                    defaultChecked={false}
                    checked={this.state.includeCredentials}
                    onChange={(e) => this.handleExcludeConfigChange(e.target.checked)}
                  >
                    Include Environment-based Configurations (e.g. Connectors API Configuration)
                  </Checkbox>
                </Form.Item>
                {this.props.type === 'import' ?
                  <div style={{ marginTop: 20 }}>
                    {this.state.attachments.length === 0 ? 
                      <div style={{ zIndex: 1, position: 'relative' }}>
                        <this.getDropzone handleAttachmentUpload={this.onUpload} />
                      </div>
                      :
                      <Table
                        columns={columns}
                        dataSource={this.state.attachments}
                        bordered
                        showHeader
                        pagination={false}
                        size='middle'
                      />}
                    <div style={{ margin: 10 }}>
                      {this.state.showSpin ?
                        <Spin />
                        : null}
                    </div>
                  </div>
                  : null}
              </Form>
            </Card>
          </Col>
          <Col xs={23} lg={8}>
            <Card title={this.props.rightColumnTitle} type='inner'>
              <Form
                autoComplete='off'
                style={{ marginTop: 20 }}
              >
                <FormItem>
                  <Checkbox
                    disabled={this.state.disabled}
                    onChange={e => {
                      this.handleOnChange('batchactions', e)
                    }}
                    style={{ float: 'left' }}
                    defaultChecked
                    checked={this.state.checked.batchAction}
                  >
                    Batch Actions
                  </Checkbox>
                </FormItem>
                <FormItem>
                  <Checkbox
                    disabled={this.state.disabled}
                    onChange={e => {
                      this.handleOnChange('batchlogging', e)
                    }}
                    style={{ float: 'left' }}
                    defaultChecked
                    checked={this.state.checked.batchLoggingProfile}
                  >
                    Batch Logging
                  </Checkbox>
                </FormItem>
                <FormItem>
                  <Checkbox
                    disabled={this.state.disabled}
                    onChange={e => {
                      this.handleOnChange('bpm', e)
                    }}
                    style={{ float: 'left' }}
                    defaultChecked
                    checked={this.state.checked.bpm}
                  >
                    BPM
                  </Checkbox>
                </FormItem>
                <FormItem>
                  <Checkbox
                    disabled={this.state.disabled}
                    onChange={e => {
                      this.handleOnChange('connectors', e)
                    }}
                    style={{ float: 'left' }}
                    defaultChecked
                    checked={this.state.checked.connector}
                  >
                    Connectors
                  </Checkbox>
                </FormItem>
                <FormItem>
                  <Checkbox
                    disabled={this.state.disabled}
                    onChange={e => {
                      this.handleOnChange('datamappings', e)
                    }}
                    style={{ float: 'left' }}
                    defaultChecked
                    checked={this.state.checked.dataMapping}
                  >
                    Data Mapping
                  </Checkbox>
                </FormItem>
                <FormItem>
                  <Checkbox
                    disabled={this.state.disabled}
                    onChange={e => {
                      this.handleOnChange('events', e)
                    }}
                    style={{ float: 'left' }}
                    defaultChecked
                    checked={this.state.checked.event}
                  >
                    Events
                  </Checkbox>
                </FormItem>
                <FormItem>
                  <Checkbox
                    disabled={this.state.disabled}
                    onChange={e => {
                      this.handleOnChange('gatewayadapters', e)
                    }}
                    style={{ float: 'left' }}
                    defaultChecked
                    checked={this.state.checked.gatewayAdapter}
                  >
                    Gateway Adapters
                  </Checkbox>
                </FormItem>
                <FormItem>
                  <Checkbox
                    disabled={this.state.disabled}
                    onChange={e => {
                      this.handleOnChange('keywords', e)
                    }}
                    style={{ float: 'left' }}
                    defaultChecked
                    checked={this.state.checked.keyword}
                  >
                    Keywords
                  </Checkbox>
                </FormItem>
                <FormItem>
                  <Checkbox
                    disabled={this.state.disabled}
                    onChange={e => {
                      this.handleOnChange('nodered', e)
                    }}
                    style={{ float: 'left' }}
                    defaultChecked
                    checked={this.state.checked.nodered}
                  >
                    Node-RED
                  </Checkbox>
                </FormItem>
                <FormItem>
                  <Checkbox
                    disabled={this.state.disabled}
                    onChange={e => {
                      this.handleOnChange('numbering', e)
                    }}
                    style={{ float: 'left' }}
                    defaultChecked
                    checked={this.state.checked.numbering}
                  >
                    Numbering
                  </Checkbox>
                </FormItem>
                <FormItem>
                  <Checkbox
                    disabled={this.state.disabled}
                    onChange={e => {
                      this.handleOnChange('roles', e)
                    }}
                    style={{ float: 'left' }}
                    defaultChecked
                    checked={this.state.checked.role}
                  >
                    Roles
                  </Checkbox>
                </FormItem>
                <FormItem>
                  <Checkbox
                    disabled={this.state.disabled}
                    onChange={e => {
                      this.handleOnChange('templates', e)
                    }}
                    style={{ float: 'left' }}
                    defaultChecked
                    checked={this.state.checked.template}
                  >
                    Templates
                  </Checkbox>
                </FormItem>
                <FormItem>
                  <Checkbox
                    disabled={this.state.disabled}
                    onChange={e => {
                      this.handleOnChange('tierstructures', e)
                    }}
                    style={{ float: 'left' }}
                    defaultChecked
                    checked={this.state.checked.tierStructure}
                  >
                    Tier Structures
                  </Checkbox>
                </FormItem>
              </Form>
            </Card>
          </Col>
        </Row>
        <Button
          style={{
            backgroundColor: '#67AD5B',
            color: 'white',
            float: 'left',
            marginTop: 20
          }}
          onClick={() => {
            this.setState({ disabled: true })

            this.props.onValidate(this.state, (success) => {
              if (success) {
                this.setState({ attachments: [], disabled: false })
              } else {
                this.setState({ disabled: false })
              }
            })
          }}
          disabled={this.state.disabled}
        >
          {this.props.buttonLabel}
        </Button>
      </Card>
    )
  }
}
