import React from 'react'
import MemoryStore from '../../../utils/memory-store'
import { Card, Switch, Input, Form, Row, Col } from 'antd'

/*
  Custom Components Import
*/
import FieldMappingSourceJson from './src-attributes/field-mapping-src-json'
import FieldMappingSourceExcel from './src-attributes/field-mapping-src-excel'
import FieldMappingDestinationJson from './des-attributes/field-mapping-des-json'
import FieldMappingDestinationExcel from './des-attributes/field-mapping-des-excel'
import TooltipIcon from '../../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../../utils/enums-tooltips'
import Description from '../../../reusables/components/description'

class FieldMappingForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: this.props.entry
    }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry[key] = value
  }

  render () {
    return (
      <Row>
        <Col span={24}>
          <Card>
            <Row>
              <Col xs={24} lg={16}>
                <h3>General Details</h3>
                <Form.Item>
                  <Switch
                    disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                    checkedChildren='Active'
                    unCheckedChildren='Inactive'
                    defaultChecked={this.state.entry.isActive}
                    onChange={(e) => {
                      this.onFieldChange('isActive', e)
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <span style={{ color: 'red' }}>* </span>{'Mapping Name '}
                  <TooltipIcon title={EnumsTooltips.general.profileName} />
                  <Input
                    name='mapping_name'
                    placeholder='Provide a Mapping Profile Name'
                    disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                    defaultValue={this.state.entry.name}
                    required
                    onChange={(e) => {
                      this.onFieldChange('name', e.target.value)
                    }}
                  />
                </Form.Item>
                <Description
                  disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                  description={this.state.entry.description}
                  onChange={(e) => {
                    this.onFieldChange('description', e.target.value)
                  }}
                />
              </Col>
            </Row>
            <Row justify='space-between' style={{ marginTop: 20 }}>
              <Col span={11}>
                <h3>Source Details</h3>
                {this.props.profile.sourceType === '1' ? <FieldMappingSourceJson tabKey={this.props.tabKey} fieldMapping={this.props.entry} /> : null}
                {this.props.profile.sourceType === '2' ? <FieldMappingSourceExcel tabKey={this.props.tabKey} isParentMulti={this.props.isParentMulti} fieldMapping={this.props.entry} /> : null}
              </Col>
              <Col span={11}>
                <h3>Destination Details</h3>
                {this.props.profile.destinationType === '1' ? <FieldMappingDestinationJson tabKey={this.props.tabKey} fieldMapping={this.props.entry} /> : null}
                {this.props.profile.destinationType === '2' ? <FieldMappingDestinationExcel tabKey={this.props.tabKey} isParentMulti={this.props.isParentMulti} fieldMapping={this.props.entry} /> : null}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default FieldMappingForm
