import { connect } from 'react-redux'
import MultiEntryMappings from '../components/multi-entry-profiles/multi-entry-mappings'
import { filterMultiEntryViewData } from '../data-mapping-utils'

const mapStateToProps = (state) => {
  return {
    theme: state.core.theme,
    state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    filterViewData: (data, searchFilter, callback) => {
      filterMultiEntryViewData(data, searchFilter, callback)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiEntryMappings)
