import uniqueId from 'lodash/uniqueId'
import MemoryStore from '../utils/memory-store'
import DataMappingFormContainer from '../data-mapping/containers/data-mapping-form-container'
import Enums from '../utils/enums'
import { formatRecordData } from '../utils/utilities'

export const filterViewData = (viewData, searchFilter) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return viewData
  } else {
    return viewData.filter(t =>
      (
        (t.data.name.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.key.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.description.toLowerCase().indexOf(searchFilter) > -1)
      )
    )
  }
}

export const filterMappingViewData = (data, searchFilter, callback) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return callback(data)
  } else {
    return callback(data.filter(t =>
      (
        (t.name.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.srcPath.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.srcType.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.srcName.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.desPath.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.desType.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.desName.toLowerCase().indexOf(searchFilter) > -1)
      )
    ))
  }
}

export const filterMultiEntryViewData = (data, searchFilter, callback) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return callback(data)
  } else {
    return callback(data.filter(t =>
      (
        (t.name.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.description.toLowerCase().indexOf(searchFilter) > -1)
      )
    ))
  }
}

export const formatRecord = (entry, data) => {
  let tempIndex = 0

  entry = formatRecordData(entry)
  entry.data.isActiveDescription = entry.data.isActive ? 'Yes' : 'No' // Needed for View Column

  if (entry.custom.isNewDoc) {
    // Add new Entry to State
    entry.custom.isNewDoc = false
    entry.custom.action = 'create'
    data.push(entry)
  } else {
    if (entry.custom.isSavedDoc) {
      entry.custom.action = 'update'
    } else {
      entry.custom.action = 'create'
    }

    // Update Entry in State
    tempIndex = data.findIndex(t => t._id === entry._id)
    data[tempIndex] = entry
  }

  return data
}

export const reverseMapping = (currEntryData, callback) => {
  const newEntry = JSON.parse(JSON.stringify(MemoryStore.datamappings.dataTemplate))
  newEntry._id = new Date().getTime().toString()
  newEntry.custom.tempId = newEntry._id

  const entryData = newEntry.data
  let multiProfile = {}
  let newMultiProfile = {}

  const tabType = Enums.VALUES_STRINGS.FORM
  const content = DataMappingFormContainer
  const closable = true
  const key = newEntry._id
  const app = Enums.APP_IDS.DATA_MAPPING
  let payload = {}
  let title = Enums.APP_PROFILE_TITLES.datamappings + Enums.VALUES_STRINGS.COLON + Enums.VALUES_STRINGS.SPACE

  // Using current entry, populate the src and des type, as well as the mappings
  entryData.key = currEntryData.key + '_reverse'
  entryData.name = currEntryData.name + ' (Reversed)'
  entryData.isActive = currEntryData.isActive
  entryData.groupName = currEntryData.groupName
  entryData.sourceType = currEntryData.destinationType
  entryData.destinationType = currEntryData.sourceType

  // Populate Field Mappings
  for (const x in currEntryData.fieldMappings) {
    entryData.fieldMappings.push(_reverseMappingExtended(currEntryData.fieldMappings[x]))
  }

  // Populate Multi Entry Profile
  for (const x in currEntryData.multiEntryProfiles) {
    multiProfile = currEntryData.multiEntryProfiles[x]

    newMultiProfile = {
      _id: uniqueId(new Date().getTime().toString()),
      isNewEntry: false,
      isActive: multiProfile.isActive,
      name: multiProfile.name,
      description: multiProfile.description,
      srcJson: {
        path: multiProfile.desJson.path
      },
      srcExcel: {
        direction: multiProfile.desExcel.direction,
        worksheet: multiProfile.desExcel.worksheet,
        startingPoint: multiProfile.desExcel.startingPoint
      },
      desJson: {
        path: multiProfile.srcJson.path
      },
      desExcel: {
        direction: multiProfile.srcExcel.direction,
        worksheet: multiProfile.srcExcel.worksheet,
        startingPoint: multiProfile.srcExcel.startingPoint
      },
      multiEntryMappings: []
    }

    // Populate Field Mappings
    for (const y in multiProfile.multiEntryMappings) {
      newMultiProfile.multiEntryMappings.push(_reverseMappingExtended(multiProfile.multiEntryMappings[y]))
    }

    entryData.multiEntryProfiles.push(newMultiProfile)
  }

  newEntry.data = entryData
  title += entryData.key

  payload = {
    content,
    title,
    key,
    app,
    tabType,
    closable
  }

  callback(payload)

  MemoryStore.activeEntries[newEntry._id] = newEntry
  return null
}

export const createCopy = (currEntryData, callback) => {
  const newEntry = JSON.parse(JSON.stringify(MemoryStore.datamappings.dataTemplate))
  newEntry._id = new Date().getTime().toString()
  newEntry.custom.tempId = newEntry._id

  const entryData = JSON.parse(JSON.stringify(currEntryData))
  const tabType = Enums.VALUES_STRINGS.FORM
  const content = DataMappingFormContainer
  const closable = true
  const key = newEntry._id
  const app = Enums.APP_IDS.DATA_MAPPING

  let title = Enums.APP_PROFILE_TITLES.datamappings + Enums.VALUES_STRINGS.COLON + Enums.VALUES_STRINGS.SPACE
  let payload = {}

  // Using current entry, populate the src and des type, as well as the mappings
  entryData.key = entryData.key + '_copy'
  entryData.name = entryData.name + ' (Copy)'

  newEntry.data = entryData
  title += entryData.key

  payload = {
    content,
    title,
    key,
    app,
    tabType,
    closable
  }

  MemoryStore.activeEntries[newEntry._id] = newEntry
  callback(payload)
}

/*
  PRIVATE FUNCTIONS
*/
const _reverseMappingExtended = (mapping) => {
  return {
    _id: uniqueId(new Date().getTime().toString()),
    isNewEntry: false,
    isActive: mapping.isActive,
    isParentMulti: mapping.isParentMulti,
    name: mapping.name,
    description: mapping.description,
    srcPath: mapping.desPath,
    srcName: mapping.desName,
    srcType: mapping.desType,
    srcNotes: mapping.desNotes,
    desPath: mapping.srcPath,
    desName: mapping.srcName,
    desType: mapping.srcType,
    desNotes: mapping.srcNotes
  }
}
