import React from 'react'
import { Form, Input, Select, Tooltip, Checkbox } from 'antd'
import AceEditor from 'react-ace'
import CompileTemplateIcon from '../../../../../../reusables/components/compile-template-icon'
import MemoryStore from '../../../../../../utils/memory-store'

import 'brace/mode/sql'
import 'brace/theme/xcode'

class SnowflakeRouteExecuteQry extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry.routeData,
      getQuery: props.entry.routeData.getQuery || '0',
      checkboxOptions: [
        { label: 'Number', value: 'Number' },
        { label: 'Date', value: 'Date' }
      ]
    }

    // Bindings
    this.changeGetQry = this.changeGetQry.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
  }

  UNSAFE_componentWillMount () {
    if (!this.props.entry.routeData.getQuery) {
      this.props.entry.routeData.getQuery = '0'
    }
  }

  changeGetQry (e) {
    this.entry.custom.isModifiedSubform = true
    this.setState({ getQuery: e })
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value
  }

  render () {
    return (
      <div>
        <Form.Item>
          Get Query Statement
          <Select
            className='get_query'
            disabled={this.props.disabled}
            defaultValue={this.state.getQuery}
            onChange={e => {
              this.onFieldChange('getQuery', e)
              this.changeGetQry(e)
            }}
          >
            <Select.Option value='0'>Here</Select.Option>
            <Select.Option value='1'>From Template Profile</Select.Option>
          </Select>
        </Form.Item>
        {this.state.getQuery === '0' ? (
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'SQL Qry Body '}
            <CompileTemplateIcon />
            <AceEditor
              readOnly={this.props.disabled}
              showPrintMargin={false}
              cursorStart={1}
              mode='sql'
              theme='xcode'
              style={{ width: '100%', height: 300 }}
              name='aceEditor'
              value={this.state.entry.sqlText}
              setOptions={{
                showLineNumbers: true,
                newLineMode: 'unix'
              }}
              onChange={e => {
                this.onFieldChange('sqlText', e)
              }}
            />
          </Form.Item>
        ) : (
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Template Profile Key '}
            <CompileTemplateIcon />
            <Input
              name='template_key'
              placeholder='Template Key'
              disabled={this.props.disabled}
              defaultValue={this.state.entry.templateKey}
              onChange={e => {
                this.onFieldChange('templateKey', e.target.value)
              }}
            />
          </Form.Item>
        )}
        <Form.Item>
          <Tooltip title='Fetch the following Types as Strings'>Fetch As String</Tooltip><br />
          <Checkbox.Group
            options={this.state.checkboxOptions}
            defaultValue={this.state.entry.fetchAsString}
            onChange={checkedValues => {
              this.onFieldChange('fetchAsString', checkedValues)
            }}
          />
        </Form.Item>
        <Form.Item>
          {'Binds '}
          <CompileTemplateIcon />
          <AceEditor
            readOnly={this.props.disabled}
            showPrintMargin={false}
            cursorStart={1}
            mode='json'
            theme='xcode'
            style={{ width: '100%', height: 300 }}
            name='aceEditor'
            value={this.state.entry.binds}
            setOptions={{
              showLineNumbers: true,
              newLineMode: 'unix'
            }}
            onChange={e => {
              this.onFieldChange('binds', e)
            }}
          />
        </Form.Item>
      </div>
    )
  }
}

export default SnowflakeRouteExecuteQry
