import React from 'react'
import Enums from '../../utils/enums'
import { Card, Button, Col, Row } from 'antd'
import AgiliteHeader from '../../reusables/components/agilite-header'

const CoreVerifyFailed = () => (
  <Row type='flex' justify='center'>
    <Col xs={23} sm={23} md={16} lg={12} xl={10} xxl={8}>
      <div>
        <center>
          <AgiliteHeader header={Enums.VALUES_STRINGS.AGILITE_WELCOME} />
        </center>
      </div>
      <Card
        type='inner'
        title='Apologies. We could not verify your accout. Please contact us at support@agilite.io'
      >
        <div className='row'>
          <div className='col-xs-12' style={{ textAlign: 'center' }}>
            <Button
              style={{
                backgroundColor: '#67AD5B',
                color: 'white',
                width: 'auto'
              }}
              onClick={e => {
                // TODO: This block of code is used multiple times and should be centralized in utilities.js
                e.preventDefault()
                const port = window.location.port !== '' ? ':' + window.location.port : ''
                window.open(window.location.protocol + '//' + window.location.hostname + port, '_self')
              }}
            >
              Return To Home
            </Button>
          </div>
        </div>
      </Card>
    </Col>
  </Row>
)

export default CoreVerifyFailed
