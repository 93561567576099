import React from 'react'

import Enums from '../../utils/enums'

import CoreLoading from './core-loading'
import CoreSignInContainer from '../containers/core-sign-in-container'
import CoreForgotPasswordContainer from '../containers/core-forgot-password-container'
import CoreForgotPasswordConfirmationContainer from '../containers/core-forgot-password-confirmation-container'
import CoreRegisterContainer from '../containers/core-register-container'
import CoreRegisterConfirmation from '../components/core-register-confirmation'
import CoreResetPasswordContainer from '../containers/core-reset-password-container'
import CoreResetPasswordConfirm from '../components/core-reset-passoword-confirmation'
import CoreTeamActivateContainer from '../containers/core-team-activate-container'
import CoreTeamActivateConfirmationContainer from '../containers/core-team-activate-confirmation-container'
import CoreTeamActivateSuccess from '../components/core-team-activate-success'
import CoreVerifySuccess from '../components/core-verify-success'
import CoreVerifyFailed from '../components/core-verify-failed'
import CoreVerifyConfirmContainer from '../containers/core-verify-confirm-container'

const CoreAnonymousWrapper = ({ facet }) => (
  <div>
    {facet === Enums.FACETS.LOADING ? <CoreLoading /> : null}
    {facet === Enums.FACETS.SIGN_IN ? <CoreSignInContainer /> : null}
    {facet === Enums.FACETS.FORGOT ? <CoreForgotPasswordContainer /> : null}
    {facet === Enums.FACETS.FORGOT_CONFIRM ? (
      <CoreForgotPasswordConfirmationContainer />
    ) : null}
    {facet === Enums.FACETS.REGISTER ? <CoreRegisterContainer /> : null}
    {facet === Enums.FACETS.REGISTER_CONFIRM ? (
      <CoreRegisterConfirmation />
    ) : null}
    {facet === Enums.FACETS.RESET ? <CoreResetPasswordContainer /> : null}
    {facet === Enums.FACETS.RESET_CONFIRM ? <CoreResetPasswordConfirm /> : null}
    {facet === Enums.FACETS.ACTIVATE ? <CoreTeamActivateContainer /> : null}
    {facet === Enums.FACETS.ACTIVATE_CONFIRMATION ? (
      <CoreTeamActivateConfirmationContainer />
    ) : null}
    {facet === Enums.FACETS.ACTIVATE_SUCCESS ? (
      <CoreTeamActivateSuccess />
    ) : null}
    {facet === Enums.FACETS.VERIFY_SUCCESS ? <CoreVerifySuccess /> : null}
    {facet === Enums.FACETS.VERIFY_FAILED ? <CoreVerifyFailed /> : null}
    {facet === Enums.FACETS.VERIFY_CONFIRM ? (
      <CoreVerifyConfirmContainer />
    ) : null}
    <div style={{ width: '100%', height: 50 }}>
      {/* This is used to create spacing below cards when resolution is around 1280 x 720 */}
    </div>
  </div>
)

export default CoreAnonymousWrapper
