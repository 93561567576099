import React from 'react'
import { Row, Col, Input, Form } from 'antd'
import MemoryStore from '../../../../utils/memory-store'

class SourceDetailsJson extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]
  }

  onChange (key, value) {
    this.entry.custom.isModified = true
    this.entry.data.srcJson[key] = value
  }

  render () {
    return (
      <Row>
        <Col xs={24} md={12}>
          <h4>JSON Details</h4>
          <Form.Item>
            JSON Starting Point (optional)
            <Input
              name='json_starting_point'
              disabled={
                MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'
              }
              placeholder='Provide a path to the starting point in the JSON Object'
              defaultValue={this.entry.data.srcJson.startingPoint}
              onChange={e => {
                this.onChange('startingPoint', e.target.value)
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    )
  }
}

export default SourceDetailsJson
