import React from 'react'
import { Form, Row, Col, Input, Select } from 'antd'
import MemoryStore from '../../../utils/memory-store'

class MultiEntryFormExcel extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry
    }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry[key] = value
  }

  render () {
    return (
      <Row style={{ marginTop: 55 }}>
        <Col span={24}>
          <Form name='multi-entry-form-excel'>
            <Form.Item>
              List Direction
              <Select
                className='list_direction'
                disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                placeholder='Specify a Direction for each Entry'
                defaultValue={this.props.entry.direction}
                onChange={(value) => {
                  this.onFieldChange('direction', value)
                }}
              >
                <Select.Option value='1'>Horizontal</Select.Option>
                <Select.Option value='2'>Vertical</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <span style={{ color: 'red' }}>* </span>Worksheet Name
              <Input
                name='worksheet_name'
                disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                placeholder='Provide an Excel Worksheet Name'
                defaultValue={this.props.entry.worksheet}
                required
                onChange={(e) => {
                  this.onFieldChange('worksheet', e.target.value)
                }}
              />
            </Form.Item>
            <Form.Item>
              <span style={{ color: 'red' }}>* </span>Starting Point
              <Input
                name='starting_point'
                disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                placeholder='(Horizontal=Row No/Vertical=Col Letter)'
                defaultValue={this.props.entry.startingPoint}
                onChange={(e) => {
                  this.onFieldChange('startingPoint', e.target.value)
                }}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default MultiEntryFormExcel
