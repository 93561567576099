import React from 'react'
import MemoryStore from '../../../utils/memory-store'
import { Icon } from 'react-fa'
import BPMFormGeneral from './bpm-form-general'
import BPMAdministration from './bpm-form-administartion'
import ProcessStepsContainer from '../../containers/process-steps-container'
import { Row, Col, Card, Tabs, Form, Button, Popconfirm, message, Dropdown, Menu, Modal, Input } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import Enums from '../../../utils/enums'
import TableKeyValueEditableDND from '../../../reusables/components/table-key-value-editable-dnd'
import AgiliteTheme from '../../../utils/agilite-theme'
import { formatAliasValue } from '../../../utils/utilities'

const TabPane = Tabs.TabPane
const MenuItem = Menu.Item

class BPMForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]

    this.profileKey = ''

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc,
      isLoading: false,
      modalOpen: false,
      errorText: '',
      btnDisabled: false,
      key: this.entry.data.key
    }

    // Setup Event Binding
    this.clearBPMPromptConfirm = this.clearBPMPromptConfirm.bind(this)
    this.handleOnFieldChange = this.handleOnFieldChange.bind(this)
    this.cancelProfileConfirm = this.cancelProfileConfirm.bind(this)
    this.updateKeywords = this.updateKeywords.bind(this)
  }

  UNSAFE_componentWillMount () {
    if (MemoryStore.userProfile.teamPrivileges.bpm !== 'Reader') {
      this.props.fetchAllData('templates', (success, error) => {
        if (error) {
          message.error(error)
        }
      })
    }
  }

  async clearBPMPromptConfirm (id) {
    const msg = 'BPM History successfully cleared'
    const tmpThis = this

    try {
      await tmpThis.props.onClearHistoryData(id)
      message.success(msg)
    } catch (error) {
      message.error(error)
    }
  }

  handleOnFieldChange (key, value) {
    if (key === 'appAdmin' && value === 'add_new') value = ''
    if (key === 'key') this.setState({ key: value })

    this.entry.custom.isModified = true
    this.entry.data[key] = value
  }

  cancelProfileConfirm () {
    if (this.entry.custom.isModified) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  saveProfile () {
    const tmpThis = this

    tmpThis.setState({
      isLoading: true
    })

    // Reset isModified and isModifiedSubform properties
    this.entry.custom.isModified = false
    this.entry.custom.isModifiedSubform = false

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, (err) => {
      tmpThis.setState({
        isLoading: false
      })

      if (err) {
        message.error(err)
      } else {
        tmpThis.props.closeTab(
          tmpThis.props.tabKey,
          tmpThis.props.tabKey,
          tmpThis.props.tabs
        )
      }
    })
  }

  updateKeywords (result) {
    this.entry.custom.isModified = true
    this.entry.data.keywords = result
  }

  getButtonProps () {
    if (this.state.btnDisabled) {
      return {
        disabled: this.state.btnDisabled
      }
    } else {
      return {
        disabled: this.state.btnDisabled,
        style: {
          backgroundColor: '#67AD5B',
          borderColor: '#67AD5B'
        }
      }
    }
  }

  onProfileKeyChange (value) {
    this.profileKey = formatAliasValue(value)

    if (this.profileKey === this.entry.data.key) {
      this.setState({
        errorText: 'New Profile Key cannot be the same as existing Profile Key',
        btnDisabled: true
      })
    } else if (!this.profileKey.trim()) {
      this.setState({
        errorText: 'Please provide a valid Profile Key',
        btnDisabled: true
      })
    } else {
      this.setState({ errorText: '', btnDisabled: false })
    }
  }

  render () {
    const menu = (
      <Menu>
        <MenuItem key='0'>
          <Popconfirm
            title='Are you sure you want to Clear All BPM History?'
            onConfirm={() => this.clearBPMPromptConfirm(this.entry.data.key)}
            okText='Yes'
            cancelText='No'
          >
            <span style={{ padding: '10 0' }}>Clear All BPM History</span>
          </Popconfirm>
        </MenuItem>
        <Menu.Item key='1'>
          <Popconfirm
            title='Are you sure you want to change the Profile Key?'
            onConfirm={() => {
              this.profileKey = this.entry.data.key
              this.setState({ modalOpen: true })
              this.onProfileKeyChange(this.entry.data.key)
            }}
            okText='Yes'
            cancelText='No'
            placement='left'
          >
            <span style={{ padding: '10 0' }}>Edit Profile Key</span>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    )

    return (
      <Row type='flex' justify='center'>
        <Col xs={23} lg={20} xl={16}>
          <Card
            title={this.props.title}
            type='inner'
            style={{ textAlign: 'left' }}
            extra={!this.state.isNewDoc && MemoryStore.userProfile.teamPrivileges.bpm !== 'Reader' ?
              <Dropdown overlay={menu} trigger={['click']}>
                <Icon
                  name='ellipsis-v'
                  style={{
                    padding: 5,
                    fontSize: 17,
                    color: '#B4483E',
                    cursor: 'pointer'
                  }}
                />
              </Dropdown>
              : null}
          >
            <Form
              autoComplete='off'
            >
              <Tabs defaultActiveKey='1' animated={false}>
                <TabPane tab='General' key='1'>
                  <BPMFormGeneral
                    onChange={this.handleOnFieldChange}
                    tabKey={this.props.tabKey}
                    onSaveRoleProfile={this.props.onSaveRoleProfile}
                    theme={this.props.theme}
                    profileKey={this.state.key}
                  />
                </TabPane>
                <TabPane tab='Process Steps' key='2'>
                  <ProcessStepsContainer
                    entry={this.entry.data.processSteps}
                    data={this.entry.data}
                    onGetRoleProfiles={this.props.onGetRoleProfiles}
                    onSaveRoleProfile={this.props.onSaveRoleProfile}
                    tabKey={this.props.tabKey}
                    isNewDoc={this.state.isNewDoc}
                  />
                </TabPane>
                <TabPane tab='Keywords' key='3'>
                  <TableKeyValueEditableDND
                    columnTitle='Key'
                    values={this.entry.data.keywords ? this.entry.data.keywords : []}
                    theme={this.props.theme}
                    privileges={MemoryStore.userProfile.teamPrivileges.bpm}
                    disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                    callback={this.updateKeywords}
                  />
                </TabPane>
                {!this.state.isNewDoc ?
                  <TabPane tab='Administration' key='4'>
                    <BPMAdministration entry={this.entry.data} />
                  </TabPane>
                  : null}
              </Tabs>
              <Row style={{ marginTop: 10 }}>
                {MemoryStore.userProfile.teamPrivileges.bpm !== 'Reader' ?
                  <Button
                    name='submit'
                    disabled={this.state.isLoading}
                    style={{
                      backgroundColor: AgiliteTheme.successColor,
                      color: 'white',
                      width: 80,
                      marginRight: 10
                    }}
                    onClick={() => this.saveProfile()}
                  >
                    {this.state.isLoading ? <LoadingOutlined spin /> : <span>Submit</span>}
                  </Button>
                  : null}
                <Button
                  name='cancel'
                  disabled={this.state.isLoading}
                  label='Cancel'
                  style={{
                    backgroundColor: '#EB5181',
                    color: 'white',
                    width: 'auto',
                    marginRight: 10
                  }}
                  onClick={e => {
                    e.preventDefault()
                    this.cancelProfileConfirm()
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </Form>
          </Card>
        </Col>
        <Modal
          title='Change Profile Key'
          onCancel={() => this.setState({ modalOpen: false })}
          onOk={() => {
            this.entry.custom.isModifiedSubform = true
            this.entry.data.key = this.profileKey
            this.setState({ key: this.profileKey })

            this.setState({ modalOpen: false })
          }}
          okText='Submit'
          okButtonProps={this.getButtonProps()}
          visible={this.state.modalOpen}
          destroyOnClose
          maskClosable={false}
        >
          <Input
            name='change_profile_key'
            defaultValue={this.entry.data.key}
            onChange={(e) => {
              this.onProfileKeyChange(e.target.value)
            }}
          />
          <span style={{ margin: 5, padding: 5, color: 'red' }}>{this.state.errorText}</span>
        </Modal>
      </Row>
    )
  }
}

export default BPMForm
