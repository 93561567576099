import Axios from 'axios'
import moment from 'moment'
import Config from '../utils/config.json'
import Enums from '../utils/enums'
import MemoryStore from '../utils/memory-store'

export const filterViewData = (viewData, searchFilter) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return viewData
  } else {
    return viewData.filter(t =>
      (
        (t.data.response.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.moduleName.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.uri.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.method.toLowerCase().indexOf(searchFilter) > -1) ||
        (t._id.toLowerCase().indexOf(searchFilter) > -1)
      )
    )
  }
}

export const updateViewData = (data, logIds, actionType) => {
  const tmpData = data.concat()
  let tmpEntries = null
  let tmpIndex = null

  logIds.map(id => {
    // Find the entries
    tmpEntries = tmpData.filter(entry => entry._id === id)

    // Loop through the entries
    tmpEntries.map(entry => {
      // Update the entry based on the actionType
      if (actionType === 'resolve') {
        entry.data.resolvedDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        entry.data.resolvedBy = `${MemoryStore.userProfile.firstName} ${MemoryStore.userProfile.lastName}`
      } else {
        entry.data.resolvedDate = null
        entry.data.resolvedBy = ''
      }

      // Find the entry in the data and replace it with the updated entry
      tmpIndex = tmpData.findIndex(dataEntry => dataEntry._id === entry._id)
      if (tmpIndex !== -1) {
        tmpData[tmpIndex] = entry
      }
      return null
    })
    return null
  })

  return tmpData
}

export const fetchAllErrorLogs = (date, includeResolvedLogs) => {
  let response = null

  return new Promise((resolve, reject) => {
    (async () => {
      try {
        response = await Axios.get(`${Config.apiServerUrl}/logs/data`, {
          headers: {
            [Enums.HEADER_PROPS.API_KEY]: MemoryStore.apiKey,
            [Enums.HEADER_PROPS.STATUS_CODES]: Enums.VALUES_STRINGS.ERROR_CODES,
            [Enums.HEADER_PROPS.START_DATE]: moment(date[0]).format('YYYY-MM-DD'),
            [Enums.HEADER_PROPS.END_DATE]: moment(date[1]).format('YYYY-MM-DD'),
            [Enums.HEADER_PROPS.INCLUDE_RESOLVED_LOGS]: includeResolvedLogs
          }
        })
        resolve(response.data)
      } catch (error) {
        if (error.response?.data.errorMessage) {
          reject(error.response.data.errorMessage)
        } else if (error.message) {
          reject(error.message)
        } else {
          reject(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      }
    })()
  })
}

export const handleMailAgilite = (data) => {
  let response = null

  return new Promise((resolve, reject) => {
    (async () => {
      try {
        response = await Axios.request(`${Config.apiServerUrl}/logs/mailErrorLog`, {
          method: Enums.REQ_TYPE.POST,
          data,
          headers: {
            [Enums.HEADER_PROPS.API_KEY]: MemoryStore.apiKey,
            'full-name': `${MemoryStore.userProfile.firstName} ${MemoryStore.userProfile.lastName}`,
            email: MemoryStore.userProfile.email
          }
        })
        resolve(response.data)
      } catch (error) {
        if (error.response?.data.errorMessage) {
          reject(error.response.data.errorMessage)
        } else if (error.message) {
          reject(error.message)
        } else {
          reject(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      }
    })()
  })
}

export const resolveLog = (logIds, condition) => {
  let response = null

  return new Promise((resolve, reject) => {
    (async () => {
      try {
        response = await Axios.request(`${Config.apiServerUrl}/logs/resolve`, {
          method: Enums.REQ_TYPE.POST,
          data: {
            logIds
          },
          headers: {
            [Enums.HEADER_PROPS.API_KEY]: MemoryStore.apiKey,
            condition
          }
        })
        resolve(response.data)
      } catch (error) {
        if (error.response?.data.errorMessage) {
          reject(error.response.data.errorMessage)
        } else if (error.message) {
          reject(error.message)
        } else {
          reject(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      }
    })()
  })
}

export const unresolveLog = (logIds, condition) => {
  let response = null

  return new Promise((resolve, reject) => {
    (async () => {
      try {
        response = await Axios.request(`${Config.apiServerUrl}/logs/unresolve`, {
          method: Enums.REQ_TYPE.POST,
          data: {
            logIds
          },
          headers: {
            [Enums.HEADER_PROPS.API_KEY]: MemoryStore.apiKey,
            condition
          }
        })
        resolve(response.data)
      } catch (error) {
        if (error.response?.data.errorMessage) {
          reject(error.response.data.errorMessage)
        } else if (error.message) {
          reject(error.message)
        } else {
          reject(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      }
    })()
  })
}

export const handleExport = (data) => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        // eslint-disable-next-line
        const blob = new Blob([JSON.stringify(data, null, 2)])
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'export.json'
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()

        resolve()
      } catch (error) {
        if (error.response?.data.errorMessage) {
          reject(error.response.data.errorMessage)
        } else if (error.message) {
          reject(error.message)
        } else {
          reject(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      }
    })()
  })
}
