import React from 'react'
import { Row, Col, Card, Tabs, Form, Button, message, Popconfirm, Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import MemoryStore from '../../../utils/memory-store'
import GatewayAdapterFormGeneral from './gateway-adapter-form-general'
import GatewayMappingsContainer from '../../containers/gateway-mappings-container'
import Enums from '../../../utils/enums'

const TabPane = Tabs.TabPane

class GatewayAdapterForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc,
      adapterType: this.entry.data.adapterType,
      isLoading: false
    }

    // Setup Event Binding
    this.handleOnFieldChange = this.handleOnFieldChange.bind(this)
    this.saveProfile = this.saveProfile.bind(this)
    this.cancelProfile = this.cancelProfile.bind(this)
    this.cancelProfileConfirm = this.cancelProfileConfirm.bind(this)
  }

  handleOnFieldChange (key, value) {
    if (key === 'adapterType') {
      this.setState({ adapterType: value })
    }

    this.entry.custom.isModified = true
    this.entry.data[key] = value
  }

  saveProfile (publish) {
    const tmpThis = this

    tmpThis.setState({
      isLoading: true
    })

    // Reset isModified and isModifiedSubform properties
    this.entry.custom.isModified = false
    this.entry.custom.isModifiedSubform = false

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, publish, (err) => {
      tmpThis.setState({
        isLoading: false
      })

      if (err) {
        message.error(err)
      } else {
        tmpThis.props.closeTab(tmpThis.props.tabKey, tmpThis.props.tabKey, tmpThis.props.tabs)
      }
    })
  }

  cancelProfileConfirm () {
    if (this.entry.custom.isModified) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  render () {
    return (
      <Row type='flex' justify='center'>
        <Col xs={23} lg={20} xl={16}>
          <Card
            title={this.props.title}
            type='inner'
            style={{ textAlign: 'left' }}
          >
            <Form
              autoComplete='off'
            >
              <Tabs
                defaultActiveKey='1'
                animated={false}
              >
                <TabPane tab='General' key='1'>
                  <GatewayAdapterFormGeneral
                    disabled={MemoryStore.userProfile.teamPrivileges.gatewayadapters === 'Reader'}
                    onChange={this.handleOnFieldChange}
                    tabKey={this.props.tabKey}
                  />
                </TabPane>
                <TabPane tab='Gateway Mappings' key='2'>
                  <GatewayMappingsContainer
                    appId={this.props.appId}
                    disabled={MemoryStore.userProfile.teamPrivileges.gatewayadapters === 'Reader'}
                    tabKey={this.props.tabKey}
                  />
                </TabPane>
              </Tabs>
              <Row style={{ marginTop: 10 }}>
                <Button
                  name='submit'
                  hidden={MemoryStore.userProfile.teamPrivileges.gatewayadapters === 'Reader'}
                  style={{ backgroundColor: '#67AD5B', color: 'white', width: 80, marginRight: 10 }}
                  onClick={() => this.saveProfile()}
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? <LoadingOutlined spin /> : <span>Submit</span>}
                </Button>
                {this.entry.data.adapterType !== 'client' ?
                  <Popconfirm
                    disabled={this.state.isLoading}
                    title='Are you sure you want to publish this profile?'
                    onConfirm={() => this.saveProfile(true)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Button
                      hidden={MemoryStore.userProfile.teamPrivileges.gatewayadapters === 'Reader'}
                      style={{ backgroundColor: '#67AD5B', color: 'white', width: 'auto', marginRight: 10 }}
                    >
                      {'Save & Publish'}
                    </Button>
                  </Popconfirm>
                  : null}
                <Button
                  name='cancel'
                  disabled={this.state.isLoading}
                  style={{ backgroundColor: '#EB5181', color: 'white', width: 'auto', marginRight: 10 }}
                  onClick={e => {
                    e.preventDefault()
                    this.cancelProfileConfirm()
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default GatewayAdapterForm
