import { connect } from 'react-redux'
import StepOptions from '../components/step-options/step-options'
import { filterStepOptionViewData } from '../bpm-utils'

const mapStateToProps = state => {
  return {
    theme: state.core.theme,
    state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    filterViewData: (data, searchFilter, callback) => {
      filterStepOptionViewData(data, searchFilter, callback)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepOptions)
