import React from 'react'
import MemoryStore from '../../utils/memory-store'
import { Card, Button, Form, Row, message, Col, Modal } from 'antd'

import KeywordsFormGeneral from './keywords-form-general'
import TableKeyValueEditableDND from '../../reusables/components/table-key-value-editable-dnd'
import Enums from '../../utils/enums'

import { LoadingOutlined } from '@ant-design/icons'

class KeywordsForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]

    this.cancelProfileConfirm = this.cancelProfileConfirm.bind(this)
    this.saveProfile = this.saveProfile.bind(this)
    this.cancelProfile = this.cancelProfile.bind(this)
    this.updateValues = this.updateValues.bind(this)

    this.state = {
      isLoading: false
    }
  }

  cancelProfileConfirm () {
    if (this.entry.custom.isModified) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  saveProfile () {
    const tmpThis = this

    this.setState({
      isLoading: true
    })

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, (err) => {
      this.setState({
        isLoading: false
      })

      if (err) {
        message.error(err)
      } else {
        // Reset isModified and isModifiedSubform properties
        tmpThis.entry.custom.isModified = false
        tmpThis.entry.custom.isModifiedSubform = false

        tmpThis.props.closeTab(tmpThis.props.tabKey, tmpThis.props.tabKey, tmpThis.props.tabs)
      }
    })
  }

  updateValues (result) {
    this.entry.custom.isModified = true
    this.entry.data.values = result
  }

  render () {
    return (
      <Row justify='center' type='flex'>
        <Col xs={23}>
          <Card
            title={this.props.title}
            style={{ textAlign: 'left' }}
            type='inner'
          >
            <Form
              autoComplete='off'
            >
              <Row type='flex' justify='space-between'>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                  <KeywordsFormGeneral entry={this.entry} theme={this.props.theme} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={15}>
                  <TableKeyValueEditableDND
                    values={this.entry.data.values}
                    theme={this.props.theme}
                    privileges={MemoryStore.userProfile.teamPrivileges.keywords}
                    disabled={MemoryStore.userProfile.teamPrivileges.keywords === 'Reader'}
                    callback={this.updateValues}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                {MemoryStore.userProfile.teamPrivileges.keywords !== 'Reader' ?
                  <Button
                    cypressid='submit'
                    name='submit'
                    style={{ backgroundColor: '#67AD5B', color: 'white', width: 80, marginRight: 10 }}
                    onClick={() => this.saveProfile()}
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? <LoadingOutlined spin /> : <span>Submit</span>}
                  </Button>
                  : null}
                <Button
                  cypressid='cancel'
                  name='cancel'
                  label='Cancel'
                  style={{ backgroundColor: '#EB5181', color: 'white', width: 'auto', marginRight: 10 }}
                  onClick={e => {
                    e.preventDefault()
                    this.cancelProfileConfirm()
                  }}
                  disabled={this.state.isLoading}
                >
                  Cancel
                </Button>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default KeywordsForm
