import React from 'react'
import { Select, Form, Input, Row, Col } from 'antd'
import MemoryStore from '../../../../utils/memory-store'
import Notes from '../../../../reusables/components/notes'

class FieldMappingSourceJson extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      fieldMapping: this.props.fieldMapping,
      type: this.props.fieldMapping.srcType
    }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.fieldMapping[key] = value
  }

  render () {
    return (
      <Row>
        <Col span={24}>
          <Form name='field-mapping-src-json'>
            <Form.Item>
              Attribute Path (optional)
              <Input
                name='path'
                placeholder='Provide a path for your Attribute'
                disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                defaultValue={this.state.fieldMapping.srcPath}
                onChange={(e) => {
                  this.onFieldChange('srcPath', e.target.value)
                }}
              />
            </Form.Item>
            <Form.Item>
              <span style={{ color: 'red' }}>* </span>Attribute Name
              <Input
                name='attribute_name'
                placeholder='Provide an Attribute Name'
                disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                defaultValue={this.state.fieldMapping.srcName}
                required
                onChange={(e) => {
                  this.onFieldChange('srcName', e.target.value)
                }}
              />
            </Form.Item>
            <Form.Item>
              Attribute Type
              <Select
                className='attribute_type'
                disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                placeholder='Specify an Attribute Type'
                defaultValue={this.state.fieldMapping.srcType}
                onChange={(value) => {
                  this.onFieldChange('srcType', value)
                }}
              >
                <Select.Option value='1'>String</Select.Option>
                <Select.Option value='2'>Integer</Select.Option>
                <Select.Option value='3'>Double</Select.Option>
                <Select.Option value='4'>Percentage</Select.Option>
              </Select>
            </Form.Item>
            <Notes
              disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
              notes={this.state.fieldMapping.srcNotes}
              onChange={(e) => {
                this.onFieldChange('srcNotes', e.target.value)
              }}
            />
          </Form>
        </Col>
      </Row>
    )
  }
}

export default FieldMappingSourceJson
