import { connect } from 'react-redux'
import { columnTemplates } from '../../utils/templates'
import { fetchAllData, addTab, deleteProfile } from '../../core/core-actions'
import { createProfile, editProfile } from '../../core/core-utils'
import { generateApiKey } from '../../api-keys/api-keys-utils'
import ListView from '../components/list-view'
import Enums from '../../utils/enums'

const mapStateToProps = (state) => {
  if (!state[state.core.tabObject.activeApp]) {
    return {}
  }

  const appId = state.core.tabObject.activeApp

  return {
    appId,
    state: state[appId],
    theme: state.core.theme,
    viewTitle: Enums.APP_VIEW_TITLES[appId],
    viewColumns: columnTemplates[appId],
    searchFilter: state[appId].searchFilter
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllData: (appId, callback) => {
      dispatch(fetchAllData(appId, callback))
    },
    addTab: (payload) => {
      dispatch(addTab(payload))
    },
    createProfile: (appId, callback) => {
      createProfile(appId, callback)
    },
    editProfile: (appId, recordId, viewData, callback) => {
      editProfile(appId, recordId, viewData, callback)
    },
    deleteProfile: (appId, activeKey, state, callback) => {
      dispatch(deleteProfile(appId, activeKey, state, callback))
    },
    onGenerateApiKey: (id, callback) => {
      generateApiKey(id, callback)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListView)
