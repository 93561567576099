import React from 'react'
import { Row, Col, Select, Form } from 'antd'

import MemoryStore from '../../../../../utils/memory-store'
import LDAPRouteSearch from './routes/ldap-search'
import LDAPRouteDeleteEntry from './routes/ldap-delete-entry'
import LDAPRouteAddEntry from './routes/ldap-add-entry'
import LDAPRouteCompare from './routes/ldap-compare'
import LDAPRouteExop from './routes/ldap-exop'
import LDAPRouteModify from './routes/ldap-modify'
import LDAPRouteModifyDn from './routes/ldap-modify-dn'
import LDAPRouteAuthenticate from './routes/ldap-authenticate'

class LDAPRouteMain extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.disabled = MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'
    this.state = { routeType: props.entry.routeData.routeType || '0' }

    // Bindings
    this.onChangeRouteType = this.onChangeRouteType.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
  }

  UNSAFE_componentWillMount () {
    if (!this.props.entry.routeData.routeType) {
      this.props.entry.routeData.routeType = '0'
    }
  }

  onChangeRouteType (e) {
    this.entry.custom.isModifiedSubform = true
    this.setState({ routeType: e })
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value
  }

  render () {
    return (
      <Row>
        <Col span={24}>
          <h3>Route Info</h3>
          <Form.Item>
            Route Type
            <Select
              className='route_type'
              disabled={this.disabled}
              defaultValue={this.state.routeType}
              onChange={e => {
                this.onChangeRouteType(e)
                this.onFieldChange('routeType', e)
              }}
            >
              <Select.Option value='0'>Authenticate</Select.Option>
              <Select.Option value='1'>Search</Select.Option>
              <Select.Option value='2'>Delete Entry</Select.Option>
              <Select.Option value='3'>Add Entry</Select.Option>
              <Select.Option value='4'>Compare</Select.Option>
              <Select.Option value='5'>Extended Operation</Select.Option>
              <Select.Option value='6'>Modify</Select.Option>
              <Select.Option value='7'>Modify DN</Select.Option>
            </Select>
          </Form.Item>
          {this.state.routeType === '0' ? (
            <LDAPRouteAuthenticate
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
            />
          ) : null}
          {this.state.routeType === '1' ? (
            <LDAPRouteSearch
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
            />
          ) : null}
          {this.state.routeType === '2' ? (
            <LDAPRouteDeleteEntry
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
              theme={this.props.theme}
            />
          ) : null}
          {this.state.routeType === '3' ? (
            <LDAPRouteAddEntry
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
              theme={this.props.theme}
            />
          ) : null}
          {this.state.routeType === '4' ? (
            <LDAPRouteCompare
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
              theme={this.props.theme}
            />
          ) : null}
          {this.state.routeType === '5' ? (
            <LDAPRouteExop
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
              theme={this.props.theme}
            />
          ) : null}
          {this.state.routeType === '6' ? (
            <LDAPRouteModify
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
              theme={this.props.theme}
            />
          ) : null}
          {this.state.routeType === '7' ? (
            <LDAPRouteModifyDn
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
              theme={this.props.theme}
            />
          ) : null}
        </Col>
      </Row>
    )
  }
}

export default LDAPRouteMain
