import React from 'react'
import { Row, Modal, Col, message } from 'antd'
import MemoryStore from '../../../utils/memory-store'
import GatewayMappingsForm from '../gateway-mappings/gateway-mapping-form'
import ListViewDND from '../../../reusables/components/list-view-dnd'
import { columnTemplates } from '../../../utils/templates'
import { cleanUpTableData } from '../../../utils/utilities'
import Enums from '../../../utils/enums'

class GatewayMappings extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry,
      dialogOpen: false,
      activeRecord: null,
      bpmState: null
    }

    // Setup Event Binding
    this.createRecord = this.createRecord.bind(this)
    this.saveRecord = this.saveRecord.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.deleteRecord = this.deleteRecord.bind(this)
    this.handleValidateRecord = this.handleValidateRecord.bind(this)
    this.formatViewData = this.formatViewData.bind(this)
    this.handleCloseModalDialog = this.handleCloseModalDialog.bind(this)
    this.updateArrayData = this.updateArrayData.bind(this)
    this.handleCloseModalDialogConfirm = this.handleCloseModalDialogConfirm.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.formatViewData()
  }

  UNSAFE_componentWillUpdate () {
    this.formatViewData()
  }

  createRecord () {
    this.entry.custom.isModifiedSubform = true

    const activeRecord = JSON.parse(JSON.stringify(MemoryStore.gatewayadapters.mappingsTemplate))
    activeRecord.isNewEntry = true
    activeRecord._id = new Date().getTime().toString()

    this.setState({
      dialogOpen: true,
      activeRecord
    })
  }

  saveRecord (activeRecord) {
    this.entry.custom.isModifiedSubform = true

    const entry = this.props.entry
    const index = entry.data.mappings.findIndex(t => t._id === activeRecord._id)

    if (activeRecord.isNewEntry) {
      entry.data.mappings.push(activeRecord)
    } else {
      entry.data.mappings.splice(index, 1, activeRecord)
    }

    this.setState({
      entry,
      dialogOpen: false,
      activeRecord: JSON.parse(JSON.stringify(MemoryStore.gatewayadapters.mappingsTemplate))
    })
  }

  editRecord (recordId) {
    const index = this.state.entry.data.mappings.findIndex(t => t._id === recordId)
    const activeRecord = JSON.parse(JSON.stringify(this.state.entry.data.mappings[index]))
    activeRecord.isNewEntry = false

    this.setState({
      dialogOpen: true,
      activeRecord
    })
  }

  deleteRecord (recordId) {
    this.entry.custom.isModifiedSubform = true

    const entry = this.props.entry
    const index = entry.data.mappings.findIndex(t => t._id === recordId)
    entry.data.mappings.splice(index, 1)

    this.setState({
      entry
    })
  }

  handleValidateRecord () {
    const activeRecord = JSON.parse(JSON.stringify(this.state.activeRecord))
    let msg = null

    // First format prop values
    activeRecord.name = activeRecord.name.trim()
    activeRecord.description = activeRecord.description.trim()
    activeRecord.endpoint = activeRecord.endpoint.trim()
    activeRecord.targetedEndpoint = activeRecord.targetedEndpoint.trim()
    activeRecord.notes = activeRecord.notes.trim()

    // Validate prop values
    if (!activeRecord.name) {
      msg = 'Please provide a Mapping Name'
    } else if (!activeRecord.endpoint) {
      msg = 'Please provide a Route URL Endpoint'
    } else if (!activeRecord.targetedEndpoint) {
      msg = 'Please provide a Targeted URL Endpoint'
    } else if (!activeRecord.requestType) {
      msg = 'Please provide a Request Type'
    } else if (activeRecord.endpoint === '/ping') {
      msg = 'Route URL Endpoint can not be /ping'
    } else if (activeRecord.endpoint.endsWith('/')) {
      msg = "Route URL Endpoint can not end with ' / '"
    } else if (!activeRecord.endpoint.startsWith('/')) {
      msg = "Route URL Endpoint must start with ' / '"
    }

    if (msg) return message.error(msg)

    // Check for duplicate Mappings
    for (const tmpEntry of this.state.entry.data.mappings) {
      if (activeRecord.endpoint.toLowerCase() === tmpEntry.endpoint.toLowerCase()) {
        if (activeRecord._id !== tmpEntry._id) {
          msg = `Mapping with Route URL endpoint '${activeRecord.endpoint.toLowerCase()}' already exists`
          break
        }
      }
    }

    if (msg) return message.error(msg)

    // Format list values
    activeRecord.ipWhitelist = cleanUpTableData(activeRecord.ipWhitelist, true)
    activeRecord.ipBlacklist = cleanUpTableData(activeRecord.ipBlacklist, true)

    // Check if Whitelist and Blacklist are populated
    if (activeRecord.ipWhitelist.length > 0 && activeRecord.ipBlacklist.length > 0) {
      msg = 'Either IP Whitelist or Blacklist can be populated. Not both'
      return message.error(msg)
    }

    this.saveRecord(activeRecord)
  }

  formatViewData () {
    // Set isActiveDescription
    this.state.entry.data.mappings.map(process => {
      process.isActiveDescription = process.isActive ? 'Yes' : 'No'
      return process.isActiveDescription
    })
  }

  handleCloseModalDialog () {
    this.setState({
      dialogOpen: false,
      activeRecord: JSON.parse(JSON.stringify(MemoryStore.gatewayadapters.mappingsTemplate))
    })
  }

  updateArrayData (data) {
    this.entry.custom.isModified = true
    this.props.entry.data.mappings = data

    this.setState({
      entry: this.props.entry
    })
  }

  handleCloseModalDialogConfirm () {
    if (this.entry.custom.isModifiedSubform) {
      Modal.confirm({
        onOk: () => this.handleCloseModalDialog(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.handleCloseModalDialog()
    }
  }

  render () {
    return (
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <ListViewDND
            appId={this.props.appId}
            btnCreateLabel='Create New'
            viewTitle='Gateway Mappings'
            createRecord={this.createRecord}
            editRecord={this.editRecord}
            deleteRecord={this.deleteRecord}
            filterViewData={this.props.filterViewData}
            viewData={this.state.entry.data.mappings}
            viewColumns={columnTemplates.gatewayMappings}
            theme={this.props.theme}
            callback={this.updateArrayData}
          />
          <Modal
            title='Gateway Mapping'
            okButtonProps={{ style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' } }}
            okText='Ok'
            visible={this.state.dialogOpen}
            onOk={this.handleValidateRecord}
            onCancel={this.handleCloseModalDialogConfirm}
            width={1000}
            style={{ top: 10 }}
            destroyOnClose
            maskClosable={false}
          >
            <GatewayMappingsForm
              disabled={this.props.disabled}
              entry={this.state.activeRecord}
              theme={this.props.theme}
              bpmState={this.state.bpmState}
              tabKey={this.props.tabKey}
            />
          </Modal>
        </Col>
      </Row>
    )
  }
}

export default GatewayMappings
