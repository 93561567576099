import { trimObjectValues } from 'agilite-utils'

import uniqueId from 'lodash/uniqueId'
import MemoryStore from '../utils/memory-store'
import { cleanupObject, isDuplicateEntry, updateArrayDataFromSave } from '../utils/utilities'
import { postData } from '../core/core-utils'
import { formatRecord, cleanupData } from './keywords-utils'
import Enums from '../utils/enums'

/*
  ENUMS
*/
export const actions = {
  RESET_STATE: 'RESET_STATE_KEYWORDS',
  CREATE_PROFILE: 'CREATE_PROFILE_KEYWORDS',
  SAVE_PROFILE: 'SAVE_PROFILE_KEYWORDS',
  CANCEL_PROFILE: 'CANCEL_PROFILE_KEYWORDS',
  EDIT_PROFILE: 'EDIT_PROFILE_KEYWORDS',
  DELETE_PROFILE: 'DELETE_PROFILE_KEYWORDS',
  FETCH_ALL_DATA: 'FETCH_ALL_DATA_KEYWORDS',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER_KEYWORDS',
  UPDATE_DATA: 'UPDATE_DATA_KEYWORDS'
}

/*
  DISPATCH FUNCTIONS
*/
export const setSearchFilter = (value) => {
  return {
    type: actions.SET_SEARCH_FILTER,
    value
  }
}

export const updateData = (data) => {
  return {
    type: actions.UPDATE_DATA,
    data
  }
}

export const saveProfile = (appId, recordId, state, callback) => {
  return async dispatch => {
    const entry = MemoryStore.activeEntries[recordId]
    const headers = {}

    let result = true
    let reqType = ''
    let tempArray = state.data.concat()
    let tempValueArray = []
    let errMsg = null

    // Cleanup and Trim
    cleanupObject(entry.data)
    await trimObjectValues(entry.data)

    // Check if profile key is valid
    if (entry.data.key === '') {
      errMsg = 'Please provide a valid Profile Key'
      return callback(errMsg)
    }

    // Duplicate Check
    result = isDuplicateEntry(entry, 'key', tempArray, true)

    if (!result) {
      errMsg = `Profile Key '${entry.data.key}' is already being used`
      return callback(errMsg)
    }

    // Check that at least one value exists
    tempValueArray = cleanupData(entry.data.values)

    if (tempValueArray.length === 0) {
      errMsg = 'Please provide at least 1 Keyword Label or Value'
      return callback(errMsg)
    }

    // Next, check for duplicate values
    for (const x in tempValueArray) {
      if (tempValueArray.filter(t => ((t.label.toLowerCase() === tempValueArray[x].label.toLowerCase()) && (t.value.toLowerCase() === tempValueArray[x].value.toLowerCase()))).length > 1) {
        errMsg = `Duplicate Label/Value found for - (${tempValueArray[x].label}/${tempValueArray[x].value})`
        return callback(errMsg)
      }
    }

    // Finalize
    entry.data.values = tempValueArray

    // Post Data to Agilit-e
    tempArray = formatRecord(entry, tempArray)
    reqType = entry.custom.action === 'create' ? 'post' : 'put'

    // Create Headers Object
    headers['record-id'] = recordId

    postData(appId, reqType, entry, headers)
      .then((response) => {
        callback(null)

        entry.custom.action = ''
        entry.custom.isSavedDoc = true
        entry.key = response.data._id
        entry._id = response.data._id
        entry.data.isActiveDescription = response.data.data.isActive ? 'Yes' : 'No'

        // Add a key for every value in Values List
        for (const x in response.data.data.values) {
          entry.data.values[x].key = uniqueId('keywords_')
        }

        // Dispatch New Array
        tempArray = updateArrayDataFromSave(entry, tempArray)
        dispatch({ type: actions.UPDATE_DATA, data: tempArray })
      })
      .catch((error) => {
        console.log(error)

        if (error.response) {
          callback(error.response.data.errorMessage)
        } else {
          callback(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }
}
