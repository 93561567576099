import React from 'react'
import { Form, Row, Col, Input, Select, Switch } from 'antd'
import AceEditor from 'react-ace'
import Globals from '../../../../../utils/globals'
import MemoryStore from '../../../../../utils/memory-store'
import CompileTemplateIcon from '../../../../../reusables/components/compile-template-icon'

import 'brace/mode/json'
import 'brace/theme/xcode'

class ConnectorsFormTypeMongoDB extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    let sslSearch = props.entry.ssl

    if (sslSearch === Globals.yesValue) {
      sslSearch = Globals.yesLabel
    } else if (sslSearch === Globals.noValue) {
      sslSearch = Globals.noLabel
    }

    this.state = {
      entry: props.entry,
      sslSearch,
      connectionType: props.entry.connectionType ? props.entry.connectionType : '',
      schema: props.entry.schema
    }
  }

  connectionTypeChange (e) {
    this.entry.custom.isModified = true
    this.setState({ connectionType: e })
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.props.entry[key] = value
  }

  render () {
    return (
      <Row type='flex' justify='space-around'>
        <Col xs={24} xl={8}>
          <Form.Item>
            Connection Type
            <Select
              className='connection_type'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.connectionType ? this.state.entry.connectionType : ''}
              onChange={e => {
                this.connectionTypeChange(e)
                this.onFieldChange('connectionType', e)
              }}
            >
              <Select.Option value=''>-Select-</Select.Option>
              <Select.Option value='1'>Agilit-e</Select.Option>
              <Select.Option value='2'>Custom</Select.Option>
            </Select>
          </Form.Item>
          {this.state.connectionType !== '1' && this.state.connectionType === '2' ? (
            <div>
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>Host Address
                <Input
                  name='host'
                  placeholder='Provide a Host Address'
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  defaultValue={this.state.entry.host}
                  onChange={e => {
                    this.onFieldChange('host', e.target.value)
                  }}
                />
              </Form.Item>
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>Port
                <Input
                  name='port'
                  placeholder='Port'
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  defaultValue={this.state.entry.port}
                  onChange={e => {
                    this.onFieldChange('port', e.target.value)
                  }}
                />
              </Form.Item>
              <Form.Item>
                SSL Enabled?
                <Switch
                  style={{ marginLeft: 10 }}
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                  defaultChecked={this.state.entry.ssl === '1' ? true : false}
                  onChange={checked => {
                    this.onFieldChange('ssl', checked === true ? '1' : '0')
                  }}
                />
              </Form.Item>
              <Form.Item>
                {'Username '}
                <CompileTemplateIcon />
                <Input
                  name='username'
                  placeholder='Provide a Username'
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  defaultValue={this.state.entry.username}
                  autoComplete='new-password'
                  onChange={e => {
                    this.onFieldChange('username', e.target.value)
                  }}
                />
              </Form.Item>
              <Form.Item>
                {'Password '}
                <CompileTemplateIcon />
                <Input
                  name='password'
                  placeholder='Provide a Password'
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  defaultValue={this.state.entry.password}
                  autoComplete='new-password'
                  type='password'
                  onChange={e => {
                    this.onFieldChange('password', e.target.value)
                  }}
                />
              </Form.Item>
            </div>
          ) : null}
        </Col>
        <Col xs={24} xl={15}>
          {this.state.connectionType !== '1' && this.state.connectionType === '2' ? (
            <Form.Item>
              <span style={{ color: 'red' }}>* </span>Database Name
              <Input
                name='database_name'
                placeholder='Name of Mongo Database'
                disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                defaultValue={this.state.entry.dbName}
                onChange={e => {
                  this.onFieldChange('dbName', e.target.value)
                }}
              />
            </Form.Item>
          ) : null}
          {this.state.connectionType === '1' || this.state.connectionType === '2' ?
            <>
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>Collection Name
                <Input
                  name='collection_name'
                  placeholder='Name of Db Collection'
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  defaultValue={this.state.entry.collectionName}
                  onChange={e => {
                    this.onFieldChange('collectionName', e.target.value)
                  }}
                />
              </Form.Item>
              <Form.Item>
                <h3>Schema spec in JSON format</h3>
                <AceEditor
                  showPrintMargin={false}
                  readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  cursorStart={1}
                  mode='json'
                  theme='xcode'
                  style={{ width: '100%', height: 300 }}
                  name='aceEditor'
                  value={this.state.schema}
                  setOptions={{
                    showLineNumbers: true,
                    newLineMode: 'unix'
                  }}
                  onChange={value => {
                    this.onFieldChange('schema', value)
                    this.setState({ schema: value })
                  }}
                />
              </Form.Item>
            </>
            : null}
        </Col>
      </Row>
    )
  }
}

export default ConnectorsFormTypeMongoDB
