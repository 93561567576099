import React from 'react'
import Globals from '../../../../../../utils/globals'
import MemoryStore from '../../../../../../utils/memory-store'

import { Input, Form, AutoComplete } from 'antd'
import AceEditor from 'react-ace'
import CompileTemplateIcon from '../../../../../../reusables/components/compile-template-icon'

import 'brace/mode/html'
import 'brace/theme/xcode'

class SMTPRouteSendMail extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    let priority = props.entry.routeData.priority ? props.entry.routeData.priority : ''

    for (var x in Globals.priorityEnums) {
      if (priority === Globals.priorityEnums[x].value) {
        priority = Globals.priorityEnums[x].label
        break
      }
    }

    this.state = {
      entry: props.entry.routeData,
      prioritySearch: props.entry.routeData.priority,
      priority
    }
  }

  onFieldChange (key, value, option) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value

    if (key === 'priority') this.setState({ priority: option.children })
  }

  render () {
    return (
      <div>
        <Form.Item>
          {'Priority '}
          <CompileTemplateIcon />
          <AutoComplete
            placeholder='Priority'
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            value={this.state.priority}
            onSelect={(value, option) => {
              this.onFieldChange('priority', value, option)
            }}
            onChange={(value, option) => {
              this.onFieldChange('priority', value, option)
            }}
          >
            {Globals.priorityEnums.map(step => {
              return <AutoComplete.Option key={step.value} value={step.value}>{step.label}</AutoComplete.Option>
            })}
          </AutoComplete>
        </Form.Item>
        <Form.Item>
          <span style={{ color: 'red' }}>* </span>
          {'From '}
          <CompileTemplateIcon />
          <Input
            name='from'
            placeholder='From'
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            defaultValue={this.state.entry.from}
            onChange={e => {
              this.onFieldChange('from', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          {'Send To '}
          <CompileTemplateIcon />
          <Input
            name='send_to'
            placeholder='Send To'
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            defaultValue={this.state.entry.sendTo}
            onChange={e => {
              this.onFieldChange('sendTo', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          {'Copy To '}
          <CompileTemplateIcon />
          <Input
            name='copy_to'
            placeholder='Copy To'
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            defaultValue={this.state.entry.copyTo}
            onChange={e => {
              this.onFieldChange('copyTo', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          {'Blind Copy To '}
          <CompileTemplateIcon />
          <Input
            name='blind_copy_to'
            placeholder='Blind Copy To'
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            defaultValue={this.state.entry.blindCopyTo}
            onChange={e => {
              this.onFieldChange('blindCopyTo', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          {'Reply To '}
          <CompileTemplateIcon />
          <Input
            name='reply_to'
            placeholder='Reply To'
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            defaultValue={this.state.entry.replyTo}
            onChange={e => {
              this.onFieldChange('replyTo', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          {'Subject '}
          <CompileTemplateIcon />
          <Input
            name='subject'
            placeholder='Subject'
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            defaultValue={this.state.entry.subject}
            onChange={e => {
              this.onFieldChange('subject', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          {'Attachment Id(s) '}
          <CompileTemplateIcon />
          <Input
            name='attachmentIds'
            placeholder='Separate ids using commas'
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            defaultValue={this.state.entry.attachmentIds}
            onChange={e => {
              this.onFieldChange('attachmentIds', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          {'HTML Body '}
          <CompileTemplateIcon />
          <AceEditor
            readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            showPrintMargin={false}
            cursorStart={1}
            mode='html'
            theme='xcode'
            style={{ width: '100%', height: 300 }}
            name='aceEditor'
            value={this.state.entry.htmlBody}
            setOptions={{
              showLineNumbers: true,
              newLineMode: 'unix'
            }}
            onChange={e => {
              this.onFieldChange('htmlBody', e)
            }}
          />
        </Form.Item>
      </div>
    )
  }
}

export default SMTPRouteSendMail
