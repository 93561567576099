import React from 'react'
import MemoryStore from '../../utils/memory-store'
import { Input, Switch, Form } from 'antd'
import GroupNameContainer from '../../reusables/containers/group-name-container'
import SolutionsContainer from '../../reusables/containers/solutions-container'
import TooltipIcon from '../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../utils/enums-tooltips'

class KeywordsFormGeneral extends React.Component {
  constructor (props) {
    super(props)

    this.entry = props.entry
    this.onFieldChange = this.onFieldChange.bind(this)
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.entry.data[key] = value
  }

  render () {
    return (
      <div>
        <Form.Item>
          <Switch
            cypressid='isActiveSwitch'
            disabled={MemoryStore.userProfile.teamPrivileges.keywords === 'Reader'}
            checkedChildren='Active'
            unCheckedChildren='Inactive'
            defaultChecked={this.entry.data.isActive}
            onChange={(value) => {
              this.onFieldChange('isActive', value)
            }}
          />
        </Form.Item>
        <Form.Item>
          <span style={{ color: 'red' }}>* </span>{'Profile Key '}
          <TooltipIcon title={EnumsTooltips.general.profileKey} />
          <Input
            cypressid='profileKey'
            placeholder='Provide a unique Profile Key'
            disabled={MemoryStore.userProfile.teamPrivileges.keywords === 'Reader'}
            required
            defaultValue={this.entry.data.key}
            onChange={(e) => {
              this.onFieldChange('key', e.target.value)
            }}
          />
        </Form.Item>
        <GroupNameContainer
          disabled={MemoryStore.userProfile.teamPrivileges.keywords === 'Reader'}
          defaultValue={this.entry.data.groupName}
          onValueChange={(value) => {
            this.onFieldChange('groupName', value)
          }}
          onValueSelect={(value) => {
            this.onFieldChange('groupName', value)
          }}
        />
        <SolutionsContainer
          disabled={MemoryStore.userProfile.teamPrivileges.keywords === 'Reader'}
          defaultValue={this.entry.data.solutions}
          onValueChange={(value) => {
            this.onFieldChange('solutions', value)
          }}
        />
      </div>
    )
  }
}

export default KeywordsFormGeneral
