import React from 'react'

import { Input, Form, Row, Col, Switch } from 'antd'
import MemoryStore from '../../../../../utils/memory-store'

class ConnectorsFormTypeSMTP extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry,
      smtpSecureValue: props.entry.smtpSecure && props.entry.smtpSecure === '1' ? true : false,
      smtpIgnoreTLSValue: props.entry.smtpIgnoreTLS && props.entry.smtpIgnoreTLS === '1' ? true : false
    }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.props.entry[key] = value
  }

  render () {
    return (
      <Row>
        <Col xs={24} lg={16}>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'SMTP Host'}
            <Input
              name='host'
              placeholder='Provide a SMTP Host'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.smtpHost}
              onChange={e => {
                this.onFieldChange('smtpHost', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Port'}
            <Input
              name='port'
              placeholder='SMTP Port'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.smtpPort}
              onChange={e => {
                this.onFieldChange('smtpPort', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Username'}
            <Input
              name='username'
              placeholder='Provide a Username'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.username}
              autoComplete='new-password'
              onChange={e => {
                this.onFieldChange('username', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Password'}
            <Input
              name='password'
              placeholder='Provide a Password'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.password}
              autoComplete='new-password'
              type='password'
              onChange={e => {
                this.onFieldChange('password', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Ignore TLS?'}
            <Switch
              style={{ marginLeft: 10 }}
              defaultChecked={this.state.smtpIgnoreTLSValue}
              onChange={checked => {
                this.onFieldChange('smtpIgnoreTLS', checked === false ? '0' : '1')
                this.setState({ smtpIgnoreTLSValue: checked })
              }}
              checkedChildren='Yes'
              unCheckedChildren='No'
            />
          </Form.Item>
          <Form.Item>
            {'Secure?'}
            <Switch
              style={{ marginLeft: 10 }}
              defaultChecked={this.state.smtpSecureValue}
              checkedChildren='Yes'
              unCheckedChildren='No'
              onChange={checked => {
                this.onFieldChange('smtpSecure', checked === false ? '0' : '1')
                this.setState({ smtpSecureValue: checked })
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    )
  }
}

export default ConnectorsFormTypeSMTP
