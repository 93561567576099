import { connect } from 'react-redux'
import NumberingForm from '../components/numbering-form'
import MemoryStore from '../../utils/memory-store'
import { saveProfile } from '../numbering-actions'
import { closeTab } from '../../core/core-actions'
import { resetNumberingCounter } from '../numbering-utils'
import Enums from '../../utils/enums'

const mapStateToProps = (state) => {
  if (!state[state.core.tabObject.activeApp]) {
    return {}
  }

  const appId = state.core.tabObject.activeApp
  const tabKey = state.core.tabObject.activeKey
  const tabs = state.core.tabObject.tabs
  const entry = MemoryStore.activeEntries[tabKey]
  let title = Enums.VALUES_STRINGS.NEW_PROPER_CASE + Enums.VALUES_STRINGS.SPACE + Enums.APP_PROFILE_TITLES[appId]

  if (entry && entry.data && !entry.custom.isNewDoc) {
    title = Enums.APP_PROFILE_TITLES[appId] + Enums.VALUES_STRINGS.COLON + Enums.VALUES_STRINGS.SPACE + entry.data.key
  }

  return {
    appId,
    tabKey,
    tabs,
    title,
    state: state[appId],
    theme: state.core.theme
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeTab: (activeKey, targetKey, tabs) => {
      dispatch(closeTab(activeKey, targetKey, tabs))
    },
    saveProfile: (appId, activeKey, state, callback) => {
      dispatch(saveProfile(appId, activeKey, state, callback))
    },
    onResetNumberingCounter: (id, callback) => {
      resetNumberingCounter(id, callback)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NumberingForm)
