import { connect } from 'react-redux'
import TierEntries from '../components/tier-entries/tier-entries'
import { filterTierLeveslsViewData } from '../tier-structure-utils'
import { addTab } from '../../core/core-actions'
import { createProfile } from '../../core/core-utils'

const mapStateToProps = (state) => {
  return {
    theme: state.core.theme,
    state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    filterViewData: (data, searchFilter, callback) => {
      filterTierLeveslsViewData(data, searchFilter, callback)
    },
    addTab: (payload) => {
      dispatch(addTab(payload))
    },
    createProfile: (appId, callback) => {
      createProfile(appId, callback)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TierEntries)
