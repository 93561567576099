import React from 'react'
import { Tooltip } from 'antd'
import { AppstoreOutlined } from '@ant-design/icons'
import Theme from '../../utils/agilite-theme'
import EnumsTooltip from '../../utils/enums-tooltips'

export default function ModuleIcon (props) {
  return (
    <Tooltip title={`${EnumsTooltip.general.moduleIcon} (${props.module})`}>
      <AppstoreOutlined style={{ color: Theme.primaryDark }} />
    </Tooltip>
  )
}
