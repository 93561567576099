import React, { useState, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ReCAPTCHA from 'react-google-recaptcha'
import Config from '../../utils/config'

import MemoryStore from '../../utils/memory-store'
import { Form, Input, Button, Col, Row, Card, Spin, Alert, message } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import Enums from '../../utils/enums'
import AgiliteHeader from '../../reusables/components/agilite-header'
// TODO: Use Enums where relevant
// TODO: Replace color codes with this.props.theme

const FormItem = Form.Item

const CoreForgotPassword = ({ forgotPassword, verifyRecaptchaToken, verifyRecaptchaTokenv2 }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [alertMessage, setAlertMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [lowScore, setLowScore] = useState(null)
  const [forgotDisabled, setForgotDisabled] = useState(true)
  const [forgotClicked, setForgotClicked] = useState(false)

  const entry = MemoryStore.registerForm
  const reCaptchaEnabled = Config.reCaptcha?.enabled || false

  const onChange = (key, value) => {
    switch (key) {
      case 'inputEmail':
        entry.email = value
        break
      default:
    }
  }

  useEffect(() => {
    if (reCaptchaEnabled) {
      executeRecaptcha('forgot_password')
        .then(result => {
          verifyRecaptchaToken(result, (res, err) => {
            if (err) {
              setLowScore(true)

              if (err.response) {
                message.error(err.response.data.errorMessage)
              } else {
                message.error(Enums.MESSAGES.UNKNOWN_ERROR)
              }
              return
            }

            if (res.data.success) {
              if (res.data.score < 0.5) {
                setLowScore(true)
              } else {
                setLowScore(false)
                setForgotDisabled(false)
              }
            } else {
              setLowScore(true)
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      setForgotDisabled(false)
    }
    // eslint-disable-next-line
  }, [])

  const verifyRecaptcha = (token) => {
    verifyRecaptchaTokenv2(token, (res, err) => {
      if (err) {
        if (err.response) {
          message.error(err.response.data.errorMessage)
        } else {
          message.error(Enums.MESSAGES.UNKNOWN_ERROR)
        }
        return
      }

      if (res.data.success) {
        setForgotDisabled(false)
      } else {
        message.error('reCaptcha Verification Failed')
      }
    })
  }

  return (
    <div>
      <Row type='flex' justify='center'>
        <Col xs={23} sm={23} md={16} lg={12} xl={10} xxl={8}>
          <div>
            <center>
              <AgiliteHeader header={Enums.VALUES_STRINGS.AGILITE_WELCOME} />
            </center>
          </div>
          <Card
            title='Provide your email below in order to submit a password reset request'
            type='inner'
          >
            <Form
              className='login-form'
            >
              <FormItem>
                <Input
                  prefix={<MailOutlined type='mail' style={{ color: '#7F7F7F' }} />}
                  type='email'
                  placeholder='Email'
                  defaultValue={entry.email}
                  required
                  onChange={e => {
                    onChange('inputEmail', e.target.value)
                  }}
                />
              </FormItem>
              <Row type='flex' justify='center'>
                <Col>
                  <FormItem>
                    <Button
                      style={{
                        backgroundColor: '#67AD5B',
                        color: 'white',
                        width: 'auto',
                        marginRight: 10
                      }}
                      disabled={forgotDisabled}
                      onClick={() => {
                        setForgotClicked(true)
                        forgotPassword((successful, message) => {
                          if (successful === false) {
                            setForgotClicked(false)
                            setAlertMessage(message)
                            setShowAlert(true)
                          } else {
                            setForgotClicked(false)
                            setAlertMessage('')
                            setShowAlert(false)
                          }
                        })
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      style={{
                        backgroundColor: '#EB5181',
                        color: 'white',
                        width: 'auto'
                      }}
                      onClick={e => {
                        e.preventDefault()
                        const port = window.location.port !== '' ? ':' + window.location.port : ''
                        window.open(window.location.protocol + '//' + window.location.hostname + port, '_self')
                      }}
                    >
                      Cancel
                    </Button>
                    {lowScore ?
                      <div style={{ marginTop: 10 }}>
                        <ReCAPTCHA
                          sitekey={Config.reCaptcha.v2_siteKey}
                          onChange={verifyRecaptcha}
                        />
                      </div>
                      : null}
                    {showAlert ? (
                      <div>
                        <Alert
                          style={{ marginTop: 10, marginBottom: 10 }}
                          message={alertMessage}
                          closable={false}
                          type='error'
                        />
                      </div>
                    ) : null}
                    {forgotClicked === true ? (
                      <div style={{ marginTop: 30 }} className='spin'>
                        <Spin size='large' />
                      </div>
                    ) : null}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Card>
          {reCaptchaEnabled ?
            <Card style={{ textAlign: 'center', marginTop: 10 }}>
              This site is protected by reCAPTCHA and the Google
              <a href='https://policies.google.com/privacy' rel='noopener noreferrer' target='_blank'> Privacy Policy</a> and
              <a href='https://policies.google.com/terms' rel='noopener noreferrer' target='_blank'> Terms of Service</a> apply.
            </Card>
            : 
            null
          }
        </Col>
      </Row>
    </div>
  )
}

export default CoreForgotPassword
