import React from 'react'
import Enums from '../../utils/enums'
import agiliteLogo from '../../images/agilite-logo-full-web.png'

export default ({ header, subheader }) => {
  return (
    <div>
      <img src={agiliteLogo} style={{ width: 400 }} alt={Enums.VALUES_STRINGS.AGILITE_LOGO} />
      <h1>{header}</h1>
      <h3>{subheader}</h3><br />
    </div>
  )
}
