import React from 'react'
import AceEditor from 'react-ace'
import MemoryStore from '../../../../../../utils/memory-store'
import { Form, Input, Select } from 'antd'
import CompileTemplateIcon from '../../../../../../reusables/components/compile-template-icon'

import 'brace/mode/json'
import 'brace/theme/xcode'

const { TextArea } = Input

class MongoDBRouteExecuteQry extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry.routeData,
      qryType: props.entry.routeData.qryType ? props.entry.routeData.qryType : ''
    }
  }

  changeQryType (e) {
    this.entry.custom.isModifiedSubform = true
    this.setState({ qryType: e })
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value.trim()
  }

  // Record Data Content
  getRecordDataContent () {
    return (
      <div>
        <h3>
          {'Record Data '}
          <CompileTemplateIcon />
        </h3>
        <AceEditor
          showPrintMargin={false}
          readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
          cursorStart={1}
          mode='json'
          theme='xcode'
          style={{ width: '100%', height: 400 }}
          name='aceEditor'
          value={this.state.entry.recordData}
          setOptions={{
            showLineNumbers: true,
            newLineMode: 'unix'
          }}
          onChange={value => {
            this.onFieldChange('recordData', value)
          }}
        />
      </div>
    )
  }

  // Query Conditions Content
  getFilterContent (label) {
    return (
      <Form.Item>
        <h3>
          {`${label} `}
          <CompileTemplateIcon />
        </h3>
        <AceEditor
          readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
          showPrintMargin={false}
          cursorStart={1}
          mode='json'
          theme='xcode'
          style={{ width: '100%', height: 200 }}
          name='aceEditor'
          value={this.state.entry.filterData}
          setOptions={{
            showLineNumbers: true,
            newLineMode: 'unix'
          }}
          onChange={value => {
            this.onFieldChange('filterData', value)
          }}
        />
      </Form.Item>
    )
  }

  getProjectionContent () {
    return (
      <Form.Item>
        {this.state.qryType === '11' ? 'Field ' : 'Projection '}
        <CompileTemplateIcon />
        <TextArea
          className='projection'
          placeholder={
            this.state.qryType === '11'
              ? 'Field'
              : 'Optional fields to return'
          }
          disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
          rows={3}
          defaultValue={this.state.entry.projection}
          onChange={e => {
            this.onFieldChange('projection', e.target.value)
          }}
        />
      </Form.Item>
    )
  }

  getOptionsContent () {
    return (
      <div>
        <h3>
          {'Options '}
          <CompileTemplateIcon />
        </h3>
        <AceEditor
          showPrintMargin={false}
          readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
          cursorStart={1}
          mode='json'
          theme='xcode'
          style={{ width: '100%', height: 200 }}
          name='aceEditor'
          value={this.state.entry.optionData}
          setOptions={{
            showLineNumbers: true,
            newLineMode: 'unix'
          }}
          onChange={value => {
            this.onFieldChange('optionData', value)
          }}
        />
      </div>
    )
  }

  getPageContent () {
    return (
      <>
        <Form.Item style={{ marginTop: 10 }}>
          {'Page '}
          <CompileTemplateIcon />
          <Input
            name='page'
            placeholder='1 for page 1, 2 for page 2, etc.'
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            defaultValue={this.state.entry.page}
            onChange={e => {
              this.onFieldChange('page', e.target.value)
            }}
          />
          <br />
        </Form.Item>
        <Form.Item>
          {'Page Limit '}
          <CompileTemplateIcon />
          <Input
            name='page_limit'
            placeholder='10 = 10 records, etc.'
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            defaultValue={this.state.entry.pageLimit}
            onChange={e => {
              this.onFieldChange('pageLimit', e.target.value)
            }}
          />
          <br />
        </Form.Item>
      </>
    )
  }

  getRecordIdContent () {
    return (
      <Form.Item>
        <span style={{ color: 'red' }}>* </span>
        {'Record Id '}
        <CompileTemplateIcon />
        <Input
          name='record_id'
          placeholder='Record Object Id (_id)'
          disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
          defaultValue={this.state.entry.recordId}
          onChange={e => {
            this.onFieldChange('recordId', e.target.value)
          }}
        />
        <br />
      </Form.Item>
    )
  }

  getIndexCreateContent () {
    return (
      <div>
        <h3>
          <span style={{ color: 'red' }}>* </span>
          {'Index Keys '}
          <CompileTemplateIcon />
        </h3>
        <AceEditor
          showPrintMargin={false}
          readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
          cursorStart={1}
          mode='json'
          theme='xcode'
          style={{ width: '100%', height: 200 }}
          name='aceEditor'
          value={this.state.entry.indexKeys}
          setOptions={{
            showLineNumbers: true,
            newLineMode: 'unix'
          }}
          onChange={value => {
            this.onFieldChange('indexKeys', value)
          }}
          placeholder={'e.g. { "fieldName": "text" }'}
        />
        <h3>
          <span style={{ color: 'red' }}>* </span>
          {'Index Options '}
          <CompileTemplateIcon />
        </h3>
        <AceEditor
          showPrintMargin={false}
          readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
          cursorStart={1}
          mode='json'
          theme='xcode'
          style={{ width: '100%', height: 200 }}
          name='aceEditor'
          value={this.state.entry.indexOptions}
          setOptions={{
            showLineNumbers: true,
            newLineMode: 'unix'
          }}
          onChange={value => {
            this.onFieldChange('indexOptions', value)
          }}
          placeholder={'e.g { "name": "textIndex" }'}
        />
      </div>
    )
  }

  getIndexDropContent () {
    return (
      <Form.Item>
        <span style={{ color: 'red' }}>* </span>
        {'Index Name '}
        <CompileTemplateIcon />
        <Input
          name='record_id'
          placeholder='Index Name'
          disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
          defaultValue={this.state.entry.indexName}
          onChange={e => {
            this.onFieldChange('indexName', e.target.value)
          }}
        />
        <br />
      </Form.Item>
    )
  }

  getIndexesDropContent () {
    return null
  }

  getQryTypeContent () {
    switch (this.state.qryType) {
      // Create / Insert Many
      case '0':
        return this.getRecordDataContent()
      // Find
      case '1':
        return (
          <div>
            {this.getFilterContent('Filter')}
            {this.getProjectionContent()}
            {this.getOptionsContent()}
            {this.getPageContent()}
          </div>
        )
      // FindById
      case '2':
        return (
          <div>
            {this.getRecordIdContent()}
            {this.getProjectionContent()}
            {this.getOptionsContent()}
          </div>
        )
      // FindByIdAndDelete
      case '3':
        return (
          <div>
            {this.getRecordIdContent()}
            {this.getOptionsContent()}
          </div>
        )
      // FindByIdAndRemove
      case '4':
        return (
          <div>
            {this.getRecordIdContent()}
            {this.getOptionsContent()}
          </div>
        )
      // FindByIdAndUpdate
      case '5':
        return (
          <div>
            {this.getRecordIdContent()}
            {this.getOptionsContent()}
            {this.getRecordDataContent()}
          </div>
        )
      // FindOne
      case '6':
        return (
          <div>
            {this.getFilterContent('Filter')}
            {this.getProjectionContent()}
            {this.getOptionsContent()}
          </div>
        )
      // FindOneAndDelete
      case '7':
        return (
          <div>
            {this.getFilterContent('Filter')}
            {this.getOptionsContent()}
          </div>
        )
      // FindOneAndRemove
      case '8':
        return (
          <div>
            {this.getFilterContent('Filter')}
            {this.getOptionsContent()}
          </div>
        )
      // FindOneAndUpdate
      case '9':
        return (
          <div>
            {this.getFilterContent('Filter')}
            {this.getOptionsContent()}
            {this.getRecordDataContent()}
          </div>
        )
      // DeleteOne
      case '10':
        return this.getFilterContent('Filter')
      // Distinct
      case '11':
        return (
          <div>
            {this.getProjectionContent()}
            {this.getFilterContent('Conditions')}
          </div>
        )
      // BulkWrite
      case '12':
        return (
          <div>
            {this.getOptionsContent()}
            {this.getRecordDataContent()}
          </div>
        )
      // UpdateMany
      case '13':
        return (
          <div>
            {this.getFilterContent('Filter')}
            {this.getOptionsContent()}
            {this.getRecordDataContent()}
          </div>
        )
      // DeleteMany
      case '14':
        return (
          <div>
            {this.getFilterContent('Filter')}
            {this.getOptionsContent()}
          </div>
        )
      // Aggregate
      case '15':
        return (
          <div>
            {this.getFilterContent('Pipeline')}
          </div>
        )
      // CountDocuments
      case '16':
        return (
          <div>
            {this.getFilterContent('Filter')}
          </div>
        )
      // EstimatedDocumentCount
      case '17':
        return (
          <div>
            {this.getOptionsContent()}
          </div>
        )
      // Create Index
      case '18':
        return (
          <div>
            {this.getIndexCreateContent()}
          </div>
        )
      // Drop Index
      case '19':
        return (
          <div>
            {this.getIndexDropContent()}
          </div>
        )
      // Drop Indexes
      case '20':
        return (
          <div>
            {this.getIndexesDropContent()}
          </div>
        )
      default:
        break
    }
  }

  render () {
    return (
      <div>
        <Form.Item>
          <span style={{ color: 'red' }}>* </span>
          Query Type
          <Select
            className='query_type'
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            defaultValue={this.state.entry.qryType ? this.state.entry.qryType : ''}
            onChange={e => {
              this.onFieldChange('qryType', e)
              this.changeQryType(e)
            }}
          >
            <Select.Option value=''>-Select-</Select.Option>
            <Select.Option value='0'>
              Create Document(s) (.create / .insertMany)
            </Select.Option>
            <Select.Option value='1'>Find Documents (.find)</Select.Option>
            <Select.Option value='2'>
              Find Document By Id (.findById)
            </Select.Option>
            <Select.Option value='3'>
              Find Document By Id And Delete (.findByIdAndDelete)
            </Select.Option>
            <Select.Option value='4'>
              Find Document By Id And Remove (.findByIdAndRemove)
            </Select.Option>
            <Select.Option value='5'>
              Find Document By Id And Update (.findByIdAndUpdate)
            </Select.Option>
            <Select.Option value='6'>
              Find One Document (.findOne)
            </Select.Option>
            <Select.Option value='7'>
              Find One Document and Delete (.findOneAndDelete)
            </Select.Option>
            <Select.Option value='8'>
              Find One Document and Remove (.findOneAndRemove)
            </Select.Option>
            <Select.Option value='9'>
              Find One Document and Update (.findOneAndUpdate)
            </Select.Option>
            <Select.Option value='10'>
              Delete Document (.deleteOne)
            </Select.Option>
            <Select.Option value='11'>Distinct (.distinct)</Select.Option>
            <Select.Option value='12'>Bulk Write (.bulkWrite)</Select.Option>
            <Select.Option value='13'>Update Many (.updateMany)</Select.Option>
            <Select.Option value='14'>Delete Many (.deleteMany)</Select.Option>
            <Select.Option value='15'>Aggregate (.aggregate)</Select.Option>
            <Select.Option value='16'>Count Documents (.countDocuments)</Select.Option>
            <Select.Option value='17'>Estimated Document Count (.estimatedDocumentCount)</Select.Option>
            <Select.Option value='18'>Create Index (.createIndex)</Select.Option>
            <Select.Option value='19'>Drop Index (.dropIndex)</Select.Option>
            <Select.Option value='20'>Drop Indexes (.dropIndexes)</Select.Option>
          </Select>
        </Form.Item>
        {this.getQryTypeContent()}
      </div>
    )
  }
}

export default MongoDBRouteExecuteQry
