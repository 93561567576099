const moduleIcon = `This field is a 'Module' field, meaning that it's referencing a different module in Agilit-e.
                  Values in this field will be used for integrating with the relevant Agilit-e module.`
const compileTemplateIcon = `This field is a 'handlebars-enabled' field, meaning that its value can be programmatically evaluated using handlebars quotes '{{}}' against an API's request body content that gets passed to Agilit-e. 
                    Reference https://handlebarsjs.com/ for more information.`
const profileKey = 'A profile\'s key is a unique, readable alias with no spaces or special characters, that is used to reference a module profile via an Agilit-e API.'
const profileName = 'A profile\'s name is a short, readable description that helps explain the profile\'s key.'
const appAdmin = 'Specify a Role for the App Admin. Agilit-e will evaluate the Role and return the responsible user via the \'getByProfileKey\' API.'
const appUrl = 'An App URL is a URL that points to the actual applicaition that makes use of this Business Process. The App URL will be returned in the response of the \'getByProfileKey\' API.'
const referenceUrl = 'A reference URL can be used to provide a URL link to learning material, videos, documents, etc. e.g. A video tutorial or diagram explaining the Business Process or a current Step of a Business Process. This reference URL is returned in the result of APIs including registerBPM Record and getRecordState.'

export default {
  general: {
    moduleIcon,
    compileTemplateIcon,
    profileKey,
    profileName
  },
  bpm: {
    appAdmin,
    appUrl,
    referenceUrl
  }
}
