import React from 'react'
import { Row, Col, Form, Input } from 'antd'
import MemoryStore from '../../../../../utils/memory-store'

import 'brace/mode/json'
import 'brace/theme/xcode'

class ConnectorsFormTypeSnowflake extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.disabled = MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'
    this.state = { entry: props.entry || {} }

    // Bindings
    this.onFieldChange = this.onFieldChange.bind(this)
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.props.entry[key] = value
  }

  render () {
    return (
      <Row>
        <Col xs={24} lg={16}>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Account'}
            <Input
              name='account'
              placeholder='Snowflake Account'
              disabled={this.disabled}
              defaultValue={this.state.entry.account}
              onChange={e => {
                this.onFieldChange('account', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Username'}
            <Input
              name='username'
              placeholder='Username'
              disabled={this.disabled}
              defaultValue={this.state.entry.username}
              onChange={e => {
                this.onFieldChange('username', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Password'}
            <Input
              name='password'
              placeholder='Database password'
              disabled={this.disabled}
              defaultValue={this.state.entry.password}
              autoComplete='new-password'
              type='password'
              onChange={e => {
                this.onFieldChange('password', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Database'}
            <Input
              name='database'
              placeholder='Database to connect to'
              disabled={this.disabled}
              defaultValue={this.state.entry.database}
              onChange={e => {
                this.onFieldChange('database', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Schema'}
            <Input
              name='schema'
              placeholder='Schema'
              disabled={this.disabled}
              defaultValue={this.state.entry.schema}
              onChange={e => {
                this.onFieldChange('schema', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Warehouse'}
            <Input
              name='warehouse'
              placeholder='Warehouse'
              disabled={this.disabled}
              defaultValue={this.state.entry.warehouse}
              onChange={e => {
                this.onFieldChange('warehouse', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Role'}
            <Input
              name='role'
              placeholder='Role'
              disabled={this.disabled}
              defaultValue={this.state.entry.role}
              onChange={e => {
                this.onFieldChange('role', e.target.value)
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    )
  }
}

export default ConnectorsFormTypeSnowflake
