import botBuilderState from './bot-builder-state'
import { actions } from './bot-builder-actions'

export default (state = botBuilderState, action) => {
  switch (action.type) {
    case actions.RESET_STATE:
      return Object.assign({}, state, botBuilderState)
    case actions.SET_SEARCH_FILTER:
      return Object.assign({}, state, {
        searchFilter: action.value
      })
    case actions.FETCH_ALL_DATA:
      return Object.assign({}, state, {
        data: action.payload
      })
    case actions.UPDATE_DATA:
      return Object.assign({}, state, {
        data: action.data
      })
    default:
      return state
  }
}
