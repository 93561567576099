import React from 'react'
import { Icon } from 'react-fa'
import { Popconfirm, Table, Row, Col, Input, Button, Spin, Tooltip, message, Tag } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import MemoryStore from '../../utils/memory-store'

import '../reusables-views.css'
import CopyKey from './copy-key'

class ListViewNested extends React.Component {
  constructor (props) {
    super(props)

    this.appId = props.appId

    this.state = {
      viewData: this.props.viewData,
      searchFilter: this.props.searchFilter,
      viewColumns: this.props.viewColumns,
      loading: false,
      columns: this.props.viewColumns,
      theme: this.props.theme
    }

    this.fetchAllData = this.fetchAllData.bind(this)
    this.generateViewHeader = this.generateViewHeader.bind(this)
    this.createProfile = this.createProfile.bind(this)
    this.editProfile = this.editProfile.bind(this)
    this.deleteProfile = this.deleteProfile.bind(this)
    this.createCopy = this.createCopy.bind(this)
    this.updateViewColumns = this.updateViewColumns.bind(this)
    this.expandedRowRender = this.expandedRowRender.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.fetchAllData()
  }

  fetchAllData () {
    this.setState({
      loading: true
    })
    this.props.fetchAllData(this.props.appId, (success, error) => {
      if (success) {
        this.updateViewColumns(this.props)
        this.setState({ loading: false })
      } else {
        message.error(error)
        this.setState({ loading: false })
      }
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.appId !== nextProps.appId) {
      return null
    }

    this.updateViewColumns(nextProps)

    this.setState({
      viewData: nextProps.viewData,
      searchFilter: nextProps.searchFilter,
      viewColumns: nextProps.viewColumns
    })
  }

  updateViewColumns (props) {
    // Add Intelligence to relevant Columns
    const columns = props.viewColumns

    for (const x in columns) {
      // Check which column needs to be grouped
      if (columns[x].groupable) {
        for (const propName in props) {
          if (propName === columns[x].key + 'Filter') {
            columns[x].filters = props[propName]
          }
        }
      }
    }

    this.setState({ columns })
  }

  expandedRowRender (entry) {
    return (
      <Table
        columns={this.props.viewColumnsNested}
        dataSource={entry.data.values}
        size='middle'
        pagination={false}
      />
    )
  }

  createProfile () {
    const tmpThis = this

    tmpThis.props.createProfile(tmpThis.props.appId, (result) => {
      if (result) {
        // Setup Content property and Add Tab
        result.content = <result.content />
        tmpThis.props.addTab(result)
      }
    })
  }

  createCopy (recordData) {
    const tmpThis = this
    tmpThis.props.onCreateCopy(recordData, function (result) {
      if (result) {
        // Setup Content property and Add Tab
        result.content = <result.content />
        tmpThis.props.addTab(result)
      }
    })
  }

  editProfile (recordId) {
    const tmpThis = this

    tmpThis.props.editProfile(tmpThis.props.appId, recordId, this.props.viewData, (result) => {
      if (result) {
        // Setup Content property and Add Tab
        result.content = <result.content />
        tmpThis.props.addTab(result)
      }
    })
  }

  deleteProfile (recordId) {
    this.props.deleteProfile(this.props.appId, recordId, this.props.state, (err) => {
      if (err) {
        message.error(err)
      }
    })
  }

  generateViewHeader () {
    return (
      <div>
        <Row type='flex'>
          <Col xs={7} sm={5} md={4} lg={4} xl={3} xxl={2} style={{ textAlign: 'left' }}>
            <Button
              disabled={MemoryStore.userProfile.teamPrivileges[this.props.appId] === 'Reader' ? true : false}
              style={{ backgroundColor: '#67AD5B', color: 'white' }}
              onClick={e => {
                e.preventDefault()
                this.createProfile()
              }}
            >
              Create New
            </Button>
          </Col>
          <Col xs={13} sm={14} md={16} lg={16} xl={19} xxl={20}>
            <Input
              name='search'
              placeholder={`Search ${this.props.viewTitle}...`}
              defaultValue={this.props.searchFilter}
              onChange={(e) => {
                this.props.setSearchFilter(e.target.value)
              }}
            />
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={2} xxl={2}>
            <Tooltip title='Refresh View Data'>
              <Button
                name='refresh'
                type='default'
                onClick={(e) => {
                  e.preventDefault()
                  this.fetchAllData()
                }}
              >
                <SyncOutlined
                  style={{ fontSize: 20, fontWeight: 'bolder', color: '#67AD5B' }}
                  spin={this.state.loading}
                />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </div>
    )
  }

  render () {
    const columns = []
    const tmpThis = this
    let tmpObj = {}
    let data = null

    for (const x in this.state.columns) {
      tmpObj = JSON.parse(JSON.stringify(this.state.columns[x]))

      // Add render function to all columns at first
      // The element rendered here will ensure that the content does not exceed width
      tmpObj.render = (text) => {
        return (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {text}
          </div>
        )
      }

      // Show Solutions
      if (this.state.columns[x].key === 'solutions') {
        tmpObj.render = (text, record) => {
          if (record.data.solutions) {
            return (
              record.data.solutions.map((entry, index) => {
                return <Tag style={{ margin: 2 }} color='geekblue' key={index}>{entry}</Tag>
              })
            )
          }
        }
      }

      // Check which column is used to edit the record and copy the key
      if (this.state.columns[x].editRecord && this.state.columns[x].copyKey) {
        tmpObj.render = (text, record) => {
          return (
            <div>
              <a onClick={() => this.editProfile(record._id)}>{text}</a>
              <CopyKey value={record.data ? record.data.key : record.key} />
            </div>
          )
        }
      } else if (this.state.columns[x].editRecord) {
        tmpObj.render = (text, record) => {
          return (
            <div><a onClick={() => this.editProfile(record._id)}>{text}</a></div>
          )
        }
      } else if (this.state.columns[x].copyKey) {
        tmpObj.render = (text, record) => {
          return (
            <div>
              {text}
              <CopyKey value={record.data ? record.data.key : record.key} />
            </div>
          )
        }
      }

      if (this.state.columns[x].groupable) {
        // eslint-disable-next-line
        tmpObj.onFilter = function (value, record) {
          data = record.data ? record.data : record
          return data[tmpThis.state.columns[x].key].toLowerCase() === value.toLowerCase()
        }
      }

      if (this.state.columns[x].isActions) {
        // eslint-disable-next-line
        tmpObj.render = (text, record) => {
          return (
            <div style={{ minWidth: 50 }}>
              {/* //First Delete Action */}
              {((this.state.columns[x].actionTypes.indexOf('delete') > -1) && (MemoryStore.userProfile.teamPrivileges[this.props.appId] !== 'Reader')) ?
                <div className={process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'}>
                  <Popconfirm
                    title='Are you sure delete this Profile?'
                    onConfirm={() => {
                      this.deleteProfile(record._id)
                    }}
                    okText='Yes'
                    cancelText='No'
                  >
                    {/* eslint-disable-next-line */}
                    <a title='Delete Profile'>
                      <Icon name='trash-o' style={{ color: tmpThis.state.theme.dangerColor, marginRight: 10 }} />
                    </a>
                  </Popconfirm>
                </div>
                : null}
              {/* Create Copy  */}
              {this.state.columns[x].actionTypes.indexOf('create-copy') > -1 ?
                <div className={process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'} style={{ marginLeft: 10, marginRight: 10 }}>
                  {/* eslint-disable-next-line */}
                  <a>
                    <Icon name='clone' onClick={() => this.createCopy(record.data)} style={{ color: tmpThis.state.theme.primaryLight }} />
                  </a>
                </div>
                : null}
              {/* //Then Generate API Keys Action */}
              {this.state.columns[x].actionTypes.indexOf('generate-api-key') > -1 ?
                <div className={process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'}>
                  {/* eslint-disable-next-line  */}
                  <a>
                    <Icon name='trash-o' style={{ color: tmpThis.state.theme.dangerColor }} />
                  </a>
                </div>
                : null}
            </div>
          )
        }
      }

      columns.push(tmpObj)
    }

    return (
      <Row type='flex' justify='center'>
        <Col xs={23}>
          <Table
            loading={this.state.loading}
            className='agilite-nested-root-table'
            columns={columns}
            dataSource={this.state.viewData}
            expandedRowRender={this.expandedRowRender}
            showHeader
            bordered
            title={this.generateViewHeader}
            size='middle'
            pagination={false}
          />
        </Col>
      </Row>
    )
  }
}

export default ListViewNested
