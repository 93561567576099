import { connect } from 'react-redux'
import Dashboard from '../components/dashboard'
import { generateReport } from '../dashboard-actions'

const mapStateToProps = state => {
  return {
    state: state.dashboard
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGenerateReport: (startDate, endDate, callback) => {
      generateReport(startDate, endDate, callback)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
