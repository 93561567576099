import MemoryStore from './memory-store'
import {
  generalTemplates
} from './templates'
import Enums from './enums'
import uniqueId from 'lodash/uniqueId'
import toLower from 'lodash/toLower'

import TypeDetect from 'agilite-utils/type-detect'
import EnumsTypeDetect from 'agilite-utils/enums-type-detect'

export const prepRecordForAdminPortal = (record, app) => {
  record.custom = JSON.parse(JSON.stringify(generalTemplates.customTemplate))
  record.custom.isSavedDoc = true
  record.custom.isNewDoc = false

  if (MemoryStore.userProfile.teamPrivileges[app] && MemoryStore.userProfile.teamPrivileges[app] === 'Reader') {
    record.custom.readOnly = true
  }

  // Setup Fields for Table
  if (!record.key) {
    record.key = record._id
  }

  if (record.data && record.data.isActive !== undefined) {
    record.data.isActiveDescription = record.data.isActive ? 'Yes' : 'No'
  }

  if (record.isActive !== undefined) {
    record.isActiveDescription = record.isActive ? 'Yes' : 'No'
  }

  // MODULECONDITION
  switch (app) {
    case Enums.APP_IDS.KEYWORDS:
      for (const x in record.data.values) {
        record.data.values[x].key = uniqueId('keywords_')
      }

      break
    case Enums.APP_IDS.TIER_STRUCTURES:
      for (const x in record.data.values) {
        record.data.values[x].key = uniqueId('tierstructures_')
      }

      break
    case Enums.APP_IDS.CONNECTORS:
      if (!record.data.connectionData) {
        record.data.connectionData = {}
      }

      for (const x in record.data.routes) {
        if (!record.data.routes[x].routeData) {
          record.data.routes[x].routeData = {}
        }
      }

      break
    case Enums.APP_IDS.ROLES:
      record.data.tmpLevels = []
      record.data.tmpResponsibleUser = record.data.responsibleUser.join(',')

      for (const x in record.data.levels) {
        record.data.tmpLevels.push(record.data.levels[x].value)
      }

      record.data.tmpLevels = record.data.tmpLevels.length > 0 ? record.data.tmpLevels.join(' - ') : ''
      break
    case Enums.APP_IDS.NODE_RED:
      record.data.isActiveDescription = record.data.isActive ? 'Active' : 'Disabled'
      break
    default:
  }

  return null
}

export const generateGrouping = (viewData, prop) => {
  const keyArray = []
  const result = []
  let data = null

  viewData.forEach(entry => {
    data = entry.data ? entry.data : entry

    if (data[prop] && data[prop] !== '') {
      if (keyArray.indexOf(data[prop].toLowerCase()) === -1) {
        keyArray.push(data[prop].toLowerCase())
        result.push({
          text: data[prop],
          value: data[prop]
        })
      }
    }
  })

  return result
}

export const isDuplicateEntry = (entry, fieldName, data, hasDataProp) => {
  const key = hasDataProp ? entry.data[fieldName].toLowerCase() : entry[fieldName].toLowerCase()
  const tempArray = data.filter(t => ((hasDataProp ? t.data[fieldName].toLowerCase() : t[fieldName].toLowerCase()) === key))
  let result = true

  if (tempArray.length > 0) {
    if (entry.custom.isNewDoc) { // Definitely a duplicate
      result = false
    } else if (tempArray.length > 1) { // Definitely a duplicate
      result = false
    } else { // It's only 1 doc returned. We need to see if the ids matcth to see if we are referencing the existing doc
      if (tempArray[0]._id !== entry._id) {
        result = false
      }
    }
  }

  return result
}

export const updateArrayDataFromSave = (entry, data) => {
  let tempIndex = -1

  // We need to see if we have to find doc by its temp id or main id
  if (entry.custom.tempId) {
    tempIndex = data.findIndex(t => t._id === entry.custom.tempId)
  }

  if (tempIndex < 0) {
    tempIndex = data.findIndex(t => t._id === entry._id)
  }

  if (tempIndex >= 0) {
    data[tempIndex] = JSON.parse(JSON.stringify(entry))
  }

  return data
}

export const cleanUpTableData = (dataArray, isJSONData) => {
  let entry = null
  let hasValue = false

  switch (isJSONData) {
    case false:
      // Single Value Tables
      // First Trim all values
      // Then delete key properties
      // Then Strip empty values from array
      for (let x = 0, y = dataArray.length; x < y; x++) {
        if (Object.keys(dataArray[x]).includes('value')) {
          dataArray[x].value = dataArray[x].value.trim()
        }

        delete dataArray[x].key

        if (dataArray[x] === '' || dataArray[x].value === '') {
          dataArray.splice(x, 1)
          x--
          y = dataArray.length
        }
      }
      break
    case true:
      // Multi Value Tables
      // First Trim all values
      // Then Strip empty values from array
      for (let x = 0, y = dataArray.length; x < y; x++) {
        entry = dataArray[x]
        hasValue = false

        for (const z in entry) {
          if (typeof entry[z] === 'string') entry[z] = entry[z].trim()

          // TODO: Include keys
          if (entry[z] !== '' && z !== 'key' && z !== 'paramType') {
            hasValue = true
          }
        }

        if (!hasValue) {
          dataArray.splice(x, 1)
          x--
          y = dataArray.length
        }
      }

      break
    default:
      break
  }

  return dataArray
}

export const formatRecordData = (record) => {
  // Key
  if (record.data.key) {
    record.data.key = formatAliasValue(record.data.key)
  }

  // Name
  if (record.data.name) {
    // record.data.name = record.data.name.trim().split(' ').map(value => {
    //     return value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();
    // }).join(" ");

    record.data.name = record.data.name.trim()
  }

  // Group Name
  if (record.data.groupName) {
    record.data.groupName = record.data.groupName.trim().replace(/[^a-z0-9_ -]/gi, '').split(' ').map(value => {
      return value.charAt(0).toUpperCase() + value.substr(1).toLowerCase()
    }).join(' ')
  }

  // Description
  if (record.data.description) {
    record.data.description = record.data.description.trim()
  }

  return record
}

export const formatAliasValue = (value) => {
  return toLower(value.replace(/[^a-z0-9_-]/gi, ''))
}

export const formatNameValue = (value) => {
  return value.trim()
}

export const cleanupObject = (dataObject) => {
  let dataType = TypeDetect(dataObject)

  // Check if dataObject is an Object or an Array else return
  if ((dataType !== EnumsTypeDetect.OBJECT) && (dataType !== EnumsTypeDetect.ARRAY)) return dataObject

  // Check if dataObject is an Object and delete relevant props
  if (dataType === EnumsTypeDetect.OBJECT) {
    delete dataObject.isNewEntry
    delete dataObject.isActiveDescription
    delete dataObject.stepTypeDescription
    delete dataObject.isFirstStepDescription
    delete dataObject.rowKey
  }

  // Loop through each prop, if prop is an object or an array we need to run cleanupObject against it
  for (const prop in dataObject) {
    dataType = TypeDetect(dataObject[prop])

    if (dataType === EnumsTypeDetect.OBJECT || dataType === EnumsTypeDetect.ARRAY) cleanupObject(dataObject[prop])
  }

  return dataObject
}
