import React, { Component } from 'react'
import { List } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import agiliteTheme from '../../utils/agilite-theme'

class PasswordValidationItem extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <List.Item>
        {this.props.item.complete ?
          <CheckCircleOutlined style={{ fontSize: 16, marginRight: 5, color: agiliteTheme.passwordValidationLime }} />
        :
          <CloseCircleOutlined style={{ fontSize: 16, marginRight: 5, color: agiliteTheme.primary }} />
        }
        {this.props.item.description}
      </List.Item>
    )
  }
}

export default PasswordValidationItem
