import React from 'react'

import { Input, Row, Col, Form, Select } from 'antd'

/*
  Custom Components Import
*/
import TableKeyValueEditableDND from '../../../../../reusables/components/table-key-value-editable-dnd'
import MemoryStore from '../../../../../utils/memory-store'
import CompileTemplateIcon from '../../../../../reusables/components/compile-template-icon'

class ConnectorsFormTypeWebAPI extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.updateQueryParams = this.updateQueryParams.bind(this)
    this.updateHeaderParams = this.updateHeaderParams.bind(this)

    this.state = {
      authType: this.props.entry.authType ? this.props.entry.authType : ''
    }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.props.entry[key] = value
  }

  updateQueryParams (data) {
    this.entry.custom.isModified = true
    this.props.entry.queryParams = data
  }

  updateHeaderParams (data) {
    this.entry.custom.isModified = true
    this.props.entry.headerParams = data
  }

  render () {
    return (
      <>
        <Row>
          <Col span={10}>
            <Form.Item>
              {'Authentication Type '}
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                value={this.state.authType}
                onChange={value => {
                  this.onFieldChange('authType', value)
                  this.setState({ authType: value })
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='basic'>Basic</Select.Option>
              </Select>
            </Form.Item>
            {this.state.authType === 'basic' ? (
              <div>
                <Form.Item>
                  <span style={{ color: 'red' }}>* </span>
                  {'Username '}
                  <CompileTemplateIcon />
                  <Input
                    name='username'
                    placeholder='Please provide a Username'
                    disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                    autoComplete='new-password'
                    defaultValue={this.props.entry.username}
                    onChange={e => {
                      this.onFieldChange('username', e.target.value)
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <span style={{ color: 'red' }}>* </span>
                  {'Password '}
                  <CompileTemplateIcon />
                  <Input
                    name='password'
                    placeholder='Please provide a Password'
                    disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                    autoComplete='new-password'
                    type='password'
                    defaultValue={this.props.entry.password}
                    onChange={e => {
                      this.onFieldChange('password', e.target.value)
                    }}
                  />
                </Form.Item>
              </div>
            ) : null}
            <Form.Item>
              <span style={{ color: 'red' }}>* </span>
              {'Web API Base URL '}
              <CompileTemplateIcon />
              <Input
                name='url'
                placeholder={'e.g. http://api.acme.com (Don\'t end URL with /)'}
                disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                defaultValue={this.props.entry.url}
                onChange={e => {
                  this.onFieldChange('url', e.target.value)
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={20}>
            <h3>
              Query Parameters <CompileTemplateIcon />
            </h3>
            <TableKeyValueEditableDND
              values={this.props.entry.queryParams ? this.props.entry.queryParams : [{ paramKey: '', paramValue: '' }]}
              columnTitle='Key'
              theme={this.props.theme}
              privileges={MemoryStore.userProfile.teamPrivileges.connectors}
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              callback={this.updateQueryParams}
              fields={['paramKey', 'paramValue']}
            />
            <br />
            <h3>
              Header Parameters <CompileTemplateIcon />
            </h3>
            <TableKeyValueEditableDND
              values={this.props.entry.headerParams ? this.props.entry.headerParams : [{ paramKey: '', paramValue: '' }]}
              columnTitle='Key'
              theme={this.props.theme}
              privileges={MemoryStore.userProfile.teamPrivileges.connectors}
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              callback={this.updateHeaderParams}
              fields={['paramKey', 'paramValue']}
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default ConnectorsFormTypeWebAPI
