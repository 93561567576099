import { connect } from 'react-redux'

import { setSearchFilter } from '../bpm-actions'
import { filterViewData, createCopy } from '../bpm-utils'
import { generateGrouping } from '../../utils/utilities'

import ListViewContainerNestedTree from '../../reusables/containers/list-view-nested-tree-container'
import Enums from '../../utils/enums'

const mapStateToProps = state => {
  if (!state[state.core.tabObject.activeApp]) return {}

  if (state.core.tabObject.activeApp !== Enums.APP_IDS.BPM) return {}

  const appId = state.core.tabObject.activeApp
  const data = state[appId].data
  const viewData = filterViewData(data, state[appId].searchFilter)
  const groupNameFilter = generateGrouping(viewData, 'groupName')
  const isActiveDescriptionFilter = generateGrouping(viewData, 'isActiveDescription')
  const nestedPropName = 'processSteps'
  const nestedPropName2 = 'stepOptions'

  return {
    viewData,
    groupNameFilter,
    isActiveDescriptionFilter,
    nestedPropName,
    nestedPropName2
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCreateCopy: (recordData, callback) => {
      createCopy(recordData, callback)
    },
    setSearchFilter: value => {
      dispatch(setSearchFilter(value))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListViewContainerNestedTree)
