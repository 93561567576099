import React, { useState } from 'react'
import { Divider, Modal } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import AceEditor from 'react-ace'

import agiliteTheme from '../../utils/agilite-theme'

import 'brace/mode/json'
import 'brace/theme/xcode'

const OracleHelp = ({ field }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const generateContent = () => {
    switch (field) {
      case 'Bind Parameters':
        return (
          <>
            <h3 style={{ marginTop: 10, marginBottom: 20 }}>Example 1: Using an Array of values</h3>
            <span><b>Query:</b> <p style={{ fontFamily: 'monospace' }}>SELECT manager_id, department_id, department_name FROM departments WHERE manager_id = :id</p></span>
            <b>Bind Parameters:</b>
            <AceEditor
              readOnly
              mode='json'
              theme='xcode'
              maxLines={1}
              style={{ width: '100%', height: 50, marginBottom: 20 }}
              value='[108] // bind value for :id in the query'
              setOptions={{ printMargin: false }}
            />
            <Divider />
            <h3 style={{ marginTop: 10, marginBottom: 20 }}>Example 2: Using JSON to define variable properties</h3>
            <span><b>Query:</b> <p style={{ fontFamily: 'monospace' }}>INSERT INTO mytable (mydata) VALUES ('Hello') RETURN myid INTO :id</p></span>
            <b>Bind Parameters:</b>
            <AceEditor
              readOnly
              mode='json'
              theme='xcode'
              maxLines={10}
              style={{ width: '100%', height: 50, marginBottom: 20 }}
              value={JSON.stringify({ id: { type: 2010, dir: 3003 } }, null, 2)}
              setOptions={{ printMargin: false }}
            />
            <p><b>NOTE:</b> OracleDB Constant Codes (e.g. 3003) can be found in OracleDB's documentation.</p>
            <p>In this example the code 2010 refers to DB Type "NUMBER" or "FLOAT". 3003 refers to an "OUT" Bind Direction.</p>
            <p>Reference OracleDB's <a href='https://oracle.github.io/node-oracledb/doc/api.html#executebindParams' target='_blank' rel='noreferrer'>Documentation</a> for more info.</p>
          </>
        )
      case 'Options':
        return (
          <>
            <h3 style={{ marginTop: 10, marginBottom: 20 }}>Example</h3>
            <span><b>Query:</b> <p style={{ fontFamily: 'monospace' }}>SELECT manager_id, department_id, department_name FROM departments WHERE manager_id = :id</p></span>
            <b>Bind Parameters:</b>
            <AceEditor
              readOnly
              mode='json'
              theme='xcode'
              maxLines={1}
              style={{ width: '100%', height: 50, marginBottom: 20 }}
              value='[108] // bind value for :id in the query'
              setOptions={{ printMargin: false }}
            />
            <b>Options:</b>
            <AceEditor
              readOnly
              mode='json'
              theme='xcode'
              maxLines={10}
              style={{ width: '100%', height: 50, marginBottom: 20 }}
              value={JSON.stringify({ extendedMetaData: true, outFormat: 4002 }, null, 2)}
              setOptions={{ printMargin: false }}
            />
            <p><b>NOTE:</b> OracleDB Constant Codes (e.g. 4002) can be found in OracleDB's documentation.</p>
            <p>Reference OracleDB's <a href='https://oracle.github.io/node-oracledb/doc/api.html#executebindParams' target='_blank' rel='noreferrer'>Documentation</a> for more info.</p>
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <QuestionCircleOutlined
        style={{
          float: 'right', color: agiliteTheme.linkBlue, fontSize: 16, cursor: 'pointer'
        }}
        onClick={() => setModalOpen(true)}
      />
      <Modal
        width='45%'
        visible={modalOpen}
        title={field ? `${field}` : 'Help'}
        okText='Close'
        maskClosable
        onCancel={() => setModalOpen(false)}
        destroyOnClose
        cancelButtonProps={{
          hidden: true
        }}
        okButtonProps={{
          hidden: true
        }}
      >
        {generateContent()}
      </Modal>
    </>
  )
}

export default OracleHelp
