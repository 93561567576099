import { connect } from 'react-redux'
import { setSearchFilter } from '../team-users-actions'
import { filterViewData } from '../team-users-utils'
import { generateGrouping } from '../../utils/utilities'
import ListViewContainer from '../../reusables/containers/list-view-container'
import Enums from '../../utils/enums'

const mapStateToProps = (state) => {
  if (!state[state.core.tabObject.activeApp]) {
    return {}
  }

  if (state.core.tabObject.activeApp !== Enums.APP_IDS.TEAM_USERS) {
    return {}
  }

  const appId = state.core.tabObject.activeApp
  const data = state[appId].data
  const viewData = filterViewData(data, state[appId].searchFilter)
  const statusFilter = generateGrouping(viewData, 'status')
  const roleFilter = generateGrouping(viewData, 'role')

  return {
    viewData,
    statusFilter,
    roleFilter
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchFilter: (value) => {
      dispatch(setSearchFilter(value))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListViewContainer)
