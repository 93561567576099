import React from 'react'
import MemoryStore from '../../../utils/memory-store'

import { Card, Input, Form, Row, Col, Tabs, Switch } from 'antd'

/*
  Custom Components Import
*/
import MultiEntryFormJson from './multi-entry-form-json'
import MultiEntryFormExcel from './multi-entry-form-excel'
import FieldMappingsContainer from '../../containers/field-mappings-container'
import TooltipIcon from '../../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../../utils/enums-tooltips'
import Description from '../../../reusables/components/description'

const TabPane = Tabs.TabPane

class MultiEntryForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: this.props.entry
    }

    this.onFieldChange = this.onFieldChange.bind(this)
    this.updateArrayData = this.updateArrayData.bind(this)
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry[key] = value
  }

  updateArrayData (data) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.multiEntryMappings = data
    this.setState({ entry: this.props.entry })
  }

  render () {
    return (
      <Row>
        <Col span={24}>
          <Card>
            <Tabs defaultActiveKey='1' animated={false}>
              <TabPane key='1' tab='General'>
                <Row type='flex' justify='space-between'>
                  <Col xs={24} lg={7}>
                    <h3>General Details</h3>
                    <Form.Item>
                      <Switch
                        disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                        checkedChildren='Active'
                        unCheckedChildren='Inactive'
                        defaultChecked={this.state.entry.isActive}
                        onChange={(e) => {
                          this.onFieldChange('isActive', e)
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <span style={{ color: 'red' }}>* </span>{'Profile Name '}
                      <TooltipIcon title={EnumsTooltips.general.profileName} />
                      <Input
                        name='multi_name'
                        placeholder='Provide a Mapping Profile Name'
                        disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                        defaultValue={this.state.entry.name}
                        required
                        onChange={(e) => {
                          this.onFieldChange('name', e.target.value)
                        }}
                      />
                    </Form.Item>
                    <Description
                      disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                      description={this.state.entry.description}
                      onChange={(e) => {
                        this.onFieldChange('description', e.target.value)
                      }}
                    />
                  </Col>
                  <Col xs={24} lg={8}>
                    <h3>Source Details</h3>
                    {this.props.profile.sourceType === '1' ? <MultiEntryFormJson tabKey={this.props.tabKey} entry={this.state.entry.srcJson} /> : null}
                    {this.props.profile.sourceType === '2' ? <MultiEntryFormExcel tabKey={this.props.tabKey} entry={this.state.entry.srcExcel} /> : null}
                  </Col>
                  <Col xs={24} lg={8}>
                    <h3>Destination Details</h3>
                    {this.props.profile.destinationType === '1' ? <MultiEntryFormJson tabKey={this.props.tabKey} entry={this.state.entry.desJson} /> : null}
                    {this.props.profile.destinationType === '2' ? <MultiEntryFormExcel tabKey={this.props.tabKey} entry={this.state.entry.desExcel} /> : null}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab='Field Mappings' key='2'>
                <FieldMappingsContainer tabKey={this.props.tabKey} updateArrayData={this.updateArrayData} data={this.state.entry.multiEntryMappings} profile={this.props.profile} isParentMulti={this.props.isParentMulti} />
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default MultiEntryForm
