import MemoryStore from '../utils/memory-store'
import { isDuplicateEntry, updateArrayDataFromSave } from '../utils/utilities'
import { postData } from '../core/core-utils'
import { formatRecord } from './bot-builder-utils'

/*
  ENUMS
*/
export const actions = {
  RESET_STATE: 'RESET_STATE_BOTBUILDER',
  CREATE_PROFILE: 'CREATE_PROFILE_BOTBUILDER',
  SAVE_PROFILE: 'SAVE_PROFILE_BOTBUILDER',
  CANCEL_PROFILE: 'CANCEL_PROFILE_BOTBUILDER',
  EDIT_PROFILE: 'EDIT_PROFILE_BOTBUILDER',
  DELETE_PROFILE: 'DELETE_PROFILE_BOTBUILDER',
  FETCH_ALL_DATA: 'FETCH_ALL_DATA_BOTBUILDER',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER_BOTBUILDER',
  SET_BOT_PROCESS_SEARCH_FILTER: 'SET_BOT_PROCESS_SEARCH_FILTER_BOTBUILDER',
  UPDATE_DATA: 'UPDATE_DATA_BOTBUILDER'
}

/*
  DISPATCH FUNCTIONS
*/
export const setSearchFilter = value => {
  return {
    type: actions.SET_SEARCH_FILTER,
    value
  }
}

export const updateData = data => {
  return {
    type: actions.UPDATE_DATA,
    data
  }
}

export const saveProfile = (appId, recordId, state, publish, callback) => {
  return dispatch => {
    const entry = MemoryStore.activeEntries[recordId]
    const headers = {}
    let result = true
    let reqType = ''
    let tempArray = state.data.concat()
    let errMsg = null

    // Duplicate Check
    result = isDuplicateEntry(entry, 'key', tempArray, true)

    if (!result) {
      errMsg = `Profile Key '${entry.data.key}' is already being used`
    } else if (entry.data.key === '') {
      errMsg = 'Please provide a Profile Key'
    } else if (entry.data.name === '') {
      errMsg = 'Please provide a Profile Name'
    } else if (entry.data.msAppId === '') {
      errMsg = 'Please provide a Microsoft Bot App Id'
    } else if (entry.data.msAppPwd === '') {
      errMsg = 'Please provide a Microsoft Bot App Password'
    } else if (entry.data.urlRouteName === '') {
      errMsg = 'Please provide a URL Route Name'
    }

    if (errMsg) {
      return callback(errMsg)
    }

    // Return result to Tab
    callback(null)

    // Post Data to Agilit-e
    tempArray = formatRecord(entry, tempArray)
    reqType = entry.custom.action === 'create' ? 'post' : 'put'

    // Create Header Object
    headers['record-id'] = recordId
    headers.publish = publish

    postData(appId, reqType, entry, headers)
      .then((response) => {
        response.data.custom.action = ''
        response.data.custom.isSavedDoc = true
        response.data.key = response.data._id
        response.data.data.isActiveDescription = response.data.data.isActive ? 'Yes' : 'No'

        // Dispatch New Array
        tempArray = updateArrayDataFromSave(response.data, tempArray)
        dispatch({ type: actions.UPDATE_DATA, data: tempArray })
      })
      .catch((error) => {
        console.log(error)
        return null
      })
  }
}
