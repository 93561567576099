import React from 'react'
import { Form, Row, Col, Card } from 'antd'

/*
  Custom Components Import
*/
import ConnectorsRouteFormGeneral from '../connectors-form/connectors-route-form-general'
import WebApiRouteMain from '../connectors-form/connection-types/web-api/web-api-route-main'
import MongoDBRouteMain from '../connectors-form/connection-types/mongodb/mongodb-route-main'
import SMTPRouteMain from './connection-types/smtp/smtp-route-main'
import DB2ISeriesRouteMain from './connection-types/db2-iseries/db2-iseries-route-main'
import MSSQLRouteMain from './connection-types/ms-sql/ms-sql-route-main'
import FTPRouteMain from './connection-types/ftp/ftp-route-main'
import LDAPRouteMain from './connection-types/ldap/ldap-route-main'
import PostgreSQLRouteMain from './connection-types/postgresql/postgresql-route-main'
import OracleDbRouteMain from './connection-types/oracledb/oracledb-route-main'
import SFTPRouteMain from './connection-types/sftp/sftp-route-main'
import IMAPRouteMain from './connection-types/imap/imap-route-main'
import SnowflakeRouteMain from './connection-types/snowflake/snowflake-route-main'

class ConnectorsRouteForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      connectionType: props.connectionType
    }
  }

  render () {
    return (
      <Row>
        <Col span={24}>
          <Form>
            <Card>
              <ConnectorsRouteFormGeneral tabKey={this.props.tabKey} route={this.props.entry} />
            </Card>
            <Card style={{ marginTop: 20 }}>
              {this.state.connectionType === '1' ?
                <WebApiRouteMain
                  entry={this.props.entry}
                  theme={this.props.theme}
                  tabKey={this.props.tabKey}
                />
                : null}
              {this.state.connectionType === '10' ?
                <MongoDBRouteMain
                  entry={this.props.entry}
                  theme={this.props.theme}
                  tabKey={this.props.tabKey}
                />
                : null}
              {this.state.connectionType === '6' ?
                <SMTPRouteMain
                  entry={this.props.entry}
                  theme={this.props.theme}
                  tabKey={this.props.tabKey}
                />
                : null}
              {this.state.connectionType === '7' ?
                <DB2ISeriesRouteMain
                  entry={this.props.entry}
                  theme={this.props.theme}
                  tabKey={this.props.tabKey}
                />
                : null}
              {this.state.connectionType === '11' ?
                <FTPRouteMain
                  entry={this.props.entry}
                  theme={this.props.theme}
                  tabKey={this.props.tabKey}
                />
                : null}
              {this.state.connectionType === '12' ?
                <MSSQLRouteMain
                  entry={this.props.entry}
                  theme={this.props.theme}
                  tabKey={this.props.tabKey}
                />
                : null}
              {this.state.connectionType === '13' ?
                <LDAPRouteMain
                  entry={this.props.entry}
                  theme={this.props.theme}
                  tabKey={this.props.tabKey}
                />
                : null}
              {this.state.connectionType === '14' ?
                <PostgreSQLRouteMain
                  entry={this.props.entry}
                  theme={this.props.theme}
                  tabKey={this.props.tabKey}
                />
                : null}
              {this.state.connectionType === '15' ?
                <OracleDbRouteMain
                  entry={this.props.entry}
                  theme={this.props.theme}
                  tabKey={this.props.tabKey}
                />
                : null}
              {this.state.connectionType === '16' ?
                <SFTPRouteMain
                  entry={this.props.entry}
                  theme={this.props.theme}
                  tabKey={this.props.tabKey}
                />
                : null}
              {this.state.connectionType === '17' ?
                <IMAPRouteMain
                  entry={this.props.entry}
                  theme={this.props.theme}
                  tabKey={this.props.tabKey}
                />
                : null}
              {this.state.connectionType === '18' ?
                <SnowflakeRouteMain
                  entry={this.props.entry}
                  theme={this.props.theme}
                  tabKey={this.props.tabKey}
                />
                : null}
            </Card>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default ConnectorsRouteForm
