import React from 'react'
import { Col, Row, Switch, Input, Select, Form } from 'antd'
import MemoryStore from '../../../utils/memory-store'
import GroupNameContainer from '../../../reusables/containers/group-name-container'
import SolutionsContainer from '../../../reusables/containers/solutions-container'
import TooltipIcon from '../../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../../utils/enums-tooltips'
import Description from '../../../reusables/components/description'

class DataMappingFormGeneral extends React.Component {
  constructor (props) {
    super(props)
    this.entry = MemoryStore.activeEntries[this.props.tabKey]
  }

  render () {
    return (
      <Row type='flex' justify='space-between'>
        <Col xs={24} lg={11}>
          <Form.Item>
            <Switch
              disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
              checkedChildren='Active'
              unCheckedChildren='Inactive'
              defaultChecked={this.entry.data.isActive}
              onChange={(e) => {
                this.props.onChange('isActive', e)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>{'Profile Key '}
            <TooltipIcon title={EnumsTooltips.general.profileKey} />
            <Input
              name='key'
              disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
              placeholder='Provide a unique Profile Key'
              defaultValue={this.entry.data.key}
              required
              onChange={(e) => {
                this.props.onChange('key', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>{'Profile Name '}
            <TooltipIcon title={EnumsTooltips.general.profileName} />
            <Input
              name='name'
              disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
              required
              placeholder='Provide a Profile Name'
              defaultValue={this.entry.data.name}
              onChange={(e) => {
                this.props.onChange('name', e.target.value)
              }}
            />
          </Form.Item>
          <Description
            disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
            description={this.entry.data.description}
            onChange={(e) => {
              this.props.onChange('description', e.target.value)
            }}
          />
          <GroupNameContainer
            disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
            defaultValue={this.entry.data.groupName}
            onValueChange={(value) => {
              this.props.onChange('groupName', value)
            }}
            onValueSelect={(value) => {
              this.props.onChange('groupName', value)
            }}
          />
          <SolutionsContainer
            disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
            defaultValue={this.entry.data.solutions}
            onValueChange={(value) => {
              this.props.onChange('solutions', value)
            }}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item style={{ marginTop: 65.5 }}>
            <span style={{ color: 'red' }}>* </span>Source Type
            <Select
              cypressid='sourceType'
              className='source_type'
              disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
              defaultValue={this.entry.data.sourceType}
              onChange={(e) => {
                this.props.onChange('sourceType', e)
              }}
            >
              <Select.Option value=''>-Select-</Select.Option>
              <Select.Option id='source-json' value='1'>JSON</Select.Option>
              <Select.Option id='source-excel' value='2'>Excel File</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>Destination Type
            <Select
              cypressid='destinationType'
              className='destination_type'
              disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
              defaultValue={this.entry.data.destinationType}
              onChange={(e) => {
                this.props.onChange('destinationType', e)
              }}
            >
              <Select.Option value=''>-Select-</Select.Option>
              <Select.Option id='des-json' value='1'>JSON</Select.Option>
              <Select.Option id='des-excel' value='2'>Excel File</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    )
  }
}

export default DataMappingFormGeneral
