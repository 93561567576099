import React from 'react'
import MemoryStore from '../../../../utils/memory-store'

import { Row, Col, Radio, Input, Form } from 'antd'
import CompileTemplateIcon from '../../../../reusables/components/compile-template-icon'

class SourceDetailsExcel extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      dropzoneActive: this.entry.data.srcExcel.attachments.length === 0,
      srcType: this.entry.data.srcExcel.srcType
    }

    this.onAttachmentUploaded = this.onAttachmentUploaded.bind(this)
    this.onAttachmentDelete = this.onAttachmentDelete.bind(this)
  }

  onChange (key, value) {
    this.entry.custom.isModified = true
    this.entry.data.srcExcel[key] = value
  }

  onAttachmentUploaded (file, res) {
    this.entry.custom.isModified = true

    if (res.success) {
      setTimeout(() => {
        const entry = {
          _id: res.data._id,
          fileName: res.data.filename
        }

        MemoryStore.activeEntries[this.props.tabKey].data.srcExcel.attachments.push(entry)

        this.setState({
          dropzoneActive: false
        })
      }, 500)
    }
  }

  onAttachmentDelete (id) {
    this.entry.custom.isModified = true

    const tmpAttachments = MemoryStore.activeEntries[this.props.tabKey].data.srcExcel.attachments
    const index = tmpAttachments.findIndex(t => t._id === id)

    if (index > -1) {
      // Add entry to delete array and remove entry from attachments array. Update State
      const entry = tmpAttachments[index]
      MemoryStore.activeEntries[this.props.tabKey].data.srcExcel.deleteAttachments.push(entry)
      tmpAttachments.splice(index, 1)

      this.setState({ dropzoneActive: true })
    }
  }

  render () {
    return (
      <Row>
        <Col xs={24} md={12}>
          <h4>Excel Details</h4>
          <Form.Item>
            <Radio.Group
              value={this.state.srcType}
              onChange={e => {
                this.onChange('srcType', e.target.value)
              }}
            >
              <Radio
                disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                value='1'
              >
                Get Excel programmatically
              </Radio>
            </Radio.Group>
          </Form.Item>
          {this.state.srcType === '1' ? (
            <Form.Item>
              <span style={{ color: 'red' }}>* </span>
              {'File Id '}
              <CompileTemplateIcon />
              <Input
                cypressid='fileId'
                name='file_id'
                disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                placeholder='Provide the Agilit-e File Id'
                defaultValue={this.entry.data.srcExcel.fileId}
                onChange={e => {
                  this.onChange('fileId', e.target.value)
                }}
              />
            </Form.Item>
          ) : null}
        </Col>
      </Row>
    )
  }
}

export default SourceDetailsExcel
