import { connect } from 'react-redux'
import { filterMappingViewData } from '../data-mapping-utils'
import FieldMappings from '../components/field-mappings/field-mappings'

const mapStateToProps = (state) => {
  return {
    theme: state.core.theme,
    state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    filterViewData: (viewData, appId, callback) => {
      filterMappingViewData(viewData, appId, callback)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FieldMappings)
