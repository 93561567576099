import React from 'react'
import { Icon } from 'react-fa'
import { Row, Col, Card, Button, Switch, Input, Form, Menu, message, Modal, Dropdown } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import MemoryStore from '../../utils/memory-store'
import GroupNameContainer from '../../reusables/containers/group-name-container'
import SolutionsContainer from '../../reusables/containers/solutions-container'
import SingleValueTableEditableDND from '../../reusables/components/single-value-table-editable-dnd'
import CompileTemplateIcon from '../../reusables/components/compile-template-icon'
import Description from '../../reusables/components/description'
import Enums from '../../utils/enums'

class RolesForm extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]
    this.tmpResponsiblePerson = null
    this.tmpLevels = []

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc,
      errorText: '',
      btnDisabled: false,
      dialogReAssignOpen: false,
      dialogConLevelOpen: false,
      isLoading: false
    }

    if (this.entry.data.levels.length === 0) {
      this.entry.data.levels.push('')
    }

    this.handleDialogReAssignOpen = this.handleDialogReAssignOpen.bind(this)
    this.handleDialogReAssignClose = this.handleDialogReAssignClose.bind(this)
    this.handleDialogResUserChange = this.handleDialogResUserChange.bind(this)
    this.handleUpdateResponsibleUser = this.handleUpdateResponsibleUser.bind(this)
    this.handleDialogConLevelOpen = this.handleDialogConLevelOpen.bind(this)
    this.handleDialogConLevelCancel = this.handleDialogConLevelCancel.bind(this)
    this.updateConLevels = this.updateConLevels.bind(this)
    this.handleValidateConlevel = this.handleValidateConlevel.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.cancelProfileConfirm = this.cancelProfileConfirm.bind(this)
    this.cancelProfile = this.cancelProfile.bind(this)
    this.saveProfile = this.saveProfile.bind(this)
    this.getButtonProps = this.getButtonProps.bind(this)
    this.updateData = this.updateData.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
  }

  handleDialogReAssignOpen () {
    this.tmpResponsiblePerson = this.entry.data.tmpResponsibleUser
    this.setState({ dialogReAssignOpen: true })
    this.handleDialogResUserChange(null)
  }

  handleDialogReAssignClose () {
    this.setState({ dialogReAssignOpen: false, errorText: '' })
  }

  handleDialogResUserChange (value) {
    if (value !== null) {
      this.tmpResponsiblePerson = value
    }

    if (this.tmpResponsiblePerson.trim() === '') {
      this.setState({
        errorText: 'Responsible User cannot be empty',
        btnDisabled: true
      })
    } else if (this.tmpResponsiblePerson === this.entry.data.tmpResponsibleUser) {
      this.setState({
        errorText: 'Responsible User cannot be the same as existing Responsible User',
        btnDisabled: true
      })
    } else {
      this.setState({ errorText: '', btnDisabled: false })
    }
  }

  handleUpdateResponsibleUser () {
    const tmpThis = this
    let msg = 'Role successfully Re-Assigned to ' + tmpThis.tmpResponsiblePerson
    let success = true

    tmpThis.handleDialogReAssignClose()

    tmpThis.props.onReAssignResponsibleUser(tmpThis.entry._id, tmpThis.tmpResponsiblePerson, (err, result) => {
      if (err) {
        success = false
        if (err.data) {
          msg = err.data.errorMessage
        } else {
          msg = err
        }
      }

      if (success) {
        // Update the Responsible Person in the UI and in the existing entry
        tmpThis.entry.data.tmpResponsibleUser = tmpThis.tmpResponsiblePerson
        tmpThis.saveProfile()
      } else {
        // Revert the Responsible User
        tmpThis.tmpResponsiblePerson = tmpThis.entry.data.tmpResponsibleUser
        message.error(msg)
      }
    })
  }

  handleDialogConLevelOpen () {
    this.tmpLevels = this.entry.data.levels.concat()

    this.setState({
      dialogConLevelOpen: true,
      btnDisabled: false
    })
  }

  handleDialogConLevelCancel () {
    this.entry.data.levels = this.tmpLevels.concat()
    this.tmpLevels = []

    this.setState({
      dialogConLevelOpen: false,
      errorText: ''
    })
  }

  updateConLevels () {
    const tmpThis = this
    let success = true
    let msg = ''

    tmpThis.props.onChangeConLevels(tmpThis.entry._id, this.entry.data.levels, function (err, result) {
      if (err) {
        success = false
        if (err.data) {
          msg = err.data.errorMessage
        } else {
          msg = err
        }
      }

      if (success) {
        tmpThis.saveProfile()
      } else {
        // Revert the Conditional Levels
        tmpThis.entry.data.levels = tmpThis.tmpLevels.concat()
        message.error(msg)
      }

      tmpThis.tmpLevels = []
    })
  }

  handleValidateConlevel () {
    const tmpArray1 = []
    const tmpArray2 = []

    for (const level of this.entry.data.levels) {
      tmpArray1.push(level.value)
    }

    for (const level of this.tmpLevels) {
      tmpArray2.push(level.value)
    }

    if (tmpArray1.join('-') === tmpArray2.join('-')) {
      this.setState({
        errorText: 'New Conditional Levels can\'t be the same as existing Conditional Levels'
      })
    } else {
      this.setState({
        errorText: '',
        dialogConLevelOpen: false
      })

      this.updateConLevels()
    }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.entry.data[key] = value
  }

  cancelProfileConfirm () {
    if (this.entry.custom.isModified) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  saveProfile () {
    const tmpThis = this

    tmpThis.setState({
      isLoading: true
    })

    // Reset isModified and isModifiedSubform properties
    this.entry.custom.isModified = false
    this.entry.custom.isModifiedSubform = false

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, (err) => {
      tmpThis.setState({
        isLoading: false
      })

      if (err) {
        message.error(err)
      } else {
        tmpThis.props.closeTab(tmpThis.props.tabKey, tmpThis.props.tabKey, tmpThis.props.tabs)
      }
    })
  }

  getButtonProps () {
    if (this.state.btnDisabled) {
      return {
        disabled: this.state.btnDisabled
      }
    } else {
      return {
        disabled: this.state.btnDisabled,
        style: {
          backgroundColor: '#67AD5B',
          borderColor: '#67AD5B'
        }
      }
    }
  }

  updateData (data) {
    this.entry.data.levels = data
  }

  handleOnChange (key, value, index) {
    this.entry.data[key][index] = { value }
  }

  render () {
    const menu = (
      <Menu>
        <Menu.Item key='0' onClick={this.handleDialogReAssignOpen}>
          <span style={{ padding: '10 0' }}>Re-Assign Responsible User</span>
        </Menu.Item>
        <Menu.Item key='1' onClick={this.handleDialogConLevelOpen}>
          <span style={{ padding: '10 0' }}>Change Conditional Levels</span>
        </Menu.Item>
      </Menu>
    )

    return (
      <Row type='flex' justify='center'>
        <Col xs={23} lg={20} xl={16}>
          <Card
            title={this.props.title}
            style={{ textAlign: 'left' }}
            extra={!this.state.isNewDoc && MemoryStore.userProfile.teamPrivileges.roles !== 'Reader' ?
              <Dropdown overlay={menu} trigger={['click']}>
                <Icon name='ellipsis-v' style={{ padding: 5, fontSize: 17, color: '#B4483E', cursor: 'pointer' }} />
              </Dropdown>
              : null}
            type='inner'
          >
            <Form
              autoComplete='off'
            >
              <Row type='flex' justify='space-around'>
                <Col xs={24} lg={11}>
                  <Form.Item>
                    <Switch
                      disabled={MemoryStore.userProfile.teamPrivileges.roles === 'Reader'}
                      checkedChildren='Active'
                      unCheckedChildren='Inactive'
                      defaultChecked={this.entry.data.isActive}
                      onChange={(e) => {
                        this.onFieldChange('isActive', e)
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    required
                  >
                    <span style={{ color: 'red' }}>* </span>Role Name
                    <Input
                      name='name'
                      placeholder='Provide a Role Name'
                      disabled={MemoryStore.userProfile.teamPrivileges.roles === 'Reader' || !this.state.isNewDoc}
                      defaultValue={this.entry.data.name}
                      required
                      onChange={(e) => {
                        this.onFieldChange('name', e.target.value)
                      }}
                    />
                  </Form.Item>
                  <Description
                    disabled={MemoryStore.userProfile.teamPrivileges.roles === 'Reader'}
                    description={this.entry.data.description}
                    onChange={(e) => {
                      this.onFieldChange('description', e.target.value)
                    }}
                  />
                  <GroupNameContainer
                    disabled={MemoryStore.userProfile.teamPrivileges.roles === 'Reader'}
                    defaultValue={this.entry.data.groupName}
                    onValueSelect={(value) => {
                      this.onFieldChange('groupName', value)
                    }}
                    onValueChange={(value) => {
                      this.onFieldChange('groupName', value)
                    }}
                  />
                  <SolutionsContainer
                    disabled={MemoryStore.userProfile.teamPrivileges.roles === 'Reader'}
                    defaultValue={this.entry.data.solutions}
                    onValueChange={(value) => {
                      this.onFieldChange('solutions', value)
                    }}
                  />
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    style={{ marginTop: 67.5 }}
                    required
                  >
                    <span style={{ color: 'red' }}>* </span>{'Responsible User '}
                    <CompileTemplateIcon />
                    <Input
                      name='responsible_user'
                      placeholder='This can be a Name, Email, ID, etc.'
                      disabled={MemoryStore.userProfile.teamPrivileges.roles === 'Reader' || !this.state.isNewDoc}
                      defaultValue={this.entry.data.tmpResponsibleUser}
                      required
                      onChange={(e) => {
                        this.onFieldChange('tmpResponsibleUser', e.target.value)
                      }}
                    />
                  </Form.Item>
                  <div>
                    <SingleValueTableEditableDND
                      data={this.entry.data.levels}
                      disabled={!this.state.isNewDoc || MemoryStore.userProfile.teamPrivileges.roles === 'Reader'}
                      title={<span>Conditional Levels (optional) <CompileTemplateIcon /></span>}
                      theme={this.props.theme}
                      callback={this.updateData}
                      arrayKey='levels'
                      onChange={this.handleOnChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                {MemoryStore.userProfile.teamPrivileges.roles !== 'Reader' ?
                  <Button
                    name='submit'
                    style={{ backgroundColor: '#67AD5B', color: 'white', width: 80, marginRight: 10, float: 'left' }}
                    onClick={() => this.saveProfile()}
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? <LoadingOutlined spin /> : <span>Submit</span>}
                  </Button>
                  : null}
                <Button
                  name='cancel'
                  disabled={this.state.isLoading}
                  label='Cancel'
                  onClick={e => {
                    e.preventDefault()
                    this.cancelProfileConfirm()
                  }}
                  style={{ backgroundColor: '#EB5181', color: 'white', width: 'auto', marginRight: 10, float: 'left' }}
                >
                  Cancel
                </Button>
              </Row>
            </Form>
            <Modal
              title='Re-Assign Responsible User'
              onCancel={this.handleDialogReAssignClose}
              onOk={this.handleUpdateResponsibleUser}
              okText='Ok'
              okButtonProps={this.getButtonProps()}
              visible={this.state.dialogReAssignOpen}
              destroyOnClose
              maskClosable={false}
            >
              <Input
                name='new_responsible_user'
                placeholder='This can be a Name, Email, ID etc.'
                defaultValue={this.tmpResponsiblePerson}
                onChange={(e) => {
                  this.handleDialogResUserChange(e.target.value)
                }}
              />
              <span style={{ margin: 5, padding: 5, color: 'red' }}>{this.state.errorText}</span>
            </Modal>
            <Modal
              title='Edit Conditional Levels'
              okButtonProps={{ style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' } }}
              onOk={this.handleValidateConlevel}
              onCancel={this.handleDialogConLevelCancel}
              okText='Ok'
              visible={this.state.dialogConLevelOpen}
              destroyOnClose
              maskClosable={false}
            >
              <SingleValueTableEditableDND
                data={this.entry.data.levels}
                title='Conditional Levels (optional) {{}}'
                theme={this.props.theme}
                callback={this.updateData}
                arrayKey='levels'
                onChange={this.handleOnChange}
              />
              <div style={{ color: 'red', marginTop: '10', fontSize: '13' }}>
                {this.state.errorText}
              </div>
            </Modal>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default RolesForm
