import { connect } from 'react-redux'
import Solutions from '../components/solutions'

const mapStateToProps = (state) => {
  if (!state[state.core.tabObject.activeApp]) {
    return {}
  }

  const appId = state.core.tabObject.activeApp

  return {
    appId,
    state: state.solutions,
    theme: state.core.theme
  }
}

export default connect(
  mapStateToProps
)(Solutions)
