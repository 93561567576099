import React from 'react'
import { Row, Modal, Col, message } from 'antd'
import MemoryStore from '../../../utils/memory-store'
import StepOptionForm from '../step-options/step-option-form'
import ListViewDND from '../../../reusables/components/list-view-dnd'
import { columnTemplates } from '../../../utils/templates'
import { cleanUpTableData } from '../../../utils/utilities'
import Enums from '../../../utils/enums'

class StepOptions extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry,
      dialogOpen: false,
      activeRecord: null,
      stepList: [],
      isNewDoc: this.props.isNewDoc
    }

    // Setup Event Binding
    this.createRecord = this.createRecord.bind(this)
    this.saveRecord = this.saveRecord.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.deleteRecord = this.deleteRecord.bind(this)
    this.handleValidateRecord = this.handleValidateRecord.bind(this)
    this.generateStepList = this.generateStepList.bind(this)
    this.formatViewData = this.formatViewData.bind(this)
    this.handleCloseModalDialog = this.handleCloseModalDialog.bind(this)
    this.handleCloseModalDialogConfirm = this.handleCloseModalDialogConfirm.bind(this)
    this.updateArrayData = this.updateArrayData.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.formatViewData()
    this.generateStepList()
  }

  UNSAFE_componentWillUpdate () {
    this.formatViewData()
  }

  createRecord () {
    const activeRecord = JSON.parse(
      JSON.stringify(MemoryStore.bpm.stepOptionTemplate)
    )
    activeRecord.isNewEntry = true
    activeRecord._id = new Date().getTime().toString()

    this.setState({
      dialogOpen: true,
      activeRecord,
      isNewDoc: true
    })
  }

  saveRecord (tmpActiveRecord) {
    const activeRecord = JSON.parse(JSON.stringify(tmpActiveRecord))
    const entry = this.props.entry
    const index = entry.findIndex(t => t._id === activeRecord._id)

    activeRecord.eventStamp = cleanUpTableData(activeRecord.eventStamp, false)

    if (activeRecord.isNewEntry) {
      entry.push(activeRecord)
    } else {
      entry.splice(index, 1, activeRecord)
    }

    this.setState({
      entry,
      dialogOpen: false,
      activeRecord: JSON.parse(
        JSON.stringify(MemoryStore.bpm.stepOptionTemplate)
      ),
      isNewDoc: this.props.isNewDoc
    })
  }

  editRecord (recordId) {
    const index = this.state.entry.findIndex(t => t._id === recordId)
    const activeRecord = JSON.parse(JSON.stringify(this.state.entry[index]))

    activeRecord.isNewEntry = false
    this.setState({ dialogOpen: true, activeRecord })
  }

  deleteRecord (recordId) {
    this.entry.custom.isModifiedSubform = true

    const entry = this.props.entry
    const index = entry.findIndex(t => t._id === recordId)

    entry.splice(index, 1)
    this.setState({ entry })
  }

  handleValidateRecord () {
    const tmpActiveRecord = Object.assign({}, this.state.activeRecord)
    const tmpArray = []
    let success = true
    let errorMessage = ''

    // Trim Step OptionKey & Name
    tmpActiveRecord.key = tmpActiveRecord.key.trim()
    tmpActiveRecord.name = tmpActiveRecord.name.trim()

    if (!tmpActiveRecord.key) {
      message.error('Please provide a Step Option Key')
      return
    }

    if (!tmpActiveRecord.name) {
      message.error('Please provide a Step Option Name')
      return
    }

    if (!tmpActiveRecord.nextStep) {
      message.error('Please select a Next Step')
      return
    }

    this.props.entry.map(entry => {
      if (entry.name === tmpActiveRecord.name && entry._id !== tmpActiveRecord._id) {
        success = false
        errorMessage = `Step Option '${tmpActiveRecord.name}' already exists`
      }
      return entry
    })

    if (success) {
      // Cleanup Empty Visible Objects

      tmpActiveRecord.visibleObjects.map((object, index) => {
        // Cleanup choices and messages
        object.inputOptions.choices = cleanUpTableData(object.inputOptions.choices, true)

        object.inputOptions.choices.map(choice => {
          return delete choice.key
        })

        object.inputOptions.messages = cleanUpTableData(object.inputOptions.messages, false)

        if (!object.id) {
          tmpActiveRecord.visibleObjects.splice(index, 1)
        }

        return true
      })

      this.setState({ activeRecord: tmpActiveRecord })
    }

    // Validate that no duplicate Visible Objects exist
    tmpActiveRecord.visibleObjects.map(object => {
      return tmpArray.push(object.id)
    })

    tmpArray.map((value, index) => {
      tmpArray.splice(index, 1)
      if (tmpArray.includes(value)) {
        success = false
        errorMessage = `Visible Object '${value}' already exists`
      }
      return success
    })

    this.setState({
      activeRecord: tmpActiveRecord
    })

    if (success) {
      this.saveRecord(tmpActiveRecord)
    } else {
      message.error(errorMessage)
    }
  }

  generateStepList () {
    const stepList = []
    let tempEntry = null

    for (const x in this.props.processSteps) {
      if (this.props.processSteps[x].key !== this.props.data.key) {
        tempEntry = {
          label:
            this.props.processSteps[x].name +
            ' (' +
            this.props.processSteps[x].key +
            ')',
          value: this.props.processSteps[x].key
        }

        stepList.push(tempEntry)
        tempEntry = null
      }
    }

    return this.setState({ stepList })
  }

  formatViewData () {
    // Set isActiveDescription
    this.state.entry.map(stepOption => {
      return (stepOption.isActiveDescription = stepOption.isActive ? 'Yes' : 'No')
    })
  }

  handleCloseModalDialog () {
    this.entry.custom.isModifiedSubform = false

    this.setState({
      dialogOpen: false,
      activeRecord: JSON.parse(
        JSON.stringify(MemoryStore.bpm.stepOptionTemplate)
      )
    })
  }

  handleCloseModalDialogConfirm () {
    if (this.entry.custom.isModifiedSubform) {
      Modal.confirm({
        onOk: () => this.handleCloseModalDialog(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.handleCloseModalDialog()
    }
  }

  updateArrayData (data) {
    this.entry.custom.isModifiedSubform = true
    this.props.data.stepOptions = data

    this.setState({
      entry: this.props.data.stepOptions
    })
  }

  render () {
    return (
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <ListViewDND
            btnCreateLabel='Create New'
            viewTitle='Step Options'
            createRecord={this.createRecord}
            editRecord={this.editRecord}
            deleteRecord={this.deleteRecord}
            filterViewData={this.props.filterViewData}
            viewData={this.state.entry}
            viewColumns={columnTemplates.stepOptions}
            theme={this.props.theme}
            callback={this.updateArrayData}
          />
          <Modal
            title='Step Option Profile'
            okButtonProps={{
              style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' }
            }}
            okText='Ok'
            visible={this.state.dialogOpen}
            onOk={this.handleValidateRecord}
            onCancel={this.handleCloseModalDialogConfirm}
            width={1000}
            style={{ top: 10 }}
            destroyOnClose
            maskClosable={false}
          >
            <StepOptionForm
              entry={this.state.activeRecord}
              theme={this.props.theme}
              data={this.props.data}
              stepList={this.state.stepList}
              tabKey={this.props.tabKey}
              isNewDoc={this.state.isNewDoc}
            />
          </Modal>
        </Col>
      </Row>
    )
  }
}

export default StepOptions
