import React from 'react'
import { importData } from '../../core-utils'
import MemoryStore from '../../../utils/memory-store'

import { Row, Col, Form, Spin, message } from 'antd'
import ImportExportContainer from '../../../reusables/containers/import-export-container'

class AdminImport extends React.Component {
  constructor () {
    super()

    this.state = {
      showProgress: false,
      attachments: []
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.handleValidateImport = this.handleValidateImport.bind(this)
  }

  onSubmit (state, callback) {
    const tmpThis = this
    const trueValue = true
    const falseValue = false

    MemoryStore.activeEntries.import.custom.isModified = true

    if (state.attachments.length > 0) {
      tmpThis.setState({ showProgress: true })

      importData(state, (err, result) => {
        if (err) {
          MemoryStore.activeEntries.import.custom.isModified = false

          message.error('Import Failed. Please check the format of the JSON file being imported')
          callback(falseValue)
        } else {
          MemoryStore.activeEntries.import.custom.isModified = false

          message.success('Import Successful')
          callback(trueValue)
        }

        tmpThis.setState({ showProgress: false })
      })
    } else {
      callback(falseValue)
      message.error('Please drop or select a file for Importing')
    }

    return null
  }

  handleValidateImport (state, callback) {
    let itemsChecked = 0
    const obj = state.checked
    const falseValue = false

    Object.keys(obj).forEach(e => {
      if (obj[e] === true) itemsChecked++
    })

    if (itemsChecked <= 0) {
      callback(falseValue)
      message.error('Please select at least 1 module to import')
    } else {
      this.onSubmit(state, callback)
    }
  }

  render () {
    return (
      <Row type='flex' justify='center'>
        <Col xs={23} lg={12}>
          <Form
            autoComplete='off'
            style={{ marginTop: 20 }}
          >
            <div style={{ marginTop: 10 }}>
              {this.state.showProgress ?
                <Spin />
                : null}
            </div>
            <ImportExportContainer
              onValidate={this.handleValidateImport}
              cardTitle='Administration - Import'
              leftColumnTitle='Import Options'
              rightColumnTitle='Modules To Import'
              buttonLabel='Import'
              type='import'
              attachments={this.state.attachments}
            />
          </Form>
        </Col>
      </Row>
    )
  }
}

export default AdminImport
