import React from 'react'
import { Row, Col, Select, Form } from 'antd'

import MemoryStore from '../../../../../utils/memory-store'
import IMAPRouteSearch from './routes/imap-route-search'
import IMAPRouteAddDeleteBox from './routes/imap-route-add-delete-box'
import IMAPRouteAddDeleteFlags from './routes/imap-route-add-delete-flags'
import IMAPRouteAppend from './routes/imap-route-append'
import IMAPRouteMoveMessage from './routes/imap-route-move-message'
import IMAPRouteDeleteMessage from './routes/imap-route-delete-message'
import IMAPRouteGetPartData from './routes/imap-route-get-part-data'
import IMAPRouteGetParts from './routes/imap-route-get-parts'

class IMAPRouteMain extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.disabled = MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'
    this.state = { routeType: props.entry.routeData.routeType || '' }

    // Bindings
    this.onChangeRouteType = this.onChangeRouteType.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
  }

  UNSAFE_componentWillMount () {
    if (!this.props.entry.routeData.routeType) {
      this.props.entry.routeData.routeType = ''
    }
  }

  onChangeRouteType (e) {
    this.entry.custom.isModifiedSubform = true
    this.setState({ routeType: e })
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value
  }

  render () {
    return (
      <Row>
        <Col span={24}>
          <h3>Route Info</h3>
          <Form.Item>
            Route Type
            <Select
              className='route_type'
              disabled={this.disabled}
              defaultValue={this.state.routeType}
              onChange={e => {
                this.onChangeRouteType(e)
                this.onFieldChange('routeType', e)
              }}
            >
              <Select.Option value=''>-Select-</Select.Option>
              <Select.Option value='0'>Get Boxes</Select.Option>
              <Select.Option value='1'>Search</Select.Option>
              <Select.Option value='2'>Add Box</Select.Option>
              <Select.Option value='3'>Delete Box</Select.Option>
              <Select.Option value='4'>Add Flags</Select.Option>
              <Select.Option value='5'>Delete Flags</Select.Option>
              <Select.Option value='6'>Append</Select.Option>
              <Select.Option value='10'>Move Message</Select.Option>
              <Select.Option value='9'>Delete Message</Select.Option>
              <Select.Option value='7'>Get Parts</Select.Option>
              <Select.Option value='8'>Get Part Data</Select.Option>
            </Select>
          </Form.Item>
          {this.state.routeType === '1' ? (
            <IMAPRouteSearch
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
            />
          ) : null}
          {this.state.routeType === '2' || this.state.routeType === '3' ? (
            <IMAPRouteAddDeleteBox
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
            />
          ) : null}
          {this.state.routeType === '4' || this.state.routeType === '5' ? (
            <IMAPRouteAddDeleteFlags
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
            />
          ) : null}
          {this.state.routeType === '6' ? (
            <IMAPRouteAppend
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
            />
          ) : null}
          {this.state.routeType === '10' ? (
            <IMAPRouteMoveMessage
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
            />
          ) : null}
          {this.state.routeType === '9' ? (
            <IMAPRouteDeleteMessage
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
            />
          ) : null}
          {this.state.routeType === '7' ? (
            <IMAPRouteGetParts
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
            />
          ) : null}
          {this.state.routeType === '8' ? (
            <IMAPRouteGetPartData
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
            />
          ) : null}
        </Col>
      </Row>
    )
  }
}

export default IMAPRouteMain
