import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { forgotPassword, verifyRecaptchaToken, verifyRecaptchaTokenv2 } from '../core-actions'
import CoreForgotPassword from '../components/core-forgot-password'

const mapStateToProps = state => {
  return {
    theme: state.core.theme
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      forgotPassword,
      verifyRecaptchaToken,
      verifyRecaptchaTokenv2
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoreForgotPassword)
