import MemoryStore from '../utils/memory-store'
import { cleanupObject, isDuplicateEntry } from '../utils/utilities'
import { postData } from '../core/core-utils'
import { formatRecord, restartFlows } from './node-red-utils'
import { fetchAllData } from '../core/core-actions'
import Enums from '../utils/enums'

/*
  ENUMS
*/
export const actions = {
  RESET_STATE: 'RESET_STATE_NODERED',
  CREATE_PROFILE: 'CREATE_PROFILE_NODERED',
  SAVE_PROFILE: 'SAVE_PROFILE_EVENTS',
  CANCEL_PROFILE: 'CANCEL_PROFILE_NODERED',
  EDIT_PROFILE: 'EDIT_PROFILE_NODERED',
  DELETE_PROFILE: 'DELETE_PROFILE_NODERED',
  FETCH_ALL_DATA: 'FETCH_ALL_DATA_NODERED',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER_NODERED',
  UPDATE_DATA: 'UPDATE_DATA_NODERED'
}

/*
  DISPATCH FUNCTIONS
*/
export const setSearchFilter = value => {
  return {
    type: actions.SET_SEARCH_FILTER,
    value
  }
}

export const updateData = data => {
  return {
    type: actions.UPDATE_DATA,
    data
  }
}

export const saveProfile = (appId, recordId, state, activate, callback) => {
  return dispatch => {
    const entry = MemoryStore.activeEntries[recordId]
    const headers = {}
    let reqType = ''
    let tempArray = state.data.concat()
    let result = true
    let errMsg = null
    let restartFlow = false

    // Cleanup and Trim
    // We are not running trimObjectValues here because the profile contains node-red's data
    cleanupObject(entry.data)

    // Validation
    if (entry.data.key && entry.data.key.trim() === '') {
      errMsg = 'Please provide a Profile Key'
      return callback(errMsg)
    }

    if (entry.data.name && entry.data.name.trim() === '') {
      errMsg = 'Please provide a Profile Name'
      return callback(errMsg)
    }

    // Duplicate Check
    result = isDuplicateEntry(entry, 'key', tempArray, true)

    if (!result) {
      errMsg = `Profile Key '${entry.data.key}' is already being used`
      return callback(errMsg)
    }

    // Post Data to Agilit-e
    tempArray = formatRecord(entry, tempArray)
    reqType = entry.custom.action === 'create' ? 'post' : 'put'

    // Create Headers Object
    headers['record-id'] = recordId

    // If activate is true pass header
    if (activate) {
      headers.activate = true
      restartFlow = true
    }

    if (entry.data.isActive) {
      restartFlow = true
    }

    // Dispatch New Array
    dispatch({ type: actions.UPDATE_DATA, data: tempArray })

    postData(appId, reqType, entry, headers)
      .then((response) => {
        callback(null)

        if (restartFlow) {
          restartFlows()
        }

        response.data.custom.action = ''
        response.data.custom.isSavedDoc = true

        // Setup Fields for Table
        if (!response.data.data.key) response.data.data.key = response.data.data._id

        if (response.data.data && response.data.data.isActive !== undefined) {
          response.data.data.isActiveDescription = response.data.data.isActive ? 'Active' : 'Disabled'
        }

        dispatch(fetchAllData(appId, () => {}))
      })
      .catch((error) => {
        console.log(error)

        if (error.response) {
          callback(error.response.data.errorMessage)
        } else {
          callback(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }
}
