import React from 'react'
import { Layout, Drawer, Menu, Dropdown, Modal, Input, message } from 'antd'
import { MenuOutlined, UserOutlined, CaretDownOutlined, BranchesOutlined, HomeOutlined, ArrowRightOutlined, ApartmentOutlined, OrderedListOutlined, ApiOutlined, SwapOutlined, LayoutOutlined, BookOutlined, CommentOutlined, DeploymentUnitOutlined, AppstoreAddOutlined, CalendarOutlined, FileTextOutlined, SettingOutlined, AreaChartOutlined, SolutionOutlined, UserAddOutlined, KeyOutlined, UploadOutlined, DownloadOutlined, YoutubeOutlined, FacebookOutlined, TwitterOutlined, GlobalOutlined, ReadOutlined, GithubOutlined, ForkOutlined, BugOutlined } from '@ant-design/icons'
import Agilite from 'agilite'
import Enums from '../../utils/enums'
import config from '../../utils/config'
import MemoryStore from '../../utils/memory-store'
import Globals from '../../utils/globals'
import { BrowserView, MobileView } from 'react-device-detect'

/*
  Custom Components Import
*/
// import ToolbarMenuItemContainer from '../containers/toolbar-menu-item-container'
import CoreUserMenuContainer from '../containers/core-user-menu-container'

import agiliteTheme from '../../utils/agilite-theme'
import { handleSwitchMenu } from '../core-utils'

const { Header } = Layout
const SubMenu = Menu.SubMenu

class CoreToolbar extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      menuLeftVisible: false,
      menuRightvisible: false,
      teams: MemoryStore.userProfile.teams || [],
      teamId: MemoryStore.userProfile.teamId,
      hasTeamId: false,
      membershipType: '',
      createTeamDialog: {
        teamId: '',
        open: false
      }
    }

    this.handleMenuItemClick = this.handleMenuItemClick.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleCreateTeam = this.handleCreateTeam.bind(this)
    this.handleShowLeftDrawer = this.handleShowLeftDrawer.bind(this)
    this.handleShowRightDrawer = this.handleShowRightDrawer.bind(this)
    this.handleOnClose = this.handleOnClose.bind(this)
    this.handleModalCreateTeamOpen = this.handleModalCreateTeamOpen.bind(this)
    this.handleModalCreateTeamClose = this.handleModalCreateTeamClose.bind(this)
    this.teamActionPrompt = this.teamActionPrompt.bind(this)

    this.nodeRedUrl = Globals.config.nodeRedUrl
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!this.state.hasTeamId && MemoryStore.userProfile.teamId) {
      this.setState({
        membershipType: MemoryStore.userProfile.membershipType,
        teamId: MemoryStore.userProfile.teamId,
        teams: MemoryStore.userProfile.teams,
        hasTeamId: true
      })
    } else if (this.state.hasTeamId && !MemoryStore.userProfile.teamId) {
      this.setState({
        membershipType: null,
        teamId: null,
        teams: [],
        hasTeamId: false
      })
    }
  }

  onChange (key, value) {
    switch (key) {
      case 'inputTeamId':
        this.setState({
          createTeamDialog: {
            ...this.state.createTeamDialog,
            teamId: value
          }
        })
        break
      default:
    }
  }

  handleModalCreateTeamOpen () {
    this.setState({
      createTeamDialog: {
        teamId: '',
        open: true
      }
    })
  }

  handleModalCreateTeamClose () {
    this.setState({
      createTeamDialog: {
        teamId: '',
        open: false
      }
    })
  }

  handleTeamMenuSwitch (key) {
    switch (key) {
      case 'create_team':
        this.handleDialogCreateTeamOpen()
        break
      default:
        if (MemoryStore.userProfile.teamId === key) break

        this.props.switchTeam(key, (err, res) => {
          if (err) {
            message.error(err)
          } else {
            // Set API Key to the API Key that is relevant to the team
            MemoryStore.apiKey = res.data.apiKey

            // Create new instance of Agilit-e using new credentials
            MemoryStore.agilite = new Agilite({
              apiServerUrl: Globals.config.apiServerUrl,
              apiKey: MemoryStore.apiKey,
              teamId: key
            })

            // Set User Profile data
            MemoryStore.userProfile = {
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              email: res.data.email,
              teamId: key,
              teams: res.data.teamRights.teams,
              privileges: res.data.teamRights.privileges,
              role: res.data.teamRights.role,
              membershipType: res.data.teamRights.membershipType,
              canCreateTeam: false
            }

            // Set Team Privileges
            MemoryStore.userProfile.teamPrivileges = res.data.teamRights.privileges
            MemoryStore.userProfile.teamRights = {}
            MemoryStore.userProfile.teamRights[key] = {
              role: res.data.teamRights.role,
              type: res.data.teamRights.membershipType
            }

            if (MemoryStore.userProfile.role === 'Admin') {
              switch (MemoryStore.userProfile.membershipType) {
                case '1':
                  if (MemoryStore.userProfile.teams.length === 0) {
                    MemoryStore.userProfile.canCreateTeam = true
                  }

                  break
                default:
                  MemoryStore.userProfile.canCreateTeam = true
              }
            }

            this.setState({
              teamId: MemoryStore.userProfile.teamId,
              teams: MemoryStore.userProfile.teams,
              membershipType: MemoryStore.userProfile.membershipType
            })
            this.props.resetTabs()
          }
        })
    }
  }

  handleCreateTeam () {
    const tmpThis = this
    let msg = ''
    let success = true

    if (this.state.createTeamDialog.teamId === '') {
      return message.error('The Team ID is required')
    }

    tmpThis.state.createTeamDialog.teamId = tmpThis.state.createTeamDialog.teamId
      .replace(/[^\w]/gi, '')
      .toLowerCase()

    tmpThis.props.createTeam(tmpThis.state.createTeamDialog.teamId, (err, result) => {
      if (err) {
        success = false
        msg = err
      } else {
        msg =
          'Team: ' +
          tmpThis.state.createTeamDialog.teamId +
          ' successfully created'
      }

      if (success) {
        tmpThis.teamCreateSuccess(msg)

        // Set API Key to the API Key that is relevant to the team
        MemoryStore.apiKey = result.data.apiKey

        // Create new instance of Agilit-e using new credentials
        MemoryStore.agilite = new Agilite({
          apiServerUrl: Globals.config.apiServerUrl,
          apiKey: MemoryStore.apiKey,
          teamId: result.data.teamRights.defaultTeam
        })

        // Set User Profile data
        MemoryStore.userProfile = {
          firstName: result.data.firstName,
          lastName: result.data.lastName,
          email: result.data.email,
          teamId: result.data.teamRights.defaultTeam,
          teams: result.data.teamRights.teams,
          privileges: result.data.teamRights.privileges,
          role: result.data.teamRights.role,
          membershipType: result.data.teamRights.membershipType,
          canCreateTeam: false
        }

        // Set Team Privileges
        MemoryStore.userProfile.teamPrivileges = result.data.teamRights.privileges
        MemoryStore.userProfile.teamRights = {}
        MemoryStore.userProfile.teamRights[result.data.teamRights.defaultTeam] = {
          role: result.data.teamRights.role,
          type: result.data.teamRights.membershipType
        }

        if (MemoryStore.userProfile.role === 'Admin') {
          switch (MemoryStore.userProfile.membershipType) {
            case '1':
              if (MemoryStore.userProfile.teams.length === 0) {
                MemoryStore.userProfile.canCreateTeam = true
              }

              break
            default:
              MemoryStore.userProfile.canCreateTeam = true
          }
        }

        this.setState({
          teamId: MemoryStore.userProfile.teamId,
          teams: MemoryStore.userProfile.teams,
          membershipType: MemoryStore.userProfile.membershipType,
          createTeamDialog: {
            teamId: '',
            open: false
          }
        })
        this.props.resetTabs()
      } else {
        tmpThis.teamCreateError(msg)
        return tmpThis.setState({
          createTeamDialog: {
            teamId: tmpThis.state.createTeamDialog.teamId,
            open: true
          }
        })
      }
    })
  }

  handleShowLeftDrawer () {
    this.setState({
      menuLeftVisible: true
    })
  }

  handleShowRightDrawer () {
    this.setState({
      menuRightvisible: true
    })
  }

  handleOnClose () {
    this.setState({
      menuLeftVisible: false,
      menuRightvisible: false
    })
  }

  teamCreateSuccess (msg) {
    // TODO: Need to test this
    message.success(msg)
  }

  teamCreateError (msg) {
    message.error(msg)
  }

  teamActionPrompt (action, key) {
    let tmpValue = false

    if (MemoryStore.userProfile.teamId === key && action === 'switch') {
      return
    }

    for (let x in MemoryStore.activeEntries) {
      if (MemoryStore.activeEntries[x].custom.isModified) {
        tmpValue = true
      }
    }

    if (tmpValue) {
      Modal.confirm({
        onOk: () => {
          this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
          action === 'switch' ? this.handleTeamMenuSwitch(key) : this.handleModalCreateTeamOpen()
        },
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT_TEAMS,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      action === 'switch' ? this.handleTeamMenuSwitch(key) : this.handleModalCreateTeamOpen()
    }
  }

  handleMenuItemClick (e) {
    handleSwitchMenu(e)
    this.setState({ menuLeftVisible: false })
  }

  render () {
    const teamMenu = (
      <Menu style={{ marginTop: '-20' }}>
        {this.state.teams.map(entry => (
          <Menu.Item
            key={entry}
            onClick={e => {
              this.teamActionPrompt('switch', e.key)
            }}
          >
            {entry.toUpperCase()}
          </Menu.Item>
        ))}
        {MemoryStore.userProfile.canCreateTeam ? <Menu.Divider /> : null}
        {MemoryStore.userProfile.canCreateTeam ? (
          <Menu.Item key='create_team' onClick={this.teamActionPrompt}>
            Create New Team
          </Menu.Item>
        ) : null}
      </Menu>
    )

    return (
      <div>
        <Layout>
          <Header
            style={{
              backgroundColor: this.props.theme.primary,
              color: 'white',
              fontSize: '15pt',
              height: 50
            }}
          >
            <div className='headerContents'>
              <span style={{ fontSize: '14pt' }}>{this.props.title}</span>
              {this.props.loggedIn ? (
                <MenuOutlined
                  className='menu-left'
                  onClick={this.handleShowLeftDrawer}
                />
              ) : null}
              <MenuOutlined
                className='menu-right'
                onClick={this.handleShowRightDrawer}
              />
              {this.props.loggedIn ? (
                <Dropdown
                  overlay={
                    <CoreUserMenuContainer
                      handleMenuClick={e => this.handleMenuClick(e)}
                    />
                  }
                  placement='bottomCenter'
                  className='user-menu'
                >
                  <UserOutlined />
                </Dropdown>
              ) : null}
              {this.props.loggedIn ? (
                <Dropdown
                  overlay={teamMenu}
                  trigger={['click']}
                  className='team_dropdown'
                >
                  <div
                    className='ant-dropdown-link'
                    style={{ cursor: 'pointer' }}
                  >
                    {`TEAM: ${this.state.teamId.toUpperCase()} `}{' '}
                    <CaretDownOutlined />
                  </div>
                </Dropdown>
              ) : null}
              {this.props.loggedIn && this.nodeRedUrl !== '' ? (
                <div className='node_red_button' onClick={() => this.handleMenuItemClick({ key: Enums.APP_IDS.NODE_RED_DASHBOARD })}>
                  <MobileView>
                    <BranchesOutlined className='node_red_button_icon' />
                  </MobileView>
                  <BrowserView>
                    <BranchesOutlined className='node_red_button_icon' />{' '}{Enums.APP_NAMES[Enums.APP_IDS.NODE_RED_DASHBOARD]}
                  </BrowserView>
                </div>
              ) : null}
            </div>
          </Header>
        </Layout>
        <Drawer
          title='Agilit-e Resources'
          placement='right'
          closable={false}
          width='300'
          onClose={this.handleOnClose}
          visible={this.state.menuRightvisible}
          className='menu-drawer'
        >
          <Menu onClick={this.handleClick} style={{ width: 250 }} mode='inline'>
            <Menu.Item
              key='1'
              onClick={e => {
                this.props.switchResourcesMenu(0)
              }}
            >
              <BookOutlined /> Agilit-e Full Documentation
            </Menu.Item>
            <Menu.Item
              key='2'
              onClick={e => {
                this.props.switchResourcesMenu(1)
              }}
            >
              <BookOutlined /> Agilit-e API Reference Docs
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key='3'
              onClick={e => {
                this.props.switchResourcesMenu(2)
              }}
            >
              <ForkOutlined /> Agilit-e NPM Modules
            </Menu.Item>
            <Menu.Item
              key='4'
              onClick={e => {
                this.props.switchResourcesMenu(3)
              }}
            >
              <GithubOutlined /> Agilit-e Github Projects
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key='5'
              onClick={e => {
                this.props.switchResourcesMenu(4)
              }}
            >
              <GlobalOutlined style={{ color: agiliteTheme.primary }} /> Agilit-e Website
            </Menu.Item>
            <Menu.Item
              key='6'
              onClick={e => {
                this.props.switchResourcesMenu(5)
              }}
            >
              <ReadOutlined style={{ color: agiliteTheme.primary }} /> Agilit-e Blog
            </Menu.Item>
            <Menu.Item
              key='7'
              onClick={e => {
                this.props.switchResourcesMenu(6)
              }}
            >
              <YoutubeOutlined style={{ color: '#ff0000' }} /> Agilit-e YouTube Channel
            </Menu.Item>
            <Menu.Item
              key='8'
              onClick={e => {
                this.props.switchResourcesMenu(7)
              }}
            >
              <FacebookOutlined style={{ color: '#3b5998' }} /> Agilit-e Facebook Page
            </Menu.Item>
            <Menu.Item
              key='9'
              onClick={e => {
                this.props.switchResourcesMenu(8)
              }}
            >
              <TwitterOutlined style={{ color: '#1da1f2' }} /> Agilit-e Twitter Account
            </Menu.Item>
          </Menu>
        </Drawer>
        {this.props.loggedIn ? (
          <Drawer
            title={Enums.VALUES_STRINGS.PORTAL_MENU}
            placement='left'
            closable={false}
            width='300'
            onClose={this.handleOnClose}
            visible={this.state.menuLeftVisible}
            className='menu-drawer'
          >
            <Menu
              onClick={this.handleMenuItemClick}
              style={{ width: 250 }}
              defaultSelectedKeys={[Enums.APP_IDS.HOME]}
              defaultOpenKeys={['subMenu1']}
              mode='inline'
            >
              <Menu.Item
                cypressid='home'
                key={Enums.APP_IDS.HOME}
                onClick={() => {
                  this.setState({ menuLeftVisible: false })
                }}
              >
                <HomeOutlined /> {Enums.APP_NAMES[Enums.APP_IDS.HOME]}
              </Menu.Item>
              <SubMenu
                key='subMenu1'
                title={
                  <span>
                    <MenuOutlined />{' '}
                    <span>{Enums.VALUES_STRINGS.MODULES}</span>
                  </span>
                }
              >
                {config.modules.keywords.enabled &&
                MemoryStore.userProfile.teamPrivileges[
                  Enums.APP_IDS.KEYWORDS
                ] !== '' ?
                  (
                    <Menu.Item cypressid='keywords' key={Enums.APP_IDS.KEYWORDS}>
                      <ArrowRightOutlined />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.KEYWORDS]}
                    </Menu.Item>
                  ) : null}
                {config.modules.tierstructures.enabled &&
                MemoryStore.userProfile.teamPrivileges[
                  Enums.APP_IDS.TIER_STRUCTURES
                ] !== '' ?
                  (
                    <Menu.Item cypressid='tierStructures' key={Enums.APP_IDS.TIER_STRUCTURES}>
                      <ApartmentOutlined />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.TIER_STRUCTURES]}
                    </Menu.Item>
                  ) : null}
                {config.modules.numbering.enabled &&
                MemoryStore.userProfile.teamPrivileges[
                  Enums.APP_IDS.NUMBERING
                ] !== '' ?
                  (
                    <Menu.Item cypressid='numbering' key={Enums.APP_IDS.NUMBERING}>
                      <OrderedListOutlined />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.NUMBERING]}
                    </Menu.Item>
                  ) : null}
                {config.modules.connectors.enabled &&
                MemoryStore.userProfile.teamPrivileges[
                  Enums.APP_IDS.CONNECTORS
                ] !== '' ?
                  (
                    <Menu.Item cypressid='connectors' key={Enums.APP_IDS.CONNECTORS}>
                      <ApiOutlined />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.CONNECTORS]}
                    </Menu.Item>
                  ) : null}
                {config.modules.datamappings.enabled &&
                MemoryStore.userProfile.teamPrivileges[
                  Enums.APP_IDS.DATA_MAPPING
                ] !== '' ?
                  (
                    <Menu.Item cypressid='dataMappings' key={Enums.APP_IDS.DATA_MAPPING}>
                      <SwapOutlined />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.DATA_MAPPING]}
                    </Menu.Item>
                  ) : null}
                {config.modules.templates.enabled &&
                MemoryStore.userProfile.teamPrivileges[
                  Enums.APP_IDS.TEMPLATES
                ] !== '' ?
                  (
                    <Menu.Item cypressid='templates' key={Enums.APP_IDS.TEMPLATES}>
                      <LayoutOutlined />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.TEMPLATES]}
                    </Menu.Item>
                  ) : null}
                {config.modules.bpm.enabled &&
                MemoryStore.userProfile.teamPrivileges[Enums.APP_IDS.BPM] !==
                  '' ?
                  (
                    <Menu.Item cypressid='bpm' key={Enums.APP_IDS.BPM}>
                      <BookOutlined />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.BPM]}
                    </Menu.Item>
                  ) : null}
                {config.modules.roles.enabled &&
                MemoryStore.userProfile.teamPrivileges[Enums.APP_IDS.ROLES] !==
                  '' ?
                  (
                    <Menu.Item cypressid='roles' key={Enums.APP_IDS.ROLES}>
                      <UserOutlined /> {Enums.APP_NAMES[Enums.APP_IDS.ROLES]}
                    </Menu.Item>
                  ) : null}
                {config.modules.botbuilder.enabled ? (
                  <Menu.Item cypressid='botBuilder' key={Enums.APP_IDS.BOT_BUILDER}>
                    <CommentOutlined />{' '}
                    {Enums.APP_NAMES[Enums.APP_IDS.BOT_BUILDER]}
                  </Menu.Item>
                ) : null}
                {config.modules.gatewayadapters.enabled &&
                MemoryStore.userProfile.teamPrivileges[
                  Enums.APP_IDS.GATEWAY_ADAPTERS
                ] !== '' ?
                  (
                    <Menu.Item cypressid='gatewayAdapters' key={Enums.APP_IDS.GATEWAY_ADAPTERS}>
                      <DeploymentUnitOutlined name='exchange' />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.GATEWAY_ADAPTERS]}
                    </Menu.Item>
                  ) : null}
                {config.modules.batchactions.enabled &&
                MemoryStore.userProfile.teamPrivileges[
                  Enums.APP_IDS.BATCH_ACTIONS
                ] !== '' ?
                  (
                    <Menu.Item cypressid='batchActions' key={Enums.APP_IDS.BATCH_ACTIONS}>
                      <AppstoreAddOutlined />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.BATCH_ACTIONS]}
                    </Menu.Item>
                  ) : null}
                {config.modules.events.enabled &&
                MemoryStore.userProfile.teamPrivileges[
                  Enums.APP_IDS.EVENTS
                ] !== '' ?
                  (
                    <Menu.Item cypressid='events' key={Enums.APP_IDS.EVENTS}>
                      <CalendarOutlined />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.EVENTS]}
                    </Menu.Item>
                  ) : null}
                {config.modules.batchlogging.enabled &&
                MemoryStore.userProfile.teamPrivileges[
                  Enums.APP_IDS.BATCH_LOGGING
                ] !== '' ?
                  (
                    <Menu.Item cypressid='batchLogging' key={Enums.APP_IDS.BATCH_LOGGING}>
                      <FileTextOutlined />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.BATCH_LOGGING]}
                    </Menu.Item>
                  ) : null}
                {config.modules.nodered.enabled &&
                MemoryStore.userProfile.teamPrivileges[
                  Enums.APP_IDS.NODE_RED
                ] !== '' ?
                  (
                    <Menu.Item cypressid='nodeRed' key={Enums.APP_IDS.NODE_RED} className='nodered'>
                      <BranchesOutlined />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.NODE_RED]}
                    </Menu.Item>
                  ) : null}
              </SubMenu>
              <SubMenu
                cypressid='adminSection'
                key='subMenu2'
                title={
                  <span>
                    <SettingOutlined size='lg' name='cog' />{' '}
                    <span>{Enums.VALUES_STRINGS.ADMINISTRATION}</span>
                  </span>
                }
              >
                <Menu.Item cypressid='about' key='About'>
                  <FileTextOutlined /> About
                </Menu.Item>
                {MemoryStore.userProfile.role === 'Admin' ?
                  <Menu.Item cypressid='account' key='AdminAccount'>
                    <UserOutlined /> Account
                  </Menu.Item>
                  : null}
                {MemoryStore.userProfile.teamPrivileges.dashboards !== '' ? (
                  <Menu.Item cypressid='dashboard' key='Dashboard'>
                    <AreaChartOutlined /> Dashboard
                  </Menu.Item>
                ) : null}
                {MemoryStore.userProfile.teamPrivileges[Enums.APP_IDS.ERROR_LOGS] !== '' ?
                  (
                    <Menu.Item cypressid='errorLogs' key={Enums.APP_IDS.ERROR_LOGS}>
                      <BugOutlined />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.ERROR_LOGS]}
                    </Menu.Item>
                  ) : null}
                {config.modules.solutions.enabled && MemoryStore.userProfile.role === 'Admin' ?
                  (
                    <Menu.Item cypressid='solutions' key={Enums.APP_IDS.SOLUTIONS}>
                      <SolutionOutlined name='link' />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.SOLUTIONS]}
                    </Menu.Item>
                  ) : null}
                {MemoryStore.userProfile.teamPrivileges.teams !== '' &&
                this.state.membershipType !== 'indie' ?
                  (
                    <Menu.Item cypressid='teamUsers' key={Enums.APP_IDS.TEAM_USERS}>
                      <UserAddOutlined />{' '}
                      {Enums.APP_NAMES[Enums.APP_IDS.TEAM_USERS]}
                    </Menu.Item>
                  ) : null}
                {MemoryStore.userProfile.teamPrivileges.apikeys !== '' ? (
                  <Menu.Item cypressid='apiKeys' key={Enums.APP_IDS.API_KEYS}>
                    <KeyOutlined />{' '}
                    {Enums.APP_NAMES[Enums.APP_IDS.API_KEYS]}
                  </Menu.Item>
                ) : null}
                {MemoryStore.userProfile.teamPrivileges.importexport !== '' ? (
                  <Menu.Item cypressid='importData' key='Import'>
                    <DownloadOutlined /> Import Data
                  </Menu.Item>
                ) : null}
                {MemoryStore.userProfile.teamPrivileges.importexport !== '' ? (
                  <Menu.Item cypressid='exportData' key='Export'>
                    <UploadOutlined /> Export Data
                  </Menu.Item>
                ) : null}
              </SubMenu>
            </Menu>
          </Drawer>
        ) : null}
        <Modal
          title='New Team'
          okButtonProps={{
            style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' }
          }}
          okText='Create'
          visible={this.state.createTeamDialog.open}
          onOk={this.handleCreateTeam}
          onCancel={this.handleModalCreateTeamClose}
          maskClosable={false}
          destroyOnClose
        >
          <Input
            placeholder='Team ID (No spaces or special characters)'
            defaultValue=''
            required
            onChange={e => {
              this.onChange('inputTeamId', e.target.value)
            }}
          />
        </Modal>
      </div>
    )
  }
}

export default CoreToolbar
