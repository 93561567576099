import React from 'react'
import { CopyOutlined } from '@ant-design/icons'
import { message, Tooltip } from 'antd'

import Theme from '../../utils/agilite-theme'
import CopyToClipboard from 'react-copy-to-clipboard'

const CopyKey = ({ value }) => {
  return (
    <Tooltip title='Copy Value to clipboard'>
      <CopyToClipboard
        text={value}
        onCopy={() => message.info('Value copied to clipboard')}
      >
        <CopyOutlined
          className='copy-key'
          style={{ marginLeft: 10, color: Theme.linkBlue, cursor: 'pointer', fontSize: 17, opacity: 0.6 }}
        />
      </CopyToClipboard>
    </Tooltip>
  )
}

export default CopyKey
