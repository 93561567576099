import teamUsersState from './team-users-state'
import { actions } from './team-users-actions'

export default (state = teamUsersState, action) => {
  switch (action.type) {
    case actions.RESET_STATE:
      return Object.assign({}, state, teamUsersState)
    case actions.SET_SEARCH_FILTER:
      return Object.assign({}, state, {
        searchFilter: action.value
      })
    case actions.FETCH_ALL_DATA:
      return Object.assign({}, state, {
        data: action.payload
      })
    case actions.UPDATE_DATA:
      return Object.assign({}, state, {
        data: action.data
      })
    default:
      return state
  }
}
