import React from 'react'
import { Icon } from 'react-fa'
import { LoadingOutlined } from '@ant-design/icons'
import { Tabs, Button, Form, Card, Row, Col, message, Modal, Dropdown, Menu, Popconfirm } from 'antd'

import MemoryStore from '../../../utils/memory-store'
import Enums from '../../../utils/enums'
import { resetConnectionPool } from '../../connectors-utils'

import ConnectorsFormGeneral from './connectors-form-general'
import ConnectorsFormTypeWebAPI from './connection-types/web-api/web-api-main'
import ConnectorsFormRoutesContainer from '../../containers/connectors-form-routes-container'
import ConnectorsFormTypeMongoDB from './connection-types/mongodb/mongodb-main'
import ConnectorsFormTypeSMTP from './connection-types/smtp/smtp-main'
import ConnectorsFormTypeDB2ISeries from './connection-types/db2-iseries/db2-iseries-main'
import ConnectorsFormTypeMSSQL from './connection-types/ms-sql/ms-sql-main'
import ConnectorsFormTypeFTP from './connection-types/ftp/ftp-main'
import ConnectorsFormTypeLDAP from './connection-types/ldap/ldap-main'
import ConnectorsFormTypePostgreSQL from './connection-types/postgresql/postgresql-main'
import ConnectorsFormTypeOracleDb from './connection-types/oracledb/oracledb-main'
import ConnectorsFormTypeSFTP from './connection-types/sftp/sftp-main'
import ConnectorsFormTypeIMAP from './connection-types/imap/imap-main'
import ConnectorsFormTypeSnowflake from './connection-types/snowflake/snowflake-main'

const TabPane = Tabs.TabPane
class ConnectorsForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc,
      activeTab: this.entry.data.activeEntryTab,
      connectionType: this.entry.data.connectionType,
      isLoading: false
    }

    this.handleSetActiveTab = this.handleSetActiveTab.bind(this)
    this.handleOnConnectionTypeChange = this.handleOnConnectionTypeChange.bind(this)
    this.handleOnFieldChange = this.handleOnFieldChange.bind(this)
    this.cancelProfileConfirm = this.cancelProfileConfirm.bind(this)
    this.cancelProfile = this.cancelProfile.bind(this)
    this.saveProfile = this.saveProfile.bind(this)
    this.handleResetConnectionPool = this.handleResetConnectionPool.bind(this)
  }

  handleSetActiveTab (tab) {
    this.entry.data.activeEntryTab = tab.props.value
    this.setState({ activeTab: tab.props.value })
  }

  handleOnConnectionTypeChange (value) {
    this.setState({ connectionType: value })
  }

  handleOnFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.entry.data[key] = value
  }

  cancelProfileConfirm () {
    if (this.entry.custom.isModified) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  saveProfile () {
    const tmpThis = this

    tmpThis.setState({
      isLoading: true
    })

    // Reset isModified and isModifiedSubform properties
    this.entry.custom.isModified = false
    this.entry.custom.isModifiedSubform = false

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, (err) => {
      tmpThis.setState({
        isLoading: false
      })

      if (err) {
        message.error(err)
      } else {
        tmpThis.props.closeTab(
          tmpThis.props.tabKey,
          tmpThis.props.tabKey,
          tmpThis.props.tabs
        )
      }
    })
  }

  async handleResetConnectionPool (profileKey) {
    try {
      await resetConnectionPool(profileKey)
      message.success('Connection Pool successfully reset')
    } catch (e) {
      message.error(e)
    }
  }

  determinePrivileges () {
    if (MemoryStore.userProfile.role === 'Admin') {
      return true
    } else if ((MemoryStore.userProfile.role === 'User') && (MemoryStore.userProfile.teamPrivileges.connectors === 'Editor')) {
      return true
    }

    return false
  }

  render () {
    const menu = (
      <Menu cypressid='menu'>
        <Menu.Item key='0'>
          <Popconfirm
            title='Are you sure you want to reset the Connection Pool for this Profile?'
            onConfirm={() => this.handleResetConnectionPool(this.entry.data.key)}
            okText='Yes'
            cancelText='No'
          >
            <span style={{ padding: '10 0' }}>Reset Connection Pool</span>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    )

    return (
      <Row type='flex' justify='center'>
        <Col xs={23} lg={20} xl={16}>
          <Card
            title={this.props.title}
            type='inner'
            style={{ textAlign: 'left' }}
            extra={!this.state.isNewDoc && this.determinePrivileges() ?
              <Dropdown overlay={menu} trigger={['click']}>
                <Icon name='ellipsis-v' style={{ padding: 5, fontSize: 17, color: '#B4483E', cursor: 'pointer' }} />
              </Dropdown>
              : null}
          >
            <Form
              name='connectors-form'
            >
              <Tabs defaultActiveKey='1' animated={false}>
                <TabPane tab='General' key='1' onActive={this.handleSetActiveTab}>
                  <ConnectorsFormGeneral
                    onChange={this.handleOnFieldChange}
                    tabKey={this.props.tabKey}
                    onConnectionTypeChange={this.handleOnConnectionTypeChange}
                  />
                </TabPane>
                <TabPane
                  tab='API Configuration'
                  key='2'
                  onActive={this.handleSetActiveTab}
                >
                  {this.entry.data.connectionType === '1' ?
                    <ConnectorsFormTypeWebAPI
                      onChange={this.handleOnFieldChange}
                      entry={this.entry.data.connectionData}
                      tabKey={this.props.tabKey}
                      theme={this.props.theme}
                    />
                    : null}
                  {this.entry.data.connectionType === '10' ?
                    <ConnectorsFormTypeMongoDB
                      onChange={this.handleOnFieldChange}
                      entry={this.entry.data.connectionData}
                      tabKey={this.props.tabKey}
                      theme={this.props.theme}
                    />
                    : null}
                  {this.entry.data.connectionType === '6' ?
                    <ConnectorsFormTypeSMTP
                      onChange={this.handleOnFieldChange}
                      entry={this.entry.data.connectionData}
                      tabKey={this.props.tabKey}
                      theme={this.props.theme}
                    />
                    : null}
                  {this.entry.data.connectionType === '7' ?
                    <ConnectorsFormTypeDB2ISeries
                      onChange={this.handleOnFieldChange}
                      entry={this.entry.data.connectionData}
                      tabKey={this.props.tabKey}
                      theme={this.props.theme}
                    />
                    : null}
                  {this.entry.data.connectionType === '11' ?
                    <ConnectorsFormTypeFTP
                      onChange={this.handleOnFieldChange}
                      entry={this.entry.data.connectionData}
                      tabKey={this.props.tabKey}
                      theme={this.props.theme}
                    />
                    : null}
                  {this.entry.data.connectionType === '12' ?
                    <ConnectorsFormTypeMSSQL
                      onChange={this.handleOnFieldChange}
                      entry={this.entry.data.connectionData}
                      tabKey={this.props.tabKey}
                      theme={this.props.theme}
                    />
                    : null}
                  {this.entry.data.connectionType === '13' ?
                    <ConnectorsFormTypeLDAP
                      onChange={this.handleOnFieldChange}
                      entry={this.entry.data.connectionData}
                      tabKey={this.props.tabKey}
                      theme={this.props.theme}
                    />
                    : null}
                  {this.entry.data.connectionType === '14' ?
                    <ConnectorsFormTypePostgreSQL
                      onChange={this.handleOnFieldChange}
                      entry={this.entry.data.connectionData}
                      tabKey={this.props.tabKey}
                      theme={this.props.theme}
                    />
                    : null}
                  {this.entry.data.connectionType === '15' ?
                    <ConnectorsFormTypeOracleDb
                      onChange={this.handleOnFieldChange}
                      entry={this.entry.data.connectionData}
                      tabKey={this.props.tabKey}
                      theme={this.props.theme}
                    />
                    : null}
                  {this.entry.data.connectionType === '16' ?
                    <ConnectorsFormTypeSFTP
                      onChange={this.handleOnFieldChange}
                      entry={this.entry.data.connectionData}
                      tabKey={this.props.tabKey}
                      theme={this.props.theme}
                    />
                    : null}
                  {this.entry.data.connectionType === '17' ?
                    <ConnectorsFormTypeIMAP
                      onChange={this.handleOnFieldChange}
                      entry={this.entry.data.connectionData}
                      tabKey={this.props.tabKey}
                      theme={this.props.theme}
                    />
                    : null}
                  {this.entry.data.connectionType === '18' ?
                    <ConnectorsFormTypeSnowflake
                      onChange={this.handleOnFieldChange}
                      entry={this.entry.data.connectionData}
                      tabKey={this.props.tabKey}
                      theme={this.props.theme}
                    />
                    : null}
                </TabPane>
                <TabPane tab='Routes' key='3' onActive={this.handleSetActiveTab}>
                  <ConnectorsFormRoutesContainer
                    connectionType={this.entry.data.connectionType}
                  />
                </TabPane>
              </Tabs>
              <Row style={{ marginTop: 10 }}>
                {MemoryStore.userProfile.teamPrivileges.connectors !== 'Reader' ?
                  <Button
                    name='submit'
                    disabled={this.state.isLoading}
                    htmlType='submit'
                    style={{
                      backgroundColor: '#67AD5B',
                      color: 'white',
                      width: 80,
                      marginRight: 10
                    }}
                    onClick={() => this.saveProfile()}
                  >
                    {this.state.isLoading ? <LoadingOutlined spin /> : <span>Submit</span>}
                  </Button>
                  : null}
                <Button
                  name='cancel'
                  disabled={this.state.isLoading}
                  label='Cancel'
                  style={{
                    backgroundColor: '#EB5181',
                    color: 'white',
                    width: 'auto',
                    marginRight: 10
                  }}
                  onClick={e => {
                    e.preventDefault()
                    this.cancelProfileConfirm()
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default ConnectorsForm
