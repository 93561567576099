import React from 'react'
import { Form, Row, Col, Card, Button, Switch, Input, Select, message, Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import ReactQuill from 'react-quill'
import AceEditor from 'react-ace'
import MemoryStore from '../../utils/memory-store'

import 'brace/mode/css'
import 'brace/mode/html'
import 'brace/mode/javascript'
import 'brace/mode/json'
import 'brace/mode/plain_text'
import 'brace/mode/sql'
import 'brace/mode/xml'

import 'brace/theme/github'
import 'brace/theme/kuroir'
import 'brace/theme/monokai'
import 'brace/theme/solarized_dark'
import 'brace/theme/solarized_light'
import 'brace/theme/xcode'

import 'react-quill/dist/quill.snow.css'

import GroupNameContainer from '../../reusables/containers/group-name-container'
import SolutionsContainer from '../../reusables/containers/solutions-container'
import EnumsTooltips from '../../utils/enums-tooltips'
import TooltipIcon from '../../reusables/components/tooltip-icon'
import CompileTemplateIcon from '../../reusables/components/compile-template-icon'
import Description from '../../reusables/components/description'
import Enums from '../../utils/enums'

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ['clean'] // remove formatting button
]

class TemplateForm extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]

    this.state = {
      templateType: this.entry.data.templateType,
      editorType: this.entry.data.editorType,
      mode: this.entry.data.mode,
      theme: this.entry.data.theme,
      data: this.entry.data.data,
      isLoading: false
    }

    this.setData = this.setData.bind(this)
    this.setMode = this.setMode.bind(this)
    this.setTheme = this.setTheme.bind(this)
    this.setTemplateType = this.setTemplateType.bind(this)
    this.setEditorType = this.setEditorType.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.cancelProfileConfirm = this.cancelProfileConfirm.bind(this)
    this.cancelProfile = this.cancelProfile.bind(this)
    this.saveProfile = this.saveProfile.bind(this)
  }

  setData (value) {
    this.setState({
      data: value
    })
  }

  setMode (value) {
    this.setState({
      mode: value
    })
  }

  setTheme (value) {
    this.setState({
      theme: value
    })
  }

  setTemplateType (templateType) {
    let mode = this.state.mode

    if (templateType === 'memo') {
      mode = 'html'
      this.entry.data.mode = mode
    }

    this.setState({
      templateType,
      mode
    })
  }

  setEditorType (editorType) {
    this.setState({
      editorType
    })
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.entry.data[key] = value
  }

  cancelProfileConfirm () {
    if (this.entry.custom.isModified) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  saveProfile () {
    const tmpThis = this

    tmpThis.setState({
      isLoading: true
    })

    if (this.entry.data.templateType !== 'memo') {
      if (this.entry.data.data === '') {
        tmpThis.setState({
          isLoading: false
        })
        message.error('Template Data can not be empty')
        return
      }
    }

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, (err) => {
      tmpThis.setState({
        isLoading: false
      })

      if (err) {
        message.error(err)
      } else {
        // Reset isModified and isModifiedSubform properties
        tmpThis.entry.custom.isModified = false
        tmpThis.entry.custom.isModifiedSubform = false

        tmpThis.props.closeTab(tmpThis.props.tabKey, tmpThis.props.tabKey, tmpThis.props.tabs)
      }
    })
  }

  render () {
    return (
      <Row type='flex' justify='center'>
        <Col xs={23}>
          <Card
            title={this.props.title}
            type='inner'
            style={{ textAlign: 'left' }}
          >
            <Form
              autoComplete='off'
            >
              <Row type='flex' justify='space-between'>
                <Col xs={24} xl={7}>
                  <Form.Item>
                    <Switch
                      disabled={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                      checkedChildren='Active'
                      unCheckedChildren='Inactive'
                      defaultChecked={this.entry.data.isActive}
                      onChange={(e) => {
                        this.onFieldChange('isActive', e)
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <span style={{ color: 'red' }}>* </span>{'Profile Key '}
                    <TooltipIcon title={EnumsTooltips.general.profileKey} />
                    <Input
                      placeholder='Provide a unique Profile Key'
                      disabled={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                      defaultValue={this.entry.data.key}
                      required
                      onChange={(e) => {
                        this.onFieldChange('key', e.target.value)
                      }}
                    />
                  </Form.Item>
                  <Description
                    disabled={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                    description={this.entry.data.description}
                    onChange={(e) => {
                      this.onFieldChange('description', e.target.value)
                    }}
                  />
                  <GroupNameContainer
                    disabled={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                    defaultValue={this.entry.data.groupName}
                    onValueChange={(value) => {
                      this.onFieldChange('groupName', value)
                    }}
                    onValueSelect={(value) => {
                      this.onFieldChange('groupName', value)
                    }}
                  />
                  <SolutionsContainer
                    disabled={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                    defaultValue={this.entry.data.solutions}
                    onValueChange={(value) => {
                      this.onFieldChange('solutions', value)
                    }}
                  />
                  <Form.Item>
                    Template Type
                    <Select
                      disabled={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                      defaultValue={this.state.templateType}
                      onChange={(e) => {
                        this.onFieldChange('templateType', e)
                        this.setTemplateType(e)
                      }}
                    >
                      <Select.Option value='std'>Standard</Select.Option>
                      <Select.Option value='memo'>Email Notification</Select.Option>
                    </Select>
                  </Form.Item>
                  {this.state.templateType === 'memo' &&
                    <>
                      <Form.Item>
                        Editor Type
                        <Select
                          disabled={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                          defaultValue={this.state.editorType}
                          onChange={(e) => {
                            this.onFieldChange('editorType', e)
                            this.setEditorType(e)
                          }}
                        >
                          <Select.Option value='design'>Design</Select.Option>
                          <Select.Option value='src'>Source</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item>
                        Editor Theme
                        <Select
                          disabled={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                          value={this.state.theme}
                          onChange={(e) => {
                            this.onFieldChange('theme', e)
                            this.setTheme(e)
                          }}
                        >
                          <Select.Option value='github'>GitHub</Select.Option>
                          <Select.Option value='kuroir'>Kuroir</Select.Option>
                          <Select.Option value='monokai'>Monokai</Select.Option>
                          <Select.Option value='solarized_dark'>Solarized Dark</Select.Option>
                          <Select.Option value='solarized_light'>Solarized Light</Select.Option>
                          <Select.Option value='xcode'>Xcode</Select.Option>
                        </Select>
                      </Form.Item>
                    </>}
                  {this.state.templateType === 'std' &&
                    <>
                      <Form.Item>
                        Editor Mode
                        <Select
                          disabled={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                          defaultValue={this.state.mode}
                          onChange={(e) => {
                            this.onFieldChange('mode', e)
                            this.setMode(e)
                          }}
                        >
                          <Select.Option value='css'>CSS</Select.Option>
                          <Select.Option value='html'>HTML</Select.Option>
                          <Select.Option value='javascript'>JavaScript</Select.Option>
                          <Select.Option value='json'>JSON</Select.Option>
                          <Select.Option value='plain_text'>Plain Text</Select.Option>
                          <Select.Option value='xml'>XML</Select.Option>
                          <Select.Option value='sql'>SQL</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item>
                        Editor Theme
                        <Select
                          disabled={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                          value={this.state.theme}
                          onChange={(e) => {
                            this.onFieldChange('theme', e)
                            this.setTheme(e)
                          }}
                        >
                          <Select.Option value='github'>GitHub</Select.Option>
                          <Select.Option value='kuroir'>Kuroir</Select.Option>
                          <Select.Option value='monokai'>Monokai</Select.Option>
                          <Select.Option value='solarized_dark'>Solarized Dark</Select.Option>
                          <Select.Option value='solarized_light'>Solarized Light</Select.Option>
                          <Select.Option value='xcode'>Xcode</Select.Option>
                        </Select>
                      </Form.Item>
                    </>}
                </Col>
                <Col xs={24} xl={16}>
                  {this.state.templateType === 'memo' &&
                    <>
                      <Form.Item>
                        {'Send To '}
                        <CompileTemplateIcon />
                        <Input
                          placeholder='Separate values using comma'
                          disabled={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                          defaultValue={this.entry.data.sendTo}
                          onChange={(e) => {
                            this.onFieldChange('sendTo', e.target.value)
                          }}
                        />
                      </Form.Item>
                      <Form.Item>
                        {'Copy To (cc) '}
                        <CompileTemplateIcon />
                        <Input
                          placeholder='Separate values using comma'
                          disabled={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                          defaultValue={this.entry.data.copyTo}
                          onChange={(e) => {
                            this.onFieldChange('copyTo', e.target.value)
                          }}
                        />
                      </Form.Item>
                      <Form.Item>
                        {'Blind Copy To (bcc) '}
                        <CompileTemplateIcon />
                        <Input
                          placeholder='Separate values using comma'
                          disabled={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                          defaultValue={this.entry.data.blindCopyTo}
                          onChange={(e) => {
                            this.onFieldChange('blindCopyTo', e.target.value)
                          }}
                        />
                      </Form.Item>
                      <Form.Item>
                        {'Subject '}
                        <CompileTemplateIcon />
                        <Input
                          placeholder='Memo Subject'
                          disabled={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                          defaultValue={this.entry.data.subject}
                          onChange={(e) => {
                            this.onFieldChange('subject', e.target.value)
                          }}
                        />
                      </Form.Item>
                    </>}
                  <h3>{this.state.templateType === 'memo' ? 'Body Data ' : 'Template Data '}<CompileTemplateIcon /></h3>
                  {(this.state.templateType === 'std' || (this.state.templateType === 'memo' && this.state.editorType === 'src')) &&
                    <AceEditor
                      readOnly={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                      showPrintMargin={false}
                      cursorStart={1}
                      mode={this.state.mode}
                      theme={this.state.theme}
                      style={{ width: '100%', height: 700 }}
                      name='aceEditor'
                      value={this.state.data}
                      editorProps={{
                        $blockScrolling: Infinity
                      }}
                      setOptions={{ showLineNumbers: true, newLineMode: 'unix' }}
                      onChange={(e) => {
                        this.onFieldChange('data', e)
                        this.setData(e)
                      }}
                    />}
                  {this.state.templateType === 'memo' && this.state.editorType === 'design' &&
                    <ReactQuill
                      readOnly={MemoryStore.userProfile.teamPrivileges.templates === 'Reader'}
                      style={{ width: '100%', height: 700 }}
                      theme='snow'
                      modules={{ toolbar: toolbarOptions }}
                      value={this.state.data}
                      onChange={(e) => {
                        this.onFieldChange('data', e)
                        this.setData(e)
                      }}
                    />}
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                {MemoryStore.userProfile.teamPrivileges.templates !== 'Reader'
                  ? <Button
                    disabled={this.state.isLoading}
                    style={{ backgroundColor: '#67AD5B', color: 'white', width: 80, marginRight: 10, float: 'left' }}
                    onClick={() => this.saveProfile()}
                  >
                    {this.state.isLoading ? <LoadingOutlined spin /> : <span>Submit</span>}
                  </Button>
                  : null}
                <Button
                  label='Cancel'
                  disabled={this.state.isLoading}
                  onClick={e => {
                    e.preventDefault()
                    this.cancelProfileConfirm()
                  }}
                  style={{ backgroundColor: '#EB5181', color: 'white', width: 'auto', marginRight: 10, float: 'left' }}
                >
                  Cancel
                </Button>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default TemplateForm
