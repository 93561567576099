import React from 'react'
import MemoryStore from '../../../utils/memory-store'
import BotBuilderFormGeneral from './bot-builder-form-general'
import BotProcessesContainer from '../../containers/bot-processes-container'
import { Row, Col, Card, Tabs, Form, Button, message } from 'antd'

const TabPane = Tabs.TabPane

class BotBuilderForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc
    }

    // Setup Event Binding
    this.handleOnFieldChange = this.handleOnFieldChange.bind(this)
    this.saveProfile = this.saveProfile.bind(this)
    this.cancelProfile = this.cancelProfile.bind(this)
  }

  handleOnFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.entry.data[key] = value
  }

  saveProfile (publish) {
    const tmpThis = this

    // Reset isModified and isModifiedSubform properties
    this.entry.custom.isModified = false
    this.entry.custom.isModifiedSubform = false

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, publish, (validations) => {
      if (validations) {
        // Validation Failed
        message.error(validations)
      } else {
        tmpThis.props.closeTab(
          tmpThis.props.tabKey,
          tmpThis.props.tabKey,
          tmpThis.props.tabs
        )
      }
    })
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  render () {
    return (
      <Row type='flex' justify='center'>
        <Col lg={18}>
          <Form
            autoComplete='off'
          >
            <Card
              title={this.props.title}
              type='inner'
              style={{ textAlign: 'left' }}
            >
              <Tabs defaultActiveKey='1' animated={false}>
                <TabPane tab='General' key='1'>
                  <BotBuilderFormGeneral
                    onChange={this.handleOnFieldChange}
                    tabKey={this.props.tabKey}
                  />
                </TabPane>
                <TabPane tab='Bot Processes' key='2'>
                  <BotProcessesContainer />
                </TabPane>
              </Tabs>
              <Row>
                <div>
                  <Button
                    htmlType='submit'
                    style={{
                      backgroundColor: '#67AD5B',
                      color: 'white',
                      width: 'auto',
                      marginRight: 10
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    style={{
                      backgroundColor: '#67AD5B',
                      color: 'white',
                      width: 'auto',
                      marginRight: 10
                    }}
                    onClick={() => this.saveProfile()}
                  >
                    {'Save & Publish'}
                  </Button>
                  <Button
                    style={{
                      backgroundColor: '#EB5181',
                      color: 'white',
                      width: 'auto',
                      marginRight: 10
                    }}
                    onClick={e => {
                      e.preventDefault()
                      this.cancelProfile()
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Row>
            </Card>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default BotBuilderForm
