import React from 'react'
import { Button, Row, Col, Card, message } from 'antd'
import AgiliteHeader from '../../reusables/components/agilite-header'

const CoreVerifyConfirm = ({ resendVerification, initHomePage }) => (
  <Row type='flex' justify='center'>
    <Col xs={23} sm={23} md={16} lg={12} xl={10} xxl={8}>
      <div>
        <div className='col-xs-12'>
          <center>
            <AgiliteHeader header='Authentication Failed' />
          </center>
        </div>
        <div id='divMessagesSuccess' className='col-xs-6 col-xs-offset-3 messagesSuccess' />
        <div id='divMessagesError' className='col-xs-6 col-xs-offset-3 messagesError' />
        <Card
          style={{ textAlign: 'center', marginTop: 20 }}
          type='inner'
          title={
            <span>
              Your Account is not yet active.
              <br />
              Click on the button below to resend a verification
            </span>
          }
        >
          <Button
            style={{
              backgroundColor: '#67AD5B',
              color: 'white',
              width: 'auto',
              marginRight: 10
            }}
            onClick={e => {
              e.preventDefault()
              resendVerification(callback => {
                message.info(callback)
              })
            }}
          >
            Resend Verification
          </Button>
          <Button
            style={{
              backgroundColor: '#EB5181',
              color: 'white',
              width: 'auto',
              marginRight: 10
            }}
            onClick={e => {
              e.preventDefault()
              initHomePage()
            }}
          >
            Return To Home
          </Button>
        </Card>
      </div>
    </Col>
  </Row>
)

export default CoreVerifyConfirm
