import React from 'react'

import { Row, Modal, Col, message } from 'antd'
import MemoryStore from '../../../utils/memory-store'

import ConnectorsRouteForm from '../connectors-form/connectors-route-form'
import ListViewDND from '../../../reusables/components/list-view-dnd'
import { columnTemplates } from '../../../utils/templates'
import { cleanUpTableData, formatAliasValue, formatNameValue } from '../../../utils/utilities'
import Enums from '../../../utils/enums'

class ConnectorsFormRoutes extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry,
      dialogOpen: false,
      activeRecord: null
    }

    // Setup Event Binding
    this.createRecord = this.createRecord.bind(this)
    this.saveRecord = this.saveRecord.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.deleteRecord = this.deleteRecord.bind(this)
    this.handleValidateRecord = this.handleValidateRecord.bind(this)
    this.formatViewData = this.formatViewData.bind(this)
    this.handleCloseModalDialog = this.handleCloseModalDialog.bind(this)
    this.handleCloseModalDialogConfirm = this.handleCloseModalDialogConfirm.bind(this)
    this.updateArrayData = this.updateArrayData.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.formatViewData()
  }

  UNSAFE_componentWillUpdate () {
    this.formatViewData()
  }

  createRecord () {
    this.entry.custom.isModified = true

    const activeRecord = JSON.parse(
      JSON.stringify(MemoryStore.connectors.routeTemplate)
    )
    activeRecord.isNewEntry = true
    activeRecord._id = new Date().getTime().toString()

    this.setState({
      dialogOpen: true,
      activeRecord
    })
  }

  saveRecord () {
    this.entry.custom.isModified = true
    this.entry.custom.isModifiedSubform = false

    const entry = this.props.entry
    const tmpRecord = this.state.activeRecord
    const index = entry.data.routes.findIndex(
      t => t._id === this.state.activeRecord._id
    )

    if (tmpRecord.routeData.headerParams) {
      tmpRecord.routeData.headerParams = cleanUpTableData(
        tmpRecord.routeData.headerParams,
        true
      )
    }

    if (tmpRecord.routeData.queryParams) {
      tmpRecord.routeData.queryParams = cleanUpTableData(
        tmpRecord.routeData.queryParams,
        true
      )
    }

    if (this.state.activeRecord.isNewEntry) {
      entry.data.routes.push(tmpRecord)
    } else {
      entry.data.routes.splice(index, 1, tmpRecord)
    }

    this.setState({
      entry,
      dialogOpen: false,
      activeRecord: JSON.parse(
        JSON.stringify(MemoryStore.connectors.routeTemplate)
      )
    })
  }

  editRecord (recordId) {
    const index = this.state.entry.data.routes.findIndex(t => t._id === recordId)
    const activeRecord = JSON.parse(JSON.stringify(this.state.entry.data.routes[index]))

    activeRecord.isNewEntry = false
    this.setState({ dialogOpen: true, activeRecord })
  }

  deleteRecord (recordId) {
    this.entry.custom.isModified = true

    this.props.entry.custom.isModified = true

    const entry = this.props.entry
    const index = entry.data.routes.findIndex(t => t._id === recordId)

    entry.data.routes.splice(index, 1)
    this.setState({ entry })
  }

  handleValidateRecord () {
    const connectionTypeValue = this.state.entry.data.connectionType
    const activeRecord = this.state.activeRecord
    let success = true
    let msg = ''

    // Format Key
    activeRecord.key = formatAliasValue(activeRecord.key)
    // Format Name
    activeRecord.name = formatNameValue(activeRecord.name)
    this.setState({ activeRecord })

    this.props.entry.data.routes.map(route => {
      if (
        route.key.trim() === this.state.activeRecord.key &&
        route._id !== this.state.activeRecord._id
      ) {
        msg = `Route with key '${this.state.activeRecord.key}' already exists`
        success = false
      }

      return null
    })

    switch (connectionTypeValue) {
      case '1': // Web API
        if (this.state.activeRecord.routeData.requestType && this.state.activeRecord.routeData.requestType === '') {
          msg = 'Please provide a Request Type'
          success = false
        }

        if (this.state.activeRecord.routeData.headerParams) {
          for (let x = 0; x < this.state.activeRecord.routeData.headerParams.length; x++) {
            if (!this.state.activeRecord.routeData.headerParams[x].paramKey && this.state.activeRecord.routeData.headerParams[x].paramValue) {
              msg = 'Please provide a key or key and value for the Header Parameters'
              success = false
            }
          }
        }

        if (this.state.activeRecord.routeData.queryParams) {
          for (let x = 0; x < this.state.activeRecord.routeData.queryParams.length; x++) {
            if (!this.state.activeRecord.routeData.queryParams[x].paramKey && this.state.activeRecord.routeData.queryParams[x].paramValue) {
              msg = 'Please provide a key or key and value for the URL Parameters'
              success = false
            }
          }
        }

        if (
          this.state.activeRecord.routeData.requestType === 'post' ||
          this.state.activeRecord.routeData.requestType === 'put' ||
          this.state.activeRecord.routeData.requestType === 'patch'
        ) {
          if (!this.state.activeRecord.routeData.bodyType) {
            msg = 'Please select a Body Type'
            success = false
          } else if (this.state.activeRecord.routeData.bodyType === 'binary') {
            if (!this.state.activeRecord.routeData.body) {
              msg = 'Please provide a File ID'
              success = false
            }
          }
        }

        break
      case '7': // DB2 (iSeries)
        if (this.state.activeRecord.routeData.getQuery && this.state.activeRecord.routeData.getQuery === '0') {
          if (!this.state.activeRecord.routeData.data) {
            msg = 'Please provide SQL Query Body'
            success = false
          }
        } else {
          if (!this.state.activeRecord.routeData.templateKey) {
            msg = 'Please provide a Template Profile Key'
            success = false
          }
        }

        if (!this.state.activeRecord.routeData.routeType) {
          msg = 'Please provide a Route Type'
          success = false
        }
        break
      case '11': // FTP
      case '16': // SFTP
        if (!this.state.activeRecord.routeData.routeType) {
          msg = 'Please provide a Route Type'
          success = false
        }

        switch (this.state.activeRecord.routeData.routeType) {
          case '2':
          case '3':
            if (!this.state.activeRecord.routeData.fileName) {
              msg = 'Please provide a File Name'
              success = false
            }
            if (!this.state.activeRecord.routeData.path) {
              msg = 'Please provide a Path'
              success = false
            }
            if (!this.state.activeRecord.routeData.fileId) {
              msg = 'Please provide an Agilit-e File Id'
              success = false
            }
            break
          case '1':
          case '5':
          case '9':
          case '10':
            if (!this.state.activeRecord.routeData.fileName) {
              msg = 'Please provide a File Name'
              success = false
            }
            if (!this.state.activeRecord.routeData.path) {
              msg = 'Please provide a Path'
              success = false
            }
            break
          case '4':
            if (!this.state.activeRecord.routeData.newFileName) {
              msg = 'Please provide a New File Name'
              success = false
            }
            if (!this.state.activeRecord.routeData.newPath) {
              msg = 'Please provide a Path'
              success = false
            }
            if (!this.state.activeRecord.routeData.oldFileName) {
              msg = 'Please provide a Old File Name'
              success = false
            }
            if (!this.state.activeRecord.routeData.oldPath) {
              msg = 'Please provide a Path'
              success = false
            }
            break
          case '6':
          case '7':
            if (!this.state.activeRecord.routeData.path) {
              msg = 'Please provide a Path'
              success = false
            }
            break
          case '0':
            if (!this.state.activeRecord.routeData.path) {
              msg = 'Please provide a Path'
              success = false
            }
            break
          default:
            break
        }

        break
      case '6': // SMTP
        if (!this.state.activeRecord.routeData.from) {
          msg = 'Please provide a value in \'From\''
          success = false
        }

        if (!this.state.activeRecord.routeData.subject && !this.state.activeRecord.routeData.htmlBody) {
          msg = 'Please provide a value in either \'Subject or HTML Body\''
          success = false
        }

        if (!this.state.activeRecord.routeData.sendTo && !this.state.activeRecord.routeData.copyTo && !this.state.activeRecord.routeData.blindCopyTo) {
          msg =
            'Please provide a value in either \'Send To, Copy To or Blind Copy To\''
          success = false
        }

        if (!this.state.activeRecord.routeData.routeType) {
          msg = 'Please provide a Route Type'
          success = false
        }
        break
      case '10': // MongoDB
        // Qry Type
        if (!this.state.activeRecord.routeData.qryType) {
          msg = 'Please select a Query Type'
          success = false
        }

        switch (this.state.activeRecord.routeData.qryType) {
          case '0':
          case '9':
            if (!this.state.activeRecord.routeData.recordData) {
              msg = 'Please provide Record Data'
              success = false
            }
            break
          case '2':
          case '3':
          case '4':
            if (!this.state.activeRecord.routeData.recordId) {
              msg = 'Please provide a Record ID'
              success = false
            }
            break
          case '5':
            if (!this.state.activeRecord.routeData.recordData) {
              msg = 'Please provide Record Data'
              success = false
            }

            if (!this.state.activeRecord.routeData.recordId) {
              msg = 'Please provide a Record ID'
              success = false
            }
            break
          case '11':
            if (!this.state.activeRecord.routeData.projection) {
              msg = 'Please provide a Field'
              success = false
            }
            break
          case '18':
            if (!this.state.activeRecord.routeData.indexKeys) {
              msg = 'Please provide Keys for the Index'
              success = false
            } else if (!this.state.activeRecord.routeData.indexOptions) {
              msg = 'Please provide Options for the Index'
              success = false
            }
            break
          case '19':
            if (!this.state.activeRecord.routeData.indexName) {
              msg = 'Please provide a Index Name'
              success = false
            }
            break
          default:
        }

        if (!this.state.activeRecord.routeData.routeType) {
          msg = 'Please provide a Route Type'
          success = false
        }
        break
      case '12': // SQL
        // Execute Query
        if (
          this.state.activeRecord.routeData.routeType &&
          this.state.activeRecord.routeData.routeType === '0'
        ) {
          // Get Query: Here
          if (
            this.state.activeRecord.routeData.getQuery &&
            this.state.activeRecord.routeData.getQuery === '0'
          ) {
            if (
              !this.state.activeRecord.routeData.data ||
              this.state.activeRecord.routeData.data === ''
            ) {
              msg = 'Please provide SQL Query Body'
              success = false
            }
          } else {
            // Get Query: From Template Profile Key
            if (
              !this.state.activeRecord.routeData.templateKey ||
              this.state.activeRecord.routeData.templateKey === ''
            ) {
              msg = 'Please provide a Template Profile Key'
              success = false
            }
          }
        }

        // Execute Stored Procedure
        if (
          this.state.activeRecord.routeData.routeType &&
          this.state.activeRecord.routeData.routeType === '1'
        ) {
          if (
            !this.state.activeRecord.routeData.name ||
            this.state.activeRecord.routeData.name === ''
          ) {
            msg = 'Please provide a Procedure Name'
            success = false
          }
        }

        if (this.state.activeRecord.routeData.inputs) {
          this.state.activeRecord.routeData.inputs.map(input => {
            if (input.paramKey !== '' && input.paramType === '') {
              msg = 'Please specify a Input Type for every Input Key that was specified'
              success = false
            } else if (input.paramKey === '' && input.paramType !== '') {
              msg = 'Please specify a Input Key for every Input Type that was specified'
              success = false
            } else if (input.paramValue !== '' && input.paramKey === '') {
              msg = 'Please specify a Input Key for every Input Value that was specified'
              success = false
            }
            return null
          })
        }

        if (this.state.activeRecord.routeData.outputs) {
          this.state.activeRecord.routeData.outputs.map(output => {
            if (output.paramKey !== '' && output.paramType === '') {
              msg = 'Please specify a Output Type for every Output Key that was specified'
              success = false
            } else if (output.paramKey === '' && output.paramType !== '') {
              msg = 'Please specify a Output Key for every Output Type that was specified'
              success = false
            }
            return null
          })
        }
        break
      case '13': // LDAP
        // Auth -- No Vadiation fields
        // Search
        if (
          this.state.activeRecord.routeData.routeType &&
          this.state.activeRecord.routeData.routeType === '1'
        ) {
          if (
            !this.state.activeRecord.routeData.searchDN ||
            this.state.activeRecord.routeData.searchDN === ''
          ) {
            msg = 'Please provide a Distinguished Name search query'
            success = false
          } else if (
            !this.state.activeRecord.routeData.options ||
            this.state.activeRecord.routeData.options === ''
          ) {
            msg = 'Please provide Options for the search query'
            success = false
          }
        }

        // Delete Entry
        if (
          this.state.activeRecord.routeData.routeType &&
          this.state.activeRecord.routeData.routeType === '2'
        ) {
          if (
            !this.state.activeRecord.routeData.dnToDelete ||
            this.state.activeRecord.routeData.dnToDelete === ''
          ) {
            msg = 'Please provide a Distinguished Name to delete'
            success = false
          }
        }

        // Add Entry
        if (
          this.state.activeRecord.routeData.routeType &&
          this.state.activeRecord.routeData.routeType === '3'
        ) {
          if (
            !this.state.activeRecord.routeData.dn ||
            this.state.activeRecord.routeData.dn === ''
          ) {
            msg = 'Please provide a Distinguished Name'
            success = false
          } else if (
            !this.state.activeRecord.routeData.entry ||
            this.state.activeRecord.routeData.entry === ''
          ) {
            msg = 'Please provide an Entry'
            success = false
          }
        }

        // Compare
        if (
          this.state.activeRecord.routeData.routeType &&
          this.state.activeRecord.routeData.routeType === '4'
        ) {
          if (
            !this.state.activeRecord.routeData.dn ||
            this.state.activeRecord.routeData.dn === ''
          ) {
            msg = 'Please provide a Distinguished Name'
            success = false
          } else if (
            !this.state.activeRecord.routeData.attribute ||
            this.state.activeRecord.routeData.attribute === ''
          ) {
            msg = 'Please provide an Attribute Name'
            success = false
          } else if (
            !this.state.activeRecord.routeData.value ||
            this.state.activeRecord.routeData.value === ''
          ) {
            msg = 'Please provide an Attribute Value'
            success = false
          }
        }

        // Extended Operation
        if (
          this.state.activeRecord.routeData.routeType &&
          this.state.activeRecord.routeData.routeType === '5'
        ) {
          if (
            !this.state.activeRecord.routeData.oid ||
            this.state.activeRecord.routeData.oid === ''
          ) {
            msg = 'Please provide an Object Identifier'
            success = false
          }
        }

        // Modify
        if (
          this.state.activeRecord.routeData.routeType &&
          this.state.activeRecord.routeData.routeType === '6'
        ) {
          if (
            !this.state.activeRecord.routeData.dn ||
            this.state.activeRecord.routeData.dn === ''
          ) {
            msg = 'Please provide a Distinguished Name'
            success = false
          } else {
            if (
              !this.state.activeRecord.routeData.changes ||
              this.state.activeRecord.routeData.changes === ''
            ) {
              msg = 'Please complete a JSON array for the Changes'
              success = false
            }
          }
        }

        // Modify DN
        if (
          this.state.activeRecord.routeData.routeType &&
          this.state.activeRecord.routeData.routeType === '7'
        ) {
          if (
            !this.state.activeRecord.routeData.dn ||
            this.state.activeRecord.routeData.dn === ''
          ) {
            msg = 'Please provide a Distinguished Name'
            success = false
          } else if (
            !this.state.activeRecord.routeData.newDn ||
            this.state.activeRecord.routeData.newDn === ''
          ) {
            msg = 'Please provide a New Distinguished Name'
            success = false
          }
        }
        break
      case '17':
        // Search
        if (this.state.activeRecord.routeData.routeType && this.state.activeRecord.routeData.routeType === '1') {
          if (!this.state.activeRecord.routeData.folderName) {
            msg = 'Please provide a Folder/Mailbox Name'
            success = false
          } else if (!this.state.activeRecord.routeData.searchCriteria) {
            msg = 'Please provide a Search Crtiteria'
            success = false
          }
        }

        // Add Box
        if (this.state.activeRecord.routeData.routeType && this.state.activeRecord.routeData.routeType === '2') {
          if (!this.state.activeRecord.routeData.folderName) {
            msg = 'Please provide a Folder/Mailbox Name'
            success = false
          }
        }

        // Delete Box
        if (this.state.activeRecord.routeData.routeType && this.state.activeRecord.routeData.routeType === '3') {
          if (!this.state.activeRecord.routeData.folderName) {
            msg = 'Please provide a Folder/Mailbox Name'
            success = false
          }
        }

        // Add Flags
        if (this.state.activeRecord.routeData.routeType && this.state.activeRecord.routeData.routeType === '4') {
          if (!this.state.activeRecord.routeData.folderName) {
            msg = 'Please provide a Folder/Mailbox Name'
            success = false
          }

          if (!this.state.activeRecord.routeData.messageIds) {
            msg = 'Please provide one or more Message UIDs'
            success = false
          }

          if (!this.state.activeRecord.routeData.flags) {
            msg = 'Please provide one or more Message Flags'
            success = false
          }
        }

        // Delete Flags
        if (this.state.activeRecord.routeData.routeType && this.state.activeRecord.routeData.routeType === '5') {
          if (!this.state.activeRecord.routeData.folderName) {
            msg = 'Please provide a Folder/Mailbox Name'
            success = false
          }

          if (!this.state.activeRecord.routeData.messageIds) {
            msg = 'Please provide one or more Message UIDs'
            success = false
          }

          if (!this.state.activeRecord.routeData.flags) {
            msg = 'Please provide one or more Message Flags'
            success = false
          }
        }

        // Append
        if (this.state.activeRecord.routeData.routeType && this.state.activeRecord.routeData.routeType === '6') {
          if (!this.state.activeRecord.routeData.message) {
            msg = 'Please provide a Message'
            success = false
          }

          if (!this.state.activeRecord.routeData.messageOptions) {
            msg = 'Please provide Message Options'
            success = false
          }
        }

        // Get Parts
        if (this.state.activeRecord.routeData.routeType && this.state.activeRecord.routeData.routeType === '7') {
          if (!this.state.activeRecord.routeData.struct) {
            msg = 'Please provide a Struct'
            success = false
          }
        }

        // Get Part Data
        if (this.state.activeRecord.routeData.routeType && this.state.activeRecord.routeData.routeType === '8') {
          if (!this.state.activeRecord.routeData.message) {
            msg = 'Please provide a Message'
            success = false
          }

          if (!this.state.activeRecord.routeData.part) {
            msg = 'Please provide a Part'
            success = false
          }
        }

        // Move Message
        if (this.state.activeRecord.routeData.routeType && this.state.activeRecord.routeData.routeType === '10') {
          if (!this.state.activeRecord.routeData.folderName) {
            msg = 'Please provide a Folder/Mailbox Name'
            success = false
          }

          if (!this.state.activeRecord.routeData.messageId) {
            msg = 'Please provide a Message UID'
            success = false
          }
        }

        // Delete Message
        if (this.state.activeRecord.routeData.routeType && this.state.activeRecord.routeData.routeType === '9') {
          if (!this.state.activeRecord.routeData.folderName) {
            msg = 'Please provide a Folder/Mailbox Name'
            success = false
          }

          if (!this.state.activeRecord.routeData.messageIds) {
            msg = 'Please provide one or more Message UIDs'
            success = false
          }
        }

        break
      case '18': // Snowflake
        // Execute Query
        if (
          this.state.activeRecord.routeData.routeType &&
          this.state.activeRecord.routeData.routeType === '0'
        ) {
          // Get Query: Here
          if (
            this.state.activeRecord.routeData.getQuery &&
            this.state.activeRecord.routeData.getQuery === '0'
          ) {
            if (
              !this.state.activeRecord.routeData.sqlText ||
              this.state.activeRecord.routeData.sqlText === ''
            ) {
              msg = 'Please provide SQL Query Body'
              success = false
            }
          } else {
            // Get Query: From Template Profile Key
            if (
              !this.state.activeRecord.routeData.templateKey ||
              this.state.activeRecord.routeData.templateKey === ''
            ) {
              msg = 'Please provide a Template Profile Key'
              success = false
            }
          }
        }
        break
      default:
    }

    if (this.state.activeRecord.name.trim() === '') {
      msg = 'Please provide a Route Name'
      success = false
    }

    if (this.state.activeRecord.key.trim() === '') {
      msg = 'Please provide a Route Key'
      success = false
    }

    if (!success) {
      message.error(msg)
    } else {
      this.saveRecord()
    }
  }

  formatViewData () {
    // Set isActiveDescription
    this.state.entry.data.routes.map(process => {
      process.isActiveDescription = process.isActive ? 'Yes' : 'No'
      return process.isActiveDescription
    })
  }

  handleCloseModalDialog () {
    this.setState({
      dialogOpen: false,
      activeRecord: JSON.parse(
        JSON.stringify(MemoryStore.connectors.routeTemplate)
      )
    })
  }

  handleCloseModalDialogConfirm () {
    if (this.entry.custom.isModifiedSubform) {
      Modal.confirm({
        onOk: () => this.handleCloseModalDialog(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.handleCloseModalDialog()
    }
  }

  updateArrayData (data) {
    this.entry.custom.isModified = true
    this.props.entry.data.routes = data

    this.setState({
      entry: this.props.entry
    })
  }

  render () {
    return (
      <Row>
        <Col span={24}>
          <ListViewDND
            appId={Enums.APP_IDS.CONNECTORS}
            btnCreateLabel='Create New'
            viewTitle='Connector Routes'
            createRecord={this.createRecord}
            editRecord={this.editRecord}
            deleteRecord={this.deleteRecord}
            filterViewData={this.props.filterViewData}
            viewData={this.state.entry.data.routes}
            viewColumns={columnTemplates.connectorRoutes}
            theme={this.props.theme}
            callback={this.updateArrayData}
          />
          <Modal
            title='Route Profile'
            okButtonProps={{
              style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' }
            }}
            okText='Ok'
            visible={this.state.dialogOpen}
            onOk={this.handleValidateRecord}
            onCancel={this.handleCloseModalDialogConfirm}
            width={1000}
            style={{ top: 10 }}
            destroyOnClose
            maskClosable={false}
          >
            <ConnectorsRouteForm
              entry={this.state.activeRecord}
              tabKey={this.props.tabKey}
              connectionType={this.state.entry.data.connectionType}
              theme={this.props.theme}
            />
          </Modal>
        </Col>
      </Row>
    )
  }
}

export default ConnectorsFormRoutes
