import toLower from 'lodash/toLower'
import MemoryStore from '../utils/memory-store'

export const filterViewData = (viewData, searchFilter) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return viewData
  } else {
    return viewData.filter(
      t =>
        t.firstName.toLowerCase().indexOf(searchFilter) > -1 ||
        t.description.toLowerCase().indexOf(searchFilter) > -1
    )
  }
}

export const formatRecord = (entry, data) => {
  let tempIndex = 0

  entry.firstName = toLower(entry.firstName.split(' ').join(''))
  entry.description = entry.description.trim()

  entry.teamRights.isActiveDescription = entry.teamRights.isActive ? 'Yes' : 'No'

  if (entry.custom.isNewDoc) {
    // Add new Entry to State
    entry.custom.isNewDoc = false
    entry.custom.action = 'create'
    data.push(entry)
  } else {
    if (entry.custom.isSavedDoc) {
      entry.custom.action = 'update'
    } else {
      entry.custom.action = 'create'
    }

    // Update Entry in State
    tempIndex = data.findIndex(t => t._id === entry._id)
    data[tempIndex] = entry
  }

  return data
}

export const generateApiKey = (id, callback) => {
  MemoryStore.agilite.ApiKeys.generateApiKey(id)
    .then((response) => {
      callback(null, response)
    })
    .catch((error) => {
      callback(error)
    })
}

export const resetApiKeys = (id, callback) => {
  MemoryStore.agilite.ApiKeys.resetApiKeys(id)
    .then((response) => {
      callback(null, response)
    })
    .catch((error) => {
      callback(error)
    })
}

export const disableApiKey = (id, callback) => {
  MemoryStore.agilite.ApiKeys.disableApiKey(id)
    .then((response) => {
      callback(null, response)
    })
    .catch((error) => {
      callback(error, null)
    })
}

export const enableApiKey = (id, callback) => {
  MemoryStore.agilite.ApiKeys.enableApiKey(id)
    .then((response) => {
      callback(null, response)
    })
    .catch((error) => {
      callback(error)
    })
}
