import React from 'react'
import { Col, Row, message, Spin } from 'antd'

import { exportAllData } from '../../../core/core-utils'
import ImportExportContainer from '../../../reusables/containers/import-export-container'

class AdminExport extends React.Component {
  constructor () {
    super()

    this.state = {
      showSpin: false
    }

    this.handleValidateExport = this.handleValidateExport.bind(this)
    this.export = this.export.bind(this)
  }

  handleValidateExport (data, callback) {
    const obj = data.checked
    const falseValue = false
    let itemsChecked = 0

    if (data.filterBySolutions && data.solutions.length === 0) {
      message.error('Please provide at least 1 solution when filtering by solution(s)')
      callback(falseValue)
    } else {
      Object.keys(obj).forEach(e => {
        if (obj[e] === true) itemsChecked++
      })

      if (itemsChecked <= 0) {
        message.error('Please select at least 1 module to export')
        callback(falseValue)
      } else {
        this.export(data, callback)
        this.setState({ showSpin: true })
      }
    }
  }

  export (data, callback) {
    const trueValue = true
    const falseValue = false
    let msg = 'Modules successfully exported'

    exportAllData(data, (err, result) => {
      if (err) {
        msg = 'An error occured when exporting data'
        message.error(msg)
        this.setState({ showSpin: false })
        callback(falseValue)
        return
      }

      message.success(msg)
      this.setState({ showSpin: false })
      callback(trueValue)
    })
  }

  render () {
    return (
      <Row type='flex' justify='center'>
        <Col xs={23} lg={12}>
          <div style={{ marginTop: 10 }}>
            {this.state.showSpin ?
              <Spin />
              : null}
          </div>
          <ImportExportContainer
            onValidate={this.handleValidateExport}
            cardTitle='Administration - Export Data'
            leftColumnTitle='Export Options'
            rightColumnTitle='Modules To Export'
            buttonLabel='Export Data to JSON'
          />
        </Col>
      </Row>
    )
  }
}

export default AdminExport
