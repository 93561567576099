import React from 'react'
import { Form, Input } from 'antd'
import CompileTemplateIcon from '../../../../../reusables/components/compile-template-icon'
import MemoryStore from '../../../../../utils/memory-store'

class SFTPRoute extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      entry: props.entry.routeData
    }
  }

  onFieldChange (key, value) {
    this.props.entry.routeData[key] = value.trim()
  }

  render () {
    return (
      <>
        {this.props.routeType === '0' ||
          this.props.routeType === '1' ||
          this.props.routeType === '2' ||
          this.props.routeType === '3' ||
          this.props.routeType === '5' ||
          this.props.routeType === '6' ||
          this.props.routeType === '7' ||
          this.props.routeType === '9' ||
          this.props.routeType === '10' ? (
          <>
            {this.props.routeType === '2' || this.props.routeType === '3' ? (
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>
                {'File Id '}
                <CompileTemplateIcon />
                <Input
                  name='file_id'
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  placeholder='Provide the Agilit-e File Id'
                  defaultValue={this.state.entry.fileId}
                  onChange={e => {
                    this.onFieldChange('fileId', e.target.value)
                  }}
                />
              </Form.Item>
            ) : null}
            <Form.Item>
              <span style={{ color: 'red' }}>* </span>
              {'Path '}
              <CompileTemplateIcon />
              <Input
                name='path'
                placeholder={'e.g \'/\' or \'folderName/\''}
                disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                defaultValue={this.state.entry.path}
                onChange={e => {
                  this.onFieldChange('path', e.target.value)
                }}
              />
            </Form.Item>
            {this.props.routeType === '1' ||
              this.props.routeType === '2' ||
              this.props.routeType === '3' ||
              this.props.routeType === '5' ||
              this.props.routeType === '9' ||
              this.props.routeType === '10' ?
              (
                <Form.Item>
                  <span style={{ color: 'red' }}>* </span>
                  {'File Name '}
                  <CompileTemplateIcon />
                  <Input
                    name='file_name'
                    placeholder='e.g. textFile.txt'
                    disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                    defaultValue={this.state.entry.fileName}
                    onChange={e => {
                      this.onFieldChange('fileName', e.target.value)
                    }}
                  />
                </Form.Item>
              ) : null}
          </>
        ) : null}
        {this.props.routeType === '4' ?
          (
            <>
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>
                {'Old Path '}
                <CompileTemplateIcon />
                <Input
                  name='old_path'
                  placeholder={'e.g \'/\' or \'folderName/\''}
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  defaultValue={this.state.entry.oldPath}
                  onChange={e => {
                    this.onFieldChange('oldPath', e.target.value)
                  }}
                />
              </Form.Item>
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>
                {'Old File Name '}
                <CompileTemplateIcon />
                <Input
                  name='old_file_name'
                  placeholder='e.g. oldFile.txt'
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  defaultValue={this.state.entry.oldFileName}
                  onChange={e => {
                    this.onFieldChange('oldFileName', e.target.value)
                  }}
                />
              </Form.Item>
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>
                {'New Path '}
                <CompileTemplateIcon />
                <Input
                  name='new_path'
                  placeholder={'e.g \'/\' or \'folderName/\''}
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  defaultValue={this.state.entry.newPath}
                  onChange={e => {
                    this.onFieldChange('newPath', e.target.value)
                  }}
                />
              </Form.Item>
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>
                {'New File Name '}
                <CompileTemplateIcon />
                <Input
                  name='new_file_name'
                  placeholder='e.g. newFile.txt'
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  defaultValue={this.state.entry.newFileName}
                  onChange={e => {
                    this.onFieldChange('newFileName', e.target.value)
                  }}
                />
              </Form.Item>
            </>
          ) : null}
      </>
    )
  }
}

export default SFTPRoute
