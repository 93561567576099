import toLower from 'lodash/toLower'
import axios from 'agilite-utils/axios'

import MemoryStore from '../utils/memory-store'
import NodeRedFormContainer from '../node-red/containers/node-red-form-container'
import Enums from '../utils/enums'

import Config from '../utils/config'

export const filterViewData = (viewData, searchFilter) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return viewData
  } else {
    return viewData.filter(
      t =>
        t.data.name.toLowerCase().indexOf(searchFilter) > -1 ||
        t.data.key.toLowerCase().indexOf(searchFilter) > -1 ||
        t.data.description.toLowerCase().indexOf(searchFilter) > -1
    )
  }
}

export const formatRecord = (entry, data) => {
  let tempIndex = 0

  entry.data.key = toLower(entry.data.key.split(' ').join(''))
  entry.data.description = entry.data.description.trim()

  if (entry.data.isActive !== undefined) {
    entry.data.isActiveDescription = entry.data.isActive ? 'Active' : 'Disabled'
  }

  if (entry.custom.isNewDoc) {
    // Add new Entry to State
    entry.custom.isNewDoc = false
    entry.custom.action = 'create'
    data.push(entry)
  } else {
    if (entry.custom.isSavedDoc) {
      entry.custom.action = 'update'
    } else {
      entry.custom.action = 'create'
    }

    // Update Entry in State
    tempIndex = data.findIndex(t => t._id === entry._id)
    data[tempIndex] = entry
  }

  return data
}

export const restartFlows = () => {
  const axiosConfig = {
    url: `${Config.nodeRedUrl}/flows`,
    method: Enums.REQ_TYPE.POST,
    headers: {
      'Node-RED-Deployment-Type': 'reload'
    }
  }

  axios.request(axiosConfig)
    .catch(err => {
      console.log(err)
    })
}

export const createCopy = (currEntryData, callback) => {
  const newEntry = JSON.parse(JSON.stringify(MemoryStore.nodered.dataTemplate))
  newEntry._id = new Date().getTime().toString()
  newEntry.custom.tempId = newEntry._id

  const entryData = JSON.parse(JSON.stringify(currEntryData))
  const tabType = Enums.VALUES_STRINGS.FORM
  const content = NodeRedFormContainer
  const closable = true
  const key = newEntry._id
  const app = Enums.APP_IDS.NODE_RED

  let title = Enums.APP_PROFILE_TITLES.nodered + Enums.VALUES_STRINGS.COLON + Enums.VALUES_STRINGS.SPACE
  let payload = {}

  // create unique key
  entryData.key = currEntryData.key + '_copy'
  entryData.name = currEntryData.name + ' (Copy)'

  newEntry.data = entryData

  title += entryData.key

  payload = {
    content,
    title,
    key,
    app,
    tabType,
    closable
  }

  MemoryStore.activeEntries[newEntry._id] = newEntry

  callback(payload)
}
