import React from 'react'
import { Button, Row, Col } from 'antd'
import AgiliteHeader from '../../reusables/components/agilite-header'

// TODO: The confirmation components have pretty much the same thing in common, except for button wording. Consider consolidating components into 1
// TODO: Use Enums where relevant
// TODO: Replace color codes with theme
const CoreForgotPasswordConfirmation = () => (
  <div>
    <Row type='flex' justify='center'>
      <Col xs={23} sm={23} md={16} lg={12} xl={10} xxl={8}>
        <center>
          <AgiliteHeader
            header='Password Reset Request Submitted'
            subheader='Your password reset request has been submitted. You will receive a notification shortly'
          />
          <Button
            style={{
              backgroundColor: '#67AD5B',
              color: 'white',
              width: 'auto'
            }}
            onClick={e => {
              e.preventDefault()
              const port = window.location.port !== '' ? ':' + window.location.port : ''
              window.open(window.location.protocol + '//' + window.location.hostname + port, '_self')
            }}
          >
            Return to Home Page
          </Button>
        </center>
      </Col>
    </Row>
  </div>
)

export default CoreForgotPasswordConfirmation
