import React, { useState, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ReCAPTCHA from 'react-google-recaptcha'
import Config from '../../utils/config'

import MemoryStore from '../../utils/memory-store'
import Enums from '../../utils/enums'
import { Form, Input, Button, Row, Col, Spin, Select, Card, Alert, List, message } from 'antd'
import AgiliteHeader from '../../reusables/components/agilite-header'
import PasswordValidationItem from '../../reusables/components/password-validation-item'

const FormItem = Form.Item
const Option = Select.Option

const data = [
  {
    property: 'min',
    description: 'Minimum of 8 characters',
    complete: false
  },
  {
    property: 'symbols',
    description: 'Contains a symbol',
    complete: false
  },
  {
    property: 'digits',
    description: 'Contains a number',
    complete: false
  },
  {
    property: 'uppercase',
    description: 'Contains an uppercase letter',
    complete: false
  },
  {
    property: 'password',
    description: 'Passwords must match',
    complete: false
  }
]

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 10 },
    lg: { span: 10 },
    xl: { span: 8 },
    xxl: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 },
    lg: { span: 14 },
    xl: { span: 16 },
    xxl: { span: 16 }
  }
}

const CoreRegister = ({ validatePassword, registerUser, verifyRecaptchaToken, verifyRecaptchaTokenv2 }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [alertMessage, setAlertMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [lowScore, setLowScore] = useState(null)
  const [registerDisabled, setRegisterDisabled] = useState(true)
  const [passwordValid, setPasswordValid] = useState({ value: false })
  // eslint-disable-next-line
  const [membershipType, setMembershipType] = useState(MemoryStore.registerForm.membershipType)
  const [registerClicked, setRegisterClicked] = useState(false)

  const entry = MemoryStore.registerForm
  const reCaptchaEnabled = Config.reCaptcha?.enabled || false

  const onChange = (key, value) => {
    switch (key) {
      case 'inputFirstName':
        entry.firstName = value
        break
      case 'inputLastName':
        entry.lastName = value
        break
      case 'inputEmail':
        entry.email = value
        break
      case 'inputTeamId':
        entry.teamId = value
        break
      case 'inputPassword':
        entry.password = value

        validatePassword(data, entry.password, (result) => {
          result.map(element => {
            data.map(obj => {
              if (obj.property === element) {
                obj.complete = false
              }
              return true
            })
            return true
          })

          // Check if passwords match
          if (entry.password === entry.passwordConfirm && entry.password !== '') {
            data[4].complete = true
          } else {
            data[4].complete = false
          }

          if (result.length === 0 && entry.passwordConfirm === entry.password) {
            setPasswordValid(JSON.parse(JSON.stringify({ value: true })))
          } else {
            setPasswordValid(JSON.parse(JSON.stringify({ value: false })))
          }
        })

        break
      case 'inputPasswordConfirm':
        entry.passwordConfirm = value

        // Check if passwords match
        if (entry.passwordConfirm === entry.password && entry.passwordConfirm !== '') {
          data[4].complete = true
          setPasswordValid(JSON.parse(JSON.stringify({ value: true })))
        } else {
          data[4].complete = false
          setPasswordValid(JSON.parse(JSON.stringify({ value: false })))
        }

        break
      case 'inputMembershipType':
        entry.membershipType = value
        setMembershipType(value)
        break
      default:
    }
  }

  useEffect(() => {
    if (reCaptchaEnabled) {
      executeRecaptcha('register')
        .then(result => {
          verifyRecaptchaToken(result, (res, err) => {
            if (err) {
              setLowScore(true)

              if (err.response) {
                message.error(err.response.data.errorMessage)
              } else {
                message.error(Enums.MESSAGES.UNKNOWN_ERROR)
              }
              return
            }

            if (res.data.success) {
              if (res.data.score < 0.5) {
                setLowScore(true)
              } else {
                setLowScore(false)
                setRegisterDisabled(false)
              }
            } else {
              setLowScore(true)
            }
          })
        })
        .catch(err => {
          setLowScore(true)
          console.log(err)
        })
    } else {
      setRegisterDisabled(false)
    }
    // eslint-disable-next-line
  }, [])

  const verifyRecaptcha = (token) => {
    verifyRecaptchaTokenv2(token, (res, err) => {
      if (err) {
        if (err.response) {
          message.error(err.response.data.errorMessage)
        } else {
          message.error(Enums.MESSAGES.UNKNOWN_ERROR)
        }
        return
      }

      if (res.data.success) {
        setRegisterDisabled(false)
      } else {
        message.error('reCaptcha Verification Failed')
      }
    })
  }

  return (
    <div>
      <Row type='flex' justify='center'>
        <Col xs={23} sm={23} md={16} lg={12} xl={10} xxl={8}>
          <div>
            <center>
              <AgiliteHeader header={Enums.VALUES_STRINGS.AGILITE_WELCOME} />
            </center>
          </div>
          <Card
            title='Use the below form to register with Agilit-e'
            type='inner'
          >
            <Form
              className='login-form'
            >
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    <span style={{ color: 'red' }}>*</span> First Name
                  </span>
                }
              >
                <Input
                  type='text'
                  required
                  onChange={e => {
                    onChange('inputFirstName', e.target.value)
                  }}
                />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    <span style={{ color: 'red' }}>*</span> Last Name
                  </span>
                }
              >
                <Input
                  required
                  type='text'
                  onChange={e => {
                    onChange('inputLastName', e.target.value)
                  }}
                />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    <span style={{ color: 'red' }}>*</span> Membership Type
                  </span>
                }
              >
                <Select
                  defaultValue='1'
                  onChange={e => {
                    onChange('inputMembershipType', e.target.value)
                  }}
                >
                  <Option value='1'>Indie Spark Plan</Option>
                </Select>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    <span style={{ color: 'red' }}>*</span> Team ID
                  </span>
                }
              >
                <Input
                  required
                  type='text'
                  onChange={e => {
                    onChange('inputTeamId', e.target.value)
                  }}
                />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    <span style={{ color: 'red' }}>*</span> Email Address
                  </span>
                }
              >
                <Input
                  required
                  type='email'
                  onChange={e => {
                    onChange('inputEmail', e.target.value)
                  }}
                />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    <span style={{ color: 'red' }}>*</span> Password
                  </span>
                }
              >
                <Input.Password
                  required
                  type='password'
                  onChange={e => {
                    onChange('inputPassword', e.target.value)
                  }}
                />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    <span style={{ color: 'red' }}>*</span> Confirm Password
                  </span>
                }
              >
                <Input.Password
                  required
                  type='password'
                  onChange={e => {
                    onChange('inputPasswordConfirm', e.target.value)
                  }}
                />
              </FormItem>
              <Row type='flex' justify='center'>
                <Col>
                  <FormItem>
                    <List
                      dataSource={data}
                      renderItem={item => (
                        <PasswordValidationItem item={item} />
                      )}
                    />
                  </FormItem>
                  <FormItem>
                    <Button
                      disabled={!passwordValid.value || registerDisabled}
                      style={{
                        backgroundColor: '#67AD5B',
                        color: 'white',
                        width: 'auto',
                        marginRight: 10
                      }}
                      onClick={() => {
                        setRegisterClicked(true)
                        registerUser((successful, message) => {
                          if (successful === false) {
                            setRegisterClicked(false)
                            setAlertMessage(message)
                            setShowAlert(true)
                          } else {
                            setRegisterClicked(false)
                            setAlertMessage('')
                            setShowAlert(false)
                          }
                        })
                      }}
                    >
                      Register
                    </Button>
                    <Button
                      style={{
                        backgroundColor: '#EB5181',
                        color: 'white',
                        width: 'auto'
                      }}
                      onClick={e => {
                        e.preventDefault()
                        const port = window.location.port !== '' ? ':' + window.location.port : ''
                        window.open(window.location.protocol + '//' + window.location.hostname + port, '_self')
                      }}
                    >
                      Cancel
                    </Button>
                  </FormItem>
                  {lowScore ?
                    <div style={{ marginTop: 10 }}>
                      <ReCAPTCHA
                        sitekey={Config.reCaptcha.v2_siteKey}
                        onChange={verifyRecaptcha}
                      />
                    </div>
                    : null}
                  {showAlert ? (
                    <div>
                      <Alert
                        style={{ marginTop: 10, marginBottom: 10 }}
                        message={alertMessage}
                        closable={false}
                        type='error'
                      />
                    </div>
                  ) : null}
                  {registerClicked === true ? (
                    <div className='spin'>
                      <Spin size='large' />
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Form>
          </Card>
          {reCaptchaEnabled ?
            <Card style={{ textAlign: 'center', marginTop: 10 }}>
              This site is protected by reCAPTCHA and the Google
              <a href='https://policies.google.com/privacy' rel='noopener noreferrer' target='_blank'> Privacy Policy</a> and
              <a href='https://policies.google.com/terms' rel='noopener noreferrer' target='_blank'> Terms of Service</a> apply.
            </Card>
            : 
            null
          }
        </Col>
      </Row>
    </div>
  )
}

export default CoreRegister
