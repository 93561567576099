import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Button, message, Spin, Skeleton, Empty } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Cell, LabelList } from 'recharts'

import AgiliteTheme from '../../utils/agilite-theme'
import Enums from '../../utils/enums'
import agiliteLogo from '../../images/agilite-logo-full-web.png'
import { generateReport } from '../core-actions'
import { handleSwitchMenu } from '../core-utils'
import MemoryStore from '../../utils/memory-store'

const CoreTabHome = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    chartData: [],
    teamInfo: {
      totalAPIKeys: 0,
      totalProfiles: 0,
      totalTeamUsers: 0,
      membershipType: ''
    }
  })
  const colors = [
    '#0000ff',
    '#ff0000',
    '#006400',
    '#9acd32',
    '#66cdaa',
    '#191970',
    '#ff8c00',
    '#dda0dd',
    '#ba55d3',
    '#800000',
    '#fa8072',
    '#ffd700',
    '#ff1493',
    '#87cefa',
    '#dc143c'
  ]

  const TriangleBar = (props) => {
    const {
      fill, x, y, width, height
    } = props

    return <path d={getPath(x, y, width, height)} stroke='none' fill={fill} />
  }

  const getPath = (x, y, width, height) => `M${x},${y + height}
    C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + 2 * width / 3},${y + height} ${x + width}, ${y + height}
    Z`

  useEffect(() => {
    setLoading(true)

    generateReport((err, res) => {
      setLoading(false)
      if (err) return message.error(err)
      setData(res)
    })
  }, [])

  return (
    <div style={{ background: '#fff' }}>
      <Row justify='center'>
        <Col span={23}>
          <Card style={{ textAlign: 'center' }}>
            <Card
              type='inner'
              title={<span style={{ color: AgiliteTheme.primary }}>Team Details</span>}
              style={{ textAlign: 'left', width: '20%', minWidth: '320px' }}
              loading={loading}
            >
              <Row justify='space-between'>
                <Col>
                  <span style={{ fontSize: 16 }}>Total Profiles:</span><br />
                  <span style={{ fontSize: 16 }}>Total Team Users:</span><br />
                  <span style={{ fontSize: 16 }}>Total API Keys:</span><br />
                  <span style={{ fontSize: 16 }}>Membership Type:</span>
                </Col>
                <Col>
                  <span style={{ fontSize: 16, color: AgiliteTheme.primary }}>{data.teamInfo.totalProfiles}</span><br />
                  <span style={{ fontSize: 16, color: AgiliteTheme.primary }}>{data.teamInfo.totalTeamUsers}</span><br />
                  <span style={{ fontSize: 16, color: AgiliteTheme.primary }}>{data.teamInfo.totalAPIKeys}</span><br />
                  <span style={{ fontSize: 16, color: AgiliteTheme.primary }}>{data.teamInfo.membershipType}</span>
                </Col>
              </Row>
            </Card>
            <img src={agiliteLogo} style={{ width: 300, position: 'absolute', top: 5, left: '42%' }} alt='Agilit-e' />
            <h1 style={{ color: AgiliteTheme.secondaryDark, position: 'absolute', top: '55%', left: '39%' }}>{Enums.VALUES_STRINGS.AGILITE_WELCOME}</h1>
          </Card>
        </Col>
      </Row>
      <br />
      <Row justify='center'>
        <Col span={23}>
          <Card
            title={<h2>Admin Portal Dashboard</h2>}
            type='inner'
            size='small'
            extra={
              <Button
                disabled={loading}
                style={{ borderRadius: 5, position: 'relative' }}
                onClick={() => {
                  setLoading(true)

                  generateReport((err, res) => {
                    setLoading(false)
                    if (err) return message.error(err)
                    setData(res)
                  })
                }}
              >
                <SyncOutlined style={{ fontSize: 15, color: AgiliteTheme.successColor }} />
              </Button>
            }
          >
            {loading || data.chartData.length === 0 ?
              <>
                {loading ?
                  <Skeleton active loading />
                  :
                  <Empty />
                }
              </>
              :
              <Row justify='center'>
                <Col span={24}>
                  <Card title={<div style={{ textAlign: 'left' }}>Module Summary Report (Total Profiles Per Module)</div>} size='small'>
                    <ResponsiveContainer height={313}>
                      <BarChart
                        data={data.chartData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                      >
                        <XAxis dataKey='name' />
                        <YAxis />
                        <Bar
                          className='home-chart-bar'
                          barSize={60} dataKey='total'
                          shape={<TriangleBar />}
                          onClick={(e) => {
                            if (MemoryStore.userProfile.teamPrivileges[e.appId] !== '') {
                              handleSwitchMenu({ key: e.appId })
                            }
                          }}
                          isAnimationActive={false}
                        >
                          <LabelList dataKey='total' position='top' />
                          {
                            data.chartData.map((entry, index) => {
                              const color = colors[index]
                              return <Cell key={index} fill={color} stroke='black' style={{ opacity: 0.5 }} strokeWidth={1} />
                            })
                          }
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </Card>
                </Col>
              </Row>
            }
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default CoreTabHome
