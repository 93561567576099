import { connect } from 'react-redux'
import { filterActionsViewData } from '../../batch-actions/batch-actions-utils'
import BatchActions from '../components/batch-actions'

const mapStateToProps = state => {
  return {
    theme: state.core.theme,
    state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    filterViewData: (data, searchFilter, callback) => {
      filterActionsViewData(data, searchFilter, callback)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BatchActions)
