import React from 'react'
import { Button, Row, Col } from 'antd'
import AgiliteHeader from '../../reusables/components/agilite-header'

const CoreTeamActivateSuccess = () => (
  <div>
    <Row type='flex' justify='center'>
      <Col xs={23} sm={23} md={16} lg={12} xl={10} xxl={8}>
        <center>
          <AgiliteHeader
            header='Account Activation Successful'
            subheader='Your account has been successfully activated. Click below to Sign In'
          />
          <Button
            style={{
              backgroundColor: '#67AD5B',
              color: 'white',
              width: 'auto'
            }}
            onClick={e => {
              e.preventDefault()
              const port =
                window.location.port !== '' ? ':' + window.location.port : ''
              window.open(
                window.location.protocol +
                  '//' +
                  window.location.hostname +
                  port,
                '_self'
              )
            }}
          >
            Sign In
          </Button>
        </center>
      </Col>
    </Row>
  </div>
)

export default CoreTeamActivateSuccess
