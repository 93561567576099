import React from 'react'
import { Form, Input, Select } from 'antd'
import CompileTemplateIcon from '../../../../../../reusables/components/compile-template-icon'
import TableKeyTypeValueEditableDnd from '../../../../../../reusables/components/table-key-type-value-editable-dnd'
import TableKeyTypeEditableDnd from '../../../../../../reusables/components/table-key-type-editable-dnd'
import MemoryStore from '../../../../../../utils/memory-store'

import 'brace/mode/sql'
import 'brace/theme/xcode'

class MSSQLRouteExecuteStoredProcedure extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.selectOptions = [
      { key: 'string', value: 'String' },
      { key: 'number', value: 'Number' },
      { key: 'boolean', value: 'Boolean' },
      { key: 'date', value: 'Date' },
      { key: 'buffer', value: 'Buffer' },
      { key: 'table', value: 'SQL.Table' }
    ]

    this.state = {
      entry: props.entry.routeData,
      selectInputOptions: null,
      selectOutputOptions: null
    }

    // Bindings
    this.updateSelectOptions = this.updateSelectOptions.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.updateInputs = this.updateInputs.bind(this)
    this.updateOutputs = this.updateOutputs.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.updateSelectOptions()

    if (this.props.entry.routeData.inputs === undefined) {
      this.props.entry.routeData.inputs = []
    }

    if (this.props.entry.routeData.outputs === undefined) {
      this.props.entry.routeData.outputs = []
    }
  }

  updateSelectOptions () {
    let tmpOptions = null

    tmpOptions = this.selectOptions.map(entry => {
      return (
        <Select.Option key={entry.key} value={entry.key}>
          {entry.value}
        </Select.Option>
      )
    })

    this.setState({
      selectInputOptions: tmpOptions,
      selectOutputOptions: tmpOptions
    })
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value.trim()
  }

  updateInputs (data) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData.inputs = data
  }

  updateOutputs (data) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData.outputs = data
  }

  render () {
    return (
      <>
        <Form.Item>
          <span style={{ color: 'red' }}>* </span>
          {'Procedure Name '}
          <CompileTemplateIcon />
          <Input
            name='procedure_name'
            placeholder='Name of Stored Procedure'
            disabled={this.props.disabled}
            defaultValue={this.state.entry.name}
            onChange={e => {
              this.onFieldChange('name', e.target.value)
            }}
          />
        </Form.Item>
        <h3>
          Inputs <CompileTemplateIcon />
        </h3>
        <Form.Item>
          <TableKeyTypeValueEditableDnd
            values={this.props.entry.routeData.inputs}
            selectOptions={this.state.selectInputOptions}
            columnTitle='Key'
            theme={this.props.theme}
            privileges={MemoryStore.userProfile.teamPrivileges.connectors}
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            callback={data => this.updateInputs(data)}
          />
        </Form.Item>
        <h3>
          Outputs <CompileTemplateIcon />
        </h3>
        <Form.Item>
          <TableKeyTypeEditableDnd
            values={this.props.entry.routeData.outputs}
            selectOptions={this.state.selectOutputOptions}
            columnTitle='Key'
            theme={this.props.theme}
            privileges={MemoryStore.userProfile.teamPrivileges.connectors}
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            callback={data => this.updateOutputs(data)}
          />
        </Form.Item>
      </>
    )
  }
}

export default MSSQLRouteExecuteStoredProcedure
