import React, { Component } from 'react'
import { Col, Form, AutoComplete, Switch, message } from 'antd'
import TooltipIcon from '../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../utils/enums-tooltips'
import Description from '../../reusables/components/description'
import GroupNameContainer from '../../reusables/containers/group-name-container'
import SolutionsContainer from '../../reusables/containers/solutions-container'
import MemoryStore from '../../utils/memory-store'

import { getLinkedProfiles } from '../events-utils'
import orderBy from 'lodash/orderBy'

class EventsFormGeneral extends Component {
  constructor (props) {
    super(props)

    this.entry = props.entry

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc,
      eventKeys: [{ label: 'batch-logging-init', value: 'batch-logging-init' }, { label: 'batch-logging-complete', value: 'batch-logging-complete' }],
      key: this.entry.data.key,
      linkedProfiles: []
    }
  }

  UNSAFE_componentWillMount () {
    let tmpArray = []

    getLinkedProfiles((err, result) => {
      if (err) {
        if (err.response.data.errorMessage) {
          message.error(err.response.data.errorMessage)
        }
      } else {
        result.data.map(entry => {
          tmpArray.push({ label: entry.data.key, value: entry.data.key })

          return null
        })

        tmpArray = orderBy(tmpArray, null, 'asc')

        this.setState({ linkedProfiles: tmpArray })
      }
    })
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.entry.data[key] = value
  }

  render () {
    return (
      <Col xs={23} lg={20} xl={14}>
        <div>
          <Form.Item>
            <Switch
              ref='isActive'
              disabled={MemoryStore.userProfile.teamPrivileges.events === 'Reader'}
              checkedChildren='Active'
              unCheckedChildren='Inactive'
              defaultChecked={this.entry.data.isActive}
              onChange={(e) => {
                this.onFieldChange('isActive', e)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Event Key '}
            <TooltipIcon title={EnumsTooltips.general.profileKey} />
            <AutoComplete
              className='key'
              options={this.state.eventKeys}
              placeholder='Provide a unique Event Key'
              disabled={!this.state.isNewDoc || MemoryStore.userProfile.teamPrivileges.events === 'Reader'}
              defaultValue={this.state.key}
              onChange={value => {
                this.setState({ key: value })
                this.onFieldChange('key', value)
              }}
              onSelect={value => {
                this.setState({ key: value })
                this.onFieldChange('key', value)
              }}
              required
            />
          </Form.Item>
          {this.state.key === 'batch-logging-init' || this.state.key === 'batch-logging-complete' ? (
            <Form.Item>
              <span style={{ color: 'red' }}>* </span>
              {'Linked Profile Key'}
              <AutoComplete
                className='key'
                options={this.state.linkedProfiles}
                placeholder='Provide a Linked Profile Key'
                disabled={!this.state.isNewDoc || MemoryStore.userProfile.teamPrivileges.events === 'Reader'}
                defaultValue={this.entry.data.linkedProfile}
                onChange={value => this.onFieldChange('linkedProfile', value)}
                onSelect={value => this.onFieldChange('linkedProfile', value)}
                required
              />
            </Form.Item>
          ) : null}
          <Description
            disabled={MemoryStore.userProfile.teamPrivileges.events === 'Reader'}
            description={this.entry.data.description}
            onChange={e => {
              this.onFieldChange('description', e.target.value)
            }}
          />
          <GroupNameContainer
            disabled={MemoryStore.userProfile.teamPrivileges.events === 'Reader'}
            defaultValue={this.entry.data.groupName}
            onValueChange={value => {
              this.onFieldChange('groupName', value)
            }}
            onValueSelect={value => {
              this.onFieldChange('groupName', value)
            }}
          />
          <SolutionsContainer
            disabled={MemoryStore.userProfile.teamPrivileges.events === 'Reader'}
            defaultValue={this.entry.data.solutions}
            onValueChange={(value) => {
              this.onFieldChange('solutions', value)
            }}
          />
        </div>
      </Col>
    )
  }
}

export default EventsFormGeneral
