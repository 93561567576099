import React from 'react'
import MemoryStore from '../../utils/memory-store'
import { Icon } from 'react-fa'
import { message, Form, Button, Col, Row, Card, Switch, Select, Input, Menu, Popconfirm, Dropdown, Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import GroupNameContainer from '../../reusables/containers/group-name-container'
import SolutionsContainer from '../../reusables/containers/solutions-container'
import CompileTemplateIcon from '../../reusables/components/compile-template-icon'
import TooltipIcon from '../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../utils/enums-tooltips'
import Description from '../../reusables/components/description'
import Enums from '../../utils/enums'

const MenuItem = Menu.Item
const Option = Select.Option

class NumberingForm extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc,
      isLoading: false,
      startAt: this.entry.data.startAt,
      minLength: this.entry.data.minLength
    }

    // Setup Event Binding
    this.resetPromptConfirm = this.resetPromptConfirm.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.saveProfile = this.saveProfile.bind(this)
    this.cancelProfile = this.cancelProfile.bind(this)
  }

  resetPromptConfirm (id) {
    const msg = 'Numbering Counters successfully reset'
    const tmpThis = this

    tmpThis.props.onResetNumberingCounter(id, function (err, result) {
      if (err) {
        if (err.response) {
          message.error(err.response.data.errorMessage)
        } else {
          message.error(err)
        }
      } else {
        message.success(msg)
      }
    })
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.entry.data[key] = value
  }

  handleNumberFieldValueChange (key, value) {
    if (value !== '') {
      try {
        value = parseInt(value)
      } catch (err) {
        message.error('This field can only contain number values from 1 to 99')
      }
    }

    switch (key) {
      case 'minLength':
        if (value <= 99 && value >= 1) {
          this.setState({
            [key]: value
          })
          this.onFieldChange(key, value)
        }
        break
      default:
        if (value.toString().length <= 16) {
          this.setState({
            [key]: value
          })
          this.onFieldChange(key, value)
        }
        break
    }
  }

  cancelProfileConfirm () {
    if (this.entry.custom.isModified) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  saveProfile () {
    const tmpThis = this

    tmpThis.setState({
      isLoading: true
    })

    // Reset isModified and isModifiedSubform properties
    this.entry.custom.isModified = false
    this.entry.custom.isModifiedSubform = false

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, (err) => {
      tmpThis.setState({
        isLoading: false
      })

      if (err) {
        message.error(err)
      } else {
        tmpThis.props.closeTab(tmpThis.props.tabKey, tmpThis.props.tabKey, tmpThis.props.tabs)
      }
    })
  }

  render () {
    const menu = (
      <Menu>
        <MenuItem key='0'>
          <Popconfirm
            title='Are you sure you want to reset all Numbering Counters for this Profile?'
            onConfirm={() => this.resetPromptConfirm(this.entry._id)}
            okText='Yes'
            cancelText='No'
          >
            <span style={{ padding: '10 0' }}>Reset Numbering Counters</span>
          </Popconfirm>
        </MenuItem>
      </Menu>
    )

    return (
      <Row type='flex' justify='center'>
        <Col xs={23} lg={20} xl={16}>
          <Card
            title={this.props.title}
            style={{ textAlign: 'left' }}
            type='inner'
            extra={!this.state.isNewDoc && MemoryStore.userProfile.teamPrivileges.numbering !== 'Reader' ?
              <Dropdown overlay={menu} trigger={['click']}>
                <Icon name='ellipsis-v' style={{ padding: 5, fontSize: 17, color: '#B4483E', cursor: 'pointer' }} />
              </Dropdown>
              : null}
          >
            <Form
              autoComplete='off'
            >
              <Row type='flex' justify='space-between'>
                <Col xs={24} lg={11}>
                  <Form.Item>
                    <Switch
                      cypressid='isActiveSwitch'
                      ref='isActive'
                      disabled={MemoryStore.userProfile.teamPrivileges.numbering === 'Reader'}
                      checkedChildren='Active'
                      unCheckedChildren='Inactive'
                      defaultChecked={this.entry.data.isActive}
                      onChange={(e) => {
                        this.onFieldChange('isActive', e)
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <span style={{ color: 'red' }}>* </span>{'Profile Key '}
                    <TooltipIcon title={EnumsTooltips.general.profileKey} />
                    <Input
                      cypressid='profileKey'
                      name='key'
                      placeholder='Provide a unique Profile Key'
                      disabled={!this.state.isNewDoc || MemoryStore.userProfile.teamPrivileges.numbering === 'Reader'}
                      defaultValue={this.entry.data.key}
                      onChange={(e) => {
                        this.onFieldChange('key', e.target.value)
                      }}
                      required
                    />
                  </Form.Item>
                  <Form.Item>
                    <span style={{ color: 'red' }}>* </span>{'Profile Name '}
                    <TooltipIcon title={EnumsTooltips.general.profileName} />
                    <Input
                      cypressid='profileName'
                      name='name'
                      placeholder='Provide a Profile Name'
                      disabled={MemoryStore.userProfile.teamPrivileges.numbering === 'Reader'}
                      defaultValue={this.entry.data.name}
                      onChange={(e) => {
                        this.onFieldChange('name', e.target.value)
                      }}
                      required
                    />
                  </Form.Item>
                  <Description
                    disabled={MemoryStore.userProfile.teamPrivileges.numbering === 'Reader'}
                    description={this.entry.data.description}
                    onChange={(e) => {
                      this.onFieldChange('description', e.target.value)
                    }}
                  />
                  <GroupNameContainer
                    disabled={MemoryStore.userProfile.teamPrivileges.numbering === 'Reader'}
                    defaultValue={this.entry.data.groupName}
                    onValueChange={(value) => {
                      this.onFieldChange('groupName', value)
                    }}
                    onValueSelect={(value) => {
                      this.onFieldChange('groupName', value)
                    }}
                  />
                  <SolutionsContainer
                    disabled={MemoryStore.userProfile.teamPrivileges.numbering === 'Reader'}
                    defaultValue={this.entry.data.solutions}
                    onValueChange={(value) => {
                      this.onFieldChange('solutions', value)
                    }}
                  />
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item style={{ marginTop: 67.5 }}>
                    {'Prefix (Optional) '}
                    <CompileTemplateIcon />
                    <Input
                      cypressid='prefix'
                      name='prefix'
                      placeholder='A prefix for the number generated'
                      disabled={MemoryStore.userProfile.teamPrivileges.numbering === 'Reader'}
                      defaultValue={this.entry.data.prefix}
                      onChange={(e) => {
                        this.onFieldChange('prefix', e.target.value)
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    {'Suffix (Optional) '}
                    <CompileTemplateIcon />
                    <Input
                      cypressid='suffix'
                      name='suffix'
                      placeholder='A suffix for the number generated'
                      disabled={MemoryStore.userProfile.teamPrivileges.numbering === 'Reader'}
                      defaultValue={this.entry.data.suffix}
                      onChange={(e) => {
                        this.onFieldChange('suffix', e.target.value)
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <span style={{ color: 'red' }}>* </span>Start Number At
                    <Input
                      cypressid='startNumberAt'
                      name='start'
                      placeholder='Start generating numbers at a certain value'
                      disabled={MemoryStore.userProfile.teamPrivileges.numbering === 'Reader'}
                      type='number'
                      value={this.state.startAt}
                      onChange={(e) => {
                        this.handleNumberFieldValueChange('startAt', e.target.value)
                      }}
                      required
                    />
                  </Form.Item>
                  <Form.Item>
                    <span style={{ color: 'red' }}>* </span>Minimum length of Number
                    <Input
                      cypressid='minLength'
                      name='length'
                      placeholder='A minimum amount of characters'
                      disabled={MemoryStore.userProfile.teamPrivileges.numbering === 'Reader'}
                      type='number'
                      value={this.state.minLength}
                      onChange={(e) => {
                        this.handleNumberFieldValueChange('minLength', e.target.value)
                      }}
                      required
                    />
                  </Form.Item>
                  <Form.Item>
                    Increment Number Based On
                    <Select
                      cypressid='incrementBasedOn'
                      ref='increment'
                      defaultValue={this.entry.data.incrementBasedOn}
                      disabled={MemoryStore.userProfile.teamPrivileges.numbering === 'Reader'}
                      autoWidth
                      onChange={(value) => {
                        this.onFieldChange('incrementBasedOn', value)
                      }}
                    >
                      <Option value=''>-None-</Option>
                      <Option value='1'>Prefix</Option>
                      <Option value='2'>Suffix</Option>
                      <Option value='3'>Both</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                {MemoryStore.userProfile.teamPrivileges.numbering !== 'Reader' ?
                  <Button
                    cypressid='submit'
                    name='submit'
                    disabled={this.state.isLoading}
                    style={{ backgroundColor: '#67AD5B', color: 'white', width: 80, marginRight: 10, float: 'left' }}
                    onClick={() => this.saveProfile()}
                  >
                    {this.state.isLoading ? <LoadingOutlined spin /> : <span>Submit</span>}
                  </Button>
                  : null}
                <Button
                  disabled={this.state.isLoading}
                  cypressid='cancel'
                  name='cancel'
                  label='Cancel'
                  onClick={e => {
                    e.preventDefault()
                    this.cancelProfileConfirm()
                  }}
                  style={{ backgroundColor: '#EB5181', color: 'white', width: 'auto', marginRight: 10, float: 'left' }}
                >
                  Cancel
                </Button>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default NumberingForm
