import React from 'react'
import { Icon } from 'react-fa'
import { DragSource, DropTarget } from 'react-dnd'
import { withDragDropContext } from './html5-backend'
import update from 'immutability-helper'
import { Table, Form, Input, Select, message, Tooltip, Modal } from 'antd'
import { TranslationOutlined } from '@ant-design/icons'
import { v1 } from 'agilite-utils/uuid'

import '../reusables-views.css'
import AgiliteTheme from '../../utils/agilite-theme'
import LanguageTranslation from './language-translation'
import MemoryStore from '../../utils/memory-store'

let isDraggable = true

function dragDirection (
  dragIndex,
  hoverIndex,
  initialClientOffset,
  clientOffset,
  sourceClientOffset
) {
  const hoverMiddleY = (initialClientOffset.y - sourceClientOffset.y) / 2
  const hoverClientY = clientOffset.y - sourceClientOffset.y

  if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
    return 'downward'
  }

  if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
    return 'upward'
  }
}

class BodyRow extends React.Component {
  render () {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      dragRow,
      clientOffset,
      sourceClientOffset,
      initialClientOffset,
      ...restProps
    } = this.props

    const style = {
      ...restProps.style,
      cursor: 'move'
    }

    let className = restProps.className

    if (isOver && initialClientOffset) {
      const direction = dragDirection(
        dragRow.index,
        restProps.index,
        initialClientOffset,
        clientOffset,
        sourceClientOffset
      )

      if (direction === 'downward') {
        className += ' drop-over-downward'
      }

      if (direction === 'upward') {
        className += ' drop-over-upward'
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    )
  }
}

const rowSource = {
  beginDrag (props) {
    return { index: props.index }
  },
  canDrag () {
    return isDraggable
  }
}

const rowTarget = {
  drop (props, monitor) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  }
}

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  sourceClientOffset: monitor.getSourceClientOffset()
}))(
  DragSource('row', rowSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    dragRow: monitor.getItem(),
    clientOffset: monitor.getClientOffset(),
    initialClientOffset: monitor.getInitialClientOffset()
  }))(BodyRow)
)

class DragSortingTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      typeAheadData: this.props.typeAheadLabelInput ? this.props.typeAheadLabelInputData : null,
      languageModalVisible: false
    }

    this.trashEnabled = null
    this.tmpIndex = null
    this.tmpDataEntry = {
      label: '',
      value: '',
      iln: {
        value: {}
      }
    }

    // Setup Event Binding
    this.moveRow = this.moveRow.bind(this)
    this.handleDataChange = this.handleDataChange.bind(this)
    this.handleDataSubmit = this.handleDataSubmit.bind(this)

    this.columns = [
      {
        title: this.props.columnTitle || 'Label',
        width: this.props.columnWidth || '33.3%',
        dataIndex: 'label',
        key: 'label',
        render: (text, record, index) => {
          if (this.props.typeAheadLabelInput) {
            return (
              <Form.Item>
                <Select
                  cypressid='label'
                  showSearch
                  style={{ width: '100%' }}
                  placeholder='Select a Language'
                  optionFilterProp='children'
                  onChange={value => {
                    if (value === this.state.data[index].label || this.state.data.filter(entry => value === entry.label).length > 0) {
                      message.error('The language you selected already exists')
                    } else {
                      this.changeRow(index, this.props.fields ? this.props.fields[0] : 'label', value)
                    }
                  }}
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  value={this.props.fields ? record[this.props.fields[0]] : record.label}
                  disabled={this.props.privileges === 'Reader' || record.readOnly}
                >
                  <Select.Option value=''>-Select-</Select.Option>
                  {this.props.typeAheadLabelInputData.map(entry => {
                    return <Select.Option key={entry.value} value={entry.value}>{entry.label}</Select.Option>
                  })}
                </Select>
              </Form.Item>
            )
          } else {
            return (
              <Form.Item>
                <Input
                  cypressid='label'
                  name='label'
                  placeholder={`Provide a ${this.props.columnTitle || 'Label'}`}
                  disabled={this.props.privileges === 'Reader' || record.readOnly}
                  value={this.props.fields ? record[this.props.fields[0]] : record.label}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    this.changeRow(index, this.props.fields ? this.props.fields[0] : 'label', e.target.value)
                  }}
                  onFocus={() => {
                    isDraggable = false
                  }}
                  onBlur={() => {
                    isDraggable = true
                  }}
                />
              </Form.Item>
            )
          }
        }
      }, {
        title: this.props.valueColumnTitle || 'Value',
        width: this.props.valueColumnWidth || '33.3%',
        dataIndex: 'value',
        key: 'value',
        render: (text, record, index) => {
          if (this.props.typeAheadLabelInput) {
            return (
              <Form.Item>
                <Input.TextArea
                  rows={2}
                  cypressid='value'
                  name='value'
                  placeholder='Provide value for the relevant language'
                  disabled={this.props.disabled || record.readOnly}
                  value={this.props.fields ? record[this.props.fields[1]] : record.value}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    this.changeRow(index, this.props.fields ? this.props.fields[1] : 'value', e.target.value)
                  }}
                  onFocus={() => {
                    isDraggable = false
                  }}
                  onBlur={() => {
                    isDraggable = true
                  }}
                />
              </Form.Item>
            )
          } else {
            return (
              <Form.Item>
                <Input
                  cypressid='value'
                  name='value'
                  placeholder={`Provide a ${this.props.valueColumnTitle || 'Value'}`}
                  disabled={this.props.disabled || record.readOnly}
                  value={this.props.fields ? record[this.props.fields[1]] : record.value}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    this.changeRow(index, this.props.fields ? this.props.fields[1] : 'value', e.target.value)
                  }}
                  onFocus={() => {
                    isDraggable = false
                  }}
                  onBlur={() => {
                    isDraggable = true
                  }}
                />
              </Form.Item>
            )
          }
        }
      },
      {
        title: 'Actions',
        width: '10%',
        dataIndex: 'actions',
        render: (text, record, index) => {
          return (
            <div style={{ minWidth: 50 }}>
              <div className={process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'}>
                {this.state.data.length > 1 && this.props.privileges !== 'Reader' && !record.readOnly ?
                  /* eslint-disable-next-line */
                  <a onClick={e => {
                    e.preventDefault()
                    this.deleteRow(index)
                  }}
                  >
                    <Icon
                      cypressid='delete'
                      name='trash-o'
                      style={{ color: AgiliteTheme.dangerColor }}
                      hidden={this.props.disabled}
                    />
                  </a>
                  :
                  <Icon
                    cypressid='delete'
                    name='trash-o'
                    hidden={this.props.disabled}
                  />}
              </div>
              {this.props.privileges !== 'Reader' ?
                <div style={{ marginLeft: 15 }} className={process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'}>
                  {/* eslint-disable-next-line */}
                  <a onClick={e => {
                    e.preventDefault()
                    this.addRow(index)
                  }}
                  >
                    <Icon
                      cypressid='add'
                      name='plus'
                      style={{ color: AgiliteTheme.successColor }}
                      hidden={this.props.disabled}
                    />
                  </a>
                </div>
                : null}
              {this.props.languageTranslationEnabled ?
                <div className={process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'}>
                  <Tooltip title='Add language alternatives for field value'>
                    <TranslationOutlined
                      onClick={() => {
                        this.tmpDataEntry = record
                        this.tmpIndex = index
                        this.setState({ languageModalVisible: true })
                      }}
                      style={{ color: 'blueviolet', fontSize: 16, marginLeft: 15 }}
                    />
                  </Tooltip>
                </div>
                : null}
            </div>
          )
        }
      }
    ]

    this.components = {
      body: {
        row: DragableBodyRow
      }
    }

    // Set Prop Names based on fields props that were passed
    if (this.props.fields) {
      this.param1 = this.props.fields[0]
      this.param2 = this.props.fields[1]
    } else {
      this.param1 = 'label'
      this.param2 = 'value'
    }

    if (props.values.length === 0) {
      const tmpObj = {}

      tmpObj.rowKey = v1()
      tmpObj[this.param1] = ''
      tmpObj[this.param2] = ''

      props.values.push(tmpObj)
    } else {
      props.values.map((entry, index) => {
        entry.rowKey = v1()
        return null
      })
    }

    this.state = {
      data: this.props.values
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({ data: nextProps.values })
  }

  generateTableTitle () {
    return (
      <h2>Key/Value Listing</h2>
    )
  }

  moveRow (dragIndex, hoverIndex) {
    const { data } = this.state
    const dragRow = data[dragIndex]

    this.setState(
      update(this.state, {
        data: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]]
        }
      })
    )

    return this.props.callback(this.state.data)
  }

  addRow (index) {
    const data = this.state.data
    const tmpIndex = index + 1
    const tmpObj = {}

    // Set Prop Names based on fields props that were passed
    if (this.props.fields) {
      this.param1 = this.props.fields[0]
      this.param2 = this.props.fields[1]
    } else {
      this.param1 = 'label'
      this.param2 = 'value'
    }

    tmpObj.rowKey = v1()
    tmpObj[this.param1] = ''
    tmpObj[this.param2] = ''

    const entry = tmpObj

    data.splice(tmpIndex, 0, entry)
    this.setState({ data })

    return this.props.callback(this.state.data)
  }

  deleteRow (index) {
    const data = this.state.data
    data.splice(index, 1)

    this.setState({ data })
    return this.props.callback(this.state.data)
  }

  changeRow (index, field, value, isLanguageTranslation) {
    const data = this.state.data

    if (isLanguageTranslation) {
      data[index].iln = {
        label: value
      }
    } else {
      data[index][field] = value
    }

    this.setState({ data })
    return this.props.callback(this.state.data)
  }

  handleDataChange (data) {
    this.changeRow(this.tmpIndex, 'choices', data, true)
  }

  handleDataSubmit () {
    this.setState({ languageModalVisible: false })
  }

  render () {
    return (
      <div>
        <Table
          id='table-key-value-editable-dnd'
          size='middle'
          bordered
          pagination={false}
          showHeader
          columns={this.columns}
          dataSource={Object.assign([], this.state.data)}
          components={this.components}
          onRow={(record, index) => ({
            index,
            moveRow: this.moveRow
          })}
          rowKey={record => record.rowKey}
        />
        {this.tmpDataEntry ?
          <Modal
            title='Language Translate'
            visible={this.state.languageModalVisible}
            onOk={this.handleDataSubmit}
            onCancel={() => this.setState({ languageModalVisible: false })}
            closable={false}
            width='50%'
            destroyOnClose
            okButtonProps={{
              style: {
                backgroundColor: '#67AD5B',
                color: 'white'
              }
            }}
            okText='Submit'
          >
            <LanguageTranslation
              theme={this.props.theme}
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              data={this.tmpDataEntry.iln ? this.tmpDataEntry.iln.label ? this.tmpDataEntry.iln.label : {} : {}}
              dataKey='label'
              defaultLanguage={MemoryStore.defaultLanguage}
              privileges={MemoryStore.userProfile.teamPrivileges.bpm}
              fieldValue={this.tmpDataEntry.label ? this.tmpDataEntry.label : ''}
              onDataChange={this.handleDataChange}
            />
          </Modal>
          : null}
      </div>
    )
  }
}

const TableKeyValueEditableDND = withDragDropContext(DragSortingTable)

export default TableKeyValueEditableDND
