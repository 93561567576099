import React from 'react'
import { Card, message, Form, Skeleton, Row, Col, Input } from 'antd'

class AdminAccount extends React.Component {
  constructor () {
    super()

    this.state = {
      showProgress: true,
      titleProgress: 'Account Details Loading...',
      titleDone: 'Account Details',
      data: {
        // TODO: This should just be data?
        totalTeams: '',
        totalApiCalls: '',
        activeMonth: '',
        membershipType: '',
        membershipTypeDescription: '',
        numberTeamsAllowed: '',
        numberTeamUsersAllowed: '',
        allowedApis: '',
        membershipRenewal: '',
        remainingApi: ''
      }
    }
  }

  componentDidMount () {
    this.props.adminFetchAccountData((err, response) => {
      if (err) {
        message.error('Error: Request Unsuccessful')
        this.setState({
          showProgress: false
        })
      } else if (response) {
        this.setState({
          showProgress: false,
          data: {
            // TODO: This should just pass data?
            totalTeams: response.data.totalTeams,
            totalApiCalls: response.data.apiCallsMade,
            activeMonth: response.data.activeMonth,
            membershipTypeDescription: response.data.membershipTypeDescription,
            membershipType: response.data.membershipType,
            numberTeamsAllowed: response.data.teamsAllowed,
            numberTeamUsersAllowed: response.data.teamsUsersAllowed,
            allowedApis: response.data.allowedApis,
            membershipRenewal: response.data.monthEnd,
            remainingApi: response.data.apisRemaining
          }
        })
      }
    })
  }

  render () {
    return (
      <Row type='flex' justify='center'>
        <Col xs={23} xl={12}>
          <Form>
            {!this.state.showProgress ? (
              <Card
                title={
                  this.state.showProgress
                    ? this.state.titleProgress
                    : this.state.titleDone
                }
                type='inner'
                headStyle={{ textAlign: 'left' }}
              >
                <div style={{ textAlign: 'left' }}>
                  <div className='account_form'>
                    <h2 style={{ color: 'gray', fontSize: '1em' }}>General</h2>
                    <Form.Item style={{ width: '50%' }}>
                      Total Teams Registered
                      <Input
                        readOnly
                        defaultValue={this.state.data.totalTeams}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ width: '50%' }}
                    >
                      Current Membership Type
                      <Input
                        readOnly
                        defaultValue={this.state.data.membershipTypeDescription}
                        // suffix={
                        //   // eslint-disable-next-line
                        //   <a>Change</a>
                        // }
                      />
                    </Form.Item>
                    <h2 style={{ color: 'gray', fontSize: '1em', marginTop: 15 }}>Membership Offerings</h2>
                    <Form.Item style={{ width: '50%' }}>
                      Number of teams allowed
                      <Input
                        readOnly
                        defaultValue={this.state.data.numberTeamsAllowed}
                      />
                    </Form.Item>
                    <Form.Item style={{ width: '50%' }}>
                      Number of team users allowed
                      <Input
                        readOnly
                        defaultValue={this.state.data.numberTeamUsersAllowed}
                      />
                    </Form.Item>
                    <Form.Item style={{ width: '50%' }}>
                      Total API calls allowed per month
                      <Input
                        readOnly
                        defaultValue={this.state.data.allowedApis}
                      />
                    </Form.Item>
                    {/* {this.state.data.membershipType === 'enterprise' || this.state.data.membershipType === 'npo' ? null :
                      (
                        <div>
                          <hr style={{ borderWidth: 2 }} />
                          <h2 style={{ color: 'gray' }}>Agilit-e Online</h2>
                          <Form.Item style={{ width: '50%' }}>
                            Active month
                            <Input
                              readOnly
                              defaultValue={this.state.data.activeMonth}
                            />
                          </Form.Item>
                          <Form.Item style={{ width: '50%' }}>
                            Membership Renewal Date
                            <Input
                              readOnly
                              defaultValue={this.state.data.membershipRenewal}
                            />
                          </Form.Item>
                          <Form.Item style={{ width: '50%' }}>
                            Total API calls made
                            <Input
                              readOnly
                              defaultValue={this.state.data.totalApiCalls}
                            />
                          </Form.Item>
                          <Form.Item style={{ width: '50%' }}>
                            API calls remaining for current month
                            <Input
                              readOnly
                              defaultValue={this.state.data.remainingApi}
                            />
                          </Form.Item>
                        </div>
                      )} */}
                  </div>
                  <br />
                  <hr style={{ borderWidth: 1 }} />
                  <div className='contact'>
                    Contact Agilit-e Support for assistance -{' '}
                    <a href='mailto:support@agilite.io'>support@agilite.io</a>
                  </div>
                </div>
              </Card>
            ) : (
              <Card>
                <Skeleton
                  active
                  title={
                    this.state.showProgress
                      ? this.state.titleProgress
                      : this.state.titleDone
                  }
                />
              </Card>
            )}
          </Form>
        </Col>
      </Row>
    )
  }
}

export default AdminAccount
