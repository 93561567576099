import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { initHomePage } from '../core-actions'
import CoreForgotPasswordConfirmation from '../components/core-forgot-password-confirmation'

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      initHomePage
    },
    dispatch
  )
}

export default connect(
  null,
  mapDispatchToProps
)(CoreForgotPasswordConfirmation)
