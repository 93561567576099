import React from 'react'
import { Input, Form, Col, Divider } from 'antd'
import MemoryStore from '../../utils/memory-store'
import TooltipIcon from '../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../utils/enums-tooltips'
import Description from '../../reusables/components/description'
import AgiliteTheme from '../../utils/agilite-theme'

const FormItem = Form.Item

class APIKeysFormGeneral extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isNewDoc: this.props.entry.custom.isNewDoc
    }
  }

  render () {
    return (
      <Col xs={24} lg={11}>
        <h3 style={{ marginTop: 20 }}>General</h3>
        <Divider />
        <div>
          <h3
            style={
              this.props.entry.status === 'Active'
                ? { color: AgiliteTheme.successColor }
                : { color: AgiliteTheme.dangerColor }
            }
          >
            {this.props.entry.status}
          </h3>
          <FormItem>
            <span style={{ color: 'red' }}>* </span>
            {'Profile Key '}
            <TooltipIcon title={EnumsTooltips.general.profileKey} />
            <Input
              placeholder='Provide a unique Profile Key'
              disabled={!this.state.isNewDoc || MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
              value={this.props.entry.firstName}
              onChange={e => {
                this.props.onFieldChange('firstName', e.target.value)
              }}
              required
            />
          </FormItem>
          <Description
            disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
            description={this.props.entry.description}
            onChange={e => {
              this.props.onFieldChange('description', e.target.value)
            }}
          />
        </div>
      </Col>
    )
  }
}

export default APIKeysFormGeneral
