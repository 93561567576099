import React from 'react'
import { Icon } from 'react-fa'
import { DragSource, DropTarget } from 'react-dnd'
import { Card, Table, Input, Modal, Tooltip } from 'antd'
import { TranslationOutlined } from '@ant-design/icons'
import { withDragDropContext } from './html5-backend'
import update from 'immutability-helper'
import uniqueId from 'lodash/uniqueId'

import '../reusables-views.css'
import AgiliteTheme from '../../utils/agilite-theme'
import LanguageTranslation from './language-translation'
import MemoryStore from '../../utils/memory-store'

let isDraggable = true

function dragDirection (
  dragIndex,
  hoverIndex,
  initialClientOffset,
  clientOffset,
  sourceClientOffset
) {
  const hoverMiddleY = (initialClientOffset.y - sourceClientOffset.y) / 2
  const hoverClientY = clientOffset.y - sourceClientOffset.y

  if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
    return 'downward'
  }

  if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
    return 'upward'
  }
}

class BodyRow extends React.Component {
  render () {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      dragRow,
      clientOffset,
      sourceClientOffset,
      initialClientOffset,
      ...restProps
    } = this.props

    const style = {
      ...restProps.style,
      cursor: 'move'
    }

    let className = restProps.className

    if (isOver && initialClientOffset) {
      const direction = dragDirection(
        dragRow.index,
        restProps.index,
        initialClientOffset,
        clientOffset,
        sourceClientOffset
      )

      if (direction === 'downward') {
        className += ' drop-over-downward'
      }

      if (direction === 'upward') {
        className += ' drop-over-upward'
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    )
  }
}

const rowSource = {
  beginDrag (props) {
    return { index: props.index }
  },
  canDrag () {
    return isDraggable
  }
}

const rowTarget = {
  drop (props, monitor) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  }
}

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  sourceClientOffset: monitor.getSourceClientOffset()
}))(
  DragSource('row', rowSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    dragRow: monitor.getItem(),
    clientOffset: monitor.getClientOffset(),
    initialClientOffset: monitor.getInitialClientOffset()
  }))(BodyRow)
)

class DragSortingTable extends React.Component {
  constructor (props) {
    super(props)

    this.components = {
      body: {
        row: DragableBodyRow
      }
    }

    this.trashEnabled = null
    this.tmpDataEntry = {
      value: '',
      iln: {
        value: {}
      }
    }
    this.tmpIndex = null

    this.state = {
      data: this.formatData(props.data),
      languageModalVisible: false
    }

    this.trashEnabled = this.state.data.length > 1

    // Setup Event Binding
    this.onChange = this.onChange.bind(this)
    this.onAdd = this.onAdd.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.formatData = this.formatData.bind(this)
    this.moveRow = this.moveRow.bind(this)
    this.handleDataChange = this.handleDataChange.bind(this)
    this.handleDataSubmit = this.handleDataSubmit.bind(this)
  }

  moveRow (dragIndex, hoverIndex) {
    const tmpThis = this

    if (tmpThis.props.disabled) {
      return null
    }

    tmpThis.setState({
      data: tmpThis.formatData(tmpThis.props.data)
    })

    const {
      data
    } = tmpThis.state
    const dragRow = data[dragIndex]

    tmpThis.setState(
      update(tmpThis.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        }
      })
    )

    return tmpThis.props.callback(tmpThis.state.data.map(entry => {
      return entry
    }))
  }

  onChange (index, value) {
    const tmpIndex = this.state.data.findIndex(entry => {
      return entry.key === index
    })

    return this.props.onChange(this.props.arrayKey, value, tmpIndex)
  }

  onAdd (index) {
    const data = this.state.data.concat()
    let tmpIndex = ''

    tmpIndex = this.state.data.findIndex(entry => {
      return entry.key === index
    })

    tmpIndex++

    const entry = {
      value: '',
      key: uniqueId()
    }

    this.props.data.splice(tmpIndex, 0, '')
    data.splice(tmpIndex, 0, entry)

    this.trashEnabled = true
    this.setState({ data })

    return true
  }

  onDelete (index) {
    const data = this.state.data.concat()
    let tmpIndex = ''

    tmpIndex = this.state.data.findIndex(entry => {
      return entry.key === index
    })

    data.splice(tmpIndex, 1)
    this.props.data.splice(tmpIndex, 1)

    if (data.length <= 1) {
      this.trashEnabled = false
    }

    this.setState({ data })

    return true
  }

  formatData (data) {
    data = data.map(entry => {
      if (entry.iln) {
        return {
          iln: entry.iln,
          value: entry.value,
          key: uniqueId()
        }
      } else {
        return {
          value: entry.value,
          key: uniqueId()
        }
      }
    })

    return data
  }

  handleDataChange (data) {
    this.tmpDataEntry.iln = {
      value: data
    }

    this.props.onChange(this.props.arrayKey, this.tmpDataEntry, this.tmpIndex, true)
  }

  handleDataSubmit () {
    this.setState({ languageModalVisible: false })
  }

  render () {
    const columns = [
      {
        title: 'Value',
        key: 'value',
        render: (text, record) => (
          <Input
            name='value'
            placeholder='Provide a Value'
            disabled={this.props.disabled}
            defaultValue={record.value}
            onChange={(e) => {
              this.onChange(record.key, e.target.value)
            }}
            onFocus={() => {
              isDraggable = false
            }}
            onBlur={() => {
              isDraggable = true
            }}
          />
        )
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text, record, index) => (
          <div style={{ minWidth: 50 }}>
            {this.trashEnabled ?
              <Icon
                name='trash-o'
                style={{ color: AgiliteTheme.dangerColor, cursor: 'pointer' }}
                onClick={() => this.onDelete(record.key)}
                hidden={this.props.disabled}
              />
              :
              <Icon
                name='trash-o'
                style={{ color: AgiliteTheme.secondaryDark }}
                hidden={this.props.disabled}
              />}
            <Icon
              name='plus'
              style={{ color: AgiliteTheme.successColor, cursor: 'pointer', marginLeft: 20 }}
              onClick={() => this.onAdd(record.key)}
              hidden={this.props.disabled}
            />
            {this.props.languageTranslationEnabled ?
              <Tooltip title='Add language alternatives for field value'>
                <TranslationOutlined
                  onClick={() => {
                    this.tmpDataEntry = this.props.data[index]
                    this.tmpIndex = index
                    this.setState({ languageModalVisible: true, data: this.formatData(this.props.data) })
                  }}
                  style={{ color: 'blueviolet', fontSize: 16, marginLeft: 20 }}
                />
              </Tooltip>
              : null}
          </div>
        )
      }
    ]

    return (
      <Card
        title={this.props.title}
      >
        <Table
          id='single-value-table-editable-dnd'
          size='middle'
          dataSource={Object.assign([], this.state.data)}
          bordered
          columns={columns}
          pagination={false}
          components={this.components}
          onRow={(record, index) => ({
            index,
            moveRow: this.moveRow
          })}
        />
        {this.tmpDataEntry ?
          <Modal
            title='Language Translate'
            visible={this.state.languageModalVisible}
            onOk={this.handleDataSubmit}
            onCancel={() => this.setState({ languageModalVisible: false })}
            closable={false}
            width='50%'
            destroyOnClose
            okButtonProps={{
              style: {
                backgroundColor: '#67AD5B',
                color: 'white'
              }
            }}
            okText='Submit'
          >
            <LanguageTranslation
              theme={this.props.theme}
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              data={this.tmpDataEntry.iln ? this.tmpDataEntry.iln.value ? this.tmpDataEntry.iln.value : {} : {}}
              dataKey='value'
              defaultLanguage={MemoryStore.defaultLanguage}
              privileges={MemoryStore.userProfile.teamPrivileges.bpm}
              fieldValue={this.tmpDataEntry.value ? this.tmpDataEntry.value : ''}
              onDataChange={this.handleDataChange}
            />
          </Modal>
          : null}
      </Card>
    )
  }
}

const SingleValueTableEditableDND = withDragDropContext(DragSortingTable)

export default SingleValueTableEditableDND
