import React from 'react'
import { Form, Input } from 'antd'

const { TextArea } = Input

export default class Notes extends React.Component {
  render () {
    return (
      <Form.Item>
        Notes (optional) {this.props.icon ? this.props.icon : null}
        <TextArea
          className='notes'
          placeholder='Internal Notes related to this Profile'
          rows={4}
          disabled={this.props.disabled}
          defaultValue={this.props.notes}
          onChange={this.props.onChange}
        />
      </Form.Item>
    )
  }
}
