import { connect } from 'react-redux'
import ProcessSteps from '../components/process-steps/process-steps'
import { filterProcessStepViewData } from '../bpm-utils'

const mapStateToProps = state => {
  return {
    theme: state.core.theme,
    state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    filterViewData: (data, searchFilter, callback) => {
      filterProcessStepViewData(data, searchFilter, callback)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessSteps)
