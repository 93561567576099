export default {
  primary: '#d32f2f',
  primaryLight: '#ffa4a2',
  primaryDark: '#9a0007',
  secondary: '#9e9e9e',
  secondaryLight: '#cfcfcf',
  secondaryLight2: '#eeeeee',
  secondaryDark: '#707070',
  successColor: '#4caf50',
  dangerColor: '#f44336',
  warningColor: '#ff9800',
  passwordValidationLime: 'lime',
  white: '#ffffff',
  black: '#000000',
  linkBlue: '#0000EE'
}
