import dataMappingState from './data-mapping-state'
import { actions } from './data-mapping-actions'

export default (state = dataMappingState, action) => {
  switch (action.type) {
    case actions.RESET_STATE:
      return Object.assign({}, state, dataMappingState)
    case actions.SET_SEARCH_FILTER:
      return Object.assign({}, state, {
        searchFilter: action.value
      })
    case actions.SET_MAPPING_SEARCH_FILTER:
      return Object.assign({}, state, {
        mappingSearchFilter: action.value
      })
    case actions.SET_MULTI_ENTRY_SEARCH_FILTER:
      return Object.assign({}, state, {
        multiEntrySearchFilter: action.value
      })
    case actions.FETCH_ALL_DATA:
      return Object.assign({}, state, {
        data: action.payload
      })
    case actions.UPDATE_DATA:
      return Object.assign({}, state, {
        data: action.data
      })
    default:
      return state
  }
}
