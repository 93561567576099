import React from 'react'
import { Form } from 'antd'
import AceEditor from 'react-ace'

import CompileTemplateIcon from '../../../../../../reusables/components/compile-template-icon'
import MemoryStore from '../../../../../../utils/memory-store'

import 'brace/mode/json'
import 'brace/theme/xcode'

class IMAPRouteGetParts extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry.routeData
    }

    // Bindings
    this.onFieldChange = this.onFieldChange.bind(this)
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value.trim()
  }

  render () {
    return (
      <div>
        <Form.Item>
          <h3>
            <span style={{ color: 'red' }}>* </span>
            {'Struct '}
            <CompileTemplateIcon />
          </h3>
          <AceEditor
            showPrintMargin={false}
            readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            cursorStart={1}
            mode='json'
            theme='xcode'
            style={{ width: '100%', height: 200 }}
            name='aceEditor'
            value={this.state.entry.struct}
            setOptions={{
              showLineNumbers: true,
              newLineMode: 'unix'
            }}
            onChange={value => {
              this.onFieldChange('struct', value)
            }}
          />
        </Form.Item>
      </div>
    )
  }
}

export default IMAPRouteGetParts
