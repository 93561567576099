import React from 'react'
import { Row, Col, Select, Form } from 'antd'
import MemoryStore from '../../../../../utils/memory-store'
import FTPRoute from './ftp-route'

class FTPRouteMain extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry,
      routeType: props.entry.routeData.routeType ? props.entry.routeData.routeType : ''
    }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value
  }

  render () {
    return (
      <Row>
        <Col xs={24} xl={16}>
          <h3>Route Info</h3>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>Route Type
            <Select
              className='route_type'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.routeType}
              onChange={e => {
                this.setState({ routeType: e })
                this.onFieldChange('routeType', e)
              }}
            >
              <Select.Option value=''>-Select-</Select.Option>
              <Select.Option value='0'>List Directory</Select.Option>
              <Select.Option value='1'>Get File</Select.Option>
              <Select.Option value='2'>Put File</Select.Option>
              <Select.Option value='3'>Append File</Select.Option>
              <Select.Option value='4'>Rename File</Select.Option>
              <Select.Option value='5'>Delete File</Select.Option>
              <Select.Option value='6'>Create Directory</Select.Option>
              <Select.Option value='7'>Remove Directory</Select.Option>
              <Select.Option value='9'>File Size</Select.Option>
              <Select.Option value='10'>Last Modified</Select.Option>
              <Select.Option value='8'>Print Working Directory</Select.Option>
            </Select>
          </Form.Item>
          <FTPRoute entry={this.props.entry} routeType={this.state.routeType} />
        </Col>
      </Row>
    )
  }
}

export default FTPRouteMain
