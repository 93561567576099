import React from 'react'
import { Form, Input } from 'antd'
import AceEditor from 'react-ace'

import CompileTemplateIcon from '../../../../../../reusables/components/compile-template-icon'
import MemoryStore from '../../../../../../utils/memory-store'

import 'brace/mode/json'
import 'brace/theme/xcode'

class IMAPRouteSearch extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry.routeData
    }

    // Bindings
    this.onFieldChange = this.onFieldChange.bind(this)
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value.trim()
  }

  render () {
    return (
      <div>
        <Form.Item>
          <span style={{ color: 'red' }}>* </span>
          {'Mailbox / Folder Name '}
          <CompileTemplateIcon />
          <Input
            name='mailboxFolderName'
            placeholder='Mailbox / Folder Name (e.g. Inbox)'
            disabled={this.props.disabled}
            defaultValue={this.state.entry.folderName}
            onChange={e => {
              this.onFieldChange('folderName', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          <h3>
            <span style={{ color: 'red' }}>* </span>
            {'Search Criteria '}
            <CompileTemplateIcon />
          </h3>
          <AceEditor
            showPrintMargin={false}
            readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            cursorStart={1}
            mode='json'
            theme='xcode'
            style={{ width: '100%', height: 200 }}
            name='aceEditor'
            value={this.state.entry.searchCriteria}
            setOptions={{
              showLineNumbers: true,
              newLineMode: 'unix'
            }}
            onChange={value => {
              this.onFieldChange('searchCriteria', value)
            }}
          />
        </Form.Item>
        <Form.Item>
          <h3>
            {'Fetch Options '}
            <CompileTemplateIcon />
          </h3>
          <AceEditor
            showPrintMargin={false}
            readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            cursorStart={1}
            mode='json'
            theme='xcode'
            style={{ width: '100%', height: 200 }}
            name='aceEditor'
            value={this.state.entry.fetchOptions}
            setOptions={{
              showLineNumbers: true,
              newLineMode: 'unix'
            }}
            onChange={value => {
              this.onFieldChange('fetchOptions', value)
            }}
          />
        </Form.Item>
      </div>
    )
  }
}

export default IMAPRouteSearch
