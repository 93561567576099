import keywordsState from './keywords-state'
import { actions } from './keywords-actions'

export default (state = keywordsState, action) => {
  switch (action.type) {
    case actions.RESET_STATE:
      return Object.assign({}, state, keywordsState)
    case actions.SET_SEARCH_FILTER:
      return Object.assign({}, state, {
        searchFilter: action.value
      })
    case actions.FETCH_ALL_DATA:
      return Object.assign({}, state, {
        data: action.payload
      })
    case actions.UPDATE_DATA:
      return Object.assign({}, state, {
        data: action.data
      })
    default:
      return state
  }
}
