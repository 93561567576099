import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { adminFetchAccountData } from '../../core-utils'
import AdminAccount from '../../components/admin/admin-account'

const mapStateToProps = state => {
  // TODO: State is not being used in the component?
  return {
    state
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      adminFetchAccountData
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAccount)
