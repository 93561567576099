import React, { Component } from 'react'
import { Form, Select, Switch, Col, Row, Input, AutoComplete, message } from 'antd'
import MemoryStore from '../../utils/memory-store'
import Description from '../../reusables/components/description'
import TooltipIcon from '../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../utils/enums-tooltips'
import { getConnectorProfiles } from '../batch-actions-utils'
import orderBy from 'lodash/orderBy'

class ActionForm extends Component {
  constructor (props) {
    super(props)

    this.entry = this.props.data

    this.state = {
      actionType: this.props.action.actionType,
      storeResponseDataAs: this.props.action.storeResponseDataAs || '',
      profileKeys: [],
      connectorProfiles: [],
      routeKeys: [],
      profileKey: this.props.action.profileKey,
      routeKey: this.props.action.routeKey
    }
  }

  UNSAFE_componentWillMount () {
    const profileArray = []
    let profileKeyArray = []

    getConnectorProfiles((err, result) => {
      if (err) {
        if (err.response.data.errorMessage) {
          message.error(err.response.data.errorMessage)
        }
      } else {
        result.data.map(entry => {
          profileKeyArray.push({ label: entry.data.key, value: entry.data.key })
          profileArray.push(entry.data)

          return null
        })

        profileKeyArray = orderBy(profileKeyArray, null, 'asc')

        this.setState({ profileKeys: profileKeyArray, connectorProfiles: profileArray })
      }
    })
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.action[key] = value
  }

  render () {
    return (
      <Row>
        <Col span={24}>
          <Form>
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Form.Item>
                  <Switch
                    cypressid='isActiveSwitch'
                    ref='isActive'
                    disabled={MemoryStore.userProfile.teamPrivileges.batchactions === 'Reader'}
                    checkedChildren='Active'
                    unCheckedChildren='Inactive'
                    defaultChecked={this.props.action.isActive}
                    onChange={(e) => {
                      this.onFieldChange('isActive', e)
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <span style={{ color: 'red' }}>* </span>
                  Action Type
                  <Select
                    cypressid='actionType'
                    ref='actionType'
                    disabled={MemoryStore.userProfile.teamPrivileges.batchactions === 'Reader'}
                    defaultValue={this.props.action.actionType}
                    onChange={e => {
                      this.onFieldChange('actionType', e)
                      this.setState({ actionType: e })
                    }}
                  >
                    <Select.Option value=''>-None-</Select.Option>
                    <Select.Option value='connectors/execute'>Connectors - Execute</Select.Option>
                  </Select>
                </Form.Item>
                <Description
                  disabled={MemoryStore.userProfile.teamPrivileges.batchactions === 'Reader'}
                  description={this.props.action.description}
                  onChange={(e) => {
                    this.onFieldChange('description', e.target.value)
                  }}
                />
              </Col>
            </Row>
            {this.state.actionType === 'connectors/execute' ?
              <Row>
                <Col span={24}>
                  <Form.Item style={{ width: '50%' }}>
                    <span style={{ color: 'red' }}>* </span>
                    {'Profile Key '}
                    <TooltipIcon title={EnumsTooltips.general.profileKey} />
                    <AutoComplete
                      cypressid='profileKey'
                      className='profileKey'
                      options={this.state.profileKeys}
                      placeholder='Please provide the Connector Profile Key'
                      disabled={MemoryStore.userProfile.teamPrivileges.batchactions === 'Reader'}
                      value={this.state.profileKey}
                      onChange={value => {
                        this.setState({ profileKey: value })
                        this.onFieldChange('profileKey', value)
                      }}
                      onSelect={(value) => {
                        let profile = null
                        let tmpArray = []

                        profile = this.state.connectorProfiles.find((entry) => {
                          return entry.key === value
                        })

                        profile.routes.map(entry => {
                          tmpArray.push({ label: entry.key, value: entry.key })
                          return null
                        })

                        tmpArray = orderBy(tmpArray, null, 'asc')

                        this.setState({ routeKeys: tmpArray, profileKey: value })
                        this.onFieldChange('profileKey', value)
                      }}
                      required
                    />
                  </Form.Item>
                  <Form.Item style={{ width: '50%' }}>
                    <span style={{ color: 'red' }}>* </span>
                    {'Route Key '}
                    <TooltipIcon title={EnumsTooltips.general.profileKey} />
                    <AutoComplete
                      cypressid='routeKey'
                      className='routeKey'
                      options={this.state.routeKeys}
                      placeholder='Please provide the Connector Route Key'
                      disabled={MemoryStore.userProfile.teamPrivileges.batchactions === 'Reader'}
                      value={this.state.routeKey}
                      onChange={value => {
                        this.setState({ routeKey: value })
                        this.onFieldChange('routeKey', value)
                      }}
                      onSelect={value => {
                        this.setState({ routeKey: value })
                        this.onFieldChange('routeKey', value)
                      }}
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
              :
              null}
            <Form.Item style={{ width: '50%' }}>
              <span style={{ color: 'red' }}>* </span>
              Store Response Data As?
              <Select
                cypressid='storeResponseDataAs'
                ref='storeResponseDataAs'
                disabled={MemoryStore.userProfile.teamPrivileges.batchactions === 'Reader'}
                defaultValue={this.state.storeResponseDataAs}
                onChange={e => {
                  this.onFieldChange('storeResponseDataAs', e)
                  this.setState({ storeResponseDataAs: e })
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='overwrite'>Overwrite current Request Body</Select.Option>
                <Select.Option value='add'>Add as property to Request Body</Select.Option>
              </Select>
            </Form.Item>
            {this.state.storeResponseDataAs === 'add' ? 
              <Form.Item style={{ width: '50%' }}>
                <span style={{ color: 'red' }}>* </span>
                Property Name
                <Input
                  cypressid='propName'
                  name='propName'
                  disabled={MemoryStore.userProfile.teamPrivileges.batchactions === 'Reader'}
                  placeholder='Please provide a Property Name'
                  defaultValue={this.props.action.propName}
                  onChange={e => {
                    this.onFieldChange('propName', e.target.value)
                  }}
                />
              </Form.Item>
              : null}
          </Form>
        </Col>
      </Row>
    )
  }
}

export default ActionForm
