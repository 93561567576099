import React from 'react'
import { Form, Input } from 'antd'

import CompileTemplateIcon from '../../../../../../reusables/components/compile-template-icon'
import MemoryStore from '../../../../../../utils/memory-store'

class IMAPRouteAddDeleteFlags extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry.routeData
    }

    // Bindings
    this.onFieldChange = this.onFieldChange.bind(this)
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value.trim()
  }

  render () {
    return (
      <div>
        <Form.Item>
          <span style={{ color: 'red' }}>* </span>
          {'Mailbox / Folder Name '}
          <CompileTemplateIcon />
          <Input
            name='mailboxFolderName'
            placeholder='Mailbox / Folder Name (e.g. Inbox)'
            disabled={this.props.disabled}
            defaultValue={this.state.entry.folderName}
            onChange={e => {
              this.onFieldChange('folderName', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          <span style={{ color: 'red' }}>* </span>
          {'Message(s) UID '}
          <CompileTemplateIcon />
          <Input
            name='messageIds'
            placeholder='Comma separated values e.g. "uid1,uid2"'
            disabled={this.props.disabled}
            defaultValue={this.state.entry.messageIds}
            onChange={e => {
              this.onFieldChange('messageIds', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          <span style={{ color: 'red' }}>* </span>
          {'Flag(s) '}
          <CompileTemplateIcon />
          <Input
            name='flags'
            placeholder='Comma separated values e.g. "Seen,Unseen"'
            disabled={this.props.disabled}
            defaultValue={this.state.entry.flags}
            onChange={e => {
              this.onFieldChange('flags', e.target.value)
            }}
          />
        </Form.Item>
      </div>
    )
  }
}

export default IMAPRouteAddDeleteFlags
