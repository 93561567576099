import React from 'react'
import moment, { isMoment } from 'moment'
import { Cell, RadialBar, RadialBarChart, Pie, PieChart, LineChart, BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Tooltip as TooltipAnt, Button, DatePicker, Progress, message, Col, Row, Modal, Card, Empty } from 'antd'
import { SyncOutlined, CalendarOutlined } from '@ant-design/icons'

import AgiliteTheme from '../../utils/agilite-theme'

const { RangePicker } = DatePicker

class Dashboard extends React.Component {
  constructor () {
    super()

    this.state = {
      showProgress: false,
      modalOpen: false,
      titleProgress: 'Generating Agilit-e Dashboard Reports',
      titleDone: '',
      dates: [moment().startOf('month'), moment()],
      hackValue: null,
      disableDates: [false, true],
      todaysDate: [moment()],
      reportData: {
        chartColors: [],
        summary: [],
        summaryPerModule: [],
        modules: []
      }
    }

    this.disabledDate = this.disabledDate.bind(this)
    this.handleOnOpenChange = this.handleOnOpenChange.bind(this)
    this.handleGenerateReport = this.handleGenerateReport.bind(this)
    this.postGenerateReport = this.postGenerateReport.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleValidate = this.handleValidate.bind(this)
    this.renderCustomizedLabel = this.renderCustomizedLabel.bind(this)
  }

  disabledDate (current) {
    const disableFutureDates = this.state.todaysDate[0] && current.diff(this.state.todaysDate[0], 'days') > 0
    if (!this.state.dates || this.state.dates.length === 0) {
      return disableFutureDates
    }

    const tooLate = this.state.dates[0] && current.diff(this.state.dates[0], 'days') > 31
    const tooEarly = this.state.dates[1] && this.state.dates[1].diff(current, 'days') > 31
    return disableFutureDates || tooLate || tooEarly
  }

  handleOnOpenChange (open) {
    if (open) {
      this.setState({
        hackValue: [],
        dates: [],
        disableDates: [false, true]
      })
    } else {
      this.setState({
        hackValue: undefined,
        disableDates: [false, true]
      })
    }
    this.setState({
      disableDates: [false, false]
    })
  }

  componentDidMount () {
    this.handleGenerateReport()
  }

  handleGenerateReport () {
    const tmpThis = this
    let success = true
    let msg = ''
    let startDate = this.state.dates[0]
    let endDate = this.state.dates[1]

    tmpThis.setState({ showProgress: true })

    if (startDate !== '' && endDate !== '') {
      endDate = moment(endDate).format('YYYY-MM-DD') + 'T23:59:59.999'
      startDate = moment(startDate).format('YYYY-MM-DD') + 'T00:00:00.000'
    }

    tmpThis.props.onGenerateReport(startDate, endDate, (err, result) => {
      if (err) {
        success = false
        if (err.response) {
          msg = err.response.data.errorMessage
        } else {
          msg = err
        }
      }

      if (success) {
        // Update Report Data
        tmpThis.setState({
          showProgress: false,
          reportData: result.data,
          startDate: result.data.startDate,
          endDate: result.data.endDate
        })
        tmpThis.postGenerateReport()
      } else {
        tmpThis.setState({ showProgress: false })
        message.error(msg)
      }
    })
  }

  postGenerateReport () {
    const titleDone =
      'Agilit-e Dashboard Reports (' +
      moment(this.state.dates[0]).format('YYYY-MM-DD') +
      ' - ' +
      moment(this.state.dates[1]).format('YYYY-MM-DD') +
      ')'
    this.setState({ titleDone })
  }

  handleOpen () {
    this.setState({ modalOpen: true })
  }

  handleClose () {
    this.setState({ modalOpen: false })
  }

  handleChange (value) {
    if (value && value.length > 0) {
      this.setState({ dates: value })
    } else {
      this.setState({ dates: [moment().startOf('month'), moment()] })
    }
  }

  handleValidate () {
    let diff = null

    if (this.state.dates[0] === '') {
      return message.error('Start Date is required')
    } else if (this.state.endDate === '') {
      return message.error('End Date is required')
    }

    const startDate = moment(moment(this.state.dates[0]).format('YYYY-MM-DD'))
    const endDate = moment(moment(this.state.dates[1]).format('YYYY-MM-DD'))

    diff = startDate.diff(endDate, 'days')

    if (diff < -31) {
      message.info('Date range cannot exceed a full calendar month')
    } else if (diff > 0) {
      message.info('Start Date cannot be greater than the End Date')
    } else {
      this.handleClose()
      this.handleGenerateReport()
    }
  }

  renderCustomizedLabel ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) {
    const RADIAN = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const item = this.state.reportData.summary[index].value

    return (
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
      >
        {item}
      </text>
    )
  }

  render () {
    const legendStylePie = { top: 45, left: 220, fontSize: 10 }
    const legendStyleRadial = { top: 20, left: 240, fontSize: 10 }
    const legendStyleLine = { fontSize: 10 }

    return (
      <Row>
        <Col span={24}>
          <Card
            title={this.state.showProgress ? this.state.titleProgress : this.state.titleDone}
            style={{ textAlign: 'left' }}
            type='inner'
            extra={
              <div>
                <TooltipAnt title='Refresh Report'>
                  <Button
                    onClick={this.handleGenerateReport}
                    style={{ margin: 5, fontWeight: 600, color: '#67AD5B' }}
                  >
                    <SyncOutlined />
                  </Button>
                </TooltipAnt>
                <Button
                  onClick={this.handleOpen}
                  style={{ margin: 5, fontWeight: 400 }}
                >
                  <span>
                    <CalendarOutlined style={{ color: AgiliteTheme.primary }} /> Change Dates
                  </span>
                </Button>
              </div>
            }
          >
            {this.state.showProgress ? (
              <Progress
                percent={100}
                status='active'
                showInfo={false}
                strokeColor='#67AD5B'
              />
            ) : (
              <Row>
                {this.state.reportData.summary.length === 0 ?
                  <Col span={24}>
                    <Empty />
                  </Col>
                  :
                  <Col span={24}>
                    <Row type='flex' justify='space-around'>
                      <Col span={5}>
                        {this.state.reportData.summary.length > 0 ? (
                          <Card title={<h4>Total API Calls</h4>} style={{ marginBottom: 10 }}>
                            <ResponsiveContainer
                              height={300}
                            >
                              <PieChart>
                                <Legend
                                  width={250}
                                  layout='vertical'
                                  verticalAlign='middle'
                                  wrapperStyle={legendStylePie}
                                />
                                <Tooltip />
                                <Pie
                                  dataKey='value'
                                  data={this.state.reportData.summary}
                                  cx={120}
                                  cy={120}
                                  labelLine={false}
                                  label={this.renderCustomizedLabel}
                                  outerRadius={80}
                                  fill='#8884d8'
                                  isAnimationActive={false}
                                >
                                  {this.state.reportData.summary.map((entry, index) => (
                                    <Cell
                                      key={index}
                                      fill={
                                        this.state.reportData.chartColors[index % this.state.reportData.chartColors.length]
                                      }
                                    />
                                  ))}
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                          </Card>
                        ) : <Empty />}
                      </Col>
                      <Col span={18}>
                        {this.state.reportData.summaryPerModule.length > 0 ? (
                          <Card title={<h4>Total API Calls Per Module</h4>} style={{ marginBottom: 10 }}>
                            <ResponsiveContainer
                              height={300}
                            >
                              <BarChart
                                data={this.state.reportData.summaryPerModule}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                              >
                                <CartesianGrid strokeDasharray='3 3' vertical={false} />
                                <XAxis dataKey='name' />
                                <YAxis />
                                <Tooltip />
                                <Legend wrapperStyle={legendStyleLine} />
                                <Bar isAnimationActive={false} dataKey='Successful' fill='#8BC34A' />
                                <Bar isAnimationActive={false} dataKey='Failed' fill='#FF5252' />
                              </BarChart>
                            </ResponsiveContainer>
                          </Card>
                        ) : null}
                      </Col>
                    </Row>
                    {this.state.reportData.modules.map((entry, index) => (
                      <div key={index}>
                        <Row type='flex' justify='space-around'>
                          <Col span={5}>
                            <Card title={<h4>{`${entry.name}: Total Calls Per API`}</h4>} style={{ marginBottom: 10 }}>
                              <ResponsiveContainer
                                height={300}
                              >
                                <RadialBarChart
                                  cx={120}
                                  cy={120}
                                  innerRadius={15}
                                  outerRadius={120}
                                  barSize={15}
                                  data={entry.totalPerAPI}
                                >
                                  <RadialBar
                                    minAngle={15}
                                    label={{ position: 'insideStart', fill: '#fff' }}
                                    background
                                    clockWise
                                    dataKey='value'
                                    isAnimationActive={false}
                                  />
                                  <Legend
                                    width={250}
                                    layout='vertical'
                                    verticalAlign='middle'
                                    wrapperStyle={legendStyleRadial}
                                  />
                                </RadialBarChart>
                              </ResponsiveContainer>
                            </Card>
                          </Col>
                          <Col span={18}>
                            <Card title={<h4>{`${entry.name}: Total Calls Per API Per Day`}</h4>} style={{ marginBottom: 10 }}>
                              <ResponsiveContainer
                                height={300}
                              >
                                <LineChart
                                  data={entry.totalPerDay}
                                >
                                  <XAxis dataKey='name' />
                                  <YAxis />
                                  <CartesianGrid strokeDasharray='3 3' />
                                  <Tooltip />
                                  <Legend wrapperStyle={legendStyleLine} />
                                  {entry.totalPerAPI.map((entry2, index2) => (
                                    <Line
                                      key={index2}
                                      type='monotone'
                                      dataKey={entry2.name}
                                      stroke={entry2.fill}
                                      isAnimationActive={false}
                                    />
                                  ))}
                                </LineChart>
                              </ResponsiveContainer>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Col>
                }
              </Row>
            )}
          </Card>
          <Modal
            title='Select Date Range'
            okButtonProps={{
              style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' }
            }}
            okText='Apply'
            onCancel={this.handleClose}
            onOk={this.handleValidate}
            visible={this.state.modalOpen}
            maskClosable={false}
          >
            <RangePicker
              disabledDate={this.disabledDate}
              disabled={this.state.disableDates}
              onOpenChange={this.handleOnOpenChange}
              onCalendarChange={value => this.setState({ dates: value })}
              onChange={(value) => this.handleChange(value)}
              value={this.state.dates || this.state.hackValue}
              style={{ width: '100%' }}
            />
          </Modal>
        </Col>
      </Row>
    )
  }
}
export default Dashboard
