import { connect } from 'react-redux'
import ConnectorsFormRoutes from '../components/connectors-form/connectors-form-routes'
import MemoryStore from '../../utils/memory-store'
import { filterRouteData } from '../connectors-utils'

const mapStateToProps = state => {
  const tabKey = state.core.tabObject.activeKey
  const entry = MemoryStore.activeEntries[tabKey]

  return {
    entry,
    theme: state.core.theme,
    tabKey
  }
}

const mapDispatchToProps = dispatch => {
  return {
    filterViewData: (data, searchFilter, callback) => {
      filterRouteData(data, searchFilter, callback)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectorsFormRoutes)
