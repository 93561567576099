import React from 'react'
import { Form, Input } from 'antd'

const { TextArea } = Input

export default class Description extends React.Component {
  render () {
    return (
      <Form.Item>
        Description (optional) {this.props.icon ? this.props.icon : null}
        <TextArea
          cypressid='description'
          className='description'
          placeholder='A detailed description for this Profile'
          rows={4}
          disabled={this.props.disabled}
          defaultValue={this.props.description}
          onChange={this.props.onChange}
        />
      </Form.Item>
    )
  }
}
