import React from 'react'
import MemoryStore from '../../../utils/memory-store'

import { Form, Switch, Input, Row, Col } from 'antd'
import TooltipIcon from '../../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../../utils/enums-tooltips'
import Description from '../../../reusables/components/description'

class ConnectorsRouteFormGeneral extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      route: this.props.route
    }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.route[key] = value
  }

  render () {
    return (
      <Row>
        <Col xs={24} xl={16}>
          <h3>General Info</h3>
          <Form.Item>
            <Switch
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              checkedChildren='Active'
              unCheckedChildren='Inactive'
              defaultChecked={this.props.route.isActive}
              onChange={e => {
                this.onFieldChange('isActive', e)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Route Key '}
            <TooltipIcon title={EnumsTooltips.general.profileKey} />
            <Input
              name='key'
              placeholder='Provide a unique Key for this Route'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.props.route.key}
              required
              onChange={e => {
                this.onFieldChange('key', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Route Name '}
            <TooltipIcon title={EnumsTooltips.general.profileName} />
            <Input
              name='name'
              placeholder='Provide a Route Name'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.props.route.name}
              required
              onChange={e => {
                this.onFieldChange('name', e.target.value)
              }}
            />
          </Form.Item>
          <Description
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            description={this.props.route.description}
            onChange={e => {
              this.onFieldChange('description', e.target.value)
            }}
          />
        </Col>
      </Row>
    )
  }
}

export default ConnectorsRouteFormGeneral
