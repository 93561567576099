import React, { Component } from 'react'
import { Modal, message } from 'antd'
import { columnTemplates } from '../../utils/templates'
import agiliteTheme from '../../utils/agilite-theme'
import Enums from '../../utils/enums'
import MemoryStore from '../../utils/memory-store'
import { isWebUri } from 'valid-url'
import { v1 } from 'agilite-utils/uuid'

import ListViewDND from '../../reusables/components/list-view-dnd'
import EventsSubscriptionForm from './events-subscription-form'

class EventsSubscriptionsView extends Component {
  constructor (props) {
    super(props)

    this.entry = props.entry
    this.data = props.data

    this.state = {
      entry: props.data,
      dialogOpen: false,
      activeRecord: null,
      newSub: false
    }

    // Setup Event Binding
    this.createRecord = this.createRecord.bind(this)
    this.saveRecord = this.saveRecord.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.deleteRecord = this.deleteRecord.bind(this)
    this.handleValidateRecord = this.handleValidateRecord.bind(this)
    this.formatViewData = this.formatViewData.bind(this)
    this.handleCloseModalDialog = this.handleCloseModalDialog.bind(this)
    this.handleCloseModalDialogConfirm = this.handleCloseModalDialogConfirm.bind(this)
    this.updateArrayData = this.updateArrayData.bind(this)
    this.generateSubscribers = this.generateSubscribers.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.formatViewData()
  }

  UNSAFE_componentWillUpdate () {
    this.formatViewData()
  }

  createRecord () {
    const activeRecord = JSON.parse(JSON.stringify(MemoryStore.events.subscriptionTemplate))
    activeRecord.isNewEntry = true
    activeRecord._id = v1()

    this.setState({
      dialogOpen: true,
      activeRecord,
      newSub: true
    })
  }

  saveRecord () {
    const activeRecord = JSON.parse(JSON.stringify(this.state.activeRecord))
    const entry = this.props.data
    const index = entry.findIndex(t => t._id === this.state.activeRecord._id)

    if (activeRecord.isNewEntry) {
      entry.push(activeRecord)
    } else {
      entry.splice(index, 1, activeRecord)
    }

    this.setState({
      entry,
      dialogOpen: false,
      activeRecord: null
    })
  }

  editRecord (recordId) {
    const index = this.state.entry.findIndex(t => t._id === recordId)
    const activeRecord = JSON.parse(JSON.stringify(this.state.entry[index]))

    activeRecord.isNewEntry = false
    this.setState({ dialogOpen: true, activeRecord, newSub: false })
  }

  deleteRecord (recordId) {
    this.entry.custom.isModifiedSubform = true

    const entry = this.props.data
    const index = entry.findIndex(t => t._id === recordId)

    entry.splice(index, 1)
    this.setState({ entry })
  }

  handleValidateRecord () {
    let success = true
    let errMsg = null

    if (!this.state.activeRecord.name.trim()) {
      errMsg = 'Please provide a Subscription Name'
      success = false
    } else if (!this.state.activeRecord.webhookUrl.trim()) {
      errMsg = 'Please provide a Webhook URL'
      success = false
    } else if (!isWebUri(this.state.activeRecord.webhookUrl.trim())) {
      errMsg = 'Please provide a valid Webhook URL'
      success = false
    } else if (!this.state.activeRecord.subscriberType.trim()) {
      errMsg = 'Please provide a Subscriber Type'
      success = false
    } else if (!this.state.activeRecord.subscriber.trim()) {
      errMsg = 'Please provide a Subscriber'
      success = false
    }

    this.props.data.map(entry => {
      if (this.state.activeRecord._id !== entry._id) {
        if (this.state.activeRecord.webhookUrl.toLowerCase() === entry.webhookUrl.toLowerCase()) {
          errMsg = 'The Subscription Webhook URL already exists in another Subscription'
          success = false
        }
      }
      return null
    })

    if (success) {
      this.saveRecord()
    } else {
      message.error(errMsg)
    }

    return null
  }

  formatViewData () {
    // Set isActiveDescription
    this.state.entry.map(subscription => {
      subscription.isActiveDescription = subscription.isActive ? 'Yes' : 'No'
      return subscription.isActiveDescription
    })
  }

  handleCloseModalDialog () {
    this.entry.custom.isModifiedSubform = false

    this.setState({
      dialogOpen: false,
      activeRecord: null
    })
  }

  handleCloseModalDialogConfirm () {
    if (this.entry.custom.isModifiedSubform) {
      Modal.confirm({
        onOk: () => this.handleCloseModalDialog(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.handleCloseModalDialog()
    }
  }

  updateArrayData (data) {
    this.entry.custom.isModified = true
    this.entry.data.subscriptions = data

    this.setState({
      entry: this.entry.data.subscriptions
    })
  }

  generateSubscribers (callback) {
    const tmpArray = []

    this.entry.data.subscriptions.map(record => {
      if (!tmpArray.includes(record.subscriber) && record.subscriber !== '') {
        tmpArray.push({ label: record.subscriber, value: record.subscriber })
      }

      return tmpArray
    })

    callback(tmpArray)
  }

  render () {
    return (
      <div style={{ marginBottom: 10 }}>
        <ListViewDND
          btnCreateLabel='Create New'
          viewTitle='Subscriptions'
          createRecord={this.createRecord}
          editRecord={this.editRecord}
          deleteRecord={this.deleteRecord}
          filterViewData={this.props.filterViewData}
          viewData={this.state.entry}
          viewColumns={columnTemplates.eventsSubscriptions}
          theme={agiliteTheme}
          callback={this.updateArrayData}
        />
        {this.state.activeRecord ?
          <Modal
            title={this.state.newSub ? 'New Subscription' : `Subscription: ${this.state.activeRecord.name}`}
            okButtonProps={{
              style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' }
            }}
            okText='Ok'
            visible={this.state.dialogOpen}
            onOk={this.handleValidateRecord}
            onCancel={this.handleCloseModalDialogConfirm}
            width={1050}
            style={{ top: 10 }}
            destroyOnClose
            maskClosable={false}
          >
            <EventsSubscriptionForm
              subscription={this.state.activeRecord}
              data={this.props.entry}
              generateSubscribers={this.generateSubscribers}
            />
          </Modal>
          : null}
      </div>
    )
  }
}

export default EventsSubscriptionsView
