import React from 'react'

import { Input, Form, Row, Col, Switch } from 'antd'
import MemoryStore from '../../../../../utils/memory-store'
import AceEditor from 'react-ace'

import 'brace/mode/json'
import 'brace/theme/xcode'

class ConnectorsFormTypeIMAP extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry,
      imapTLS: props.entry.imapTLS && props.entry.imapTLS === '1' ? true : false
    }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.props.entry[key] = value
  }

  render () {
    return (
      <Row>
        <Col xs={24} lg={16}>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Username '}
            <Input
              name='username'
              placeholder='Provide a Username'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.username}
              autoComplete='new-password'
              onChange={e => {
                this.onFieldChange('username', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Password '}
            <Input
              name='password'
              placeholder='Provide a Password'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.password}
              autoComplete='new-password'
              type='password'
              onChange={e => {
                this.onFieldChange('password', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Host '}
            <Input
              name='host'
              placeholder='IMAP Host (Default: localhost)'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.imapHost}
              onChange={e => {
                this.onFieldChange('imapHost', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Port '}
            <Input
              name='port'
              placeholder='IMAP Port (Default: 143)'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.imapPort}
              onChange={e => {
                this.onFieldChange('imapPort', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'TLS '}
            <Switch
              style={{ marginLeft: 10 }}
              defaultChecked={this.state.imapTLS}
              checkedChildren='Yes'
              unCheckedChildren='No'
              onChange={e => {
                this.onFieldChange('imapTLS', e === false ? '0' : '1')
                this.setState({ imapTLS: e })
              }}
            />
          </Form.Item>
          {this.state.imapTLS ?
            <Form.Item>
              <h3>
                {'TLS Options '}
              </h3>
              <AceEditor
                showPrintMargin={false}
                readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                cursorStart={1}
                mode='json'
                theme='xcode'
                style={{ width: '100%', height: 200 }}
                name='aceEditor'
                value={this.state.entry.tlsOptions}
                setOptions={{
                  showLineNumbers: true,
                  newLineMode: 'unix'
                }}
                onChange={value => {
                  this.onFieldChange('tlsOptions', value)
                }}
              />
            </Form.Item>
            : null}
        </Col>
      </Row>
    )
  }
}

export default ConnectorsFormTypeIMAP
