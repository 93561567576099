import MemoryStore from '../utils/memory-store'
import Globals from '../utils/globals'
import Enums from '../utils/enums'

import Axios from 'agilite-utils/axios'
import toLower from 'lodash/toLower'

export const filterViewData = (viewData, searchFilter) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return viewData
  } else {
    return viewData.filter(t =>
      (
        (t.firstName.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.lastName.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.email.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.role.toLowerCase().indexOf(searchFilter) > -1)
      )
    )
  }
}

export const enableTeamUser = (id, callback) => {
  const url = `${Globals.config.apiServerUrl}/${Enums.APP_IDS.TEAM_USERS}/enable`
  const config = {
    headers: {
      'api-key': MemoryStore.apiKey,
      'record-id': id,
      'team-name': MemoryStore.userProfile.teamId
    }
  }

  let errMsg = null

  Axios.get(url, config)
    .then(function (response) {
      callback(null, response)
    })
    .catch(function (error) {
      if (error.response) {
        callback(null, error.response)
      } else {
        errMsg = 'Unknown Error Occurred'
        callback(errMsg)
      }
    })

  return null
}

export const disableTeamUser = (id, callback) => {
  const url = `${Globals.config.apiServerUrl}/${Enums.APP_IDS.TEAM_USERS}/disable`

  const config = {
    headers: {
      'api-key': MemoryStore.apiKey,
      'record-id': id,
      'team-name': MemoryStore.userProfile.teamId
    }
  }

  let errMsg = null

  Axios.get(url, config)
    .then(function (response) {
      callback(null, response)
    })
    .catch(function (error) {
      if (error.response) {
        callback(null, error.response)
      } else {
        errMsg = 'Unknown Error Occurred'
        callback(errMsg)
      }
    })

  return null
}

export const formatRecord = (entry, data) => {
  let tempIndex = 0

  entry.key = entry._id // Needed for View's Unique key requirement
  entry.email = toLower(entry.email)

  if (entry.custom.isNewDoc) {
    // Add new Entry to State
    entry.custom.isNewDoc = false
    entry.custom.action = 'create'
    data.push(entry)
  } else {
    if (entry.custom.isSavedDoc) {
      entry.custom.action = 'update'
    } else {
      entry.custom.action = 'create'
    }

    // Update Entry in State
    tempIndex = data.findIndex(t => t._id === entry._id)
    data[tempIndex] = entry
  }

  return data
}
