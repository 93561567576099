import { connect } from 'react-redux'
import { filterSubscriptionsViewData } from '../../events/events-utils'
import EventsSubscriptionsView from '../components/events-subscriptions-view'

const mapStateToProps = state => {
  return {
    theme: state.core.theme,
    state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    filterViewData: (data, searchFilter, callback) => {
      filterSubscriptionsViewData(data, searchFilter, callback)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsSubscriptionsView)
