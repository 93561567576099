import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resendTeamActivation, initHomePage } from '../core-actions'
import CoreTeamActivateConfirmation from '../components/core-team-activate-confirmation'

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      resendTeamActivation,
      initHomePage
    },
    dispatch
  )
}

export default connect(
  null,
  mapDispatchToProps
)(CoreTeamActivateConfirmation)
