import { connect } from 'react-redux'

import { setSearchFilter } from '../connectors-actions'
import { filterViewData, createCopy } from '../connectors-utils'
import { generateGrouping } from '../../utils/utilities'

import ListViewContainer from '../../reusables/containers/list-view-container'
import Enums from '../../utils/enums'
import Globals from '../../utils/globals'

const mapStateToProps = state => {
  if (!state[state.core.tabObject.activeApp]) return {}
  if (state.core.tabObject.activeApp !== Enums.APP_IDS.CONNECTORS) return {}

  const appId = state.core.tabObject.activeApp
  const data = state[appId].data

  data.map(entry => {
    return (entry.data.connectionTypeLabel = Globals.returnConnectorTypeLabel(
      entry.data.connectionType
    ))
  })

  const viewData = filterViewData(data, state[appId].searchFilter)
  const connectionTypeLabelFilter = generateGrouping(viewData, 'connectionTypeLabel')
  const isActiveDescriptionFilter = generateGrouping(viewData, 'isActiveDescription')

  return {
    viewData,
    connectionTypeLabelFilter,
    isActiveDescriptionFilter
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCreateCopy: (recordData, callback) => {
      createCopy(recordData, callback)
    },
    setSearchFilter: value => {
      dispatch(setSearchFilter(value))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListViewContainer)
