import axios from 'agilite-utils/axios'
import validate from 'agilite-utils/validate-js'
import toLower from 'lodash/toLower'
import startCase from 'lodash/startCase'
import orderBy from 'lodash/orderBy'
import trim from 'lodash/trim'
import Agilite from 'agilite'
import PasswordValidator from 'password-validator'

import MemoryStore from '../utils/memory-store'
import Globals from '../utils/globals'
import Enums from '../utils/enums'
import { prepRecordForAdminPortal } from '../utils/utilities'
import { toProperCase } from 'agilite-utils'
import { postData } from './core-utils'

// MODULECONDITION
import { actions as actionsKeywords } from '../keywords/keywords-actions'
import { actions as actionsTierStructure } from '../tier-structures/tier-structure-actions'
import { actions as actionsNumbering } from '../numbering/numbering-actions'
import { actions as actionsTeamUsers } from '../team-users/team-users-actions'
import { actions as actionsRoles } from '../roles/roles-actions'
import { actions as actionsApiKeys } from '../api-keys/api-keys-actions'
import { actions as actionsTemplates } from '../templates/template-actions'
import { actions as actionsDataMapping } from '../data-mapping/data-mapping-actions'
import { actions as actionsBpm } from '../bpm/bpm-actions'
import { actions as actionsConnectors } from '../connectors/connectors-actions'
import { actions as actionsBotBuilder } from '../bot-builder/bot-builder-actions'
import { actions as actionsGatewayAdapters } from '../gateway-adapters/gateway-adapters-actions'
import { actions as actionsBatchActions } from '../batch-actions/batch-actions-actions'
import { actions as actionsEvents } from '../events/events-actions'
import { actions as actionsBatchLogging } from '../batch-logging/batch-logging-actions'
import { actions as actionsNodeRed } from '../node-red/node-red-actions'
import { actions as actionsSolutions } from '../solutions/solutions-actions'
import { actions as actionsErrorLogs } from '../error-logs/error-logs-actions'

// Schema for password validation
const schema = new PasswordValidator()

schema
  .is().min(8)
  .has().symbols()
  .has().digits()
  .has().uppercase()

export const actions = {
  SIGN_IN_USER: 'SIGN_IN_USER',
  SIGN_OUT_USER: 'SIGN_OUT_USER',
  SIGN_IN_CLICKED: 'SIGN_IN_CLICKED',
  LOGIN_FAILED: 'LOGIN_FAILED',
  INIT_HOME_PAGE: 'INIT_HOME_PAGE',
  INIT_REGISTER_FORM: 'INIT_REGISTER_FORM',
  INIT_RESET_FORM: 'INIT_RESET_FORM',
  INIT_ACTIVATE_FORM: 'INIT_ACTIVATE_FORM',
  INIT_FORGOT_PASSWORD_FORM: 'INIT_FORGOT_PASSWORD_FORM',
  INIT_REGISTER_CONFIRMATION_PAGE: 'INIT_REGISTER_CONFIRMATION_PAGE',
  INIT_RESET_CONFIRMATION_PAGE: 'INIT_RESET_CONFIRMATION_PAGE',
  INIT_ACTIVATE_CONFIRMATION_PAGE: 'INIT_ACTIVATE_CONFIRMATION_PAGE',
  INIT_ACTIVATE_SUCCESS_PAGE: 'INIT_ACTIVATE_SUCCESS_PAGE',
  INIT_FORGOT_PASSWORD_CONFIRMATION_PAGE: 'INIT_FORGOT_PASSWORD_CONFIRMATION_PAGE',
  INIT_VERIFIED_SUCCESS_PAGE: 'INIT_VERIFIED_SUCCESS_PAGE',
  INIT_VERIFIED_FAILED_PAGE: 'INIT_VERIFIED_FAILED_PAGE',
  INIT_VERIFIED_CONFIRM_PAGE: 'INIT_VERIFIED_CONFIRM_PAGE',
  UPDATE_TABS: 'UPDATE_TABS',
  CHANGE_TAB: 'CHANGE_TAB',
  CLOSE_TAB: 'CLOSE_TAB',
  RESET_TABS: 'RESET_TABS'
}

export const signInClick = () => {
  return dispatch => {
    dispatch({
      type: actions.SIGN_IN_CLICKED
    })
  }
}

export const initHomePage = () => {
  return {
    type: actions.INIT_HOME_PAGE
  }
}

export const initRegisterForm = () => {
  return {
    type: actions.INIT_REGISTER_FORM
  }
}

export const initResetForm = () => {
  return {
    type: actions.INIT_RESET_FORM
  }
}

export const initActivateForm = () => {
  return {
    type: actions.INIT_ACTIVATE_FORM
  }
}

export const initForgotPasswordForm = () => {
  return {
    type: actions.INIT_FORGOT_PASSWORD_FORM
  }
}

export const switchResourcesMenu = index => {
  return dispatch => {
    // TODO: Does this need a dispatch?
    let url = ''

    switch (index) {
      case 0: // Agilit-e Full Documentation
        url = 'https://docs.agilite.io'
        break
      case 1: // Agilit-e API Reference Docs
        url = 'https://docs.agilite.io/reference'
        break
      case 2: // Agilit-e NPM Modules
        url = 'https://www.npmjs.com/~agilite'
        break
      case 3: // Agilit-e Github Projects
        url = 'https://github.com/agilitehub'
        break
      case 4: // Agilit-e Website
        url = 'https://www.agilite.io'
        break
      case 5: // Agilit-e Blog
        url = 'https://blog.agilite.io'
        break
      case 6: // Agilit-e YouTube
        url = 'https://www.youtube.com/channel/UCFgN9BRpvEVLPzEQYJQ15eA'
        break
      case 7: // Facebook
        url = 'https://www.facebook.com/agilitehub'
        break
      case 8: // Twitter
        url = 'https://twitter.com/AgiliteHub'
        break
      default:
    }

    window.open(url)
  }
}

export const signOutUser = () => {
  return dispatch => {
    dispatch({
      type: actions.SIGN_OUT_USER
    })
  }
}

export const fetchAllData = (app, callback) => {
  return dispatch => {
    const result = true
    let actionType = ''
    let index = -1

    MemoryStore.agilite
      .executeCRUDRequest(app, Enums.REQ_TYPE.GET)
      .then((response) => {
        // We need to add custom object to each record
        for (const x in response.data) {
          // If Team Users, return index of current user's record
          if (app === Enums.APP_IDS.TEAM_USERS) {
            if (response.data[x].email === MemoryStore.userProfile.email) {
              index = parseInt(x)
            }
          }

          prepRecordForAdminPortal(response.data[x], app)
        }

        // MODULECONDITION
        switch (app) {
          case Enums.APP_IDS.KEYWORDS:
            actionType = actionsKeywords.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.TIER_STRUCTURES:
            actionType = actionsTierStructure.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.NUMBERING:
            actionType = actionsNumbering.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.CONNECTORS:
            actionType = actionsConnectors.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.TEAM_USERS:
            // Remove current user's entry from list
            if (index > -1) response.data.splice(index, 1)
            actionType = actionsTeamUsers.FETCH_ALL_DATA

            break
          case Enums.APP_IDS.ROLES:
            actionType = actionsRoles.FETCH_ALL_DATA

            // Sort Data By Role Name, then Responsible User
            response.data = orderBy(
              response.data,
              ['data.name', 'data.responsibleUser'],
              ['asc', 'asc']
            )

            break
          case Enums.APP_IDS.API_KEYS:
            for (const x in response.data) {
              response.data[x].teamRights.isActiveDescription = response.data[x].teamRights.isActive ? 'Yes' : 'No'
            }

            actionType = actionsApiKeys.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.TEMPLATES:
            actionType = actionsTemplates.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.DATA_MAPPING:
            actionType = actionsDataMapping.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.BPM:
            actionType = actionsBpm.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.BOT_BUILDER:
            actionType = actionsBotBuilder.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.GATEWAY_ADAPTERS:
            actionType = actionsGatewayAdapters.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.BATCH_ACTIONS:
            actionType = actionsBatchActions.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.EVENTS:
            actionType = actionsEvents.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.BATCH_LOGGING:
            actionType = actionsBatchLogging.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.NODE_RED:
            actionType = actionsNodeRed.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.SOLUTIONS:
            actionType = actionsSolutions.FETCH_ALL_DATA
            break
          case Enums.APP_IDS.ERROR_LOGS:
            actionType = actionsErrorLogs.FETCH_ALL_DATA
            break
          default:
          // Do Nothing
        }

        callback(result)

        dispatch({
          type: actionType,
          payload: response.data
        })
      })
      .catch((error) => {
        console.log(error)

        if (error.response) {
          callback(error.response.data.errorMessage)
        } else {
          callback(null, Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }
}

export const verifyUser = token => {
  const config = {
    headers: { token },
    method: Enums.REQ_TYPE.GET,
    url: `${Globals.config.apiServerUrl}/entity/verify`
  }

  return axios.request(config)
}

export const signInUser = callback => {
  return dispatch => {
    const entry = MemoryStore.registerForm
    const email = entry.email
    const trueValue = true

    let result = true
    let alertMessage = ''
    let constraints = {}
    let validMessage = ''

    // First, validate email address
    if (!email || email === '') {
      result = false
      alertMessage = 'Please provide an Email Address'
    } else {
      // Check if value is a valid email address
      constraints = {
        username: {
          email: true
        }
      }
      validMessage = validate(
        {
          username: email
        },
        constraints
      )

      if (validMessage) {
        result = false
        alertMessage = 'Email is not a valid address'
      }
    }

    if (result) {
      // Finalise formatting of the values
      entry.email = toLower(email)

      // Authenticate User
      axios
        .get(`${Globals.config.apiServerUrl}/auth/user`, { headers: { email: entry.email, password: entry.password } })
        .then(response => {
          MemoryStore.agilite = new Agilite({
            apiServerUrl: Globals.config.apiServerUrl,
            apiKey: response.data.apiKey,
            teamId: response.data.teamRights.defaultTeam
          })

          MemoryStore.apiKey = response.data.apiKey // TODO: Delete when you finished updating the Admin Portal to use Agilite Node Module
          delete response.data.apiKey

          MemoryStore.userProfile = {
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            email: response.data.email,
            teamId: response.data.teamRights.defaultTeam,
            teams: response.data.teamRights.teams,
            privileges: response.data.teamRights.privileges,
            role: response.data.teamRights.role,
            membershipType: response.data.teamRights.membershipType,
            canCreateTeam: false
          }
          MemoryStore.registerForm = {}
          MemoryStore.userProfile.teamRights = {}
          MemoryStore.defaultLanguage = response.data.defaultLanguage

          // Default the first team in the list if there is none, else return error if no teams are found
          if (!MemoryStore.userProfile.teamId && MemoryStore.userProfile.teams.length < 1) {
            result = false
            alertMessage = 'No Teams found for your user account'
          } else {
            MemoryStore.userProfile.teamPrivileges = MemoryStore.userProfile.privileges
            MemoryStore.userProfile.teamRights[MemoryStore.userProfile.teamId] = {
              role: response.data.teamRights.role,
              type: response.data.teamRights.membershipType
            }

            // We need to determine if the user can create a team
            MemoryStore.userProfile.canCreateTeam = false

            if (MemoryStore.userProfile.role === 'Admin') {
              switch (MemoryStore.userProfile.membershipType) {
                case '1':
                  if (MemoryStore.userProfile.teams.length === 0) {
                    MemoryStore.userProfile.canCreateTeam = true
                  }

                  break
                default:
                  MemoryStore.userProfile.canCreateTeam = true
              }
            }

            dispatch({
              type: actions.SIGN_IN_USER
            })

            fetchISOLanguages()
              .then(res => {
                MemoryStore.isoLanguages = res.data
              })
              .catch(err => {
                console.log(err)
              })
          }
        })
        .catch(err => {
          console.log(err)
          result = false

          if (err.response) {
            if (
              err.response.data.errorMessage
                .toLowerCase()
                .indexOf('your account is not yet active') !== -1
            ) {
              dispatch({ type: actions.INIT_VERIFIED_CONFIRM_PAGE })
              return false
            }

            alertMessage = `${err.response.data.errorMessage}`
          } else {
            alertMessage = Enums.MESSAGES.UNKNOWN_ERROR
          }

          callback(trueValue, alertMessage)
          dispatch({ type: actions.LOGIN_FAILED })
        })
    }

    // Finalise
    if (result) {
      alertMessage = ''
    } else {
      callback(trueValue, alertMessage) // TODO: Why are we returning true as a first parameter
      dispatch({ type: actions.LOGIN_FAILED })
    }
  }
}

export const registerUser = callback => {
  return dispatch => {
    let result = true
    let alertMessage = ''
    let constraints = {}
    let validMessage = ''

    const entry = MemoryStore.registerForm
    const firstName = entry.firstName
    const lastName = entry.lastName
    const teamId = entry.teamId
    const membershipType = entry.membershipType
    const email = entry.email
    const password = entry.password
    const passwordConfirm = entry.passwordConfirm

    // TODO: Check if these field validations are still required
    // Validate First Name
    if (!firstName || firstName === '') {
      result = false
      alertMessage = 'Please provide a First Name'
    }

    // Validate Last Name
    if (!lastName || lastName === '') {
      result = false
      alertMessage = 'Please provide a Last Name'
    }

    // First, validate email address
    if (!email || email === '') {
      result = false
      alertMessage = 'Please provide an Email Address'
    } else {
      // Check if value is a valid email address
      constraints = {
        username: {
          email: true
        }
      }
      validMessage = validate(
        {
          username: email
        },
        constraints
      )

      if (validMessage) {
        result = false
        alertMessage = 'Email is not a valid address'
      }
    }

    // Validate Team ID
    if (!teamId || teamId === '') {
      result = false
      alertMessage = 'Please provide a Team ID'
    }

    // Validate Membership Type
    if (!membershipType || membershipType === '') {
      result = false
      alertMessage = 'Please provide a Membership Type'
    }

    // Then, validate password and Confirm
    if (!password || password === '') {
      result = false
      alertMessage = 'Please provide a Password'
    } else if (!passwordConfirm || passwordConfirm === '') {
      result = false
      alertMessage = 'Please confirm your Password'
    }

    if (result) {
      // Finalise formatting of the values
      entry.firstName = startCase(toLower(trim(firstName)))
      entry.lastName = startCase(toLower(trim(lastName)))
      entry.teamId = teamId.replace(/[^\w]/gi, '').toLowerCase()
      entry.email = toLower(email)

      // Register User API
      axios.post(`${Globals.config.apiServerUrl}/entity/register`, entry)
        .then(response => {
          MemoryStore.registerForm = {}

          dispatch({
            type: actions.INIT_REGISTER_CONFIRMATION_PAGE
          })
        })
        .catch(err => {
          result = false

          if (err.response) {
            alertMessage = `${err.response.data.errorMessage}`
          } else {
            alertMessage = Enums.MESSAGES.UNKNOWN_ERROR
          }

          callback(result, alertMessage)
        })
    }

    // Finalise
    if (result) {
      alertMessage = ''
    } else {
      callback(result, alertMessage)
    }
  }
}

export const resendVerification = callback => {
  return dispatch => {
    const email = toLower(MemoryStore.registerForm.email)
    const config = {
      headers: {
        email
      }
    }
    let result = null

    // Submit API
    axios.get(`${Globals.config.apiServerUrl}/entity/resendVerification`, config)
      .then(response => {
        result = `Email verification successfully submitted to ${MemoryStore.registerForm.email}`
        callback(result)
      })
      .catch(err => {
        callback(err.response.data.errorMessage)
      })
  }
}

export const resendTeamActivation = callback => {
  return dispatch => {
    const email = toLower(MemoryStore.registerForm.email)
    const data = { email }
    let result = null

    // Submit API
    axios.post(`${Globals.config.apiServerUrl}/teamusers/resendActivation`, data)
      .then(response => {
        result = `Email Activation successfully submitted to ${MemoryStore.registerForm.email}`
        callback(result)
      })
      .catch(err => {
        callback(err.response.data.errorMessage)
      })
  }
}

export const updateUserProfile = () => {
  // TODO: This needs to be refactored still
  return dispatch => {
    const htmlStart = '<div><ul>'
    const htmlEnd = '</ul></div>'
    const tmpDiv = document.getElementById('divMessages')
    const entry = MemoryStore.userProfile
    const firstName = entry.firstName
    const lastName = entry.lastName

    let result = true
    let htmlContent = ''
    let html = ''

    // Validate First Name
    if (firstName === '') {
      result = false
      htmlContent += '<li>Please provide a First Name</li>'
    } else {
      // Convert to Uppercase
      entry.firstName = toProperCase(firstName)
    }

    // Validate Last Name
    if (lastName === '') {
      result = false
      htmlContent += '<li>Please provide a Last Name</li>'
    } else {
      // Convert to Uppercase
      entry.lastName = toProperCase(lastName)
    }

    if (result) {
      // Finalise formatting of the values
      entry.firstName = startCase(toLower(trim(firstName)))
      entry.lastName = startCase(toLower(trim(lastName)))

      // Register User API
      axios.post(`${Globals.config.apiServerUrl}/user/update`, entry)
        .then(response => {
          dispatch({
            type: actions.INIT_PREVIOUS_APP // TODO: This doesn't exist anymore. Instead provide a sucess message
          })
        })
        .catch(err => {
          result = false

          htmlContent += `<li>${err.response.data.errorMessage}</li>`
          html = htmlStart + htmlContent + htmlEnd
          tmpDiv.innerHTML = html
        })
    }

    // Finalise
    if (result) {
      html = ''
    } else {
      html = htmlStart + htmlContent + htmlEnd
    }

    tmpDiv.innerHTML = html
  }
}

export const forgotPassword = callback => {
  return dispatch => {
    const email = MemoryStore.registerForm.email
    let result = true
    let alertMessage = ''
    let constraints = {}
    let validMessage = ''

    // First, validate email address
    if (!email || email === '') {
      result = false
      alertMessage = 'Please provide an Email Address'
    } else {
      // Check if value is a valid email address
      constraints = {
        username: {
          email: true
        }
      }
      validMessage = validate(
        {
          username: email
        },
        constraints
      )

      if (validMessage) {
        result = false
        alertMessage = 'Email is not a valid address'
      }
    }

    if (result) {
      // Finalise formatting of the values
      MemoryStore.registerForm.email = toLower(email)

      // Register User API
      axios
        .get(`${Globals.config.apiServerUrl}/user/forgotPassword`, { headers: { email: MemoryStore.registerForm.email } })
        .then(response => {
          MemoryStore.registerForm = {}
          dispatch({ type: actions.INIT_FORGOT_PASSWORD_CONFIRMATION_PAGE })
        })
        .catch(err => {
          result = false

          if (err.response) {
            if (
              err.response.data.errorMessage ===
              'Your account is not yet active. Please check your mailbox for a verification mail from Agilit-e'
            ) {
              dispatch({ type: actions.INIT_VERIFIED_CONFIRM_PAGE })
              return false
            } else if (
              err.response.data.errorMessage ===
              'Your account is not yet active. Please check your mailbox for a activation mail from Agilit-e'
            ) {
              dispatch({ type: actions.INIT_ACTIVATE_CONFIRMATION_PAGE })
              return false
            }

            alertMessage = `${err.response.data.errorMessage}`
          } else {
            alertMessage = Enums.MESSAGES.UNKNOWN_ERROR
          }

          callback(result, alertMessage)
        })
    }

    // Finalise
    if (result) {
      alertMessage = ''
    } else {
      callback(result, alertMessage)
    }
  }
}

export const resetUser = (token, callback) => {
  return dispatch => {
    const password = MemoryStore.registerForm.password
    const passwordConfirm = MemoryStore.registerForm.passwordConfirm
    let result = true
    let alertMessage = ''

    // Then, validate password and Confirm
    if (password === '') {
      result = false
      alertMessage = 'Please provide a new Password'
    } else if (passwordConfirm === '') {
      result = false
      alertMessage = 'Please confirm your new Password'
    }

    if (result) {
      // Register User API
      axios.post(`${Globals.config.apiServerUrl}/user/resetPassword`, { password: MemoryStore.registerForm.password, passwordConfirm: MemoryStore.registerForm.passwordConfirm }, { headers: { token } })
        .then(response => {
          MemoryStore.registerForm = {}

          dispatch({
            type: actions.INIT_RESET_CONFIRMATION_PAGE
          })
        })
        .catch(err => {
          result = false

          if (err.response) {
            alertMessage = `${err.response.data.errorMessage}`
          } else {
            alertMessage = Enums.MESSAGES.UNKNOWN_ERROR
          }

          callback(result, alertMessage)
        })
    }

    // Finalise
    if (result) {
      alertMessage = ''
    } else {
      callback(result, alertMessage)
    }
  }
}

export const changePassword = callback => {
  const email = MemoryStore.userProfile.email
  const currentPassword = MemoryStore.registerForm.currentPassword
  const password = MemoryStore.registerForm.password
  const passwordConfirm = MemoryStore.registerForm.passwordConfirm
  let result = true
  let alertMessage = ''

  // Then, validate passwords
  if (password === '') {
    result = false
    alertMessage = 'Please provide a new Password'
  } else if (passwordConfirm === '') {
    result = false
    alertMessage = 'Please confirm your new Password'
  } else if (currentPassword === '') {
    result = false
    alertMessage = 'Please provide your Current Password'
  }

  if (result) {
    axios
      .post(`${Globals.config.apiServerUrl}/user/changePassword`,
        {
          email,
          password,
          passwordConfirm,
          currentPassword
        },
        {
          headers: {
            'api-key': MemoryStore.apiKey,
            email: MemoryStore.userProfile.email
          }
        }
      )
      .then(response => {
        MemoryStore.registerForm = {}
        callback(result)
      })
      .catch(err => {
        result = false

        if (err.response) {
          alertMessage = `${err.response.data.errorMessage}`
        } else {
          alertMessage = Enums.MESSAGES.UNKNOWN_ERROR
        }

        callback(result, alertMessage)
      })
  }

  // Finalise
  if (result) {
    alertMessage = ''
  } else {
    callback(result, alertMessage)
  }
}

export const activateUser = callback => {
  return dispatch => {
    let result = true
    let alertMessage = ''

    const entry = MemoryStore.registerForm
    const firstName = entry.firstName
    const lastName = entry.lastName
    const password = entry.password
    const passwordConfirm = entry.passwordConfirm

    // Then, validate password and Confirm
    if (firstName === '') {
      result = false
      alertMessage = 'Please provide your First Name'
    } else if (lastName === '') {
      result = false
      alertMessage = 'Please provide your Last Name'
    } else if (password === '') {
      result = false
      alertMessage = 'Please provide a new Password'
    } else if (passwordConfirm === '') {
      result = false
      alertMessage = 'Please confirm your new Password'
    }

    // Trim Values
    entry.firstName = entry.firstName.trim()
    entry.lastName = entry.lastName.trim()

    if (result) {
      // Activate User API
      axios
        .post(`${Globals.config.apiServerUrl}/teamusers/activate`, entry, { headers: { token: MemoryStore.registerForm.id } })
        .then(response => {
          MemoryStore.registerForm = {}

          callback(result, alertMessage)

          dispatch({
            type: actions.INIT_ACTIVATE_SUCCESS_PAGE
          })
        })
        .catch(err => {
          result = false
          if (err.response) {
            alertMessage = `${err.response.data.errorMessage}`
          } else {
            alertMessage = Enums.MESSAGES.UNKNOWN_ERROR
          }

          callback(result, alertMessage)
        })
    }

    // Finalise
    if (result) {
      alertMessage = ''
    } else {
      callback(result, alertMessage)
    }
  }
}

export const createTeam = (teamId, callback) => {
  return dispatch => { // TODO: Do we need dispatch here?
    let result = null

    const config = {
      headers: {},
      method: Enums.REQ_TYPE.GET,
      url: `${Globals.config.apiServerUrl}/entity/createTeam`
    }

    config.headers[Enums.HEADER_PROPS.API_KEY] = MemoryStore.apiKey
    config.headers[Enums.HEADER_PROPS.TEAM_ID] = teamId

    axios.request(config)
      .then((response) => {
        // Update User Profile and make new team active
        MemoryStore.userProfile.teams.push(teamId)
        MemoryStore.userProfile.teamPrivileges = response.data.teamRights.privileges
        MemoryStore.userProfile.teamId = teamId

        MemoryStore.agilite = new Agilite({
          apiServerUrl: Globals.config.apiServerUrl,
          apiKey: MemoryStore.apiKey,
          teamId
        })

        callback(null, response)
      })
      .catch((error) => {
        if (error.response) {
          callback(error.response.data.errorMessage)
        } else {
          result = Enums.MESSAGES.UNKNOWN_ERROR
          callback(result)
        }
      })
  }
}

export const switchTeam = (teamId, callback) => {
  return dispatch => {
    const config = {
      headers: { 'team-id': teamId, 'api-key': MemoryStore.apiKey },
      method: Enums.REQ_TYPE.GET,
      url: `${Globals.config.apiServerUrl}/user/team/switch`
    }

    axios.request(config)
      .then(res => callback(null, res))
      .catch(err => {
        if (err.response) {
          callback(err.response.data.errorMessage)
        } else {
          callback(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }
}

export const addTab = payload => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_TABS,
      payload
    })
  }
}

export const changeTab = (key, tabs) => {
  let tabType = ''
  let app = ''

  // Get the active Tab to return tabType
  for (const x in tabs) {
    if (tabs[x].key === key) {
      app = tabs[x].app
      tabType = tabs[x].tabType
    }
  }

  return dispatch => {
    dispatch({
      type: actions.CHANGE_TAB,
      key,
      app,
      tabType
    })
  }
}

export const resetTabs = () => {
  return dispatch => {
    dispatch({
      type: actions.RESET_TABS
    })
  }
}

export const closeTab = (activeKey, targetKey, tabs) => {
  let lastIndex = null
  let tabType = ''
  let targetTabType = ''
  let targetApp = ''
  let activeApp = ''

  // Find Target Pane to perform the resets
  for (const x in tabs) {
    if (tabs[x].key === targetKey) {
      targetTabType = tabs[x].tabType
      targetApp = tabs[x].app

      if (targetKey === activeKey) lastIndex = parseInt(x) - 1

      break
    }
  }

  const newTabs = tabs.filter(pane => pane.key !== targetKey)

  // Determine Active Tab
  if (activeKey !== targetKey) {
    for (const x in newTabs) {
      if (newTabs[x].key === activeKey) {
        tabType = newTabs[x].tabType
        activeApp = newTabs[x].app

        break
      }
    }
  } else if (lastIndex >= 0) {
    activeKey = newTabs[lastIndex].key
    activeApp = newTabs[lastIndex].app
    tabType = newTabs[lastIndex].tabType
  }

  return dispatch => {
    dispatch({
      type: actions.CLOSE_TAB,
      tabs: newTabs,
      activeKey,
      activeApp,
      tabType
    })

    // We need to also dispatch module-related actions for views/forms
    switch (targetTabType) {
      case Enums.VALUES_STRINGS.VIEW:
        // MODULECONDITION: Reset View State for Module
        switch (targetApp) {
          case Enums.APP_IDS.KEYWORDS:
            dispatch({
              type: actionsKeywords.RESET_STATE
            })

            break
          case Enums.APP_IDS.TIER_STRUCTURES:
            dispatch({
              type: actionsTierStructure.RESET_STATE
            })

            break
          case Enums.APP_IDS.NUMBERING:
            dispatch({
              type: actionsNumbering.RESET_STATE
            })

            break
          case Enums.APP_IDS.CONNECTORS:
            dispatch({
              type: actionsConnectors.RESET_STATE
            })

            break
          case Enums.APP_IDS.TEAM_USERS:
            dispatch({
              type: actionsTeamUsers.RESET_STATE
            })

            break
          case Enums.APP_IDS.ROLES:
            dispatch({
              type: actionsRoles.RESET_STATE
            })

            break
          case Enums.APP_IDS.API_KEYS:
            dispatch({
              type: actionsApiKeys.RESET_STATE
            })

            break
          case Enums.APP_IDS.TEMPLATES:
            dispatch({
              type: actionsTemplates.RESET_STATE
            })

            break
          case Enums.APP_IDS.DATA_MAPPING:
            dispatch({
              type: actionsDataMapping.RESET_STATE
            })

            break
          case Enums.APP_IDS.BPM:
            dispatch({
              type: actionsBpm.RESET_STATE
            })

            break
          case Enums.APP_IDS.BOT_BUILDER:
            dispatch({
              type: actionsBotBuilder.RESET_STATE
            })

            break
          case Enums.APP_IDS.GATEWAY_ADAPTERS:
            dispatch({
              type: actionsGatewayAdapters.RESET_STATE
            })

            break
          case Enums.APP_IDS.BATCH_ACTIONS:
            dispatch({
              type: actionsBatchActions.RESET_STATE
            })

            break
          case Enums.APP_IDS.EVENTS:
            dispatch({
              type: actionsEvents.RESET_STATE
            })

            break
          case Enums.APP_IDS.BATCH_LOGGING:
            dispatch({
              type: actionsBatchLogging.RESET_STATE
            })

            break
          case Enums.APP_IDS.NODE_RED:
            dispatch({
              type: actionsNodeRed.RESET_STATE
            })

            break
          case Enums.APP_IDS.SOLUTIONS:
            dispatch({
              type: actionsSolutions.RESET_STATE
            })

            break
          case Enums.APP_IDS.ERROR_LOGS:
            dispatch({
              type: actionsErrorLogs.RESET_STATE
            })

            break
          default:
        }

        break
      case Enums.VALUES_STRINGS.FORM:
        // Remove entry from Active Entries
        delete MemoryStore.activeEntries[targetKey]
        break
      default:
    }
  }
}

export const deleteProfile = (appId, recordId, state, callback) => {
  return dispatch => {
    const reqType = 'delete'
    const tempArray = state.data.concat()
    const tempIndex = state.data.findIndex(t => t._id === recordId)
    const entry = state.data[tempIndex]
    const headers = {}

    // Create Headers Object
    headers['record-id'] = recordId

    // Post Delete request to Agilit-e if the record exists in Agilit-e
    if (entry.custom.isSavedDoc) {
      postData(appId, reqType, null, headers)
        .then((response) => {
          tempArray.splice(tempIndex, 1)
          dispatch({
            type: 'UPDATE_DATA_' + appId.toUpperCase(),
            data: tempArray
          })
          callback()
        })
        .catch((error) => {
          callback(error.response.data.errorMessage)
          console.log(error)
          return null
        })
    } else {
      tempArray.splice(tempIndex, 1)
      dispatch({ type: 'UPDATE_DATA_' + appId.toUpperCase(), data: tempArray })
    }
  }
}

export function validatePassword (data, password, callback) {
  return dispatch => {
    let valid = null

    data.map(entry => {
      if (entry.property !== 'password') {
        entry.complete = true
      }
      return true
    })

    valid = schema.validate(password, { list: true })
    callback(valid)
  }
}

export function verifyRecaptchaToken (token, callback) {
  return dispatch => {
    // TODO: Replace static header properties with Enums
    const config = {
      headers: { 'recaptcha-token': token },
      method: Enums.REQ_TYPE.GET,
      url: `${Globals.config.apiServerUrl}/utils/verifyRecaptchaToken`
    }

    axios.request(config)
      .then(res => callback(res))
      .catch(err => callback(null, err))
  }
}

export function verifyRecaptchaTokenv2 (token, callback) {
  return dispatch => {
    // TODO: Replace static header properties with Enums
    const config = {
      headers: { 'recaptcha-token': token },
      method: Enums.REQ_TYPE.GET,
      url: `${Globals.config.apiServerUrl}/utils/verifyRecaptchaTokenv2`
    }

    axios.request(config)
      .then(res => callback(res))
      .catch(err => callback(null, err))
  }
}

export const generateReport = (callback) => {
  MemoryStore.agilite.Utils.homePageReports()
    .then((response) => {
      callback(null, response.data)
    })
    .catch((error) => {
      if (error.response) {
        callback(error.response.data.errorMessage)
      } else {
        callback(Enums.MESSAGES.UNKNOWN_ERROR)
      }
    })
}

// Private Functions

const fetchISOLanguages = () => {
  return MemoryStore.agilite.Utils.returnISOLanguages()
}
