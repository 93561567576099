import React from 'react'
import { Skeleton, Row, Col } from 'antd'
// TODO: We need to use a setTimeout to properly test the Loading screen

const CoreLoading = () => (
  <div>
    <Row type='flex' justify='center'>
      <Col xs={24} sm={16} md={12} lg={8}>
        <Skeleton active />
        <Skeleton active />
      </Col>
    </Row>
  </div>
)

export default CoreLoading
