import React from 'react'
import { Row, Col, Select, Input, Switch, Form, Card } from 'antd'

/*
  Custom Components Import
*/
import SingleValueTableEditableDND from '../../../reusables/components/single-value-table-editable-dnd'
import TooltipIcon from '../../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../../utils/enums-tooltips'
import Description from '../../../reusables/components/description'
import Notes from '../../../reusables/components/notes'
import MemoryStore from '../../../utils/memory-store'

const { TextArea } = Input

class GatewayMappingForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry
    }

    this.handleOnChange = this.handleOnChange.bind(this)
    this.updateIPWhitelist = this.updateIPWhitelist.bind(this)
    this.updateIPBlacklist = this.updateIPBlacklist.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)

    if (props.entry.ipWhitelist.length === 0) {
      props.entry.ipWhitelist.push('')
    }

    if (props.entry.ipBlacklist.length === 0) {
      props.entry.ipBlacklist.push('')
    }
  }

  updateIPWhitelist (data) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.ipWhitelist = data
  }

  updateIPBlacklist (data) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.ipBlacklist = data
  }

  handleOnChange (key, value, index) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry[key][index] = { value }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry[key] = value
  }

  render () {
    return (
      <Card>
        <Row type='flex' justify='space-between'>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form>
              <Form.Item>
                <Switch
                  disabled={this.props.disabled}
                  checkedChildren='Active'
                  unCheckedChildren='Inactive'
                  defaultChecked={this.props.entry.isActive}
                  onChange={(e) => {
                    this.onFieldChange('isActive', e)
                  }}
                />
              </Form.Item>
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>{'Profile Name '}
                <TooltipIcon title={EnumsTooltips.general.profileName} />
                <Input
                  name='mapping_name'
                  disabled={this.props.disabled}
                  placeholder='A short name for the Profile'
                  defaultValue={this.props.entry.name}
                  onChange={(e) => {
                    this.onFieldChange('name', e.target.value)
                  }}
                />
              </Form.Item>
              <Description
                disabled={this.props.disabled}
                description={this.props.entry.description}
                onChange={(e) => {
                  this.onFieldChange('description', e.target.value)
                }}
              />
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>Route URL Endpoint
                <Input
                  name='route_url'
                  disabled={this.props.disabled}
                  placeholder='e.g. /endpoint1 - Relative to Adapter URL'
                  defaultValue={this.props.entry.endpoint}
                  onChange={(e) => {
                    this.onFieldChange('endpoint', e.target.value)
                  }}
                />
              </Form.Item>
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>Targeted URL Endpoint
                <TextArea
                  className='target_url'
                  disabled={this.props.disabled}
                  placeholder='e.g. http://targetedendpoint.com'
                  defaultValue={this.props.entry.targetedEndpoint}
                  rows={2}
                  type='url'
                  onChange={(e) => {
                    this.onFieldChange('targetedEndpoint', e.target.value)
                  }}
                />
              </Form.Item>
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>Request Type
                <Select
                  className='request_type'
                  disabled={this.props.disabled}
                  defaultValue={this.props.entry.requestType}
                  onChange={(value) => {
                    this.onFieldChange('requestType', value)
                  }}
                >
                  <Select.Option value=''>-Select-</Select.Option>
                  <Select.Option value='get'>GET</Select.Option>
                  <Select.Option value='post'>POST</Select.Option>
                  <Select.Option value='put'>PUT</Select.Option>
                  <Select.Option value='patch'>PATCH</Select.Option>
                  <Select.Option value='delete'>DELETE</Select.Option>
                </Select>
              </Form.Item>
              <div>
                <SingleValueTableEditableDND
                  data={this.props.entry.ipWhitelist}
                  title='IP Whitelist (optional)'
                  theme={this.props.theme}
                  callback={this.updateIPWhitelist}
                  onChange={this.handleOnChange}
                  arrayKey='ipWhitelist'
                  disabled={this.props.disabled}
                />
              </div>
              <div>
                <SingleValueTableEditableDND
                  data={this.props.entry.ipBlacklist}
                  title='IP Blacklist (optional)'
                  theme={this.props.theme}
                  callback={this.updateIPBlacklist}
                  onChange={this.handleOnChange}
                  arrayKey='ipBlacklist'
                  disabled={this.props.disabled}
                />
              </div>
              <Notes
                disabled={this.props.disabled}
                notes={this.props.entry.notes}
                onChange={(e) => {
                  this.onFieldChange('notes', e.target.value)
                }}
              />
            </Form>
          </Col>
        </Row>
      </Card>
    )
  }
}

export default GatewayMappingForm
