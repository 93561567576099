import tierStructureState from './tier-structure-state'
import { actions } from './tier-structure-actions'

export default (state = tierStructureState, action) => {
  switch (action.type) {
    case actions.RESET_STATE:
      return Object.assign({}, state, tierStructureState)
    case actions.SET_SEARCH_FILTER:
      return Object.assign({}, state, {
        searchFilter: action.value
      })
    case actions.FETCH_ALL_DATA:
      return Object.assign({}, state, {
        data: action.payload
      })
    case actions.UPDATE_DATA:
      return Object.assign({}, state, {
        data: action.data
      })
    default:
      return state
  }
}
