import React from 'react'
import find from 'lodash/find'
import { Radio, Input, Form, Row, Col, Switch, AutoComplete, message, Select, Button, Modal, Tooltip } from 'antd'
import { TranslationOutlined, EditOutlined } from '@ant-design/icons'
import MemoryStore from '../../../utils/memory-store'
import TooltipIcon from '../../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../../utils/enums-tooltips'

/*
  Custom Components Import
*/
import SingleValueTableEditableDND from '../../../reusables/components/single-value-table-editable-dnd'
import CompileTemplateIcon from '../../../reusables/components/compile-template-icon'
import Description from '../../../reusables/components/description'
import Notes from '../../../reusables/components/notes'
import ModuleIcon from '../../../reusables/components/module-icon'
import Enums from '../../../utils/enums'
import GroupNameContainer from '../../../reusables/containers/group-name-container'
import { getRoleProfiles, saveRoleProfile } from '../../bpm-utils'
import LanguageTranslation from '../../../reusables/components/language-translation'

const { TextArea } = Input

class ProcessStepFormGeneral extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.roleProfileTemplate = MemoryStore.roles.dataTemplate
    this.tmpRoleProfile = JSON.parse(JSON.stringify(this.roleProfileTemplate))
    this.steps = [
      {
        label: 'First Step',
        value: 'First Step',
        key: '1'
      },
      {
        label: 'Active Step',
        value: 'Active Step',
        key: '2'
      },
      {
        label: 'On Hold Step',
        value: 'On Hold Step',
        key: '3'
      },
      {
        label: 'Cancelled Step',
        value: 'Cancelled Step',
        key: '4'
      },
      {
        label: 'Completed Step',
        value: 'Completed Step',
        key: '5'
      }
    ]

    this.iln = this.props.entry.iln ? this.props.entry.iln : {}

    this.state = {
      entry: props.entry,
      stepType: props.entry.stepType,
      responsibility: props.entry.responsibility,
      templates: [],
      roleProfilesElements: [],
      roleProfilesData: [],
      responsibleRole: props.entry.responsibleRole,
      modal: {
        visible: false
      },
      languageModalVisible: false,
      dataKey: null,
      key: this.props.stepKey
    }

    // Setup Event Binding
    this.getRoleProfiles = this.getRoleProfiles.bind(this)
    this.handleSaveRole = this.handleSaveRole.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.onRoleDataChange = this.onRoleDataChange.bind(this)
    this.updateData = this.updateData.bind(this)
    this.handleOnRoleLevelChange = this.handleOnRoleLevelChange.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.updateRoleLevels = this.updateRoleLevels.bind(this)
    this.updateEventStamps = this.updateEventStamps.bind(this)
    this.getNotificationTemplates = this.getNotificationTemplates.bind(this)
    this.handleDataChange = this.handleDataChange.bind(this)
    this.handleDataSubmit = this.handleDataSubmit.bind(this)

    if (props.entry.roleLevels.length === 0) {
      props.entry.roleLevels.push({
        value: ''
      })
    }

    if (props.entry.eventStamp.length === 0) {
      props.entry.eventStamp.push({
        value: ''
      })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.stepKey !== this.state.key) this.setState({ key: nextProps.stepKey })
  }

  UNSAFE_componentWillMount () {
    if (this.props.processSteps.length > 0 && this.props.entry.isNewEntry === true) {
      const tmpState = Object.assign({}, this.state)
      const step = find(this.props.processSteps, { stepType: 'First Step' })

      if (step) {
        tmpState.entry.stepType = 'Active Step'
        tmpState.stepType = 'Active Step'
      } else {
        tmpState.entry.stepType = 'First Step'
        tmpState.stepType = 'First Step'
      }

      this.setState({
        entry: tmpState.entry,
        stepType: tmpState.stepType
      })
    }

    if (MemoryStore.userProfile.teamPrivileges.bpm !== 'Reader') {
      this.getNotificationTemplates(this.props.templateState)
      this.getRoleProfiles()
    }
  }

  getRoleProfiles () {
    const tmpThis = this
    let tmpProfiles = []
    let profiles = []
    let result = null

    return new Promise((resolve, reject) => {
      (async () => {
        try {
          result = await getRoleProfiles()

          result.data.map(profile => {
            return tmpProfiles.push(profile.data.name)
          })

          profiles = [...new Set(tmpProfiles)]
          tmpProfiles = []

          profiles = profiles.sort()

          profiles.map(profile => {
            return tmpProfiles.push(
              <Select.Option key={profile}>{profile}</Select.Option>
            )
          })

          tmpThis.setState({
            roleProfileElements: tmpProfiles,
            roleProfilesData: profiles
          })

          resolve()
        } catch (error) {
          message.error(error)
          reject(error)
        }
      })()
    })
  }

  async handleSaveRole () {
    const tmpThis = this

    try {
      // Validate
      if (!this.tmpRoleProfile.data.name) return message.error('Please provide a Role Name')
      if (find(this.state.roleProfilesData, e => e.toLowerCase() === this.tmpRoleProfile.data.name.toLowerCase())) return message.error('Role Name already exists')
      if (!this.tmpRoleProfile.data.tmpResponsibleUser) return message.error('Please provide a Responsible User')

      this.tmpRoleProfile.data.responsibleUser = this.tmpRoleProfile.data.tmpResponsibleUser.split(',')

      await saveRoleProfile(tmpThis.tmpRoleProfile)
      await this.getRoleProfiles()
      message.success('Role Profile Successfully created')
      this.props.entry.responsibleRole = tmpThis.tmpRoleProfile.data.name
      this.setState({
        responsibleRole: tmpThis.tmpRoleProfile.data.name,
        modal: { visible: false }
      })
    } catch (e) {
      message.error(e)
    }
  }

  handleCloseModal () {
    this.tmpRoleProfile = JSON.parse(JSON.stringify(this.roleProfileTemplate))
    this.setState({ modal: { visible: false } })
  }

  onRoleDataChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.tmpRoleProfile.data[key] = value
  }

  updateData (data) {
    this.entry.custom.isModifiedSubform = true
    this.tmpRoleProfile.data.levels = data
  }

  handleOnRoleLevelChange (key, value, index) {
    this.entry.custom.isModifiedSubform = true
    this.tmpRoleProfile.data[key][index] = { value }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry[key] = value

    if (key === 'stepType') this.setState({ stepType: value })
    if (key === 'key') this.setState({ key: value })
  }

  handleOnChange (key, value, index) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry[key][index] = { value }
  }

  updateRoleLevels (data) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.roleLevels = data
  }

  updateEventStamps (data) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.eventStamp = data
  }

  getNotificationTemplates (state) {
    const templates = []

    state.data.map(entry => {
      if (entry.data.templateType === 'memo') {
        templates.push({
          key: entry.data.key,
          label: entry.data.key,
          value: entry.data.key
        })
      }

      return true
    })

    this.setState({ templates })
  }

  handleDataChange (data) {
    this.iln[this.state.dataKey] = data
  }

  handleDataSubmit () {
    this.setState({ languageModalVisible: false })
    this.onFieldChange('iln', this.iln)
  }

  render () {
    const radioStyle = {
      display: 'inline',
      height: '30px',
      lineHeight: '30px'
    }

    return (
      <Row type='flex' justify='space-between'>
        <Col xs={24} lg={11}>
          <Form.Item>
            <Switch
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              checkedChildren='Active'
              unCheckedChildren='Inactive'
              defaultChecked={this.props.entry.isActive}
              onChange={e => {
                this.onFieldChange('isActive', e)
              }}
            />
          </Form.Item>
          <Form.Item>
            Step Type
            <Select
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              defaultValue={this.props.entry.stepType}
              onChange={value => {
                this.onFieldChange('stepType', value)
              }}
            >
              {this.steps.map(stepEntry => {
                return <Select.Option key={stepEntry.key} value={stepEntry.value}>{stepEntry.label}</Select.Option>
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Process Step Key '}
            <TooltipIcon title={EnumsTooltips.general.profileKey} />
            <Input
              name='step_key'
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader' || !this.props.isNewDoc}
              placeholder='Provide a Process Step Key'
              required
              value={this.state.key}
              onChange={e => {
                this.onFieldChange('key', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Process Step Name '}
            <TooltipIcon title={EnumsTooltips.general.profileName} />
            <Tooltip title='Add language alternatives for field value'>
              <TranslationOutlined
                onClick={() => this.setState({ languageModalVisible: true, dataKey: 'name' })}
                style={{ color: 'blueviolet', fontSize: 16, marginLeft: 8 }}
              />
            </Tooltip>
            <Input
              name='step_name'
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              placeholder='Provide a Process Step Name'
              required
              defaultValue={this.props.entry.name}
              onChange={e => {
                this.onFieldChange('name', e.target.value)
                this.iln.name = {
                  ...this.iln.name,
                  [MemoryStore.defaultLanguage]: e.target.value
                }
              }}
            />
          </Form.Item>
          <Description
            disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
            description={this.props.entry.description}
            onChange={e => {
              this.onFieldChange('description', e.target.value)
              this.iln.description = {
                ...this.iln.description,
                [MemoryStore.defaultLanguage]: e.target.value
              }
            }}
            icon={
              <Tooltip title='Add language alternatives for field value'>
                <TranslationOutlined
                  onClick={() => this.setState({ languageModalVisible: true, dataKey: 'description' })}
                  style={{ color: 'blueviolet', fontSize: 16, marginLeft: 8 }}
                />
              </Tooltip>
            }
          />
          <Notes
            disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
            notes={this.props.entry.notes}
            onChange={e => {
              this.onFieldChange('notes', e.target.value)
              this.iln.notes = {
                ...this.iln.notes,
                [MemoryStore.defaultLanguage]: e.target.value
              }
            }}
            icon={
              <Tooltip title='Add language alternatives for field value'>
                <TranslationOutlined
                  onClick={() => this.setState({ languageModalVisible: true, dataKey: 'notes' })}
                  style={{ color: 'blueviolet', fontSize: 16, marginLeft: 8 }}
                />
              </Tooltip>
            }
          />
          <Form.Item>
            {'Reference URL (optional) '}
            <TooltipIcon title={EnumsTooltips.bpm.referenceUrl} />
            <Input
              name='step_ref_url'
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              placeholder='Reference URL for videos, resources, etc.'
              defaultValue={this.props.entry.referenceUrl}
              onChange={e => {
                this.onFieldChange('referenceUrl', e.target.value)
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Process Stage '}
            <Tooltip title='Add language alternatives for field value'>
              <TranslationOutlined
                onClick={() => this.setState({ languageModalVisible: true, dataKey: 'processStage' })}
                style={{ color: 'blueviolet', fontSize: 16, marginLeft: 8 }}
              />
            </Tooltip>
            <Input
              name='process_stage'
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              placeholder='Provide a Process Stage'
              required
              defaultValue={this.props.entry.processStage}
              onChange={e => {
                this.onFieldChange('processStage', e.target.value)
                this.iln.processStage = {
                  ...this.iln.processStage,
                  [MemoryStore.defaultLanguage]: e.target.value
                }
              }}
            />
          </Form.Item>
          {this.state.stepType !== 'Completed Step' && this.state.stepType !== 'Cancelled Step' ?
            <Form.Item>
              <span style={{ color: 'red' }}>* </span>
              {'Expected Duration (Minutes) '}
              <CompileTemplateIcon />
              <Input
                name='sla_duration'
                disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                placeholder='Provide an expected SLA Duration (Minutes)'
                defaultValue={this.props.entry.duration}
                required
                onChange={e => {
                  this.onFieldChange('duration', e.target.value)
                }}
              />
            </Form.Item>
            : null}
          <div>
            <SingleValueTableEditableDND
              data={this.props.entry.eventStamp}
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              title='Event Stamps for entering Step (optional)'
              theme={this.props.theme}
              callback={this.updateEventStamps}
              onChange={this.handleOnChange}
              arrayKey='eventStamp'
            />
          </div>
          {this.state.stepType !== 'Completed Step' && this.state.stepType !== 'Cancelled Step' ?
            <Form.Item>
              {'Instructions (optional) '}
              <Tooltip title='Add language alternatives for field value'>
                <TranslationOutlined
                  onClick={() => this.setState({ languageModalVisible: true, dataKey: 'instructions' })}
                  style={{ color: 'blueviolet', fontSize: 16, marginLeft: 8 }}
                />
              </Tooltip>
              <TextArea
                className='instructions'
                disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                placeholder='Instructions for this Step'
                defaultValue={this.props.entry.instructions}
                rows={3}
                onChange={e => {
                  this.onFieldChange('instructions', e.target.value)
                  this.iln.instructions = {
                    ...this.iln.instructions,
                    [MemoryStore.defaultLanguage]: e.target.value
                  }
                }}
              />
            </Form.Item>
            : null}
          {this.state.stepType !== 'First Step' && this.state.stepType !== 'Completed Step' && this.state.stepType !== 'Cancelled Step' ?
            <Form.Item style={{ marginTop: 20 }}>
              <h3>Role Assignment</h3>
              <Radio.Group
                value={this.state.responsibility}
                onChange={e => {
                  this.onFieldChange('responsibility', e.target.value)
                  this.setState({ responsibility: e.target.value })
                }}
              >
                <Radio
                  style={radioStyle}
                  value='1'
                  disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                >
                  Manual
                </Radio>
                <Radio
                  style={radioStyle}
                  value='2'
                  disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                >
                  Automatic
                </Radio>
              </Radio.Group>
            </Form.Item>
            : null}
          {this.state.stepType !== 'Completed Step' && this.state.stepType !== 'First Step' && this.state.stepType !== 'Cancelled Step' && this.state.responsibility === '2' ?
            <Form.Item>
              <span style={{ color: 'red' }}>* </span>
              <ModuleIcon module='ROLES' />
              {' Responsible Role'}
              <Select
                placeholder='Provide a Responsible Role'
                disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                value={this.state.responsibleRole}
                style={{ width: '85%' }}
                required
                onChange={value => {
                  this.onFieldChange('responsibleRole', value)
                  this.setState({ responsibleRole: value })
                }}
              >
                <Select.Option value=''>-Select-</Select.Option>
                {this.state.roleProfileElements}
                <Select.Option value='add_new'>-Add New-</Select.Option>
              </Select>
              {this.state.responsibleRole !== 'add_new' ?
                null
                :
                <Button
                  type='default'
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    this.setState({ modal: { visible: true } })
                    this.tmpRoleProfile = JSON.parse(
                      JSON.stringify(this.roleProfileTemplate)
                    )
                  }}
                >
                  <EditOutlined />
                </Button>
              }
            </Form.Item>
            :
            <Form.Item style={{ display: this.state.stepType === 'Completed Step' || this.state.stepType === 'Cancelled Step' ? 'none' : 'contents' }}>
              <span style={{ color: 'red' }}>* </span>
              Responsible Role
              <Input
                name='responsible_role'
                disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                placeholder='Provide a Responsible Role'
                defaultValue={this.props.entry.responsibleRole}
                required
                onChange={e => {
                  this.onFieldChange('responsibleRole', e.target.value)
                }}
              />
            </Form.Item>
          }
          {this.props.entry.responsibility === '2' ?
            <div>
              <SingleValueTableEditableDND
                data={this.props.entry.roleLevels}
                disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                title={<span>Conditional Role Levels (optional) <CompileTemplateIcon /></span>}
                theme={this.props.theme}
                callback={this.updateRoleLevels}
                onChange={this.handleOnChange}
                arrayKey='roleLevels'
              />
            </div>
            : null}
          {this.state.stepType !== 'First Step' && this.state.stepType !== 'Completed Step' && this.state.stepType !== 'Cancelled Step' ?
            <Form.Item>
              {'Notification Template '}
              <CompileTemplateIcon />
              <AutoComplete
                className='notification_template'
                disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                defaultValue={this.props.entry.notificationTemplate}
                options={this.state.templates}
                onSelect={value => {
                  this.onFieldChange('notificationTemplate', value)
                }}
                onChange={value => {
                  this.onFieldChange('notificationTemplate', value)
                }}
              />
            </Form.Item>
            : null}
        </Col>
        <Modal
          visible={this.state.modal.visible}
          title={Enums.APP_PROFILE_TITLES.roles}
          closable={false}
          okText='Ok'
          okButtonProps={{
            style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' }
          }}
          onCancel={this.handleCloseModal}
          onOk={this.handleSaveRole}
          width={1050}
          style={{ top: 10 }}
          destroyOnClose
        >
          <Form autoComplete='off'>
            <Row gutter={32} type='flex' justify='space-around'>
              <Col xs={24} sm={16} md={8} lg={8}>
                <Form.Item>
                  <Switch
                    checkedChildren='Active'
                    unCheckedChildren='Inactive'
                    defaultChecked={this.tmpRoleProfile.data.isActive}
                    onChange={e => {
                      this.onRoleDataChange('isActive', e)
                    }}
                  />
                </Form.Item>
                <Form.Item required>
                  <span style={{ color: 'red' }}>* </span>Role Name
                  <Input
                    placeholder='Provide a Role Name'
                    defaultValue={this.tmpRoleProfile.data.name}
                    required
                    onChange={e => {
                      this.onRoleDataChange('name', e.target.value)
                    }}
                  />
                </Form.Item>
                <Description
                  disabled={false}
                  description={this.tmpRoleProfile.data.description}
                  onChange={e => {
                    this.onRoleDataChange('description', e.target.value)
                  }}
                />
                <GroupNameContainer
                  defaultValue={this.tmpRoleProfile.data.groupName}
                  onValueSelect={value => {
                    this.onRoleDataChange('groupName', value)
                  }}
                  onValueChange={value => {
                    this.onRoleDataChange('groupName', value)
                  }}
                />
              </Col>
              <Col xs={24} sm={16} md={12} lg={14} style={{ marginTop: 50 }}>
                <Form.Item required>
                  <span style={{ color: 'red' }}>* </span>
                  {'Responsible User '}
                  <CompileTemplateIcon />
                  <Input
                    placeholder='This can be a Name, Email, ID, etc.'
                    defaultValue={this.tmpRoleProfile.data.responsibleUser.join(',')}
                    required
                    onChange={e => {
                      this.onRoleDataChange('tmpResponsibleUser', e.target.value)
                    }}
                  />
                </Form.Item>
                <div>
                  <SingleValueTableEditableDND
                    data={this.tmpRoleProfile.data.levels}
                    disabled={false}
                    title='Conditional Levels (optional) {{}}'
                    theme={this.props.theme}
                    callback={this.updateData}
                    arrayKey='levels'
                    onChange={this.handleOnRoleLevelChange}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          title='Language Translate'
          visible={this.state.languageModalVisible}
          onOk={this.handleDataSubmit}
          onCancel={() => this.setState({ languageModalVisible: false })}
          closable={false}
          width='50%'
          destroyOnClose
          okButtonProps={{
            style: {
              backgroundColor: '#67AD5B',
              color: 'white'
            }
          }}
          okText='Submit'
        >
          <LanguageTranslation
            theme={this.props.theme}
            disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
            data={this.iln[this.state.dataKey]}
            dataKey={this.state.dataKey}
            defaultLanguage={MemoryStore.defaultLanguage}
            privileges={MemoryStore.userProfile.teamPrivileges.bpm}
            fieldValue={this.props.entry[this.state.dataKey]}
            onDataChange={this.handleDataChange}
          />
        </Modal>
      </Row>
    )
  }
}

export default ProcessStepFormGeneral
