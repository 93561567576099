import React from 'react'
import { Icon } from 'react-fa'
import { Popconfirm, Table, Row, Col, Input, Button, Spin, Tooltip, message, Tag } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import MemoryStore from '../../utils/memory-store'

class ListViewTree extends React.Component {
  constructor (props) {
    super(props)

    this.appId = props.appId

    this.state = {
      viewData: this.props.viewData,
      searchFilter: this.props.searchFilter,
      viewColumns: this.props.viewColumns,
      newViewData: this.updateViewData(this.props),
      loading: false
    }

    this.fetchAllData = this.fetchAllData.bind(this)
    this.generateViewHeader = this.generateViewHeader.bind(this)
    this.createProfile = this.createProfile.bind(this)
    this.editProfile = this.editProfile.bind(this)
    this.deleteProfile = this.deleteProfile.bind(this)
    this.updateViewColumns = this.updateViewColumns.bind(this)
    this.createCopy = this.createCopy.bind(this)

    this.updateViewColumns(this.props)
  }

  UNSAFE_componentWillMount () {
    this.fetchAllData()
  }

  fetchAllData () {
    this.setState({
      loading: true
    })

    this.props.fetchAllData(this.props.appId, (success, error) => {
      if (success) {
        this.setState({ loading: false })
      } else {
        message.error(error)
        this.setState({ loading: false })
      }
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.appId !== nextProps.appId) {
      return null
    }

    const newViewData = this.updateViewData(nextProps)
    this.updateViewColumns(nextProps)

    this.setState({
      viewData: nextProps.viewData,
      searchFilter: nextProps.searchFilter,
      viewColumns: nextProps.viewColumns,
      newViewData
    })
  }

  createCopy (recordData) {
    const tmpThis = this

    tmpThis.props.onCreateCopy(recordData, function (result) {
      if (result) {
        // Setup Content property and Add Tab
        result.content = <result.content />
        tmpThis.props.addTab(result)
      }
    })
  }

  updateViewData (props) {
    // Refactor View Data to group by relevant column and generate children data
    const columns = props.viewColumns
    const tmpArrayKey = []
    const newViewData = []
    let column = null
    let tmpIndex = 0
    let tmpEntry = null
    let viewEntry = null

    for (const x in columns) {
      // Find column that has children data
      if (columns[x].hasChildren) {
        column = columns[x]
        break
      }
    }

    for (const x in props.viewData) {
      tmpEntry = props.viewData[x].data ? props.viewData[x].data : props.viewData[x]

      for (const y in tmpEntry) {
        if (column.key === y) {
          tmpIndex = tmpArrayKey.indexOf(tmpEntry[y].toLowerCase())

          if (tmpIndex === -1) {
            tmpIndex = tmpArrayKey.length
            tmpArrayKey.push(tmpEntry[y].toLowerCase())

            viewEntry = {}
            viewEntry.key = tmpEntry[y]
            viewEntry.custom = props.viewData[x].custom
            viewEntry.children = []

            if (props.viewData[x].data) {
              viewEntry.data = {}
              viewEntry.data[y] = tmpEntry[y]
            } else {
              viewEntry[y] = tmpEntry[y]
            }

            newViewData.push(viewEntry)
          } else {
            viewEntry = newViewData[tmpIndex]
          }

          viewEntry.children.push(JSON.parse(JSON.stringify(props.viewData[x])))
        }
      }
    }

    return newViewData
  }

  updateViewColumns (props) {
    // Add Intelligence to relevant Columns
    const columns = props.viewColumns

    for (const x in columns) {
      // Add render function to all columns at first
      // The element rendered here will ensure that the content does not exceed width
      columns[x].render = (text) => {
        return (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {text}
          </div>
        )
      }

      // Show Solutions
      if (columns[x].key === 'solutions') {
        columns[x].render = (text, record) => {
          if (record.data.solutions && !record.children) {
            return (
              record.data.solutions.map((entry, index) => {
                return <Tag style={{ margin: 2 }} color='geekblue' key={index}>{entry}</Tag>
              })
            )
          }
        }
      }

      // Check which column is used to edit the record
      if (columns[x].editRecord) {
        columns[x].render = (text, record) => {
          if (record.children) {
            return (<span>{text}</span>)
          } else {
            // eslint-disable-next-line
            return (<a onClick={() => this.editProfile(record._id)}>Edit</a>)
          }
        }
      }

      if (columns[x].isActions) {
        // eslint-disable-next-line
        columns[x].render = (text, record) => {
          if (!record.children) {
            return (

              <div style={{ minWidth: 50 }}>
                {/* //First Delete Action */}
                {((columns[x].actionTypes.indexOf('delete') > -1) && (MemoryStore.userProfile.teamPrivileges[this.props.appId] !== 'Reader')) ?
                  <div className={process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'}>
                    <Popconfirm
                      title='Are you sure delete this Profile?'
                      onConfirm={() => {
                        this.deleteProfile(record._id)
                      }}
                      okText='Yes'
                      cancelText='No'
                    >
                      {/* eslint-disable-next-line */}
                      <a title='Delete Profile'>
                        <Icon name='trash-o' style={{ color: props.theme.dangerColor, marginRight: 10 }} />
                      </a>
                    </Popconfirm>
                  </div>
                  : null}
                {/* Create Copy */}
                {columns[x].actionTypes.indexOf('create-copy') > -1 ?
                  <div className={process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'} style={{ marginLeft: 10, marginRight: 10 }}>
                    {/* eslint-disable-next-line */}
                    <a title='Create Copy'>
                      <Icon name='clone' onClick={() => this.createCopy(record.data)} style={{ color: 'pink' }} />
                    </a>
                  </div>
                  : null}
                {/* //Then Generate API Keys Action */}
                {columns[x].actionTypes.indexOf('generate-api-key') > -1 ?
                  <div className={process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'}>
                    {/* eslint-disable-next-line */}
                    <a>
                      <Icon name='trash-o' style={{ color: props.theme.dangerColor }} />
                    </a>
                  </div>
                  : null}
              </div>
            )
          }
        }
      }
    }
  }

  createProfile () {
    const tmpThis = this

    tmpThis.props.createProfile(tmpThis.props.appId, function (result) {
      if (result) {
        // Setup Content property and Add Tab
        result.content = <result.content />
        tmpThis.props.addTab(result)
      }
    })
  }

  editProfile (recordId) {
    const tmpThis = this

    tmpThis.props.editProfile(tmpThis.props.appId, recordId, this.props.viewData, function (result) {
      if (result) {
        // Setup Content property and Add Tab
        result.content = <result.content />
        tmpThis.props.addTab(result)
      }
    })
  }

  deleteProfile (recordId) {
    this.props.deleteProfile(this.props.appId, recordId, this.props.state, (err) => {
      if (err) {
        message.error(err)
      }
    })
  }

  generateViewHeader () {
    return (
      <div>
        <Row type='flex'>
          <Col xs={7} sm={5} md={4} lg={4} xl={3} xxl={2} style={{ textAlign: 'left' }}>
            <Button
              disabled={MemoryStore.userProfile.teamPrivileges[this.props.appId] === 'Reader' ? true : false}
              style={{ backgroundColor: '#67AD5B', color: 'white' }}
              onClick={e => {
                e.preventDefault()
                this.createProfile()
              }}
            >
              Create New
            </Button>
          </Col>
          <Col xs={13} sm={14} md={16} lg={16} xl={19} xxl={20}>
            <Input
              name='search'
              placeholder={`Search ${this.props.viewTitle}...`}
              defaultValue={this.props.searchFilter}
              onChange={(e) => {
                this.props.setSearchFilter(e.target.value)
              }}
            />
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={2} xxl={2}>
            <Tooltip title='Refresh View Data'>
              <Button
                type='default'
                onClick={(e) => {
                  e.preventDefault()
                  this.fetchAllData()
                }}
              >
                <SyncOutlined
                  style={{ fontSize: 20, fontWeight: 'bolder', color: '#67AD5B' }}
                  spin={this.state.loading}
                />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </div>
    )
  }

  render () {
    return (
      <Row type='flex' justify='center'>
        <Col xs={23}>
          <Table
            loading={this.state.loading}
            columns={this.state.viewColumns}
            dataSource={this.state.newViewData}
            bordered
            showHeader
            title={this.generateViewHeader}
            size='middle'
            pagination={false}
          />
        </Col>
      </Row>
    )
  }
}

export default ListViewTree
