import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { validatePassword } from '../core-actions'
import CoreUserProfile from '../components/core-user-profile'

const mapStateToProps = state => {
  return {
    theme: state.core.theme
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      validatePassword
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoreUserProfile)
