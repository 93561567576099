import { connect } from 'react-redux'
import GatewayMappings from '../components/gateway-mappings/gateway-mappings'
import MemoryStore from '../../utils/memory-store'
import { fetchAllData } from '../../core/core-actions'
import { filterMappings } from '../gateway-adapters-utils'

const mapStateToProps = (state) => {
  const tabKey = state.core.tabObject.activeKey
  const entry = MemoryStore.activeEntries[tabKey]

  return {
    entry,
    theme: state.core.theme,
    state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllData: (appId, callback) => {
      dispatch(fetchAllData(appId, callback))
    },
    filterViewData: (data, searchFilter, callback) => {
      filterMappings(data, searchFilter, callback)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GatewayMappings)
