import MemoryStore from '../utils/memory-store'
import Enums from '../utils/enums'

/*
  ADVANCED STATE FUNCTIONS
*/
export const generateReport = (startDate, endDate, callback) => {
  MemoryStore.agilite.Utils.dashboardReports(startDate, endDate)
    .then((response) => {
      callback(null, response)
    })
    .catch((error) => {
      if (error.response) {
        callback(error.response.data.errorMessage)
      } else {
        callback(Enums.MESSAGES.UNKNOWN_ERROR)
      }
    })
}
