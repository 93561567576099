import React from 'react'
import MemoryStore from '../../utils/memory-store'
import { message, Form, Button, Col, Row, Card, Switch, Input, Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import TooltipIcon from '../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../utils/enums-tooltips'
import Description from '../../reusables/components/description'
import Enums from '../../utils/enums'

class SolutionsForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc,
      isLoading: false
    }

    // Setup Event Binding
    this.onFieldChange = this.onFieldChange.bind(this)
    this.saveProfile = this.saveProfile.bind(this)
    this.cancelProfile = this.cancelProfile.bind(this)
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.entry.data[key] = value
  }

  cancelProfileConfirm () {
    if (this.entry.custom.isModified) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  saveProfile () {
    const tmpThis = this

    tmpThis.setState({
      isLoading: true
    })

    // Reset isModified and isModifiedSubform properties
    this.entry.custom.isModified = false
    this.entry.custom.isModifiedSubform = false

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, (err) => {
      tmpThis.setState({
        isLoading: false
      })

      if (err) {
        message.error(err)
      } else {
        tmpThis.props.closeTab(tmpThis.props.tabKey, tmpThis.props.tabKey, tmpThis.props.tabs)
      }
    })
  }

  render () {
    return (
      <Row type='flex' justify='center'>
        <Col xs={23} lg={20} xl={16}>
          <Card
            title={this.props.title}
            style={{ textAlign: 'left' }}
            type='inner'
          >
            <Form
              autoComplete='off'
            >
              <Row type='flex' justify='space-between'>
                <Col xs={24} lg={11}>
                  <Form.Item>
                    <Switch
                      ref='isActive'
                      disabled={MemoryStore.userProfile.teamPrivileges.solutions === 'Reader'}
                      checkedChildren='Active'
                      unCheckedChildren='Inactive'
                      defaultChecked={this.entry.data.isActive}
                      onChange={(e) => {
                        this.onFieldChange('isActive', e)
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <span style={{ color: 'red' }}>* </span>{'Profile Key '}
                    <TooltipIcon title={EnumsTooltips.general.profileKey} />
                    <Input
                      name='key'
                      placeholder='Provide a unique Profile Key'
                      disabled={!this.state.isNewDoc || MemoryStore.userProfile.teamPrivileges.solutions === 'Reader'}
                      defaultValue={this.entry.data.key}
                      onChange={(e) => {
                        this.onFieldChange('key', e.target.value)
                      }}
                      required
                    />
                  </Form.Item>
                  <Form.Item>
                    <span style={{ color: 'red' }}>* </span>{'Profile Name '}
                    <TooltipIcon title={EnumsTooltips.general.profileName} />
                    <Input
                      name='name'
                      placeholder='Provide a Profile Name'
                      disabled={MemoryStore.userProfile.teamPrivileges.solutions === 'Reader'}
                      defaultValue={this.entry.data.name}
                      onChange={(e) => {
                        this.onFieldChange('name', e.target.value)
                      }}
                      required
                    />
                  </Form.Item>
                  <Description
                    disabled={MemoryStore.userProfile.teamPrivileges.solutions === 'Reader'}
                    description={this.entry.data.description}
                    onChange={(e) => {
                      this.onFieldChange('description', e.target.value)
                    }}
                  />
                </Col>
              </Row>
              <Row>
                {MemoryStore.userProfile.teamPrivileges.solutions !== 'Reader' ?
                  <Button
                    name='submit'
                    disabled={this.state.isLoading}
                    style={{ backgroundColor: '#67AD5B', color: 'white', width: 80, marginRight: 10, float: 'left' }}
                    onClick={() => this.saveProfile()}
                  >
                    {this.state.isLoading ? <LoadingOutlined spin /> : <span>Submit</span>}
                  </Button>
                  : null}
                <Button
                  name='cancel'
                  disabled={this.state.isLoading}
                  label='Cancel'
                  onClick={e => {
                    e.preventDefault()
                    this.cancelProfileConfirm()
                  }}
                  style={{ backgroundColor: '#EB5181', color: 'white', width: 'auto', marginRight: 10, float: 'left' }}
                >
                  Cancel
                </Button>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default SolutionsForm
