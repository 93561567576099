import React from 'react'
import { Row, Col, Select, Input, Switch, Form, Card } from 'antd'
import Description from '../../../reusables/components/description'
import Notes from '../../../reusables/components/notes'

const { TextArea } = Input

/*
  Custom Components Import
*/

class BotProcessForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      entry: props.entry,
      processType: props.entry.processType,
      processName: props.entry.name,
      bpmProcesses: [],
      bpmProcessesNames: []
    }

    this.onChangeProcessType = this.onChangeProcessType.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.setProcessName = this.setProcessName.bind(this)
    this.getBPMProcesses = this.getBPMProcesses.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.getBPMProcesses(this.props.bpmState)
  }

  onChangeProcessType (value) {
    this.setState({
      processType: value
    })
  }

  onFieldChange (key, value) {
    this.props.entry[key] = value
  }

  setProcessName (index) {
    const processName = this.state.bpmProcessesNames[index]
    this.props.entry.name = processName
    this.setState({ processName })
  }

  getBPMProcesses (bpmState) {
    const bpmProcesses = []
    const bpmProcessesNames = []

    bpmState.data.map(entry => {
      bpmProcesses.push(entry.data.key)
      bpmProcessesNames.push(entry.data.name)
      return true
    })

    this.setState({ bpmProcesses, bpmProcessesNames })
  }

  render () {
    return (
      <Card>
        <Row type='flex' justify='space-between'>
          <Col xs={24} sm={24} md={14} lg={14}>
            <Form>
              <Form.Item>
                <Switch
                  checkedChildren='Active'
                  unCheckedChildren='Inactive'
                  defaultChecked={this.props.entry.isActive}
                  onChange={e => {
                    this.onFieldChange('isActive', e)
                  }}
                />
              </Form.Item>
              <Form.Item>
                Process Type
                <Select
                  defaultValue={this.props.entry.processType}
                  onChange={value => {
                    this.onFieldChange('processType', value)
                    this.onChangeProcessType(value)
                  }}
                >
                  <Select.Option value='1'>BPM Bot</Select.Option>
                </Select>
              </Form.Item>
              {this.state.processType === '1' ? (
                <Form.Item>
                  <span style={{ color: 'red' }}>* </span>Process Key
                  <Select
                    defaultValue={this.props.entry.processKey}
                    onChange={(e, value) => {
                      this.onFieldChange('processKey', e)
                      this.setProcessName(value.key)
                    }}
                  >
                    <Select.Option value=''>-Select-</Select.Option>
                    {this.state.bpmProcesses.map((entry, index) => {
                      return (
                        <Select.Option key={index} value={entry}>
                          {entry}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item>
                  <span style={{ color: 'red' }}>* </span>Process Key
                  <Input
                    onChange={e => {
                      this.onFieldChange('processKey', e.target.value)
                    }}
                  />
                </Form.Item>
              )}
              <Form.Item>
                Process Name
                <Input
                  placeholder='Provide a Name for this Bot Process'
                  disabled={this.state.processType === '1'}
                  value={this.state.processName}
                />
              </Form.Item>
              <Form.Item>
                Webhook (optional)
                <TextArea
                  placeholder='Webhook URL for Submitting Transactions'
                  defaultValue={this.props.entry.webhookSubmit}
                  rows={3}
                  onChange={e => {
                    this.onFieldChange('webhookSubmit', e.target.value)
                  }}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={24} md={9} lg={9} style={{ marginTop: 53 }}>
            <Description
              description={this.props.entry.description}
              onChange={e => {
                this.onFieldChange('description', e.target.value)
              }}
            />
            <Notes
              notes={this.props.entry.notes}
              onChange={e => {
                this.onFieldChange('notes', e.target.value)
              }}
            />
          </Col>
        </Row>
      </Card>
    )
  }
}

export default BotProcessForm
