import { connect } from 'react-redux'
import GroupName from '../components/group-name'

const mapStateToProps = (state) => {
  if (!state[state.core.tabObject.activeApp]) {
    return {}
  }

  const appId = state.core.tabObject.activeApp

  return {
    appId,
    state: state[appId],
    theme: state.core.theme
  }
}

export default connect(
  mapStateToProps
)(GroupName)
