import React from 'react'
import { Row, Col } from 'antd'
import { v1 } from 'agilite-utils/uuid'

import { columnTemplates } from '../../../utils/templates'
import ListViewDND from '../../../reusables/components/list-view-dnd'
import MemoryStore from '../../../utils/memory-store'

class TierEntries extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      tierEntries: props.tierEntries
    }

    this.deleteRecord = this.deleteRecord.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.formatViewData(this.props.tierEntries)
  }

  formatViewData (entries) {
    // Set isActiveDescription
    entries.map(entry => {
      entry.rowKey = v1()
      entry.isActiveDescription = entry.isActive ? 'Yes' : 'No'
      return entry.isActiveDescription
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.formatViewData(nextProps.tierEntries)
    this.setState({
      tierEntries: nextProps.tierEntries
    })
  }

  deleteRecord (recordId) {
    const tierEntries = this.props.tierEntries
    const index = tierEntries.findIndex(t => t._id === recordId)
    tierEntries.splice(index, 1)

    this.setState({
      tierEntries
    })
  }

  render () {
    return (
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <ListViewDND
            appId={this.props.appId}
            btnCreateLabel='Create New'
            viewTitle='Tier Entries'
            createRecord={() => {
              this.props.createTierEntry(this.props.tierEntry)
            }}
            editRecord={(id, index) => {
              this.props.openTierEntry(index)
            }}
            deleteRecord={this.deleteRecord}
            filterViewData={this.props.filterViewData}
            viewData={this.props.tierEntries}
            viewColumns={columnTemplates.tierentry}
            theme={this.props.theme}
            callback={this.props.updateArrayData}
          />
        </Col>
      </Row>
    )
  }
}

export default TierEntries
