import React from 'react'
import MemoryStore from '../../utils/memory-store'
import { Form, Row, Button, Card, message, Tabs, Col, Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import BatchActionsFormGeneral from './batch-actions-form-general'
import Enums from '../../utils/enums'
import BatchActionsContainer from '../../reusables/containers/batch-actions-container'

class BatchActionsForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]
    this.state = { isNewDoc: this.entry.custom.isNewDoc, errorText: '', isLoading: false }

    // Setup Event Binding
    this.cancelProfileConfirm = this.cancelProfileConfirm.bind(this)
    this.cancelProfile = this.cancelProfile.bind(this)
    this.saveProfile = this.saveProfile.bind(this)
    this.validateProfile = this.validateProfile.bind(this)
  }

  cancelProfileConfirm () {
    if (this.entry.custom.isModified) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  saveProfile () {
    const tmpThis = this

    tmpThis.setState({
      isLoading: true
    })

    // Delete unnecessary properties
    this.entry.data.actions.map(action => {
      delete action.isActiveDescription
      delete action.isNewEntry
      delete action.actionTypeDescription

      return null
    })

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, (err) => {
      tmpThis.setState({
        isLoading: false
      })

      if (err) {
        message.error(err)
      } else {
        // Reset isModified and isModifiedSubform properties
        tmpThis.entry.custom.isModified = false
        tmpThis.entry.custom.isModifiedSubform = false

        tmpThis.props.closeTab(
          tmpThis.props.tabKey,
          tmpThis.props.tabKey,
          tmpThis.props.tabs
        )
      }
    })
  }

  validateProfile () {
    let errMsg = null
    let success = true

    if (this.entry.data.actions.length < 1) {
      errMsg = 'Please provide at least one action'
      success = false
    }

    if (success) {
      this.saveProfile()
    } else {
      message.error(errMsg)
    }
  }

  render () {
    return (
      <Row type='flex' justify='center'>
        <Col xs={23} lg={20} xl={16}>
          <Card
            style={{ textAlign: 'left' }}
            title={this.props.title}
            type='inner'
          >
            <Form
              autoComplete='off'
            >
              <Tabs animated={false}>
                <Tabs.TabPane tab='General' key='general'>
                  <BatchActionsFormGeneral
                    tabKey={this.props.tabKey}
                    theme={this.props.theme}
                    entry={this.entry}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Actions' key='actions'>
                  <BatchActionsContainer
                    entry={this.entry}
                    data={this.entry.data.actions}
                  />
                </Tabs.TabPane>
              </Tabs>
              <Row>
                <div>
                  {MemoryStore.userProfile.teamPrivileges.batchactions !== 'Reader' ?
                    (
                      <Button
                        cypressid='submit'
                        style={{
                          backgroundColor: '#67AD5B',
                          color: 'white',
                          width: 80,
                          marginRight: 10
                        }}
                        onClick={() => this.validateProfile()}
                        disabled={this.state.isLoading}
                      >
                        {this.state.isLoading ? <LoadingOutlined spin /> : <span>Submit</span>}
                      </Button>
                    ) : null}
                  <Button
                    cypressid='cancel'
                    label='Cancel'
                    disabled={this.state.isLoading}
                    style={{
                      backgroundColor: '#EB5181',
                      color: 'white',
                      width: 'auto',
                      marginRight: 10
                    }}
                    onClick={e => {
                      e.preventDefault()
                      this.cancelProfileConfirm()
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default BatchActionsForm
