import React from 'react'
import { Row, Col, Tabs } from 'antd'

import TierEntryFormGeneral from './tier-entry-form-general'
import TierEntriesContainer from '../../containers/tier-entries-container'
import MemoryStore from '../../../utils/memory-store'

const TabPane = Tabs.TabPane

class TierEntryForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]
    this.tierPath = this.props.tierPath

    this.updateArrayData = this.updateArrayData.bind(this)
  }

  updateArrayData (data) {
    MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierHistory[MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierHistory.length - 1].isModified = true
    this.props.tierEntry.tierEntries = data
  }

  render () {
    return (
      <Row>
        <Col xxl={24}>
          <Tabs
            activeKey={this.props.activeTab}
            animated={false}
            onChange={(value) => {
              this.props.updateTabs(value)
            }}
          >
            <TabPane tab='General' key='1'>
              <TierEntryFormGeneral
                tierPath={this.tierPath}
                tabKey={this.props.tabKey}
                isFirstTier={this.props.isFirstTier}
                theme={this.props.theme}
                disabled={this.props.disabled}
                privileges={this.props.privileges}
                tierEntry={this.props.tierEntry}
                saveProfile={(id) => {
                  this.props.saveProfile(this.props.tierEntry, id)
                }}
                cancelProfile={this.props.cancelProfile}
              />
            </TabPane>
            <TabPane tab='Tier Entries' key='2'>
              <TierEntriesContainer
                tabKey={this.props.tabKey}
                appId={this.props.appId}
                theme={this.props.theme}
                disabled={this.props.disabled}
                openTierEntry={this.props.openTierEntry}
                createTierEntry={this.props.createTierEntry}
                tierEntries={this.props.tierEntry.tierEntries}
                tierEntry={this.props.tierEntry}
                updateArrayData={this.updateArrayData}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    )
  }
}

export default TierEntryForm
