import React from 'react'
import { Row, Modal, Col, message } from 'antd'
import MemoryStore from '../../../utils/memory-store'
import FieldMappingForm from '../field-mappings/field-mapping-form'
import ListViewDND from '../../../reusables/components/list-view-dnd'
import { columnTemplates } from '../../../utils/templates'
import Enums from '../../../utils/enums'

class FieldMappings extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      data: props.data,
      dialogOpen: false,
      activeRecord: null,
      tmpIndex: null
    }

    // Setup Event Binding
    this.createRecord = this.createRecord.bind(this)
    this.saveRecord = this.saveRecord.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.deleteRecord = this.deleteRecord.bind(this)
    this.handleValidateRecord = this.handleValidateRecord.bind(this)
    this.formatViewData = this.formatViewData.bind(this)
    this.handleCloseModalDialog = this.handleCloseModalDialog.bind(this)
    this.handleCloseModalDialogConfirm = this.handleCloseModalDialogConfirm.bind(this)

    this.formatViewData()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({
      data: nextProps.data
    })

    this.formatViewData()
  }

  createRecord () {
    const activeRecord = JSON.parse(JSON.stringify(MemoryStore.datamappings.fieldMappingTemplate))
    activeRecord.isNewEntry = true
    activeRecord._id = new Date().getTime().toString()

    this.setState({
      dialogOpen: true,
      activeRecord
    })
  }

  saveRecord () {
    const data = this.state.data.concat()
    const index = data.findIndex(t => t._id === this.state.activeRecord._id)
    let tmpRecord = this.state.activeRecord

    // Format isActiveDescription
    tmpRecord.isActiveDescription = tmpRecord.isActive ? 'Yes' : 'No'

    if (this.state.activeRecord.isNewEntry) {
      data.push(tmpRecord)
    } else {
      data.splice(index, 1, tmpRecord)
    }

    this.props.updateArrayData(data)
    tmpRecord = {}
    this.setState({ data, dialogOpen: false, activeRecord: null, tmpIndex: null })
  }

  editRecord (recordId) {
    const index = this.state.data.findIndex(t => t._id === recordId)
    const activeRecord = JSON.parse(JSON.stringify(this.state.data[index]))

    activeRecord.isNewEntry = false
    this.setState({ dialogOpen: true, activeRecord, tmpIndex: index })
  }

  deleteRecord (recordId) {
    this.entry.custom.isModified = true

    const data = this.state.data.concat()
    const index = data.findIndex(t => t._id === recordId)
    data.splice(index, 1)

    this.props.updateArrayData(data)
    this.setState({ data })
  }

  handleValidateRecord () {
    const data = this.state.data
    const activeRecord = this.state.activeRecord
    const profile = this.props.profile
    const isParentMulti = this.props.isParentMulti

    let errMsg = null
    let tmpVal = null

    // Format Prop Values
    activeRecord.name = activeRecord.name.trim()
    activeRecord.description = activeRecord.description.trim()
    activeRecord.srcPath = activeRecord.srcPath.trim()
    activeRecord.srcName = activeRecord.srcName.trim()
    activeRecord.srcNotes = activeRecord.srcNotes.trim()
    activeRecord.desPath = activeRecord.desPath.trim()
    activeRecord.desName = activeRecord.desName.trim()
    activeRecord.desNotes = activeRecord.desNotes.trim()

    // Check for duplicate entries
    for (const tmpEntry of data) {
      if (tmpEntry._id !== activeRecord._id) {
        if ((activeRecord.srcPath === tmpEntry.srcPath) && (activeRecord.srcName === tmpEntry.srcName) &&
        (activeRecord.desPath === tmpEntry.desPath) && (activeRecord.desName === tmpEntry.desName)) {
          errMsg = 'A Field Mapping with the same Source and Destination attributes already exist. Please Revise'
          break
        }
      }
    }

    if (errMsg) return message.error(errMsg)

    // Validate Prop Values
    if (!activeRecord.name) {
      errMsg = 'Please provide a Field Mapping Name'
    } else if ((profile.sourceType === '1') && !activeRecord.srcName) {
      errMsg = 'Please provide a Source Attribute Name'
    } else if ((profile.destinationType === '1') && !activeRecord.desName) {
      errMsg = 'Please provide a Destination Attribute Name'
    } else if (profile.sourceType === '2') {
      if (!activeRecord.srcName) {
        tmpVal = !isParentMulti ? 'Name' : 'Key'
        errMsg = `Please provide a Source Excel Cell ${tmpVal}`
      } else if (!isParentMulti && !activeRecord.srcPath) {
        errMsg = 'Please provide a Source Excel Worksheet Name'
      }
    } else if (profile.destinationType === '2') {
      if (!activeRecord.desName) {
        tmpVal = !isParentMulti ? 'Name' : 'Key'
        errMsg = `Please provide a Destination Excel Cell ${tmpVal}`
      } else if (!isParentMulti && !activeRecord.desPath) {
        errMsg = 'Please provide a Destination Excel Worksheet Name'
      }
    }

    if (errMsg) return message.error(errMsg)
    this.saveRecord()
  }

  formatViewData () {
    // Set isActiveDescription
    this.state.data.map(mapping => {
      mapping.isActiveDescription = mapping.isActive ? 'Yes' : 'No'
      return mapping.isActiveDescription
    })
  }

  handleCloseModalDialog () {
    this.entry.custom.isModifiedSubform = false

    this.setState({ dialogOpen: false, activeRecord: null })
  }

  handleCloseModalDialogConfirm () {
    if (this.entry.custom.isModifiedSubform) {
      Modal.confirm({
        onOk: () => this.handleCloseModalDialog(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.handleCloseModalDialog()
    }
  }

  render () {
    return (
      <Row>
        <Col span={24}>
          <ListViewDND
            btnCreateLabel='Create New'
            viewTitle='Field Mappings'
            createRecord={this.createRecord}
            editRecord={this.editRecord}
            deleteRecord={this.deleteRecord}
            filterViewData={this.props.filterViewData}
            viewData={this.state.data}
            viewColumns={columnTemplates.fieldMappings}
            theme={this.props.theme}
            callback={this.props.updateArrayData}
          />
          <Modal
            title='Field Mapping Profile'
            okButtonProps={{ style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' } }}
            okText='Ok'
            visible={this.state.dialogOpen}
            onOk={this.handleValidateRecord}
            onCancel={this.handleCloseModalDialogConfirm}
            width={1000}
            style={{ top: 10 }}
            destroyOnClose
            maskClosable={false}
          >
            <FieldMappingForm
              entry={this.state.activeRecord}
              theme={this.props.theme}
              profile={this.props.profile}
              isParentMulti={this.props.isParentMulti ? this.props.isParentMulti : false}
              tabKey={this.props.tabKey}
            />
          </Modal>
        </Col>
      </Row>
    )
  }
}

export default FieldMappings
