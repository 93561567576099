import React from 'react'
import { Tooltip } from 'antd'
import Theme from '../../utils/agilite-theme'
import EnumsTooltip from '../../utils/enums-tooltips'

const CompileTemplateIcon = () => {
  return (
    <Tooltip title={`${EnumsTooltip.general.compileTemplateIcon}`}>
      <span style={{ color: Theme.successColor }}><strong>{'{{}}'}</strong></span>
    </Tooltip>
  )
}

export default CompileTemplateIcon
