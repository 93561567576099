import React from 'react'
import { Icon } from 'react-fa'
import { Card, Button, Form, Row, Popconfirm, message, Menu, Dropdown, Col, Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import MemoryStore from '../../utils/memory-store'
import TeamUsersFormGeneral from './team-users-form-general'
import TeamUsersFormPrivileges from './team-users-form-privileges'
import Enums from '../../utils/enums'

class TeamUsersForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc,
      dialogDisableTeamUser: false,
      dialogEnableTeamUser: false,
      entry: Object.assign({}, this.entry),
      isLoading: false
    }

    this.saveProfile = this.saveProfile.bind(this)
    this.cancelProfileConfirm = this.cancelProfileConfirm.bind(this)
    this.cancelProfile = this.cancelProfile.bind(this)
    this.disableTeamUser = this.disableTeamUser.bind(this)
    this.enableTeamUser = this.enableTeamUser.bind(this)
    this.setPrivileges = this.setPrivileges.bind(this)
    this.handleGeneralChange = this.handleGeneralChange.bind(this)
    this.handlePrivilegesChange = this.handlePrivilegesChange.bind(this)
  }

  disableTeamUser (id) {
    const tmpThis = this
    let msg = 'User Successfully Disabled'

    tmpThis.props.onDisableTeamUser(id, function (err, result) {
      if (err) {
        msg = err
        message.error(msg)
      } else {
        tmpThis.entry.teamRights.isActive = false
        tmpThis.entry.teamRights.status = 'Inactive'
        tmpThis.saveProfile()
        message.success(msg)
      }
    })
  }

  enableTeamUser (id) {
    const tmpThis = this
    let msg = 'User Successfully Enabled'

    tmpThis.props.onEnableTeamUser(id, function (err, result) {
      if (err) {
        msg = err
        message.error(msg)
      } else {
        tmpThis.entry.teamRights.isActive = true
        tmpThis.entry.teamRights.status = 'Active'
        tmpThis.saveProfile()
        message.success(msg)
      }
    })
  }

  cancelProfileConfirm () {
    if (this.entry.custom.isModified) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  saveProfile () {
    const tmpThis = this

    tmpThis.setState({
      isLoading: true
    })

    // Reset isModified and isModifiedSubform properties
    this.entry.custom.isModified = false
    this.entry.custom.isModifiedSubform = false

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, (err) => {
      tmpThis.setState({
        isLoading: false
      })

      if (err) {
        message.error(err)
      } else {
        tmpThis.props.closeTab(tmpThis.props.tabKey, tmpThis.props.tabKey, tmpThis.props.tabs)
      }
    })
  }

  setPrivileges (value) {
    const tmpEntry = Object.assign({}, this.state.entry)

    Object.keys(tmpEntry.teamRights.privileges).forEach(key => {
      if (value === 'Reader') {
        switch (key) {
          case 'importexport':
          case 'dashboards':
            tmpEntry.teamRights.privileges[key] = 'Editor'
            break
          default:
            tmpEntry.teamRights.privileges[key] = value
            break
        }
      } else if (value === 'Editor') {
        tmpEntry.teamRights.privileges[key] = value
      } else {
        tmpEntry.teamRights.privileges[key] = ''
      }
    })

    this.setState({
      entry: tmpEntry
    })
  }

  handlePrivilegesChange (key, value) {
    const tmpEntry = Object.assign({}, this.state.entry)

    this.entry.custom.isModified = true
    this.entry.teamRights.privileges[key] = value
    tmpEntry.teamRights.privileges[key] = value

    this.setState({
      entry: tmpEntry
    })
  }

  handleGeneralChange (key, value) {
    const tmpEntry = Object.assign({}, this.state.entry)

    this.entry.custom.isModified = true
    if (key === 'role') {
      this.entry.teamRights[key] = value
      tmpEntry.teamRights[key] = value
    } else {
      this.entry[key] = value
      tmpEntry[key] = value
    }

    this.setState({
      entry: tmpEntry
    })
  }

  render () {
    const menu = (
      <Menu>
        <Menu.ItemGroup title='Default Privileges To'>
          <Menu.Item value='3' onClick={() => this.setPrivileges('Editor')}>
            <span style={{ padding: '10 0' }}>Editor</span>
          </Menu.Item>
          <Menu.Item value='4' onClick={() => this.setPrivileges('Reader')}>
            <span style={{ padding: '10 0' }}>Reader</span>
          </Menu.Item>
          <Menu.Item value='5' onClick={() => this.setPrivileges('None')}>
            <span style={{ padding: '10 0' }}>None</span>
          </Menu.Item>
        </Menu.ItemGroup>
        {!this.state.isNewDoc && MemoryStore.userProfile.teamPrivileges.teams !== 'Reader' && this.entry.status !== 'Pending' ?
          <Menu.ItemGroup title='Enable/Disable'>
            {this.entry.teamRights.status === 'Inactive' ?
              <Menu.Item value='2'>
                <Popconfirm
                  title={`Are you sure you want to enable Team User: ${this.entry.email}?`}
                  okText='Yes'
                  cancelText='No'
                  onConfirm={() => this.enableTeamUser(this.entry._id)}
                >
                  <span style={{ padding: '10 0' }}>Re-enable User</span>
                </Popconfirm>
              </Menu.Item>
              : null}

            {this.entry.teamRights.status === 'Active' ?
              <Menu.Item value='1' onClick={this.handleDisableTeamUserDialogOpen}>
                <Popconfirm
                  title={`Are you sure you want to disable Team User: ${this.entry.email}?`}
                  okText='Yes'
                  cancelText='No'
                  onConfirm={() => this.disableTeamUser(this.entry._id)}
                >
                  <span style={{ padding: '10 0' }}>Disable User</span>
                </Popconfirm>
              </Menu.Item>
              : null}
          </Menu.ItemGroup>
          : null}
      </Menu>
    )

    return (
      <Row type='flex' justify='center'>
        <Col xs={23} lg={20} xl={16}>
          <Card
            title={this.props.title}
            style={{ textAlign: 'left' }}
            extra={
              <Dropdown
                overlay={menu}
                trigger={['click']}
              >
                <Icon name='ellipsis-v' style={{ padding: 5, fontSize: 17, color: '#B4483E', cursor: 'pointer' }} />
              </Dropdown>
            }
            type='inner'
          >
            <Form
              autoComplete='off'
              style={{ textAlign: 'left' }}
            >
              <Row type='flex' justify='space-between'>
                <TeamUsersFormGeneral onFieldChange={this.handleGeneralChange} entry={this.state.entry} theme={this.props.theme} />
                <TeamUsersFormPrivileges onFieldChange={this.handlePrivilegesChange} entry={this.state.entry} theme={this.props.theme} />
              </Row>
              <Row>
                {MemoryStore.userProfile.teamPrivileges.teams !== 'Reader'
                  ? <Button
                    disabled={this.state.isLoading}
                    style={{ backgroundColor: '#67AD5B', color: 'white', width: 80, marginRight: 10 }}
                    onClick={() => this.saveProfile()}
                  >
                    {this.state.isLoading ? <LoadingOutlined spin /> : <span>Submit</span>}
                  </Button>
                  : null}
                <Button
                  label='Cancel'
                  disabled={this.state.isLoading}
                  style={{ backgroundColor: '#EB5181', color: 'white', width: 'auto', marginRight: 10 }}
                  onClick={e => {
                    e.preventDefault()
                    this.cancelProfileConfirm()
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default TeamUsersForm
