import React from 'react'
import MemoryStore from '../../../../utils/memory-store'
import { Row, Col, Select, Form } from 'antd'

class DestinationDetailsJson extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]
    this.onChange = this.onChange.bind(this)
  }

  onChange (key, value) {
    this.entry.custom.isModified = true
    this.entry.data.desJson[key] = value
  }

  render () {
    return (
      <Row>
        <Col xs={24} md={12}>
          <h4>JSON Details</h4>
          {this.entry.data.sourceType === '1' ? (
            <Form.Item>
              Output Type
              <Select
                className='output_type'
                disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                defaultValue={this.entry.data.desJson.outputType}
                fullWidth
                onChange={value => {
                  this.onChange('outputType', value)
                }}
              >
                <Select.Option value=''>-Select-</Select.Option>
                <Select.Option value='1'>Create JSON Object</Select.Option>
                <Select.Option value='2'>
                  Create JSON Object From Source
                </Select.Option>
              </Select>
            </Form.Item>
          ) : (
            <Form.Item>
              Output Type
              <Select
                className='output_type'
                disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                defaultValue={this.entry.data.desJson.outputType}
                fullWidth
                onChange={value => {
                  this.onChange('outputType', value)
                }}
              >
                <Select.Option value=''>-Select-</Select.Option>
                <Select.Option value='1'>Create JSON Object</Select.Option>
              </Select>
            </Form.Item>
          )}
        </Col>
      </Row>
    )
  }
}

export default DestinationDetailsJson
