import React from 'react'
import { Form, Input, Tooltip, Modal } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import AceEditor from 'react-ace'

import 'brace/mode/json'
import 'brace/theme/xcode'
import 'brace/ext/beautify'

import CompileTemplateIcon from '../../../../../../reusables/components/compile-template-icon'
import MemoryStore from '../../../../../../utils/memory-store'
import AgiliteTheme from '../../../../../../utils/agilite-theme'

class LDAPRouteModify extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry.routeData,
      changes: props.entry.routeData.changes || '',
      controls: props.entry.routeData.controls || '',
      modalVisible: false
    }

    // Bindings
    this.onFieldChange = this.onFieldChange.bind(this)
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value.trim()
  }

  render () {
    return (
      <div>
        <Form.Item>
          <span style={{ color: 'red' }}>* </span>
          {'DN '}
          <CompileTemplateIcon />
          <Input
            name='dn'
            placeholder='Distinguished name'
            disabled={this.props.disabled}
            defaultValue={this.state.entry.dn}
            onChange={e => {
              this.onFieldChange('dn', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          <h3>
            <span style={{ color: 'red' }}>* </span>
            Changes <CompileTemplateIcon />
            <Tooltip title='Help'>
              <QuestionCircleOutlined
                style={{ marginLeft: 5, color: AgiliteTheme.successColor, cursor: 'pointer' }}
                onClick={() => this.setState({ modalVisible: true })}
              />
            </Tooltip>
          </h3>
          <AceEditor
            showPrintMargin={false}
            readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            cursorStart={1}
            mode='json'
            theme='xcode'
            style={{ width: '100%', height: 300 }}
            name='aceEditor'
            value={this.state.changes}
            setOptions={{
              showLineNumbers: true,
              newLineMode: 'unix'
            }}
            onChange={value => {
              this.onFieldChange('changes', value)
              this.setState({ changes: value })
            }}
          />
        </Form.Item>
        <Form.Item>
          <h3>Controls <CompileTemplateIcon /></h3>
          <AceEditor
            showPrintMargin={false}
            readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            cursorStart={1}
            mode='json'
            theme='xcode'
            style={{ width: '100%', height: 300 }}
            name='aceEditor'
            value={this.state.controls}
            setOptions={{
              showLineNumbers: true,
              newLineMode: 'unix'
            }}
            onChange={value => {
              this.onFieldChange('controls', value)
              this.setState({ controls: value })
            }}
          />
        </Form.Item>
        <Modal
          okButtonProps={{
            hidden: true
          }}
          title='Help'
          onCancel={() => this.setState({ modalVisible: false })}
          cancelText='Close'
          visible={this.state.modalVisible}
          width='32%'
        >
          The value for Changes must resolve to a JSON Array. The below is an example of the structure of a JSON Array <br />
          <br />
          - The 'operation' property can only be a value of add/delete/replace <br />
          - The 'values' property must always resolve to an array of strings, even if it's just one value <br />
          - The 'type' property is the attribute name in the LDAP record <br />
          <AceEditor
            showPrintMargin={false}
            readOnly
            cursorStart={1}
            mode='json'
            theme='xcode'
            style={{ width: '100%', height: 500 }}
            name='aceEditor'
            value={`
              [
                {
                  "operation": "replace",
                  "modification": {
                    "type": "typeName",
                    "values": ["value1", "value2"]
                  }
                },
                {
                  "operation": "delete",
                  "modification": {
                    "type": "typeName",
                    "values": ["value1", "value2"]
                  }
                },
                {
                  "operation": "add",
                  "modification": {
                    "type": "typeName",
                    "values": ["value1", "value2"]
                  }
                }
              ]
            `}
            setOptions={{
              showLineNumbers: false,
              showGutter: false,
              newLineMode: 'unix'
            }}
          />
        </Modal>
      </div>
    )
  }
}

export default LDAPRouteModify
