import AgiliteTheme from '../utils/agilite-theme'
import Configurations from '../utils/config.json'
import Enums from '../utils/enums'

export default {
  app: Enums.APP_IDS.HOME, // TODO: We might not need this anymore
  facet: Enums.FACETS.LOADING,
  title: Configurations.appTitle,
  theme: AgiliteTheme,
  user: {
    loggedIn: false,
    signInClicked: false
  },
  tabObject: {
    tabs: [],
    activeKey: Enums.APP_IDS.HOME,
    activeApp: Enums.VALUES_STRINGS.EMPTY,
    tabType: Enums.VALUES_STRINGS.EMPTY
  }
}
