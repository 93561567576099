import React, { Component } from 'react'
import { Row, Col, Form, Input } from 'antd'

import GroupNameContainer from '../../reusables/containers/group-name-container'
import SolutionsContainer from '../../reusables/containers/solutions-container'
import TooltipIcon from '../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../utils/enums-tooltips'
import Description from '../../reusables/components/description'

import MemoryStore from '../../utils/memory-store'

class NodeRedFormGeneral extends Component {
  constructor (props) {
    super(props)

    this.entry = props.entry

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc
    }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.entry.data[key] = value
  }

  render () {
    return (
      <Row type='flex' justify='space-between'>
        <Col xs={24} lg={11}>
          <Form.Item>
            Status: {this.entry.data.isActive && !this.state.isNewDoc ? <b style={{ color: 'green' }}>Active</b> : <b style={{ color: 'red' }}>Disabled</b>}
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>{'Profile Key '}
            <TooltipIcon title={EnumsTooltips.general.profileKey} />
            <Input
              name='key'
              placeholder='Provide a unique Profile Key'
              disabled={!this.state.isNewDoc || MemoryStore.userProfile.teamPrivileges.nodered === 'Reader'}
              defaultValue={this.entry.data.key}
              onChange={(e) => {
                this.onFieldChange('key', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>{'Profile Name '}
            <TooltipIcon title={EnumsTooltips.general.profileName} />
            <Input
              name='name'
              placeholder='Provide a Profile Name'
              disabled={MemoryStore.userProfile.teamPrivileges.nodered === 'Reader'}
              defaultValue={this.entry.data.name}
              onChange={(e) => {
                this.onFieldChange('name', e.target.value)
              }}
            />
          </Form.Item>
          <Description
            disabled={MemoryStore.userProfile.teamPrivileges.nodered === 'Reader'}
            description={this.entry.data.description}
            onChange={(e) => {
              this.onFieldChange('description', e.target.value)
            }}
          />
          <GroupNameContainer
            disabled={MemoryStore.userProfile.teamPrivileges.nodered === 'Reader'}
            defaultValue={this.entry.data.groupName}
            onValueChange={(value) => {
              this.onFieldChange('groupName', value)
            }}
            onValueSelect={(value) => {
              this.onFieldChange('groupName', value)
            }}
          />
          <SolutionsContainer
            disabled={MemoryStore.userProfile.teamPrivileges.nodered === 'Reader'}
            defaultValue={this.entry.data.solutions}
            onValueChange={(value) => {
              this.onFieldChange('solutions', value)
            }}
          />
        </Col>
      </Row>
    )
  }
}

export default NodeRedFormGeneral
