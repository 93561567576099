import React from 'react'
import { Row, Col, Select, Form } from 'antd'

import SnowflakeRouteExecuteQry from './routes/snowflake-execute-query'
import MemoryStore from '../../../../../utils/memory-store'

class SnowflakeRouteMain extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.disabled = MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'
    this.state = { routeType: props.entry.routeData.routeType || '0' }

    // Bindings
    this.onChangeRouteType = this.onChangeRouteType.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
  }

  UNSAFE_componentWillMount () {
    if (!this.props.entry.routeData.routeType) {
      this.props.entry.routeData.routeType = '0'
    }
  }

  onChangeRouteType (e) {
    this.entry.custom.isModifiedSubform = true
    this.setState({ routeType: e })
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value
  }

  render () {
    return (
      <Row>
        <Col span={24}>
          <h3>Route Info</h3>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>Route Type
            <Select
              className='route_type'
              disabled={this.disabled}
              defaultValue={this.state.routeType}
              onChange={e => {
                this.onChangeRouteType(e)
                this.onFieldChange('routeType', e)
              }}
            >
              <Select.Option value=''>-Select-</Select.Option>
              <Select.Option value='0'>Execute Query</Select.Option>
            </Select>
          </Form.Item>
          {this.state.routeType === '0' ? (
            <SnowflakeRouteExecuteQry
              tabKey={this.props.tabKey}
              entry={this.props.entry}
              disabled={this.disabled}
            />
          ) : null}
        </Col>
      </Row>
    )
  }
}

export default SnowflakeRouteMain
