import { formatRecordData } from '../utils/utilities'
import MemoryStore from '../utils/memory-store'
import TierStructureFormContainer from '../tier-structures/containers/tier-structure-form-container'
import Enums from '../utils/enums'

export const filterViewData = (viewData, searchFilter) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return viewData
  } else {
    return viewData.filter(t =>
      (
        (t.data.key.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.description.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.values.filter(v =>
          (
            (v.label.toLowerCase().indexOf(searchFilter) > -1) ||
            (v.value.toLowerCase().indexOf(searchFilter) > -1)
          )
        )).length > 0
      )
    )
  }
}

export const filterTierLeveslsViewData = (data, searchFilter, callback) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return callback(data)
  } else {
    return callback(data.filter(t =>
      (
        (t.key.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.description.toLowerCase().indexOf(searchFilter) > -1)
      )
    ))
  }
}

export const formatTierEntriesActiveDescription = (entries) => {
  entries.map(entry => {
    entry.isActiveDescription = entry.isActive ? 'Yes' : 'No'

    if (entry.tierEntries.length > 0) {
      formatTierEntriesActiveDescription(entry.tierEntries)
    }

    return true
  })
}

export const formatRecord = (entry, data) => {
  let tempIndex = 0

  entry = formatRecordData(entry)
  entry.key = entry._id // Needed for View's Unique key requirement
  entry.data.isActiveDescription = entry.data.isActive ? 'Yes' : 'No' // Needed for View Column

  // Set isActiveDescription for nested tierEntries
  formatTierEntriesActiveDescription(entry.data.tierEntries)

  if (entry.custom.isNewDoc) {
    // Add new Entry to State
    entry.custom.isNewDoc = false
    entry.custom.action = 'create'
    data.push(entry)
  } else {
    if (entry.custom.isSavedDoc) {
      entry.custom.action = 'update'
    } else {
      entry.custom.action = 'create'
    }

    // Update Entry in State
    tempIndex = data.findIndex(t => t._id === entry._id)
    data[tempIndex] = entry
  }

  return data
}

export const cleanupData = (data) => {
  const tempArray = data.concat()
  let entry = null

  // First Strip empty values from array
  for (let x = 0, y = tempArray.length; x < y; x++) {
    entry = tempArray[x]

    if ((entry.label.trim() === '') && (entry.value.trim() === '')) {
      tempArray.splice(x, 1)
      x--
      y = tempArray.length
    }
  }

  // Next, trim labels and values
  for (const x in tempArray) {
    entry = tempArray[x]

    entry.label = entry.label.trim()
    entry.value = entry.value.trim()
  }

  return tempArray
}

export const createCopy = (currEntryData, callback) => {
  const newEntry = JSON.parse(JSON.stringify(MemoryStore.tierstructures.dataTemplate))
  newEntry._id = new Date().getTime().toString()
  newEntry.custom.tempId = newEntry._id

  const entryData = JSON.parse(JSON.stringify(currEntryData))
  const tabType = Enums.VALUES_STRINGS.FORM
  const content = TierStructureFormContainer
  const closable = true
  const key = newEntry._id
  const app = Enums.APP_IDS.TIER_STRUCTURES

  let title = Enums.APP_PROFILE_TITLES.tierstructures + Enums.VALUES_STRINGS.COLON + Enums.VALUES_STRINGS.SPACE
  let payload = {}

  // create unique key
  entryData.key = currEntryData.key + '_copy'

  newEntry.data = entryData

  title += entryData.key

  payload = {
    content,
    title,
    key,
    app,
    tabType,
    closable
  }

  MemoryStore.activeEntries[newEntry._id] = newEntry

  callback(payload)
}
