import React from 'react'
import { Tabs, Modal } from 'antd'
import CoreTabHome from './core-tab-home'

import MemoryStore from '../../utils/memory-store'
import Enums from '../../utils/enums'

const TabPane = Tabs.TabPane

class CoreLanding extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      activeKey: ''
    }

    this.handleOnEdit = this.handleOnEdit.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handlePromptTabClose = this.handlePromptTabClose.bind(this)
  }

  componentDidMount () {
    this.setState({ activeKey: this.props.activeKey })

    this.props.addTab({
      content: <CoreTabHome />,
      title: 'Home',
      key: Enums.APP_IDS.HOME,
      app: Enums.APP_IDS.HOME,
      tabType: Enums.VALUES_STRINGS.EMPTY,
      closable: false
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.activeKey !== this.props.activeKey) {
      this.setState({ activeKey: nextProps.activeKey })
    }

    if (nextProps.tabs.length === 0) {
      nextProps.addTab({
        content: <CoreTabHome />,
        title: 'Home',
        key: Enums.APP_IDS.HOME,
        app: Enums.APP_IDS.HOME,
        tabType: Enums.VALUES_STRINGS.EMPTY,
        closable: false
      })
    }
  }

  handleOnEdit (targetTab) {
    this.props.closeTab(this.state.activeKey, targetTab, this.props.tabs)
  }

  handleOnChange (key) {
    this.props.changeTab(key, this.props.tabs)
  }

  handlePromptTabClose (key) {
    let tmpValue = false

    // Loop through the activeEntries
    for (const x in MemoryStore.activeEntries) {
      // if the current tab key that was passed matches the activeEntry id
      if (key === MemoryStore.activeEntries[x]._id) {
        // if the activeEntry is modified
        if (MemoryStore.activeEntries[x].custom.isModified) {
          tmpValue = true
        }
      }
    }

    if (tmpValue) {
      Modal.confirm({
        onOk: () => this.handleOnEdit(key),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true,
        width: 500
      })
    } else {
      this.handleOnEdit(key)
    }
  }

  render () {
    return (
      <div className='App'>
        <Tabs
          type='editable-card'
          hideAdd
          activeKey={this.state.activeKey}
          onEdit={this.handlePromptTabClose}
          onChange={this.handleOnChange}
          tabBarStyle={{ textAlign: 'left' }}
          animated={false}
        >
          {this.props.tabs.map(pane => (
            <TabPane
              forceRender
              tab={pane.title}
              key={pane.key}
              closable={pane.closable}
            >
              {pane.content}
              <div style={{ width: '100%', height: 50 }}>
                {/* This is used to create spacing below cards when resolution is around 1280 x 720 */}
              </div>
            </TabPane>
          ))}
        </Tabs>
      </div>
    )
  }
}

export default CoreLanding
