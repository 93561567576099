import React from 'react'
import MemoryStore from '../../../utils/memory-store'

import { Switch, Input, Select, Row, Col, Form, Alert } from 'antd'

import Globals from '../../../utils/globals'
import GroupNameContainer from '../../../reusables/containers/group-name-container'
import SolutionsContainer from '../../../reusables/containers/solutions-container'
import TooltipIcon from '../../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../../utils/enums-tooltips'
import Description from '../../../reusables/components/description'
import Enums from '../../../utils/enums'

class ConnectorsFormGeneral extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      connectionType: this.entry.data.connectionType,
      isNewDoc: this.entry.custom.isNewDoc
    }
  }

  render () {
    return (
      <Row>
        <Col xs={24} sm={24} md={16} lg={12} xl={12} xxl={10}>
          <Form.Item>
            <Switch
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              checkedChildren='Active'
              unCheckedChildren='Inactive'
              defaultChecked={this.entry.data.isActive}
              onChange={e => {
                this.props.onChange('isActive', e)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Profile Key '}
            <TooltipIcon title={EnumsTooltips.general.profileKey} />
            <Input
              name='key'
              placeholder='Provide a unique Profile Key'
              required
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.entry.data.key}
              onChange={e => {
                this.props.onChange('key', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'Connection Name '}
            <TooltipIcon title={EnumsTooltips.general.profileName} />
            <Input
              name='name'
              placeholder='Provide a Connection Name'
              required
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.entry.data.name}
              onChange={e => {
                this.props.onChange('name', e.target.value)
              }}
            />
          </Form.Item>
          <Description
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            description={this.entry.data.description}
            onChange={e => {
              this.props.onChange('description', e.target.value)
            }}
          />
          <GroupNameContainer
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            defaultValue={this.entry.data.groupName}
            onValueChange={value => {
              this.props.onChange('groupName', value)
            }}
            onValueSelect={value => {
              this.props.onChange('groupName', value)
            }}
          />
          <SolutionsContainer
            disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
            defaultValue={this.entry.data.solutions}
            onValueChange={(value) => {
              this.props.onChange('solutions', value)
            }}
          />
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>Connection Type
            <Select
              className='connection_type'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              placeholder='Specify a Connection Type'
              defaultValue={this.entry.data.connectionType}
              required
              onChange={e => {
                this.props.onChange('connectionType', e)
                this.props.onConnectionTypeChange(e)
              }}
            >
              {Globals.connectorTypes.map(entry => (
                <Select.Option key={entry.value} value={entry.value}>
                  {entry.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {this.entry.data.connectionType && Enums.CONNECTORS_CONNECTION_DESCRIPTION[this.entry.data.connectionType]?.message ?
          <Col span={8} offset={4} style={{ marginTop: 70 }}>
            <Alert
              type='info'
              message={Enums.CONNECTORS_CONNECTION_DESCRIPTION[this.entry.data.connectionType].message}
              description={Enums.CONNECTORS_CONNECTION_DESCRIPTION[this.entry.data.connectionType].description}
            />
          </Col>
          : null}
      </Row>
    )
  }
}

export default ConnectorsFormGeneral
