import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resendVerification, initHomePage } from '../core-actions'
import CoreVerifyConfirm from '../components/core-verify-confirm'

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      resendVerification,
      initHomePage
    },
    dispatch
  )
}

export default connect(
  null,
  mapDispatchToProps
)(CoreVerifyConfirm)
