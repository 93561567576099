import { trimObjectValues } from 'agilite-utils'

import MemoryStore from '../utils/memory-store'
import { cleanupObject, updateArrayDataFromSave, isDuplicateEntry } from '../utils/utilities'
import { postData } from '../core/core-utils'
import { formatRecord } from './api-keys-utils'
import Enums from '../utils/enums'

/*
  ENUMS
*/
export const actions = {
  RESET_STATE: 'RESET_STATE_API_KEYS',
  CREATE_PROFILE: 'CREATE_PROFILE_API_KEYS',
  SAVE_PROFILE: 'SAVE_PROFILE_API_KEYS',
  CANCEL_PROFILE: 'CANCEL_PROFILE_API_KEYS',
  EDIT_PROFILE: 'EDIT_PROFILE_API_KEYS',
  DELETE_PROFILE: 'DELETE_PROFILE_API_KEYS',
  FETCH_ALL_DATA: 'FETCH_ALL_DATA_API_KEYS',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER_API_KEYS',
  UPDATE_DATA: 'UPDATE_DATA_API_KEYS'
}

/*
  DISPATCH FUNCTIONS
*/
export const setSearchFilter = value => {
  return {
    type: actions.SET_SEARCH_FILTER,
    value
  }
}

export const updateData = data => {
  return {
    type: actions.UPDATE_DATA,
    data
  }
}

export const saveProfile = (appId, recordId, state, callback) => {
  return async dispatch => {
    const entry = MemoryStore.activeEntries[recordId]
    const headers = {}
    let reqType = ''
    let tempArray = state.data.concat()
    let result = true
    let errMsg = ''

    // Cleanup and Trim
    cleanupObject(entry)
    await trimObjectValues(entry)

    // Validation
    if (entry.firstName === '') {
      errMsg = 'Please provide a Profile Key'
      return callback(errMsg)
    }

    // Duplicate Check
    result = isDuplicateEntry(entry, 'firstName', tempArray, false)

    if (!result) {
      errMsg = `API Profile Key '${entry.firstName}' is already being used`
      return callback(errMsg)
    }

    // Post Data to Agilit-e
    tempArray = formatRecord(entry, tempArray)
    reqType = entry.custom.action === 'create' ? 'post' : 'put'

    // Create Headers Object
    headers['record-id'] = recordId

    postData(appId, reqType, entry, headers)
      .then((response) => {
        // Return result to Tab
        callback(null)

        entry.custom.action = ''
        entry.custom.isSavedDoc = true
        entry._id = response.data._id

        entry.teamRights.isActiveDescription = response.data.teamRights.isActive ? 'Yes' : 'No'

        // Dispatch New Array
        tempArray = updateArrayDataFromSave(entry, tempArray)
        dispatch({ type: actions.UPDATE_DATA, data: tempArray })
      })
      .catch((error) => {
        console.log(error)

        if (error.response) {
          callback(error.response.data.errorMessage)
        } else {
          callback(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }
}
