import React from 'react'
import uniqueId from 'lodash/uniqueId'
import truncate from 'lodash/truncate'
import { v1 } from 'agilite-utils/uuid'
import MemoryStore from '../../utils/memory-store'
import TierEntryForm from './tier-entries/tier-entry-form'

import { Card, Row, Col, message, Steps, Modal } from 'antd'
import { cleanupData } from '../tier-structure-utils'
import Enums from '../../utils/enums'

const Step = Steps.Step

class TierStructureForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]
    this.tierPath = []
    this.privileges = MemoryStore.userProfile.teamPrivileges.tierstructures
    this.disabled = this.privileges === 'Reader'
    this.tierEntry = JSON.parse(JSON.stringify(this.entry.data))

    this.state = {
      steps: [],
      activeTab: '1',
      isFirstTier: true
    }

    this.updateTabs = this.updateTabs.bind(this)
    this.createTierEntry = this.createTierEntry.bind(this)
    this.saveProfile = this.saveProfile.bind(this)
    this.cancelProfileConfirm = this.cancelProfileConfirm.bind(this)
    this.cancelProfile = this.cancelProfile.bind(this)
    this.openTierEntry = this.openTierEntry.bind(this)
    this.updateState = this.updateState.bind(this)
    this.findTierEntryById = this.findTierEntryById.bind(this)
  }

  UNSAFE_componentWillMount () {
    const tmpArray = []
    const key = this.tierEntry.key !== '' ? this.tierEntry.key : 'New Tier'
    const id = '1'

    MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierHistory.push({ isModified: false })
    MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierMap.push(-1)

    tmpArray.push(
      <Step
        key={uniqueId()}
        description={key}
        status='process'
      />
    )

    this.tierPath.push({ id, key })
    this.setState({ steps: tmpArray })
  }

  updateTabs (value) {
    this.setState({ activeTab: value })
  }

  createTierEntry (tmpEntry) {
    let tierEntry = null
    let entryFound = false

    MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierHistory.push({ isModified: true })

    // First Validate Key before creating Entry
    if (tmpEntry.key.trim() === '') {
      return message.error('Please provide a Tier Key before continuing')
    }

    // If Root Entry, then update it directly, else write logic to update relevant Tier Entry
    if (this.tierPath.length === 1) {
      // Push index of newly created entry to tierMap
      MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierMap.push(this.entry.data.tierEntries.length - 1)

      this.entry.data = tmpEntry
    } else {
      // Use Tier Path to find relative entry from root entry
      tierEntry = this.findTierEntryById(this.entry.data)

      // By the time we get here, we have our current entry
      // Here we check to add/update the entry we're busy with
      entryFound = false

      for (const x in tierEntry.tierEntries) {
        if (tierEntry.tierEntries[x]._id === tmpEntry._id) {
          entryFound = true
          tierEntry.tierEntries[x] = tmpEntry

          // Push index of newly created entry to tierMap
          MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierMap.push(tierEntry.tierEntries[x].tierEntries.length)
          break
        }
      }

      if (!entryFound) {
        // Push index of newly created entry to tierMap
        MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierMap.push(tmpEntry.tierEntries.length)
        tierEntry.tierEntries.push(tmpEntry)
      }
    }

    // Create new Template and populate defaults
    tierEntry = JSON.parse(JSON.stringify(MemoryStore.tierstructures.tierEntriesTemplate))
    tierEntry.key = ''
    tierEntry.rowKey = v1()
    tierEntry._id = v1()

    // Update key in Tier Path and add new key
    this.tierPath[this.tierPath.length - 1].key = tmpEntry.key
    this.tierPath.push({
      id: tierEntry._id,
      key: 'New Tier'
    })

    this.updateState(tierEntry)
  }

  saveProfile (tmpEntry) {
    const tmpThis = this
    let tierEntry = null
    let entryFound = false
    let tmpObj = null
    let firstRun = true
    let tmpArray = null

    // Format Data
    tmpEntry.key = tmpEntry.key.split(' ').join('').toLowerCase().trim()

    // Submit Record if we're on the root entry
    if (tmpThis.tierPath.length === 1) {
      MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierMap.pop()

      // First CleanUp Values
      tmpEntry.values = cleanupData(tmpEntry.values)
      tmpThis.entry.data = tmpEntry

      // Delete unnecessary properties
      delete this.entry.data.isActiveDescription

      for (const x in this.entry.data.values) delete this.entry.data.values[x].key

      tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, tmpThis.props.state, (err) => {
        if (err) {
          message.error(err)
        } else {
          MemoryStore.activeEntries[tmpThis.props.tabKey].custom.tierStructuresCustom.tierHistory.pop()
          tmpThis.props.closeTab(tmpThis.props.tabKey, tmpThis.props.tabKey, tmpThis.props.tabs)
        }
      })
    } else {
      // Format Data
      tmpEntry.key = tmpEntry.key.split(' ').join('').toLowerCase().trim()

      if (!tmpEntry.key) {
        return message.error('Please provide a Tier Key')
      }

      // First CleanUp Values
      tmpEntry.values = cleanupData(tmpEntry.values)

      tmpArray = JSON.parse(JSON.stringify(MemoryStore.activeEntries[tmpThis.props.tabKey].custom.tierStructuresCustom.tierMap))
      for (const y in tmpArray) {
        if (firstRun) {
          if (MemoryStore.activeEntries[tmpThis.props.tabKey].custom.tierStructuresCustom.tierMap[parseInt(y)] !== -1) {
            tmpObj = MemoryStore.activeEntries[tmpThis.props.tabKey].data.tierEntries[MemoryStore.activeEntries[tmpThis.props.tabKey].custom.tierStructuresCustom.tierMap[parseInt(y)]]
            firstRun = false
          }
        } else {
          tmpObj = tmpObj.tierEntries[MemoryStore.activeEntries[tmpThis.props.tabKey].custom.tierStructuresCustom.tierMap[parseInt(y)]]
        }
      }

      tmpObj = tmpEntry

      // Use Tier Path to find relative entry from root entry
      tierEntry = this.findTierEntryById(this.entry.data)

      // By the time we get here, we have our current entry
      // Here we check to add/update the entry we're busy with
      entryFound = false

      for (const z in tierEntry.tierEntries) {
        if (tierEntry.tierEntries[z]._id === tmpEntry._id) {
          entryFound = true
          tierEntry.tierEntries[z] = tmpEntry
          break
        }
      }

      if (!entryFound) {
        tierEntry.tierEntries.push(tmpEntry)
      }

      MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierHistory.pop()
      MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierMap.pop()
      this.tierPath.pop()
      this.updateState(tierEntry)
    }
  }

  cancelProfileConfirm () {
    if (MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierHistory[MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierHistory.length - 1].isModified === true) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  cancelProfile () {
    let tierEntry = null

    MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierHistory.pop()
    MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierMap.pop()

    // Close Record if we're on the root entry
    if (this.tierPath.length === 1) {
      return this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
    }

    // Close current Tier Entry and open its parent
    tierEntry = this.findTierEntryById(this.entry.data)
    this.tierPath.pop()
    this.updateState(tierEntry)
  }

  openTierEntry (index) {
    let tmpObj = null
    let firstRun = true

    MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierHistory.push({ isModified: false })
    MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierMap.push(index)

    for (const x in MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierMap) {
      if (firstRun) {
        if (MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierMap[parseInt(x)] !== -1) {
          tmpObj = MemoryStore.activeEntries[this.props.tabKey].data.tierEntries[MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierMap[parseInt(x)]]
          firstRun = false
        } else {
          tmpObj = MemoryStore.activeEntries[this.props.tabKey].data.tierEntries[index]
        }
      } else {
        tmpObj = tmpObj.tierEntries[MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierMap[parseInt(x)]]
      }
    }

    // Add new key to Tier Path
    this.tierPath.push({
      id: tmpObj._id,
      key: tmpObj.key
    })
    this.updateState(tmpObj)
  }

  updateState (tierEntry) {
    const steps = []
    const activeTab = '1'

    let tmpStatus = 'wait'
    let isFirstTier = false

    if (this.tierPath.length === 1) {
      isFirstTier = true
    }

    // Update Steps
    for (const x in this.tierPath) {
      if (parseInt(x) === (this.tierPath.length - 1)) {
        tmpStatus = 'process'
      }

      steps.push(
        <Step
          key={this.tierPath[x].id}
          description={truncate(this.tierPath[x].key, { length: 13 })}
          status={tmpStatus}
        />
      )
    }

    // Finalize State
    this.tierEntry = tierEntry

    this.setState({
      steps,
      isFirstTier,
      activeTab
    })
  }

  findTierEntryById (tierEntry) {
    const findTierEntryByIdExtended = (tmpEntry, id) => {
      for (const x in tmpEntry.tierEntries) {
        if (tmpEntry.tierEntries[x]._id === id) {
          tierEntry = tmpEntry.tierEntries[x]
          return tierEntry
        }
      }
    }

    for (const x in this.tierPath) {
      if ((parseInt(x) > 0) && (parseInt(x) < (this.tierPath.length - 1))) {
        findTierEntryByIdExtended(tierEntry, this.tierPath[x].id)
      }
    }

    return tierEntry
  }

  render () {
    return (
      <Row justify='center' type='flex'>
        <Col lg={23}>
          <Card
            title={this.props.title}
            style={{ textAlign: 'left' }}
            type='inner'
          >
            <Card style={{ marginBottom: 10 }}>
              <Steps className='scroll-wrapper' size='small' labelPlacement='vertical'>
                {this.state.steps}
              </Steps>
            </Card>
            <Card>
              <TierEntryForm
                tabKey={this.props.tabKey}
                appId={this.props.appId}
                theme={this.props.theme}
                tierEntry={this.tierEntry}
                tierPath={this.tierPath}
                activeTab={this.state.activeTab}
                isFirstTier={this.state.isFirstTier}
                disabled={this.disabled}
                privileges={this.privileges}
                saveProfile={this.saveProfile}
                createTierEntry={this.createTierEntry}
                openTierEntry={this.openTierEntry}
                cancelProfile={this.cancelProfileConfirm}
                updateTabs={this.updateTabs}
              />
            </Card>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default TierStructureForm
