import React from 'react'
import { Table, Row, Col, Form, Input, Switch, message, Popconfirm, Spin } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import MemoryStore from '../../../../../utils/memory-store'
import AceEditor from 'react-ace'
import Dropzone from 'react-dropzone'
import configuration from '../../../../../utils/config.json'
import Enums from '../../../../../utils/enums'
import Axios from 'agilite-utils/axios'

import 'brace/mode/json'
import 'brace/theme/xcode'

import CompileTemplateIcon from '../../../../../reusables/components/compile-template-icon'
import { getFile } from '../../../../../core/core-utils'

class ConnectorsFormTypeLDAP extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.disabled = MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'
    this.state = {
      entry: props.entry || {},
      tlsOptions: props.entry.tlsOptions,
      attachments: [],
      loading: false,
      strictDn: true
    }

    // Bindings
    this.onFieldChange = this.onFieldChange.bind(this)
    this.formatAttachmentData = this.formatAttachmentData.bind(this)

    // Dropzone
    this.onUpload = this.onUpload.bind(this)
    this.onAttachmentUploaded = this.onAttachmentUploaded.bind(this)
    this.onAttachmentDelete = this.onAttachmentDelete.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.formatAttachmentData()

    switch (this.state.entry.strictDn) {
      case true:
        this.setState({ strictDn: true })
        break
      case false:
        this.setState({ strictDn: false })
        break
      default:
        this.setState({ strictDn: true })
        break
    }
  }

  onAttachmentDelete () {
    // There's only 1 attachment, so we can clear the array
    this.props.entry.certFileId = ''
    this.props.entry.certFileName = ''

    this.setState({ attachments: [] })
  }

  onUpload (fileName, contentType, data) {
    this.setState({ loading: true })

    Axios.request({
      baseURL: `${configuration.apiServerUrl}/files`,
      method: Enums.REQ_TYPE.POST,
      headers: {
        'persist-file': true,
        'api-key': MemoryStore.apiKey,
        'team-name': MemoryStore.userProfile.teamId,
        'file-name': fileName,
        'Content-Type': contentType
      },
      data
    })
      .then(res => {
        this.onAttachmentUploaded(res)
        this.setState({ loading: false })
      })
      .catch(err => {
        this.setState({ loading: false })

        if (err.response) {
          message.error(err.response.data.errorMessage)
        } else {
          message.error(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }

  onAttachmentUploaded (res) {
    this.entry.custom.isModified = true

    if (res) {
      setTimeout(() => {
        this.props.entry.certFileId = res.data._id
        this.props.entry.certFileName = res.data.filename
        this.formatAttachmentData()
      })
      return null
    }
  }

  formatAttachmentData () {
    if (this.state.entry.certFileId && this.state.entry.certFileName) {
      this.setState({ attachments: [{ key: this.state.entry.certFileId, name: this.state.entry.certFileName }] })
    }
  }

  getDropzone ({ handleAttachmentUpload, tmpThis }) {
    return (
      <Dropzone
        disabled={tmpThis.disabled}
        multiple={false}
        maxSize={configuration.dropZoneConfig.import.maxFileSize}
        onDrop={files => {
          // eslint-disable-next-line
          const reader = new FileReader()

          reader.onabort = () => message.error('File reading was aborted')
          reader.onerror = () => message.error('File reading has failed')
          reader.onload = () => {
            handleAttachmentUpload(files[0].name, Enums.VALUES_STRINGS.APPLICATION_OCTET_STREAM, reader.result)
          }
          if (files.length > 0) {
            reader.readAsArrayBuffer(files[0])
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          </section>
        )}
      </Dropzone>
    )
  }

  onFieldChange (key, value) {
    this.entry.custom.isModified = true
    this.props.entry[key] = value
  }

  render () {
    const columns = [
      {
        title: 'Attachments',
        dataIndex: 'attachment',
        key: 'attachments',
        render: (text, record) => {
          return (
            <span
              onClick={() => {
                getFile(record.key, record.name)
              }}
            >
              {/* eslint-disable-next-line */}
              <a>{record.name}</a>
            </span>
          )
        }
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text, record) => {
          if (!this.disabled) {
            return (
              <div className='trash-button'>
                <Popconfirm
                  title='Are you sure delete this attachment?'
                  onConfirm={() => this.onAttachmentDelete()}
                  okText='Yes'
                  cancelText='No'
                >
                  {/* eslint-disable-next-line */}
                  <a>
                    <DeleteOutlined
                      style={{ color: this.props.theme.dangerColor }}
                    />
                  </a>
                </Popconfirm>
              </div>
            )
          }
        }
      }
    ]

    return (
      <Row>
        <Col xs={24} lg={16}>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'LDAP URL'}
            <Input
              name='url'
              placeholder='e.g. ldap://ldap.acme.com or ldap://ldap.acme.com:port'
              disabled={this.disabled}
              defaultValue={this.state.entry.url}
              onChange={e => {
                this.onFieldChange('url', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Bind DN '} <CompileTemplateIcon />
            <Input
              name='bindDN'
              placeholder='Bind distinguished name'
              disabled={this.disabled}
              defaultValue={this.state.entry.bindDN}
              onChange={e => {
                this.onFieldChange('bindDN', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Password '} <CompileTemplateIcon />
            <Input
              name='password'
              placeholder='DN password'
              disabled={this.disabled}
              defaultValue={this.state.entry.password}
              autoComplete='new-password'
              type='password'
              onChange={e => {
                this.onFieldChange('password', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Timeout '}
            <Input
              name='timeout'
              placeholder='Timeout'
              disabled={this.disabled}
              defaultValue={this.state.entry.timeout}
              onChange={e => {
                this.onFieldChange('timeout', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            {'Strict DN '}
            <Switch
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultChecked={this.state.strictDn}
              onChange={e => {
                this.onFieldChange('strictDn', e)
              }}
            />
          </Form.Item>
          <Form.Item>
            <h3>TLS Options</h3>
            <AceEditor
              showPrintMargin={false}
              readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              cursorStart={1}
              mode='json'
              theme='xcode'
              style={{ width: '100%', height: 300 }}
              name='aceEditor'
              value={this.state.tlsOptions}
              setOptions={{
                showLineNumbers: true,
                newLineMode: 'unix'
              }}
              onChange={value => {
                this.onFieldChange('tlsOptions', value)
                this.setState({ tlsOptions: value })
              }}
            />
          </Form.Item>
          {'TLS Certificate'}
          {this.state.attachments.length === 0 ? (
            <this.getDropzone handleAttachmentUpload={this.onUpload} tmpThis={this} />
          ) : (
            <Table
              bordered
              pagination={false}
              columns={columns}
              dataSource={this.state.attachments}
              size='middle'
            />
          )}
          <div style={{ margin: 10 }}>
            {this.state.loading ?
              <Spin />
              : null}
          </div>
        </Col>
      </Row>
    )
  }
}

export default ConnectorsFormTypeLDAP
