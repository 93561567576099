import React from 'react'
import { Input, Form, Row, Col } from 'antd'
import MemoryStore from '../../../utils/memory-store'

class MultiEntryFormJson extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry
    }
  }

  onFieldChange (key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry[key] = value
  }

  render () {
    return (
      <Row style={{ marginTop: 55 }}>
        <Col span={24}>
          <Form name='multi-entry-form-json'>
            <Form.Item>
              Provide a path to the Json Array
              <Input
                name='json_path'
                placeholder='Json Path'
                disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
                defaultValue={this.props.entry.path}
                onChange={(e) => {
                  this.onFieldChange('path', e.target.value)
                }}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default MultiEntryFormJson
